import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { ButtonBase } from '../../primitives'
import type { CSS } from '../../stitches'
import { styled, theme } from '../../stitches'
import { Spinner, type SpinnerProps } from '../spinner'

function getSpinnerSize(buttonSize: IconButtonProps['size']): SpinnerProps['size'] {
  switch (buttonSize) {
    case 40:
    case 32:
      return 24
    case 24:
    case 16:
      return 16
  }
}

const interactiveBackgroundStyle: CSS = {
  '&:hover': {
    backgroundColor: theme.colors['grey-5'],
  },
  '&:active, &[aria-pressed="true"]': {
    backgroundColor: theme.colors['grey-10'],
  },
  '&:disabled, &[aria-disabled="true"]': {
    backgroundColor: 'unset',
    '&[data-loading="true"]': {
      backgroundColor: theme.colors['grey-10'],
    },
  },
}

const colorSurfaceStyle: CSS = {
  color: theme.colors['white-alpha-60'],
  '&:hover': {
    color: theme.colors['white-alpha-80'],
  },
  '&:active, &[aria-pressed="true"]': {
    color: theme.colors['white'],
  },
  '&:disabled, &[aria-disabled="true"]': {
    color: theme.colors['white-alpha-50'],
  },
}

const contrastOnBlackSurfaceStyle: CSS = {
  color: theme.colors['white'],
  '&:disabled, &[aria-disabled="true"]': {
    color: theme.colors['white-alpha-50'],
  },
}

const colorSurfaceInteractiveBackgroundStyle: CSS = {
  '&:hover': {
    backgroundColor: theme.colors['white-alpha-50'],
  },
  '&:active, &[aria-pressed="true"]': {
    backgroundColor: theme.colors['white-alpha-60'],
  },
  '&:disabled, &[aria-disabled="true"]': {
    backgroundColor: 'unset',
    '&[data-loading="true"]': {
      backgroundColor: theme.colors['white-alpha-60'],
    },
  },
}

const IconButtonBase = styled(ButtonBase, {
  display: 'grid',
  placeItems: 'center',
  borderRadius: theme.radii[8],
  variants: {
    surface: {
      default: {},
      black: {},
      blue: {},
    },
    size: {
      16: { size: 16 },
      24: { size: 24 },
      32: { size: 32 },
      40: { size: 40 },
    },
    color: {
      default: {
        color: theme.colors['black-alpha-40'],
        '&:hover': {
          color: theme.colors['black-alpha-60'],
        },
        '&:active, &[aria-pressed="true"]': {
          color: theme.colors['black-alpha-80'],
        },
        '&:disabled, &[aria-disabled="true"]': {
          color: theme.colors['black-alpha-30'],
        },
      },
      contrast: {
        color: theme.colors['black'],
        '&:disabled, &[aria-disabled="true"]': {
          color: theme.colors['black-alpha-30'],
        },
      },
      accent: {
        color: theme.colors['dark-blue'],
      },
    },
  },
  compoundVariants: [
    {
      color: 'default',
      surface: 'black',
      css: colorSurfaceStyle,
    },
    {
      color: 'contrast',
      surface: 'black',
      css: contrastOnBlackSurfaceStyle,
    },
    {
      color: 'default',
      surface: 'blue',
      css: colorSurfaceStyle,
    },
    {
      color: 'contrast',
      surface: 'blue',
      css: contrastOnBlackSurfaceStyle,
    },
    {
      size: 40,
      color: 'default',
      css: interactiveBackgroundStyle,
    },
    {
      size: 40,
      color: 'contrast',
      css: interactiveBackgroundStyle,
    },
    {
      size: 40,
      color: 'default',
      surface: 'black',
      css: colorSurfaceInteractiveBackgroundStyle,
    },
    {
      size: 40,
      color: 'contrast',
      surface: 'black',
      css: colorSurfaceInteractiveBackgroundStyle,
    },
    {
      size: 40,
      color: 'default',
      surface: 'blue',
      css: colorSurfaceInteractiveBackgroundStyle,
    },
    {
      size: 40,
      color: 'contrast',
      surface: 'blue',
      css: colorSurfaceInteractiveBackgroundStyle,
    },
  ],
  defaultVariants: {
    surface: 'primary',
    color: 'default',
    size: 40,
  },
})

export interface IconButtonProps extends React.ComponentProps<typeof IconButtonBase> {
  loading?: boolean
}

export const IconButton: GenericForwardRefComponent<IconButtonProps> = forwardRef(
  function IconButtonRef({ size = 40, loading, children, ...rest }, forwardedRef) {
    return (
      <IconButtonBase
        ref={forwardedRef}
        type="button"
        size={size}
        data-loading={loading}
        disabled={loading || rest.disabled}
        {...rest}
      >
        {loading ? <Spinner size={getSpinnerSize(size)} /> : children}
      </IconButtonBase>
    )
  }
)
