import { useSyncedRef } from '@react-hookz/web'
import { useState, useEffect } from 'react'

interface UsePageDropzoneProps {
  onFileDrop: (files: File[], event: DragEvent) => void
  root?: HTMLElement | null
}

export function usePageDropzone({ root, onFileDrop }: UsePageDropzoneProps) {
  const onFileDropRef = useSyncedRef(onFileDrop)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const onDragEnter = () => {
      setIsDragging(true)
    }

    const onDragLeave = (event: DragEvent) => {
      if (
        event.currentTarget instanceof Node &&
        event.relatedTarget instanceof Node &&
        event.currentTarget.contains(event.relatedTarget)
      ) {
        // fix event firing from children (https://reactjs.org/docs/events.html#detecting-focus-entering-and-leaving)
        return
      }
      setIsDragging(false)
    }

    const onDragOver = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(true)
    }

    const onDrop = (event: DragEvent) => {
      event.preventDefault()
      setIsDragging(false)
      if (
        event.dataTransfer &&
        event.dataTransfer.files &&
        event.dataTransfer.files.length > 0
      ) {
        onFileDropRef.current(Array.from(event.dataTransfer.files), event)
        return
      }
    }

    if (typeof window === 'undefined') {
      return
    }
    const container = root || (document.getElementById('root') ?? document.body)

    container.addEventListener('dragenter', onDragEnter)
    container.addEventListener('dragleave', onDragLeave)
    container.addEventListener('dragover', onDragOver)
    container.addEventListener('drop', onDrop)

    return () => {
      container.removeEventListener('dragenter', onDragEnter)
      container.removeEventListener('dragleave', onDragLeave)
      container.removeEventListener('dragover', onDragOver)
      container.removeEventListener('drop', onDrop)
    }
  }, [onFileDropRef, root])

  return { isDragging }
}
