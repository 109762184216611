import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Slot } from '@radix-ui/react-slot'
import { styled, theme } from '../../stitches'
import { TabsLink } from './tabs-link'

const TabsBase = styled(NavigationMenu.List, {
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 'max-content',
  columnGap: theme.space[16],
  '@bp1': { columnGap: theme.space[24] },
  variants: {
    variant: {
      default: {
        paddingBlock: theme.space[8],
        paddingInline: theme.space[8],
        '@bp1': { paddingInline: theme.space[0] },
      },
      document: {
        height: '72px',
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

interface TabsItemProps extends React.ComponentProps<typeof TabsLink> {
  asChild?: boolean
}

const TabsItem = ({ asChild, ...rest }: TabsItemProps) => (
  <NavigationMenu.Item asChild>
    <TabsLink {...rest} as={asChild ? Slot : undefined} />
  </NavigationMenu.Item>
)

interface TabsProps extends React.ComponentProps<typeof TabsBase> {}

function Tabs(props: TabsProps) {
  return (
    <NavigationMenu.Root asChild>
      <TabsBase {...props} />
    </NavigationMenu.Root>
  )
}

export { Tabs as Root, TabsItem as Item }
