import type { LoadOptions } from '@rudderstack/analytics-js'
import { RudderAnalytics } from '@rudderstack/analytics-js'

const rudderAnalytics = new RudderAnalytics()

/**
 * if URL doesn't contain the id, Rudderstack will generate one and set to storage.
 */
function populateAnonymousId() {
  if (typeof window === 'undefined') {
    return
  }
  const rudderIdFromQuery = new URLSearchParams(window.location.search).get('rudderId')
  if (rudderIdFromQuery) {
    rudderAnalytics.setAnonymousId(rudderIdFromQuery)
  }
}

function initRudderAnalytics(loadOptions?: Partial<LoadOptions>) {
  rudderAnalytics.load(
    process.env.RUDDERSTACK_PUBLIC_WRITE_KEY,
    'https://sayhelloyfposy.dataplane.rudderstack.com',
    {
      storage: { type: 'localStorage' },
      plugins: ['XhrQueue', 'StorageEncryption'],
      ...loadOptions,
    }
  )
  populateAnonymousId()
}

export { rudderAnalytics, initRudderAnalytics }
