import { useEffect, useState, type RefObject } from 'react'

export const useResizeObserver = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<DOMRect | null>(null)

  useEffect(() => {
    const element = ref.current
    if (!element) {
      return
    }

    const observer = new ResizeObserver(([entry]) => {
      setSize(entry.contentRect)
    })

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return size
}
