export const Document = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3V14.5C21 16.3826 20.7408 17.9018 20.1308 19.0202C19.4492 20.2698 18.3192 21 17 21H5H3V19V17H4.66961C4.81014 16.6732 5 15.936 5 14.5V3H21ZM5 19H17C18 19 19 18 19 14.5V5H7V14.5C7 15.5415 6.91144 16.3617 6.76068 17C6.40483 18.5066 5.70241 19 5 19ZM17 7H9V9H17V7ZM13 11H9V13H13V11Z"
      fill="currentColor"
    />
  </svg>
)
