import countryCodes from 'api/mocks/countries'
import { useFormatCountryCode } from 'kitchen/hooks/use-format-country-code'
import { cdn } from 'kitchen/utils/helpers'
import { useMemo } from 'react'
import { SUPPORTED_COUNTRY_CALLING_CODES_MAP } from '../constants'
import type { PhoneCountryCallingCode } from '../types'
import { getPhoneCountryCallingCodeId } from '../utils'

export function usePhoneCountryCallingCodes(): PhoneCountryCallingCode[] {
  const formatCountryCode = useFormatCountryCode()

  return useMemo(() => {
    return countryCodes
      .filter((code) => {
        return Boolean(SUPPORTED_COUNTRY_CALLING_CODES_MAP[code])
      })
      .map((code) => {
        const supportedCallingCode = SUPPORTED_COUNTRY_CALLING_CODES_MAP[code]

        return {
          id: getPhoneCountryCallingCodeId(code, supportedCallingCode),
          callingCode: supportedCallingCode,
          countryCode: code,
          countryImage: cdn(`/flags/${code.toLowerCase()}.svg`),
          countryName: formatCountryCode(code) || null,
        }
      })
  }, [formatCountryCode])
}
