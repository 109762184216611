import type { ApiRequest } from 'kitchen/types'
import { isNotFoundError } from 'kitchen/utils/error'
import { getRequiredUserSettings } from 'kitchen/utils/users'
import type {
  UserSettings,
  UserSettingsResponse,
  UpdateUserSettingsPayload,
} from '../types/user-settings'

export const getUserSettings: ApiRequest<void, UserSettings> = (fetch, signal) =>
  fetch
    .get(`/profile/settings`, { signal })
    .json<UserSettingsResponse>()
    .catch((error) => {
      if (isNotFoundError(error)) {
        // handle 404 as a valid response
        return {}
      }
      throw error
    })
    .then(getRequiredUserSettings)

export const updateUserSettings: ApiRequest<UpdateUserSettingsPayload, void> = (
  fetch,
  payload
) => fetch.post(`/profile/settings`, { json: payload }).void()
