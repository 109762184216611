import { useSyncedRef } from '@react-hookz/web'
import { useState, useCallback } from 'react'
import { noop } from '../utils/helpers'

export interface ControlledCallback<Value> {
  (next: Value): void
}

export interface ControlledOptions<Value> {
  defaultValue: Value
  value?: Value
  onChange?: ControlledCallback<Value>
}

export function useControlled<Value>({
  defaultValue,
  value,
  onChange = noop,
}: ControlledOptions<Value>): [value: Value, update: ControlledCallback<Value>] {
  const [state, setState] = useState<Value>(defaultValue)
  const isControlled = value !== undefined

  const changeCallbackRef = useSyncedRef(onChange)
  const handleChange: ControlledCallback<Value> = useCallback(
    (next) => {
      if (!isControlled) {
        setState(next)
      }

      changeCallbackRef.current(next)
    },
    [isControlled, changeCallbackRef]
  )

  return [isControlled ? value : state, handleChange]
}
