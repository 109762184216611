import * as DialogBase from '@radix-ui/react-dialog'
import { FocusScope } from '@radix-ui/react-focus-scope'
import { useControlled } from 'kitchen/hooks/use-controlled'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import { useMemo, forwardRef } from 'react'
import { DialogContext, type DialogContextValue } from '../../context/dialog-context'
import * as Icons from '../../icons'
import { keyframes, styled, theme } from '../../stitches'
import { GlobalBlur } from '../global-blur'
import { NavigationButton } from '../navigation-button'

interface RenderDialogChildren {
  (context: DialogContextValue): React.ReactNode
}

function useDialogContext() {
  return useRequiredContext('Dialog', DialogContext)
}

export interface DialogProps extends DialogBase.DialogProps {}

function Dialog({ defaultOpen = false, open, onOpenChange, ...rest }: DialogProps) {
  const [isOpen, setOpen] = useControlled<boolean>({
    defaultValue: defaultOpen,
    value: open,
    onChange: onOpenChange,
  })

  const contextValue = useMemo(() => ({ isOpen: isOpen, setOpen }), [isOpen, setOpen])

  return (
    <DialogContext.Provider value={contextValue}>
      <DialogBase.Root open={isOpen} onOpenChange={setOpen} {...rest} />
    </DialogContext.Provider>
  )
}

export interface DialogTriggerProps extends React.ComponentProps<typeof DialogTrigger> {}

const DialogTrigger = styled(DialogBase.Trigger)

/** @private */
const DialogContentBase = styled(DialogBase.Content, {
  gridArea: 'content',
  variants: {
    variant: {
      448: { width: '100%', maxWidth: '448px', justifySelf: 'center' },
      512: { width: '100%', maxWidth: '512px', justifySelf: 'center' },
      600: { width: '100%', maxWidth: '600px', justifySelf: 'center' },
      648: { width: '100%', maxWidth: '648px', justifySelf: 'center' },
      848: { width: '100%', maxWidth: '848px', justifySelf: 'center' },
      1056: { width: '100%', maxWidth: '1056px', justifySelf: 'center' },
      sidebar: { height: '100%', width: 'min-content' },
      'full-screen': { size: '100%' },
    },
  },
  defaultVariants: {
    variant: 512,
  },
})

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
})

/** @private */
const DialogOverlay = styled(DialogBase.Overlay, {
  position: 'fixed',
  inset: 0,
  variants: {
    variant: {
      default: {
        backdropFilter: 'blur(2px)',
        backgroundColor: theme.colors['dialog-overlay-background'],
        '&[data-state="open"]': {
          animation: `${fadeIn} 0.15s ease-out`,
        },
        '&[data-state="closed"]': {
          animation: `${fadeOut} 0.15s ease-out`,
        },
        display: 'grid',
        padding: theme.space[16],
        overflowY: 'auto',
        gridTemplate: `
          '.       close  ' 64px
          'content content' auto
          '.       .      ' 1fr / auto 48px
        `,
        '@bp2': {
          padding: theme.space[32],
          gridTemplate: `
            '. .    .       close .' 80px
            '. .    content .     .' auto
            '. .    .       .     .' 1fr / 1fr 48px minmax(auto, 1056px) 48px 1fr
          `,
        },
      },
      sidebar: {
        backdropFilter: 'blur(2px)',
        backgroundColor: theme.colors['dialog-overlay-background'],
        '&[data-state="open"]': {
          animation: `${fadeIn} 0.15s ease-out`,
        },
        '&[data-state="closed"]': {
          animation: `${fadeOut} 0.15s ease-out`,
        },
      },
      'full-screen': {},
    },
    center: {
      true: {
        '@bp2': {
          gridTemplate: `
            '. .    .       .     .' 1fr
            '. .    .       close .' 80px
            '. .    content .     .' auto
            '. .    .       .     .' 80px
            '. .    .       .     .' 1fr / 1fr 48px minmax(auto, 1056px) 48px 1fr
          `,
        },
      },
    },
  },
})

type DialogContentVariant = 448 | 512 | 600 | 648 | 848 | 1056 | 'sidebar' | 'full-screen'

function getOverlayVariant(variant: DialogContentVariant | undefined) {
  switch (variant) {
    case 'full-screen':
    case 'sidebar':
      return variant
    default:
      return 'default'
  }
}

const DialogClose = styled(DialogBase.Close)

export interface DialogTitleProps extends React.ComponentProps<typeof DialogTitle> {}

const DialogTitle = styled(DialogBase.Title, { font: 'unset' })

export interface DialogDescriptionProps
  extends React.ComponentProps<typeof DialogDescription> {}

const DialogDescription = styled(DialogBase.Description, { font: 'unset' })

export interface DialogContentProps
  extends Omit<React.ComponentProps<typeof DialogContentBase>, 'children'> {
  variant?: DialogContentVariant
  children?: RenderDialogChildren | React.ReactNode
  center?: boolean
  closable?: boolean
  onClose?: () => void
}

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  function DialogContent(
    { children, variant, closable = true, center = false, onClose, ...rest },
    forwardedRef
  ) {
    const context = useDialogContext()
    return (
      <DialogBase.Portal>
        <DialogOverlay
          variant={getOverlayVariant(variant)}
          center={center}
          ref={forwardedRef}
        >
          <GlobalBlur />
          {closable && variant !== 'full-screen' && variant !== 'sidebar' && (
            <DialogClose asChild onClick={onClose}>
              <NavigationButton
                aria-label="Close"
                variant="overlay"
                css={{ gridArea: 'close' }}
              >
                <Icons.S32.Cross />
              </NavigationButton>
            </DialogClose>
          )}
          <FocusScope trapped={false} loop asChild>
            <DialogContentBase
              variant={variant}
              onInteractOutside={(event) => {
                const isReportFeedback =
                  event.target instanceof Element &&
                  ['sentry-feedback', 'bug-report-button'].includes(event.target.id)

                const isIntercom =
                  event.target instanceof HTMLElement &&
                  event.target.classList.contains('intercom-lightweight-app')

                if (isReportFeedback || isIntercom) {
                  event.preventDefault()
                }
              }}
              forceMount
              {...rest}
            >
              {typeof children === 'function' ? children(context) : children}
            </DialogContentBase>
          </FocusScope>
        </DialogOverlay>
      </DialogBase.Portal>
    )
  }
)

export {
  Dialog as Root,
  DialogTrigger as Trigger,
  DialogClose as Close,
  DialogContent as Content,
  DialogTitle as Title,
  DialogDescription as Description,
  useDialogContext as useContext,
}
