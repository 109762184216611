export const ADVANCED_BOOKKEEPING = 'advanced-bookkeeping'
export const ALL = '*'
export const APPROVED = 'approved'
export const ARCHIVED = 'archived'
export const AUDIT_LOG = 'audit-log'
export const AUTH = 'auth'
export const BANK_DETAILS = 'bank-details'
export const BANK_FEED = 'bank-feed'
export const BATCH = 'batch'
export const BILLING = 'billing'
export const BOOKKEEPING = 'bookkeeping'
export const CALLBACK = 'callback'
export const CANCEL = 'cancel'
export const CANCELLED = 'cancelled'
export const CARD_CHALLENGE = 'card-challenge'
export const COMMENTS = 'comments'
export const COMMENT_ATTACHMENT = 'comment-attachment'
export const COMPANIES = 'companies'
export const COMPANY = 'company'
export const COMPANY_ID = ':companyId'
export const COMPANY_SYNC = 'company-sync'
export const CONNECT = 'connect'
export const CONTACTS = 'contacts'
export const CONTACT_ID = ':contactId'
export const CUSTOMERS = 'customers'
export const CUSTOMER_RULES = 'customer-rules'
export const DASHBOARD = 'dashboard'
export const DELETED = 'deleted'
export const DETAILS = 'details'
export const DIRECT_EMAILS = 'direct-emails'
export const DOCUMENTS = 'documents'
export const DOCUMENT_ID = ':documentId'
export const EMAIL = 'email'
export const EMAIL_CHAIN = 'email-chain'
export const EXPENSES = 'expenses'
export const FAILED_TO_APPROVE = 'failed-to-approve'
export const FAILED_TO_DELETE = 'failed-to-delete'
export const GENERAL = 'general'
export const HUB = 'hub'
export const IDENTITIES = 'identities'
export const INBOX = 'inbox'
export const INVITE = 'invite'
export const INVOICES = 'invoices'
export const INVOICE_ID = ':invoiceId'
export const INVOICE_SOURCE = 'invoice-source'
export const MANAGE = 'manage'
export const MANUAL_PAYMENT = 'pay-manually'
export const MEMBERS = 'members'
export const NEW = 'new'
export const ONBOARDING = 'onboarding'
export const OPTIONAL_PAYMENT_ID = `:paymentId?`
export const PAID = 'paid'
export const PAY = 'pay'
export const PAYER_ID = ':payerId'
export const PAYMENT = 'payment'
export const PAYMENTS = 'payments'
export const PAYMENT_ID = ':paymentId'
export const PAYRUN_ID = ':payrunId'
export const PENDING = 'pending'
export const PLAN = 'plan'
export const PLANS = 'plans'
export const PLAN_ID = ':planId'
export const PRACTICE = 'practice'
export const PROFILE = 'profile'
export const PUBLISHED = 'published'
export const REMITTANCE_ADVICE = 'remittance-advice'
export const REVIEW = 'review'
export const REVIEW_PAYMENT_LINK = 'review-payment-link'
export const RULES = 'rules'
export const SALES_DOCUMENTS = 'sales-documents'
export const SECURITY = 'security'
export const SELECT = 'select'
export const SETTINGS = 'settings'
export const SETUP = 'setup'
export const SETUP_2FA = 'setup-2fa'
export const SETUP_PASSWORD = 'setup-password'
export const SOURCE = ':source'
export const STATUS = ':status'
export const SUBSCRIBE = 'subscribe'
export const SUBSCRIPTION = 'subscription'
export const SUCCESS = 'success'
export const SUPPLIERS = 'suppliers'
export const SUPPLIER_RULES = 'supplier-rules'
export const SYNC_MODE = 'sync-mode'
export const TEAM = 'team'
export const TRANSACTION_ID = ':transactionId'
export const USER = 'user'
export const USER_ID = ':userId'
export const VERIFY = 'verify'
export const WORKFLOW = 'workflow'
