export const Manual = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.25V19.75H19V17.182L21 15.182V20.25C21 21.0784 20.3284 21.75 19.5 21.75H4.5C3.67157 21.75 3 21.0784 3 20.25V3.75C3 2.92157 3.67157 2.25 4.5 2.25H19.5C20.3284 2.25 21 2.92157 21 3.75L21 5.81802L19 7.81801V4.25H5ZM14.25 17.25C14.625 17.25 15.75 17.25 16.125 16.875L22.5 10.5L21 9L14.625 15.375C14.25 15.75 14.25 16.875 14.25 17.25ZM9.5 6.75H7.5V7V8.75V9H9.5H14.5H16.5V8.75V7V6.75H14.5H9.5ZM7.5 11.25H9.5H10.75H12.75V11.5V13.25V13.5H10.75H9.5H7.5V13.25V11.5V11.25Z"
      fill="currentColor"
    />
  </svg>
)
