import type {
  FieldValues,
  FieldPath,
  FieldPathValue,
  UseFormStateReturn,
  ControllerFieldState,
  UseControllerProps,
  ControllerRenderProps,
} from 'react-hook-form'
import { useController as useBaseFieldController } from 'react-hook-form'

interface ControllerOptions<Values extends FieldValues, Name extends FieldPath<Values>>
  extends UseControllerProps<Values, Name> {}

interface ControllerFieldProps<Values extends FieldValues, Name extends FieldPath<Values>>
  extends ControllerRenderProps<Values, Name> {
  onChange(value: FieldPathValue<Values, Name>): void
  onChange(event: React.ChangeEvent): void
}

interface ControllerReturn<Values extends FieldValues, Name extends FieldPath<Values>> {
  field: ControllerFieldProps<Values, Name>
  formState: UseFormStateReturn<Values>
  fieldState: ControllerFieldState
}

export function useController<Values extends FieldValues, Name extends FieldPath<Values>>(
  options: ControllerOptions<Values, Name>
): ControllerReturn<Values, Name> {
  return useBaseFieldController(options)
}

interface ControllerProps<Values extends FieldValues, Name extends FieldPath<Values>>
  extends ControllerOptions<Values, Name> {
  render: (props: ControllerReturn<Values, Name>) => React.ReactNode
}

export function Controller<Values extends FieldValues, Name extends FieldPath<Values>>({
  render,
  ...options
}: ControllerProps<Values, Name>) {
  return render(useController(options))
}
