import type { ApiRequest } from 'kitchen/types'
import type { AuthUser, UserSearchPayload, UserSearchResponse } from '../types/users'

export const getCurrentUser: ApiRequest<void, AuthUser> = (fetch, signal) => {
  return fetch.get('/users/me', { signal }).then(async (res) => {
    const intercomToken = res.headers.get('intercom-token')
    const authUser: AuthUser = { ...(await res.json()), intercomToken }
    return authUser
  })
}

export const getUserSearchResult: ApiRequest<
  UserSearchPayload,
  UserSearchResponse | null
> = (fetch, payload, signal) => {
  return fetch
    .get('/user', {
      signal,
      params: { email: payload.email, companyId: payload.companyId },
    })
    .json<UserSearchResponse>()
}
