import { useAnimation } from 'framer-motion'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import { ImpossibleError } from 'kitchen/utils/error'
import { useMemo, useState, useCallback } from 'react'
import { ImageViewerContext } from '../../context/image-viewer-context'
import { ROTATIONS, SCALES } from './constants'

interface ImageViewerContextProviderProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  children: React.ReactNode
}

export const ImageViewerContextProvider = ({
  containerRef,
  children,
}: ImageViewerContextProviderProps) => {
  const [rotationIndex, setRotationIndex] = useState(0)
  const [scale, setScale] = useState<number>(1)
  const imageAnimation = useAnimation()

  const rotateToIndex = useCallback(
    (rotationIndex: number) => {
      imageAnimation.start({
        rotate: ROTATIONS[rotationIndex],
        transition: {
          bounce: 0,
        },
      })
      setRotationIndex(rotationIndex)
    },
    [imageAnimation]
  )

  const zoom = useCallback(
    (scale: number, duration: number = 0.2) => {
      imageAnimation.start({
        scale,
        transition: {
          bounce: 0,
          duration,
        },
      })
      setScale(scale)
    },
    [imageAnimation]
  )

  const recenter = useCallback(() => {
    imageAnimation.start({
      x: 0,
      y: 0,
      transition: {
        bounce: 0,
        duration: 0.2,
      },
    })
  }, [imageAnimation])

  const rotate = useCallback(
    async (direction: 'left' | 'right') => {
      switch (direction) {
        case 'right': {
          const nextRotationIndex = (rotationIndex + 1) % ROTATIONS.length
          const isNewLoop = nextRotationIndex === 0
          if (isNewLoop) {
            imageAnimation.set({ rotate: '-90deg' })
          }
          return rotateToIndex(nextRotationIndex)
        }
        case 'left': {
          const prevRotationIndex =
            (ROTATIONS.length + rotationIndex - 1) % ROTATIONS.length
          const isNewLoop = prevRotationIndex === ROTATIONS.length - 1
          if (isNewLoop) {
            imageAnimation.set({ rotate: '365deg' })
          }
          return rotateToIndex(prevRotationIndex)
        }
        default:
          throw new ImpossibleError('unhandled rotation directon', direction)
      }
    },
    [rotationIndex, rotateToIndex, imageAnimation]
  )

  const zoomIn = useCallback(() => {
    const nextfoundIndex = SCALES.findIndex((scalePreset) => scalePreset > scale)
    const nextScaleIndex = nextfoundIndex < 0 ? SCALES.length - 1 : nextfoundIndex
    zoom(SCALES[nextScaleIndex])
  }, [scale, zoom])

  const zoomOut = useCallback(() => {
    const prevFoundIndex = SCALES.findLastIndex((scalePreset) => scalePreset < scale)
    const prevScaleIndex = prevFoundIndex < 0 ? 0 : prevFoundIndex
    zoom(SCALES[prevScaleIndex])
  }, [zoom, scale])

  const zoomToScale = useCallback(
    (value: number) => {
      zoom(value, 0)
    },
    [zoom]
  )

  const resetScale = useCallback(() => {
    zoom(1)
    recenter()
  }, [zoom, recenter])

  const value = useMemo(() => {
    return {
      rotate,
      containerRef,
      imageAnimation,
      zoomIn,
      zoomOut,
      scale,
      zoomToScale,
      resetScale,
    }
  }, [
    containerRef,
    imageAnimation,
    rotate,
    zoomIn,
    zoomOut,
    scale,
    zoomToScale,
    resetScale,
  ])

  return (
    <ImageViewerContext.Provider value={value}>{children}</ImageViewerContext.Provider>
  )
}

export function useImageViewerContext() {
  return useRequiredContext('ImageViewerContext', ImageViewerContext)
}
