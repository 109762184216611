import type { CurrencyCode } from 'domains/money/types'
import { useRifm } from 'rifm'
import * as formats from '../utils/formats'
import * as regex from '../utils/regex'

export interface PayeeReferenceRifmOptions
  extends Omit<Parameters<typeof useRifm>[0], 'format'> {
  currency: CurrencyCode
}

export const usePayeeReferenceRifm = (options: PayeeReferenceRifmOptions) =>
  useRifm({
    ...options,
    accept: regex.alphaNumeric,
    value: formats.payeeReference(options.value, options.currency),
    format: (value) => formats.payeeReference(value, options.currency),
    onChange: (value) => options.onChange(formats.payeeReference(value, options.currency)),
  })
