import type { PracticeHubRole, User } from 'api/types/users'
import { ImpossibleError } from 'kitchen/utils/error'
import { formatName, nonNullable } from 'kitchen/utils/helpers'

export function getPracticeHubRoleLabel(input: PracticeHubRole) {
  switch (input) {
    case 'ADMIN':
      return 'Admin'
    case 'MEMBER':
      return 'Member'
    case 'NONE':
      return null
    default:
      throw new ImpossibleError('Unhandled practice hub role', input)
  }
}

export function formatUserDetails(
  user: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> | undefined,
  { fallbackToEmail = false, isSamePerson = false, showEmail = false }
) {
  if (user === undefined) {
    return ''
  }

  const formattedName = [
    formatName(user.firstName, user.lastName),
    showEmail ? `(${user.email})` : undefined,
  ]
    .filter(nonNullable)
    .join(' ')

  const displayName = formattedName || (fallbackToEmail ? user.email : '')

  const currentUserPostfix = isSamePerson ? ' (you)' : ''
  return `${displayName}${currentUserPostfix}`
}
