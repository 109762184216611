import type { Company } from 'api/types/companies'
import type { RegisteredUser, UserId } from 'api/types/users'
import { useBatchUpdatePracticeClientsUsers } from 'domains/practice/queries'
import { useForm, yupResolver } from 'kitchen/forms'
import { useLocale } from 'kitchen/hooks/use-locale'
import { alphabeticSort } from 'kitchen/utils/data'
import { pluralize } from 'kitchen/utils/formats'
import { formatName } from 'kitchen/utils/helpers'
import * as Yup from 'kitchen/validations'
import { matchSorter } from 'match-sorter'
import { useDeferredValue, useId, useMemo, useState } from 'react'
import { Button, Checkbox, Dialog, Message, SearchInput, Toast } from 'salad/components'
import { HStack, LineClamp, Text, VStack } from 'salad/primitives'
import { useFormatUserName } from '../../hooks'

const schema = Yup.object({
  userIds: Yup.array()
    .of(Yup.string<UserId>().defined())
    .min(1, 'Please choose at least one member'),
})

interface FormValues {
  userIds: UserId[]
}

const initialFormValues: FormValues = {
  userIds: [],
}

interface SelectFromPracticeFormProps {
  company: Company
  practice: Company
  currentPracticeUser: RegisteredUser
}

export function SelectFromPracticeForm({
  company,
  practice,
  currentPracticeUser,
}: SelectFromPracticeFormProps) {
  const formId = useId()
  const locale = useLocale()
  const formatUserName = useFormatUserName()
  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
    register,
    setValue,
  } = useForm<FormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  })
  const userIdsValue = watch('userIds')
  const dialog = Dialog.useContext()
  const toast = Toast.useContext()
  const isCurrentUserPracticeAdmin = currentPracticeUser.practiceHubRole === 'ADMIN'

  const users = useMemo(() => {
    const assignedCompanyUsers = company.registeredUsers
      .filter((companyUser) => companyUser.presence === 'ASSIGNED')
      .map((user) => user.id)

    return practice.registeredUsers.filter(
      (practiceUser) => !assignedCompanyUsers.includes(practiceUser.id)
    )
  }, [practice.registeredUsers, company.registeredUsers])

  const batchUpdatePracticeClientsUsers = useBatchUpdatePracticeClientsUsers({
    onSuccess: () => {
      dialog.setOpen(false)
      toast.show(
        <Toast.Root>
          <Toast.Title>Members assigned to client</Toast.Title>
        </Toast.Root>
      )
    },
    onError: () => {
      toast.show(
        <Toast.Root variant="error">
          <Toast.Title>Failed to add some members to client</Toast.Title>
        </Toast.Root>
      )
    },
  })
  const [search, setSearch] = useState<string>('')
  const deferredSearch = useDeferredValue(search.trim())
  const visibleUsers = useMemo(
    () =>
      deferredSearch.length > 0
        ? matchSorter(users, deferredSearch, {
            keys: ['firstName', 'lastName', 'email'],
          })
        : users.sort((a, b) =>
            alphabeticSort(
              formatName(a.firstName, a.lastName),
              formatName(b.firstName, b.lastName),
              locale
            )
          ),
    [deferredSearch, locale, users]
  )
  const isAllVisibleSelected =
    visibleUsers.length > 0 &&
    visibleUsers.every((company) => userIdsValue.includes(company.id))

  return (
    <VStack gap={32}>
      <VStack gap={24}>
        {users.length > 0 && (
          <SearchInput
            placeholder="Search members"
            value={search}
            onValueChange={setSearch}
            size="medium"
          />
        )}
        <VStack
          as="form"
          gap={8}
          id={formId}
          onSubmit={handleSubmit((data) => {
            const selectedUsers = users.filter((user) => data.userIds.includes(user.id))
            batchUpdatePracticeClientsUsers.mutate(
              selectedUsers.map((user) => ({
                userId: user.id,
                practiceId: practice.id,
                clientId: company.id,
                presence: 'ASSIGNED',
              }))
            )
          })}
        >
          {visibleUsers.length > 0 ? (
            <VStack gap={24}>
              <Checkbox.Root>
                <Checkbox.Input
                  checked={isAllVisibleSelected}
                  indeterminate={userIdsValue.length > 0 && !isAllVisibleSelected}
                  onChange={() => {
                    const visibleUsersIds = visibleUsers.map((user) => user.id)
                    if (isAllVisibleSelected) {
                      setValue(
                        'userIds',
                        userIdsValue.filter((id) => !visibleUsersIds.includes(id)),
                        { shouldValidate: isSubmitted }
                      )
                    } else {
                      setValue(
                        'userIds',
                        [
                          ...userIdsValue,
                          ...visibleUsersIds.filter((id) => !userIdsValue.includes(id)),
                        ],
                        { shouldValidate: isSubmitted }
                      )
                    }
                  }}
                />
                <Checkbox.Label variant="label-16" color="grey-60">
                  Select all
                </Checkbox.Label>
              </Checkbox.Root>
              {visibleUsers.map((user) => (
                <Checkbox.Root key={user.id}>
                  <Checkbox.Input value={user.id} {...register('userIds')} />
                  <Checkbox.Label>
                    <LineClamp lines={1}>{formatUserName(user)}</LineClamp>
                  </Checkbox.Label>
                </Checkbox.Root>
              ))}
            </VStack>
          ) : (
            <Text variant="paragraph-16" color="grey-60">
              {deferredSearch.length > 0
                ? 'No members found'
                : isCurrentUserPracticeAdmin && 'All members are assigned'}
            </Text>
          )}
          {errors.userIds && (
            <Message variant="negative">{errors.userIds.message}</Message>
          )}
          {!isCurrentUserPracticeAdmin && (
            <Text variant="paragraph-16" color="grey-60">
              As a Practice hub member you will only see Practice hub admins from your
              Team.
            </Text>
          )}
        </VStack>
      </VStack>
      <HStack gap={8} css={{ gridAutoColumns: 'auto 1fr' }}>
        <Button.Root
          variant="minor"
          size="medium"
          onClick={() => dialog.setOpen(false)}
          hug
        >
          Cancel
        </Button.Root>
        <Button.Root
          disabled={users.length === 0}
          variant="common"
          size="medium"
          type="submit"
          form={formId}
          loading={batchUpdatePracticeClientsUsers.isLoading}
        >
          {userIdsValue.length > 0
            ? `Add ${pluralize(userIdsValue.length, 'member', 'members')}`
            : 'Add'}
        </Button.Root>
      </HStack>
    </VStack>
  )
}
