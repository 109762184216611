import type { ApiRequest } from 'kitchen/types'
import type { FeatureFlag, FeaturesPayload, FeaturesResponse } from '../types/features'

export const getFeatureFlags: ApiRequest<FeaturesPayload, FeatureFlag[]> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get(`/features`, { params: payload, signal })
    .json<FeaturesResponse>()
    .then((data) => data.features)
