import * as Icons from '../../icons'
import { HStack, type StackProps, Box, ButtonBase, Ellipsis } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { Container } from '../container'

const AlertBannerRoot = styled(Box, {
  ...typography['paragraph-16'],
  variants: {
    align: {
      center: {
        '[data-container]': {
          paddingLeft: theme.space[16],
          paddingRight: theme.space[16],
        },
        '[data-content]': {
          gridAutoColumns: 'auto',
        },
      },
    },
    variant: {
      neutral: {
        backgroundColor: theme.colors['grey-10'],
        color: theme.colors['black'],
        '[data-icon]': { color: theme.colors['dark-blue'] },
      },
      warning: {
        backgroundColor: theme.colors['light-yellow-60'],
        color: theme.colors['black'],
        '[data-icon]': { color: theme.colors['dark-yellow'] },
      },
      negative: {
        backgroundColor: theme.colors['light-red'],
        color: theme.colors['white'],
      },
      positive: {
        backgroundColor: theme.colors['light-green-30'],
        color: theme.colors['black'],
        '[data-icon]': { display: 'none' },
      },
    },
    radius: {
      0: {},
      8: { borderRadius: theme.radii[8] },
    },
  },
})

const AlertBannerContainer = styled(HStack, {
  gap: theme.space[16],
  alignItems: 'center',
  justifyContent: 'center',
  gridAutoColumns: '1fr auto',
  marginInline: 'auto',
  paddingBlock: theme.space[8],
})

export interface AlertBannerProps extends React.ComponentProps<typeof AlertBannerRoot> {}

function AlertBanner({ children, ...rest }: AlertBannerProps) {
  return (
    <AlertBannerRoot {...rest}>
      <Container data-container>
        <AlertBannerContainer data-content>{children}</AlertBannerContainer>
      </Container>
    </AlertBannerRoot>
  )
}

const AlertBannerAction = styled(ButtonBase, {
  justifySelf: 'end',
  width: 'max-content',
})

export interface AlertBannerContentProps extends StackProps {
  icon?: React.ReactNode
}

function AlertBannerContent({ children, css, icon, ...rest }: AlertBannerContentProps) {
  return (
    <HStack
      gap={8}
      css={{ ...css, gridAutoColumns: 'auto 1fr', alignItems: 'center' }}
      {...rest}
    >
      <Box pl={4} py={4} data-icon>
        {icon || <Icons.S16.ExclamationMark />}
      </Box>
      <Ellipsis>{children}</Ellipsis>
    </HStack>
  )
}

export { AlertBanner as Root, AlertBannerContent as Content, AlertBannerAction as Action }
