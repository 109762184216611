import { styled, theme } from '../../stitches'

export const Container = styled('div', {
  width: '100%',
  maxWidth: 1800,
  minWidth: 0,
  margin: '0 auto',
  padding: `0 ${theme.space[16]}`,
  '@bp1': { padding: `0 ${theme.space[24]}` },
  '@bp3': { padding: `0 ${theme.space[32]}` },
  '@bp5': { padding: `0 ${theme.space[96]}` },
  variants: {
    expand: {
      true: {
        width: 'auto',
        margin: `0 calc(${theme.space[16]} * -1)`,
        '@bp1': { margin: `0 calc(${theme.space[24]} * -1)` },
        '@bp3': { margin: `0 calc(${theme.space[32]} * -1)` },
        '@bp5': { margin: `0 calc(${theme.space[96]} * -1)` },
      },
    },
  },
})
