import type { Company, CompanyId } from 'api/types/companies'
import { useForm, yupResolver } from 'kitchen/forms'
import { pluralize } from 'kitchen/utils/formats'
import * as Yup from 'kitchen/validations'
import { matchSorter } from 'match-sorter'
import { useDeferredValue, useId, useMemo, useState } from 'react'
import { Button, Checkbox, Message, SearchInput } from 'salad/components'
import { HStack, Text, VStack } from 'salad/primitives'

const schema = Yup.object({
  companyIds: Yup.array()
    .of(Yup.string<CompanyId>().defined())
    .min(1, 'Please choose at least one client'),
})

interface FormValues {
  companyIds: CompanyId[]
}

const initialFormValues: FormValues = {
  companyIds: [],
}

interface AssignCompaniesDialogContentProps {
  assigning?: boolean
  companies: Company[]
  onCancel: () => void
  onAssign: (data: FormValues) => Promise<void> | void
}

export function AssignCompaniesForm({
  companies,
  assigning = false,
  onCancel,
  onAssign,
}: AssignCompaniesDialogContentProps) {
  const formId = useId()
  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
    register,
    setValue,
  } = useForm<FormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  })
  const companyIdsValue = watch('companyIds')
  const [search, setSearch] = useState<string>('')
  const deferredSearch = useDeferredValue(search.trim())
  const visibleCompanies = useMemo(
    () =>
      deferredSearch.length > 0
        ? matchSorter(companies, deferredSearch, {
            keys: ['name', 'legalName', 'companyNumber'],
          })
        : companies,
    [companies, deferredSearch]
  )
  const isAllVisibleSelected =
    visibleCompanies.length > 0 &&
    visibleCompanies.every((company) => companyIdsValue.includes(company.id))

  return (
    <VStack gap={24}>
      <SearchInput
        placeholder="Search clients"
        value={search}
        onValueChange={setSearch}
        size="medium"
      />
      <VStack
        as="form"
        gap={8}
        id={formId}
        onSubmit={handleSubmit((data) => {
          onAssign(data)
        })}
      >
        {visibleCompanies.length > 0 ? (
          <VStack gap={24}>
            <Checkbox.Root>
              <Checkbox.Input
                checked={isAllVisibleSelected}
                indeterminate={companyIdsValue.length > 0 && !isAllVisibleSelected}
                onChange={() => {
                  const visibleCompaniesIds = visibleCompanies.map(
                    (company) => company.id
                  )
                  if (isAllVisibleSelected) {
                    setValue(
                      'companyIds',
                      companyIdsValue.filter((id) => !visibleCompaniesIds.includes(id)),
                      { shouldValidate: isSubmitted }
                    )
                  } else {
                    setValue(
                      'companyIds',
                      [
                        ...companyIdsValue,
                        ...visibleCompaniesIds.filter(
                          (id) => !companyIdsValue.includes(id)
                        ),
                      ],
                      { shouldValidate: isSubmitted }
                    )
                  }
                }}
              />
              <Checkbox.Label variant="label-16" color="grey-60">
                Select all
              </Checkbox.Label>
            </Checkbox.Root>
            {visibleCompanies.map((company) => (
              <Checkbox.Root key={company.id}>
                <Checkbox.Input value={company.id} {...register('companyIds')} />
                <Checkbox.Label>{company.name}</Checkbox.Label>
              </Checkbox.Root>
            ))}
          </VStack>
        ) : (
          <Text variant="paragraph-16" color="grey-60">
            No clients found
          </Text>
        )}
        {errors.companyIds && (
          <Message variant="negative">{errors.companyIds.message}</Message>
        )}
      </VStack>
      <HStack gap={8} css={{ gridAutoColumns: 'auto 1fr' }}>
        <Button.Root variant="minor" size="medium" hug onClick={() => onCancel()}>
          Cancel
        </Button.Root>
        <Button.Root
          variant="common"
          size="medium"
          type="submit"
          form={formId}
          loading={assigning}
        >
          {companyIdsValue.length > 0
            ? `Assign ${pluralize(companyIdsValue.length, 'client', 'clients')}`
            : 'Assign'}
        </Button.Root>
      </HStack>
    </VStack>
  )
}
