import { styled, theme } from 'salad/stitches'

export interface ScrollShadowProps extends React.ComponentProps<typeof ScrollShadow> {}

export const ScrollShadow = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  height: theme.space[24],
  background: theme.gradients['scroll-viewport'],
})
