import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { Grid, Text, type TextProps } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import * as Button from '../button'

export interface StatusProps extends React.ComponentProps<typeof Status> {}

const Status = styled(Grid, {
  gap: theme.space[4],
  gridAutoFlow: 'row',
  gridAutoColumns: 'minmax(0, 1fr)',
  placeItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  '& > [data-status-icon] + *': {
    marginBlockStart: theme.space[28],
  },
  '& > [data-status-action]': {
    width: '100%',
    maxWidth: 384,
    marginBlockStart: theme.space[28],
    '& + &': { marginBlockStart: theme.space[12] },
  },
  variants: {
    variant: {
      dialog: {
        '& > [data-status-icon] + *': {
          marginBlockStart: theme.space[20],
        },
        '& > [data-status-title]': {
          ...typography['headline-h2'],
        },
        '& > [data-status-description]': {
          ...typography['paragraph-16'],
          color: theme.colors['grey-60'],
        },
        '& > [data-status-action]': {
          marginBlockStart: theme.space[20],
        },
      },
    },
  },
})

export interface StatusIconProps extends TextProps {}

function StatusIcon(props: StatusIconProps) {
  return <Text color="grey-60" data-status-icon {...props} />
}

export interface StatusTitleProps extends TextProps {}

const StatusTitle: GenericForwardRefComponent<StatusTitleProps> = forwardRef(
  function StatusTitleRef(props, ref) {
    return (
      <Text
        ref={ref}
        as="h2"
        variant="headline-h1"
        color="black"
        data-status-title
        {...props}
      />
    )
  }
)

export interface StatusDescriptionProps extends TextProps {}

const StatusDescription: GenericForwardRefComponent<StatusDescriptionProps> = forwardRef(
  function StatusTitleRef(props, ref) {
    return (
      <Text
        ref={ref}
        as="p"
        variant="paragraph-20"
        color="grey-60"
        data-status-description
        {...props}
      />
    )
  }
)

export interface StatusActionProps extends Button.ButtonProps {}

const StatusAction: GenericForwardRefComponent<StatusActionProps> = forwardRef(
  function StatusActionRef({ children, ...rest }, ref) {
    return (
      <Button.Root ref={ref} variant="minor" size="large" data-status-action {...rest}>
        <Button.Content>{children}</Button.Content>
      </Button.Root>
    )
  }
)

export {
  Status as Root,
  StatusIcon as Icon,
  StatusTitle as Title,
  StatusDescription as Description,
  StatusAction as Action,
}
