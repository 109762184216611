import { styled } from '../stitches'
import { margin } from '../variants'

export interface ImageProps extends React.ComponentProps<typeof Image> {}

export const Image = styled('img', {
  display: 'block',
  height: 'auto',
  maxWidth: '100%',
  variants: {
    ...margin,
  },
})
