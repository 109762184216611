import { Channel } from 'kitchen/constants'
import { useBroadcastChannel } from 'kitchen/hooks/use-broadcast-channel'
import type { AuthSession } from '../types'

export type AuthBroadcastMessage =
  | { type: 'logout' }
  | { type: 'refresh'; session: AuthSession }

export const useAuthChannel = () =>
  useBroadcastChannel<AuthBroadcastMessage>(Channel.AUTH)
