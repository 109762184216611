import type { AnimationControls } from 'framer-motion'
import { createContext } from 'react'

export interface ImageViewerContextValue {
  rotate: (direction: 'left' | 'right') => void
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  imageAnimation: AnimationControls
  scale: number
  /**
   * zoom in to the next scale breakpoint
   */
  zoomIn: () => void
  /**
   * zoom out to the previous scale breakpoint
   */
  zoomOut: () => void
  /**
   * fast manual zoom to value
   */
  zoomToScale: (value: number) => void
  /**
   * reset zoom and drag position to default
   */
  resetScale: () => void
}

export const ImageViewerContext = createContext<ImageViewerContextValue | null>(null)
