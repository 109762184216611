export const Cross = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8787 16L5.43936 24.4393L7.56068 26.5607L16 18.1213L24.4394 26.5607L26.5607 24.4393L18.1213 16L26.5607 7.56066L24.4394 5.43934L16 13.8787L7.56068 5.43934L5.43936 7.56066L13.8787 16Z"
      fill="currentColor"
    />
  </svg>
)
