import { useSendAnalyticsEvent } from 'domains/analytics/queries'
import { useLocation } from 'kitchen/router'
import { useEffect } from 'react'

export function useTrackPageVisits() {
  const location = useLocation()
  const { mutate: sendAnalyticsEvent } = useSendAnalyticsEvent()

  useEffect(() => {
    sendAnalyticsEvent({
      event: 'PAGE_VISIT',
      data: { pathname: location.pathname + location.search },
    })
  }, [sendAnalyticsEvent, location.pathname, location.search])
}
