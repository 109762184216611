import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { ButtonBase, HStack, Text, type ButtonBaseProps } from '../../primitives'
import { styled, theme } from '../../stitches'

export interface TableButtonProps extends ButtonBaseProps {
  icon?: React.ReactElement
}

const TableButtonBase = styled(ButtonBase, {
  padding: theme.space[8],
  marginInline: `calc(${theme.space[8]} * -1)`,
  borderRadius: theme.radii[8],
  'td:last-child &, th:last-child &': {
    width: 'auto',
  },
  '[data-icon]': {
    transition: 'opacity 0.3s',
    opacity: 0,
  },
  '&:where(:enabled, :any-link):hover': {
    backgroundColor: theme.colors['grey-5'],
    '[data-icon]': {
      opacity: 1,
    },
    '[data-avatar-stack]': {
      outlineColor: theme.colors['grey-5'],
    },
  },
  '&:where(:enabled, :any-link):active, &[aria-expanded="true"]': {
    backgroundColor: theme.colors['grey-10'],
    '[data-avatar-stack]': {
      outlineColor: theme.colors['grey-10'],
    },
  },
  // inside interactive Cell
  '[data-interactive] &': {
    '&:where(:enabled, :any-link):hover': {
      backgroundColor: theme.colors['grey-10'],
      '[data-avatar-stack]': {
        outlineColor: theme.colors['grey-10'],
      },
    },
    '&:where(:enabled, :any-link):active, &[aria-expanded="true"]': {
      backgroundColor: theme.colors['grey-15'],
      '[data-avatar-stack]': {
        outlineColor: theme.colors['grey-15'],
      },
    },
  },
})

const TableButton: GenericForwardRefComponent<TableButtonProps> = forwardRef(
  function TableButtonRef({ icon, children, ...rest }, forwardedRef) {
    const content = icon ? (
      <HStack css={{ alignItems: 'center', gridTemplateColumns: '1fr auto' }} gap={8}>
        {children}
        <Text color="grey-40" data-icon>
          {icon}
        </Text>
      </HStack>
    ) : (
      children
    )

    return (
      <TableButtonBase ref={forwardedRef} {...rest}>
        {content}
      </TableButtonBase>
    )
  }
)

export { TableButton as Button }
