import { useState, useEffect } from 'react'

interface DelayedProps {
  delay?: number
  fallback?: React.ReactNode
  children?: React.ReactNode
}

export function Delayed({ delay = 0, fallback, children }: DelayedProps) {
  const [show, setShow] = useState(delay > 0 ? false : true)

  useEffect(() => {
    if (delay === 0) {
      return
    }

    const timeout = setTimeout(() => setShow(true), delay)
    return () => clearTimeout(timeout)
  }, [delay])

  return <>{show ? children : fallback}</>
}
