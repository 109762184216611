import type { PropsWithChildren } from 'react'
import { useCallback } from 'react'
import { Box, ButtonBase, Text, ZStack } from '../../primitives'
import { styled, theme } from '../../stitches'
import type * as Tokens from '../../tokens'
import { Cell } from './cell'
import type { Day } from './types'

const LeftRange = styled(Box, {
  width: '50%',
  marginInlineEnd: 'auto',
  backgroundColor: theme.colors['light-blue-40'],
})

const RightRange = styled(Box, {
  width: '50%',
  marginInlineStart: 'auto',
  backgroundColor: theme.colors['light-blue-40'],
})

const StyledCell = styled(Cell, {
  variants: {
    specialDate: {
      true: {
        '&:hover': {
          borderColor: 'transparent',
        },
        '&:active': {
          borderColor: 'transparent',
        },
      },
    },
    active: {
      true: {
        background: theme.colors['light-blue-80'],
      },
    },
  },
})

const DateButton = styled(ButtonBase, {
  width: 'auto',
  overflow: 'visible',
  [`&:hover ${StyledCell}`]: {
    background: theme.colors['light-blue-80'],
  },
  [`&:active ${StyledCell}`]: {
    background: theme.colors['light-blue'],
  },
  '&[disabled]': {
    color: theme.colors['black-alpha-30'],
    cursor: 'auto',
    pointerEvents: 'none',
    [`& ${StyledCell}`]: {
      background: 'none',
      cursor: 'auto',
    },
    [`&:hover ${StyledCell}`]: {
      background: 'none',
    },
    [`&:active ${StyledCell}`]: {
      background: 'none',
    },
  },
  variants: {
    isEdge: {
      true: {
        '&[disabled]': {
          [`& ${StyledCell}`]: {
            background: theme.colors['light-blue-40'],
            color: theme.colors['black-alpha-30'],
          },
        },
      },
    },
  },
  '&:focus-visible': {
    boxShadow: 'none',
    [`& ${StyledCell}`]: {
      insetOutline: theme.colors['light-blue'],
    },
  },
})

// each cell casting shadow sideways, calendar grid gap / 2.
// if cell is a range edge, casting shadow one side only.
const RangeIndicator = styled(Box, {
  variants: {
    inRange: {
      true: {
        background: theme.colors['light-blue-40'],
        boxShadow: `-6px 0px 0 0px ${theme.colors['light-blue-40']}, 6px 0px 0px 0px ${theme.colors['light-blue-40']}`,
      },
    },
    rangeStart: {
      true: {},
    },
    rangeEnd: {
      true: {},
    },
  },
  compoundVariants: [
    {
      inRange: true,
      rangeStart: true,
      css: {
        background: 'none',
        boxShadow: `6px 0px 0px 0px ${theme.colors['light-blue-40']}`,
      },
    },
    {
      inRange: true,
      rangeEnd: true,
      css: {
        background: 'none',
        boxShadow: `-6px 0px 0 0px ${theme.colors['light-blue-40']}`,
      },
    },
    {
      rangeStart: true,
      rangeEnd: true,
      css: {
        boxShadow: `none`,
      },
    },
  ],
})

interface DateCellProps {
  day: Day
  displayOuterDates: boolean
  onClick: (date: Date) => void
  onHover: (date: Date) => void
}

export const DateCell = ({
  day,
  displayOuterDates,
  onClick,
  onHover,
  children,
}: PropsWithChildren<DateCellProps>) => {
  const {
    isSelectedTo,
    isSelectedFrom,
    isInSelectedRange,
    isInHoveredRange,
    specialDate,
    isCurrentMonth,
    isDisabled,
  } = day.meta

  const isHidden = !displayOuterDates && !isCurrentMonth
  let color: Tokens.Color = isCurrentMonth ? 'black' : 'grey-30'
  if (isDisabled) {
    color = 'grey-30'
  }
  if (isHidden) {
    color = 'transparent'
  }
  const isActive = isSelectedTo || isSelectedFrom

  const handleMouseEnter = useCallback(() => {
    if (isDisabled || isHidden) {
      return
    }
    onHover(day.date.toDate())
  }, [day.date, isDisabled, isHidden, onHover])

  const handleClick = useCallback(() => {
    if (isDisabled || isHidden) {
      return
    }
    onClick(day.date.toDate())
  }, [day.date, isDisabled, isHidden, onClick])

  const isInRange = isInSelectedRange || isInHoveredRange
  const isRangeStart = isSelectedFrom && !isSelectedTo && isInRange
  const isRangeEnd = isSelectedTo && !isSelectedFrom && isInRange

  return (
    <DateButton
      disabled={isDisabled || isHidden}
      isEdge={isSelectedFrom || isSelectedTo}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    >
      <RangeIndicator
        as={ZStack}
        rangeStart={isSelectedFrom}
        rangeEnd={isSelectedTo}
        inRange={isInRange}
      >
        {isRangeEnd && <LeftRange />}
        {isRangeStart && <RightRange />}
        <StyledCell
          active={isActive}
          specialDate={Boolean(specialDate)}
          css={{
            color,
            ...(specialDate &&
              !isActive && { border: `2px solid ${theme.colors[specialDate.color]}` }),
          }}
        >
          <Text variant="button-small">{children}</Text>
        </StyledCell>
      </RangeIndicator>
    </DateButton>
  )
}
