import { AttachmentPreview } from 'domains/attachment/components'
import { RouterLink, useSearchParams } from 'kitchen/router'
import { DocumentGroup, Logo } from 'salad/components'
import { Text } from 'salad/primitives'
import { SomethingWentWrong } from '@app/components/something-went-wrong'
import { Path } from '@app/constants'

export const CommentAttachmentReview = () => {
  const [searchParams] = useSearchParams()
  const url = searchParams.get('url')
  const fileName = searchParams.get('fileName')
  const mimeType = searchParams.get('mimeType')

  if (!url || !fileName || !mimeType) {
    return <SomethingWentWrong.Root layout="flow-page" />
  }

  const value = { url, fileName, mimeType }

  return (
    <DocumentGroup.Root>
      <DocumentGroup.Header>
        <DocumentGroup.Logo>
          <RouterLink to={Path.INDEX}>
            <Logo variant="small" />
          </RouterLink>
        </DocumentGroup.Logo>
        <Text variant="paragraph-16" css={{ gridRow: '1 / -1' }}>
          {fileName}
        </Text>
      </DocumentGroup.Header>
      <DocumentGroup.Content values={[value]}>
        {({ value }) => <AttachmentPreview value={value} />}
      </DocumentGroup.Content>
    </DocumentGroup.Root>
  )
}
