import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { styled, theme } from '../../stitches'
import * as NestedInteractive from '../nested-interactive'
import { Surface, type SurfaceProps } from '../surface'

const SnippetSurface = styled(Surface, {
  transition: 'box-shadow 0.3s',
  '@hover': {
    '&:not([data-disabled])': {
      cursor: 'pointer',
      '&:hover': { boxShadow: theme.shadows['elevation-200'] },
    },
  },
  '&:focus-visible, &[data-snippet-active]': {
    transition: 'box-shadow 0.1s ease-in',
    boxShadow: theme.shadows['focus-elevation-300'],
    '@hover': {
      '&:hover': { boxShadow: theme.shadows['focus-elevation-400'] },
    },
  },
  '&[data-snippet-selected]': {
    boxShadow: theme.shadows['elevation-200'],
  },
})

export interface SnippetProps extends Omit<SurfaceProps, 'variant'> {
  action?: string
  active?: boolean
  selected?: boolean
  disabled?: boolean
}

const Snippet: GenericForwardRefComponent<SnippetProps> = forwardRef<
  HTMLDivElement,
  SnippetProps
>(function SnippetRef({ action, active, selected, children, disabled, ...rest }, ref) {
  return (
    <NestedInteractive.Root action={action} disabled={disabled}>
      <SnippetSurface
        ref={ref}
        variant="snippet"
        data-snippet-active={active || undefined}
        data-snippet-selected={selected || undefined}
        data-disabled={disabled || undefined}
        {...rest}
      >
        {children}
      </SnippetSurface>
    </NestedInteractive.Root>
  )
})

export { Snippet as Root }
export {
  Action,
  type NestedInteractiveActionProps as SnippetActionProps,
} from '../nested-interactive'
