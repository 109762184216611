import type { ApiRequest } from 'kitchen/types'
import { AuthError } from 'kitchen/utils/error'
import type {
  AuthSession,
  SendEmailCodePayload,
  SendEmailCodeResponse,
  LoginWithCodePayload,
  LoginWithCodeResponse,
  LoginWithLinkPayload,
  LoginWithLinkResponse,
  AcceptInvitePayload,
  AcceptInviteResponse,
  AuthMethodResponse,
  AuthMethodPayload,
  LoginWithPasswordPayload,
  LoginWithPasswordResponse,
  LoginWith2FAPayload,
  LoginWith2FAResponse,
  SetupPasswordPayload,
  Setup2FAResponse,
  SetupCodeMethodPayload,
  ChangePasswordPayload,
  ConfirmPasswordChange2FaPayload,
  Verify2FAResponse,
  Verify2FAPayload,
  TurnOff2FaPayload,
  RecoveryPasswordStartPayload,
  RecoveryPasswordCompletePayload,
  AuthForce2FaStatusResponse,
} from './types'

export const refreshAuthSession: ApiRequest<void, AuthSession> = (fetch) =>
  fetch
    .post('/auth/refresh')
    .json<AuthSession>()
    .catch((_) => {
      throw new AuthError('Signed out')
    })

export const revokeAuthSession: ApiRequest<void, void> = (fetch) =>
  fetch.post('/auth/revoke').void()

export const sendEmailCode: ApiRequest<SendEmailCodePayload, SendEmailCodeResponse> = (
  fetch,
  payload
) =>
  fetch.post('/auth/send-email-password', { json: payload }).json<SendEmailCodeResponse>()

export const getAuthMethod: ApiRequest<AuthMethodPayload, AuthMethodResponse> = (
  fetch,
  payload
) => fetch.post('/auth/method', { json: payload }).json<AuthMethodResponse>()

export const getForce2FaDetails: ApiRequest<void, AuthForce2FaStatusResponse> = (fetch) =>
  fetch.get('/auth/2fa/status').json<AuthForce2FaStatusResponse>()

export const loginWithCode: ApiRequest<LoginWithCodePayload, LoginWithCodeResponse> = (
  fetch,
  payload
) =>
  fetch.post('/auth/login-with-password', { json: payload }).json<LoginWithCodeResponse>()

/** @deprecated TODO: Check the usages and remove ASAP */
export const loginWithLink: ApiRequest<LoginWithLinkPayload, LoginWithLinkResponse> = (
  fetch,
  payload
) => fetch.post('/auth/login-with-link', { json: payload }).json<LoginWithLinkResponse>()

/** @deprecated TODO: Check the usages and remove ASAP */
export const acceptInvite: ApiRequest<AcceptInvitePayload, AcceptInviteResponse> = (
  fetch,
  payload
) => fetch.post('/invite/accept', { json: payload }).json<AcceptInviteResponse>()

export const loginWithPassword: ApiRequest<
  LoginWithPasswordPayload,
  LoginWithPasswordResponse
> = (fetch, payload) =>
  fetch.post('/auth/login/password', { json: payload }).json<LoginWithPasswordResponse>()

export const loginWith2FA: ApiRequest<LoginWith2FAPayload, LoginWith2FAResponse> = (
  fetch,
  payload
) => fetch.post('/auth/login/2fa', { json: payload }).json<LoginWithPasswordResponse>()

export const startPasswordRecovery: ApiRequest<RecoveryPasswordStartPayload, void> = (
  fetch,
  payload
) => fetch.post('/auth/restore/start', { json: payload }).void()

export const completePasswordRecovery: ApiRequest<
  RecoveryPasswordCompletePayload,
  void
> = (fetch, payload) => fetch.post('/auth/restore/complete', { json: payload }).void()

export const setupPassword: ApiRequest<SetupPasswordPayload, void> = (fetch, payload) =>
  fetch.post('/auth/password/setup', { json: payload }).void()

export const changePassword: ApiRequest<ChangePasswordPayload, void> = (fetch, payload) =>
  fetch.put('/auth/password/change', { json: payload }).void()

export const confirmPasswordChange2Fa: ApiRequest<
  ConfirmPasswordChange2FaPayload,
  void
> = (fetch, payload) => fetch.put('/auth/password/change', { json: payload }).void()

export const setupCode: ApiRequest<SetupCodeMethodPayload, void> = (fetch, payload) =>
  fetch.post('/auth/code/setup', { json: payload }).void()

export const setup2FA: ApiRequest<void, Setup2FAResponse> = (fetch, payload) =>
  fetch.post('/auth/2fa/setup', { json: payload }).json<Setup2FAResponse>()

export const verify2FA: ApiRequest<Verify2FAPayload, Verify2FAResponse> = (
  fetch,
  payload
) => fetch.post('/auth/2fa/verify', { json: payload }).json<Verify2FAResponse>()

export const turnOff2Fa: ApiRequest<TurnOff2FaPayload, void> = (fetch, payload) =>
  fetch.put('/auth/2fa/delete', { json: payload }).void()
