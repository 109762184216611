import type { TextProps } from '../../primitives'
import { Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'

function SidebarNav({ children }: { children: React.ReactNode }) {
  return <>{children}</>
}

export interface SidebarNavItemProps
  extends React.ComponentProps<typeof SidebarNavItem> {}

const SidebarNavItem = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: 0,
  borderRadius: theme.space[8],
  color: theme.colors['grey-60'],
  paddingBlock: theme.space[12],
  textAlign: 'start',
  transitionDuration: '0.3s',
  transitionProperty: 'background-color, border-color',
  ':where(& [data-item-color-scheme="grey"])': {
    color: theme.colors['grey-40'],
  },
  '&:hover:not([aria-disabled="true"], [aria-pressed="true"]):is(:enabled, :any-link)': {
    backgroundColor: theme.colors['grey-5'],
  },
  '&:active:not([aria-disabled="true"]):is(:enabled, :any-link), &[aria-pressed="true"]':
    {
      backgroundColor: theme.colors['grey-10'],
    },
  '&[aria-current="page"] [data-item-content]': {
    fontWeight: theme.fontWeights[700],
  },
  '&[aria-hidden="true"]': {
    display: 'none',
  },
  '&:enabled': {
    cursor: 'pointer',
  },
  '&:hover:not([aria-disabled="true"], [aria-selected="true"],[aria-current="true"], [aria-pressed="true"]):is(:enabled, :any-link)':
    {
      backgroundColor: theme.colors['grey-2'],
    },
  '&[aria-selected="true"], &[aria-current="true"]': {
    color: theme.colors['black'],
  },
  '&:disabled, &[aria-disabled="true"]': {
    ':where(& [data-item-start], & [data-item-end], & [data-item-content])': {
      opacity: 0.3,
    },
  },
  variants: {
    size: {
      small: {
        minHeight: 40,
        gap: theme.space[8],
        paddingBlock: theme.space[8],
        paddingInline: theme.space[12],
        ...typography['button-small'],
      },
      medium: {
        minHeight: 48,
        gap: theme.space[8],
        paddingBlock: theme.space[12],
        paddingInline: theme.space[12],
        ...typography['title-16'],
      },
    },
    indicator: {
      highlight: {
        '&[aria-selected="true"], &[aria-current="true"]': {
          backgroundColor: theme.colors['grey-5'],
        },
      },
      'text-color': {
        '&[aria-selected="true"], &[aria-current="true"]': {
          color: theme.colors['black'],
        },
      },
    },
  },
  defaultVariants: {
    indicator: 'highlight',
    size: 'medium',
  },
})

export interface SidebarNavLabelProps extends TextProps {}

function SidebarNavLabel({ children, css, ...props }: SidebarNavLabelProps) {
  return (
    <Text
      color="grey-60"
      css={{
        marginBlockEnd: theme.space[8],
        paddingInline: theme.space[12],
        ...css,
      }}
      variant="label-13"
      {...props}
    >
      {children}
    </Text>
  )
}

export { SidebarNav as Root, SidebarNavItem as Item, SidebarNavLabel as Label }
