import { forwardRef } from 'react'
import { Button } from 'salad/components'
import type { ButtonProps } from 'salad/components/button'
import * as Icons from 'salad/icons'
import { theme } from 'salad/stitches'

interface ButtonIconProps {
  variant: 'default' | 'simple'
  checked?: boolean
}

const ButtonIcon = ({ variant = 'default', checked = false }: ButtonIconProps) => {
  if (variant === 'simple') {
    return null
  }
  if (checked) {
    return (
      <Button.Start
        css={{
          color: theme.colors['dark-blue'],
        }}
      >
        <Icons.S16.Check />
      </Button.Start>
    )
  }
  return (
    <Button.Start>
      <Icons.S16.Plus variant="xs" />
    </Button.Start>
  )
}

interface PermissionToggleButtonProps extends Omit<ButtonProps, 'variant'> {
  checked?: boolean
  onCheckedChange?: (value: boolean) => void
  children: React.ReactNode
  variant?: 'default' | 'simple'
}

export const PermissionToggleButton = forwardRef<
  HTMLButtonElement,
  PermissionToggleButtonProps
>(function PermissionToggleButtonRef(
  { variant = 'default', checked, onCheckedChange, children, ...rest },
  forwardedRef
) {
  return (
    <Button.Root
      hug
      variant="minor"
      size="extra-small"
      radius="round"
      ref={forwardedRef}
      active={checked}
      aria-pressed={checked}
      onClick={() => onCheckedChange?.(!checked)}
      css={{
        backgroundClip: 'padding-box',
        '&:disabled': {
          '&[aria-pressed=true]': {
            color: 'black',
            borderColor: theme.colors['button-main-background-disabled'],
            backgroundColor: theme.colors['button-main-background-disabled'],
          },
          '&:not([aria-pressed=true])': {
            borderColor: theme.colors['grey-5'],
            backgroundColor: theme.colors['white'],
          },
        },
      }}
      {...rest}
    >
      <ButtonIcon variant={variant} checked={checked} />
      <Button.Content>{children}</Button.Content>
    </Button.Root>
  )
})
