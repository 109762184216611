import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

interface CodeRifmOptions extends Omit<Parameters<typeof useRifm>[0], 'format'> {}

export const useCodeRifm = (options: CodeRifmOptions) =>
  useRifm({
    ...options,
    format: formats.code,
    value: formats.code(options.value),
    onChange: (value) => options.onChange(formats.number(value)),
  })
