import { useId, createContext, useContext } from 'react'
import { Grid, Text, type TextProps } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Label, type LabelProps } from '../label'

const DataGroupContext = createContext<string | undefined>(undefined)

const DataGroupBase = styled(Grid, {
  gridAutoFlow: 'row',
  gap: theme.space[4],
  variants: {
    align: {
      start: { textAlign: 'start', justifyContent: 'start' },
      end: { textAlign: 'end', justifyContent: 'end' },
    },
    hug: {
      true: {
        width: 'fit-content',
        minWidth: 0,
        maxWidth: '100%',
      },
    },
  },
})

export interface DataGroupProps
  extends React.ComponentPropsWithoutRef<typeof DataGroupBase> {}

function DataGroup(props: DataGroupProps) {
  const id = useId()
  return (
    <DataGroupContext.Provider value={id}>
      <DataGroupBase data-group {...props} />
    </DataGroupContext.Provider>
  )
}

export interface DataGroupLabelProps extends LabelProps {}

const DataGroupLabel = ({ css, ...rest }: DataGroupLabelProps) => {
  const labelId = useContext(DataGroupContext)
  return (
    <Label
      id={labelId}
      css={{ overflow: 'hidden', textOverflow: 'ellipsis', ...css }}
      {...rest}
    />
  )
}

export interface DataGroupValueProps extends TextProps {}

const DataGroupValue = (props: DataGroupValueProps) => {
  const labelId = useContext(DataGroupContext)
  return (
    <Text
      aria-labelledby={labelId}
      as="p"
      variant="paragraph-16"
      color="black"
      {...props}
    />
  )
}

export { DataGroup as Root, DataGroupLabel as Label, DataGroupValue as Value }
