import type { CSS } from '../stitches'
import { theme } from '../stitches'
import type { Space } from '../tokens'

interface SpaceStyles extends Record<Space, CSS> {}

const createSpaceVariant = (property: string) =>
  Object.keys(theme.space).reduce((acc, key) => {
    acc[key] = { [property]: theme.space[key] }
    return acc
  }, {} as SpaceStyles)

export const margin = {
  m: createSpaceVariant('margin'),
  ml: createSpaceVariant('marginLeft'),
  mr: createSpaceVariant('marginRight'),
  mt: createSpaceVariant('marginTop'),
  mb: createSpaceVariant('marginBottom'),
  mx: createSpaceVariant('marginX'),
  my: createSpaceVariant('marginY'),
} as const

export type Margin = keyof typeof margin

export const padding = {
  p: createSpaceVariant('padding'),
  pl: createSpaceVariant('paddingLeft'),
  pr: createSpaceVariant('paddingRight'),
  pt: createSpaceVariant('paddingTop'),
  pb: createSpaceVariant('paddingBottom'),
  px: createSpaceVariant('paddingX'),
  py: createSpaceVariant('paddingY'),
} as const

export type Padding = keyof typeof padding

export const gap = {
  gap: createSpaceVariant('gap'),
  columnGap: createSpaceVariant('columnGap'),
  rowGap: createSpaceVariant('rowGap'),
} as const

export type Gap = keyof typeof gap
