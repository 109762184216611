import { Tooltip } from 'salad/components'
import { ButtonBase } from 'salad/primitives'

interface TooltipWrapperProps {
  children: React.ReactNode
  tooltipContent: string
  enabled: boolean
  asButton?: boolean
}

export const TooltipWrapper = ({
  children,
  asButton = false,
  tooltipContent,
  enabled,
}: TooltipWrapperProps) => {
  if (!enabled) {
    return <>{children}</>
  }
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        {asButton ? (
          <ButtonBase css={{ width: 'fit-content' }}>{children}</ButtonBase>
        ) : (
          children
        )}
      </Tooltip.Trigger>
      <Tooltip.Content>{tooltipContent}</Tooltip.Content>
    </Tooltip.Root>
  )
}
