import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

interface SortCodeRifmOptions extends Omit<Parameters<typeof useRifm>[0], 'format'> {}

export const useSortCodeRifm = (options: SortCodeRifmOptions) =>
  useRifm({
    ...options,
    format: formats.sortCode,
    value: formats.sortCode(options.value),
    onChange: (value) => options.onChange(formats.number(value)),
  })
