import type { AdminPermission, UserPermission } from 'api/types/permissions'
import type { RegisteredUser } from 'api/types/users'
import { useFormContext } from 'kitchen/forms'
import { remove } from 'kitchen/utils/data'
import { Checkbox } from 'salad/components'
import * as Icons from 'salad/icons'
import { Grid, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { TooltipWrapper } from './tooltip-wrapper'
import type { PresetType, RoleEditorFormValues } from './types'

interface AdminPermissionSelectorProps {
  children: React.ReactNode
  value: AdminPermission[]
  restricted: boolean
  selectedPermissions: AdminPermission[]
  setSelectedPermissions: (value: AdminPermission[]) => void
  variant: 'checkbox' | 'always-on'
}

const AdminPermissionSelector = ({
  children,
  value,
  restricted,
  selectedPermissions,
  setSelectedPermissions,
  variant,
}: AdminPermissionSelectorProps) => {
  return (
    <TooltipWrapper
      asButton
      enabled={restricted}
      tooltipContent="You can only give out the permissions you have"
    >
      <Checkbox.Root data-disabled={variant === 'always-on' || restricted}>
        {variant === 'always-on' ? (
          <Grid
            css={{
              size: theme.space[32],
              color: theme.colors['dark-blue'],
              placeContent: 'center',
            }}
          >
            <Icons.S16.Check />
          </Grid>
        ) : (
          <Checkbox.Input
            disabled={restricted}
            checked={value.every((permission) =>
              selectedPermissions.includes(permission)
            )}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedPermissions([...selectedPermissions, ...value])
              } else {
                setSelectedPermissions(remove(selectedPermissions, value))
              }
            }}
          />
        )}
        <Checkbox.Label color={restricted ? undefined : 'black'}>
          {children}
        </Checkbox.Label>
      </Checkbox.Root>
    </TooltipWrapper>
  )
}

interface AdminPermissionsFieldsProps {
  user: RegisteredUser | undefined
  currentUserPermissions: UserPermission[]
  presetType: PresetType
  restricted: boolean
}

export function AdminPermissionsFields({
  user,
  currentUserPermissions,
  presetType,
  restricted,
}: AdminPermissionsFieldsProps) {
  const isUserPracticeHubAdmin = user?.practiceHubRole === 'ADMIN'
  const { watch, setValue } = useFormContext<RoleEditorFormValues>()
  const value = watch('adminPermissions')
  const isRestricted =
    restricted && value.some((permission) => !currentUserPermissions.includes(permission))

  const onUpdate = (value: AdminPermission[]) => {
    setValue('adminPermissions', value)
  }

  return (
    <VStack gap={12} as="ul">
      <AdminPermissionSelector
        value={['APPROVALS_EDIT']}
        variant={presetType === 'admin' ? 'always-on' : 'checkbox'}
        restricted={isRestricted}
        selectedPermissions={value}
        setSelectedPermissions={onUpdate}
      >
        Manage approval workflows
      </AdminPermissionSelector>
      <AdminPermissionSelector
        value={['USERS_EDIT']}
        variant={
          presetType === 'admin' || isUserPracticeHubAdmin ? 'always-on' : 'checkbox'
        }
        restricted={isRestricted}
        selectedPermissions={value}
        setSelectedPermissions={onUpdate}
      >
        Manage members
      </AdminPermissionSelector>
      <AdminPermissionSelector
        value={['SUBSCRIPTIONS_VIEW', 'SUBSCRIPTIONS_EDIT']}
        variant={
          presetType === 'admin' || isUserPracticeHubAdmin ? 'always-on' : 'checkbox'
        }
        restricted={isRestricted}
        selectedPermissions={value}
        setSelectedPermissions={onUpdate}
      >
        Manage subscription
      </AdminPermissionSelector>
    </VStack>
  )
}
