import { styled, theme } from '../stitches'
import { InputBase } from './input-base'

export interface CheckboxBaseProps extends React.ComponentProps<typeof CheckboxBase> {}

export const CheckboxBase = styled(InputBase, {
  borderRadius: theme.radii[4],
  display: 'grid',
  placeContent: 'center',
  '&::before': {
    content: '',
    border: `2px solid ${theme.colors.white}`,
    boxShadow: `0px 0px 0px 2px ${theme.colors.black}`,
    borderRadius: theme.radii[4],
  },
  '& + [data-checkbox-indicator]': {
    color: theme.colors.white,
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  '&:checked, &:indeterminate': {
    '& + [data-checkbox-indicator]': {
      visibility: 'visible',
    },
  },
  '&:focus-visible': {
    insetOutline: theme.colors['light-blue'],
    [`&:not(:checked, :active):before`]: {
      backgroundColor: theme.colors['grey-15'],
    },
  },
  '&:not(:disabled)': {
    cursor: 'pointer',
    '@hover': {
      [`&:hover:before`]: {
        backgroundColor: theme.colors['grey-15'],
      },
    },
    '&:active, &:checked, &:indeterminate': {
      '&:before': {
        borderColor: theme.colors.black,
        backgroundColor: theme.colors.black,
      },
    },
  },
  '&:disabled': {
    '&:before': {
      boxShadow: `0px 0px 0px 2px ${theme.colors['black-alpha-30']}`,
    },
    '&:checked, &:indeterminate': {
      '&:before': {
        backgroundColor: theme.colors['black-alpha-30'],
        border: 'none',
      },
    },
  },
  variants: {
    size: {
      24: { size: 24, '&::before': { size: 16 } },
      32: { size: 32, '&::before': { size: 22 } },
    },
  },
  defaultVariants: {
    size: 32,
  },
})

CheckboxBase.defaultProps = {
  type: 'checkbox',
}
