import { styled } from '../stitches'

export interface ButtonBaseProps extends React.ComponentProps<typeof ButtonBase> {}

export const ButtonBase = styled('button', {
  display: 'block',
  width: '100%',
  appearance: 'none',
  font: 'inherit',
  color: 'inherit',
  border: 'none',
  borderRadius: 0,
  background: 'none',
  outline: 'none',
  textAlign: 'unset',
  padding: 0,
  minWidth: 0,
  cursor: 'pointer',
  '&:disabled, &[aria-readonly], &[data-inert]': { cursor: 'auto' },
})

ButtonBase.defaultProps = {
  type: 'button',
}
