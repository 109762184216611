import type { GenericForwardRefComponent } from 'kitchen/types'
import { em } from 'kitchen/utils/styles'
import { forwardRef } from 'react'
import { styled, theme } from '../../stitches'

export interface LinkProps extends React.ComponentProps<typeof LinkBase> {}

const LinkBase = styled('a', {
  ':where(button&)': {
    appearance: 'none',
    font: 'inherit',
    color: 'inherit',
    border: 'none',
    borderRadius: 'none',
    background: 'none',
    outline: 'none',
    padding: 0,
  },
  '&:enabled': {
    cursor: 'pointer',
  },
  display: 'inline-block',
  fontWeight: theme.fontWeights[700],
  paddingInline: em(1, 3.5),
  paddingBlock: em(1, 9),
  borderRadius: theme.radii[4],
  textDecoration: 'none',
  transition: 'background-color 0.2s',
  variants: {
    color: {
      'dark-blue': {
        color: theme.colors['dark-blue'],
        '&:hover': {
          backgroundColor: theme.colors['black-alpha-5'],
        },
        '&:active, &[aria-pressed="true"]': {
          backgroundColor: theme.colors['black-alpha-10'],
        },
      },
      'grey-60': {
        color: theme.colors['grey-60'],
        '&:hover': {
          backgroundColor: theme.colors['grey-5'],
        },
        '&:active, &[aria-pressed="true"]': {
          backgroundColor: theme.colors['grey-10'],
        },
      },
    },
    standalone: {
      true: {},
      false: { marginInline: em(-6) },
    },
    highlighted: { true: {} },
  },
  compoundVariants: [
    {
      color: 'dark-blue',
      highlighted: true,
      css: {
        backgroundColor: theme.colors['black-alpha-5'],
        '&:hover': {
          backgroundColor: theme.colors['black-alpha-10'],
        },
      },
    },
    {
      color: 'grey-60',
      highlighted: true,
      css: {
        backgroundColor: theme.colors['grey-5'],
        '&:hover': {
          backgroundColor: theme.colors['grey-10'],
        },
      },
    },
  ],
  defaultVariants: {
    color: 'dark-blue',
    standalone: false,
  },
})

export const Link: GenericForwardRefComponent<LinkProps> = forwardRef(function LinkRef(
  props,
  forwardedRef
) {
  return (
    <LinkBase
      type={'as' in props && props.as === 'button' ? 'button' : undefined}
      ref={forwardedRef}
      {...props}
    />
  )
})
