import { Slot } from '@radix-ui/react-slot'
import { Box } from '../../primitives'
import { styled } from '../../stitches'
import { textColor } from '../../variants'

const SeparatorBase = styled(Box, {
  backgroundClip: 'content-box',
  '&[aria-orientation=horizontal]': { width: '100%' },
  '&[aria-orientation=vertical]': { height: '100%' },
  variants: {
    color: textColor,
    size: {
      1: {
        '&[aria-orientation=horizontal]': { height: 1 },
        '&[aria-orientation=vertical]': { width: 1 },
      },
      2: {
        '&[aria-orientation=horizontal]': { height: 2 },
        '&[aria-orientation=vertical]': { width: 2 },
      },
    },
  },
})

export interface SeparatorProps
  extends Omit<React.ComponentProps<typeof SeparatorBase>, 'size' | 'children'> {
  variant?: 'solid' | 'dotted'
  orientation?: 'horizontal' | 'vertical'
  size?: 1 | 2 | '1' | '2'
}

export const Separator = ({
  variant = 'solid',
  orientation = 'horizontal',
  size = variant === 'solid' && orientation === 'vertical' ? 1 : 2,
  color = variant === 'solid' ? 'grey-15' : 'black-alpha-40',
  ...rest
}: SeparatorProps) => {
  return (
    <SeparatorBase
      as={Slot}
      role="separator"
      size={size}
      color={color}
      aria-orientation={orientation}
      {...rest}
    >
      {variant === 'solid' ? (
        <Box
          css={{ backgroundColor: 'currentColor', '-webkit-print-color-adjust': 'exact' }}
        />
      ) : (
        <svg>
          <line
            x1="1px"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke="currentColor"
            strokeWidth={size}
            strokeLinecap="round"
            strokeDasharray="1 8"
          />
        </svg>
      )}
    </SeparatorBase>
  )
}
