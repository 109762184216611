import type { CountryCode } from 'api/types/address'
import { useFormatCountryCode } from 'kitchen/hooks/use-format-country-code'
import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { theme } from '../../stitches'
import { CountrySelect, type CountrySelectProps } from '../country-select'
import * as InputButton from '../input-button'
import * as Popover from '../popover'
import { ScrollArea } from '../scroll-area'
import { Spinner } from '../spinner'
import { Surface } from '../surface'

interface CountrySelectPopoverBaseProps {
  size?: number
  children?: React.ReactNode
}

export type CountrySelectPopoverProps = CountrySelectPopoverBaseProps & CountrySelectProps

function CountrySelectPopoverRoot({
  size = 384,
  children,
  ...rest
}: CountrySelectPopoverProps) {
  return (
    <Popover.Root>
      {children}
      <Popover.Content align="start" size={size}>
        <Surface variant="popover">
          <ScrollArea
            variant="custom-scrollbar"
            css={{
              maxHeight: `min(400px, calc(${Popover.availableContentHeight} - ${theme.space[16]}))`,
            }}
          >
            <CountrySelect {...rest} />
          </ScrollArea>
        </Surface>
      </Popover.Content>
    </Popover.Root>
  )
}

interface CountrySelectPopoverInputTriggerProps
  extends Omit<InputButton.InputButtonProps, 'defaultValue' | 'value'> {
  value: CountryCode | CountryCode[] | null | undefined | ''
  placeholder?: string
  pending?: boolean
}

const CountrySelectPopoverInputTrigger = forwardRef<
  HTMLButtonElement,
  CountrySelectPopoverInputTriggerProps
>(function CountrySelectPopoverInputTriggerRef(
  { pending, placeholder, value, ...rest },
  ref
) {
  const formatCountryCode = useFormatCountryCode()
  const isMultiple = Array.isArray(value)
  return (
    <Popover.Trigger asChild>
      {(trigger) => (
        <InputButton.Root ref={ref} {...rest}>
          {!value || (isMultiple && value.length === 0) ? (
            <InputButton.Placeholder>{placeholder}</InputButton.Placeholder>
          ) : (
            <InputButton.Content>
              {isMultiple
                ? value.map(formatCountryCode).join(', ')
                : formatCountryCode(value)}
            </InputButton.Content>
          )}
          <InputButton.End>
            {pending ? (
              <Spinner size={16} />
            ) : (
              <Icons.S16.Dropdown variant={trigger.isOpen ? 'pressed' : 'default'} />
            )}
          </InputButton.End>
        </InputButton.Root>
      )}
    </Popover.Trigger>
  )
})

export const { Trigger } = Popover
export {
  CountrySelectPopoverRoot as Root,
  CountrySelectPopoverInputTrigger as InputTrigger,
}
