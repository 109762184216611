import { TODAY } from './locale'

export const NOW = Date.UTC(
  TODAY.getFullYear(),
  TODAY.getMonth(),
  TODAY.getDate(),
  TODAY.getHours(),
  TODAY.getMinutes(),
  TODAY.getSeconds(),
  TODAY.getMilliseconds()
)

export const BENEFICIARY_LOG_START = Date.UTC(2023, 5, 10, 0, 0, 0, 0)
export const AUDIT_LOG_START = Date.UTC(2023, 10, 1, 0, 0, 0, 0)
