import { useControlled } from 'kitchen/hooks/use-controlled'
import { useSortCodeRifm } from 'kitchen/hooks/use-sort-code-rifm'
import { forwardRef } from 'react'
import { Input, type InputProps } from '../input'

export interface SortCodeInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
}

export const SortCodeInput = forwardRef<HTMLInputElement, SortCodeInputProps>(
  function SortCodeInputRef(
    { defaultValue = '', value: controlledValue, onChange, ...rest },
    forwardedRef
  ) {
    const [value, setValue] = useControlled<string>({
      defaultValue,
      value: controlledValue,
      onChange,
    })

    const rifm = useSortCodeRifm({
      value,
      onChange: setValue,
    })

    return <Input ref={forwardedRef} placeholder="##-##-##" {...rifm} {...rest} />
  }
)
