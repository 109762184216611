import type { Company } from 'api/types/companies'
import { RouterLink } from 'kitchen/router'
import type { GenericComponent } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { Status, Surface } from 'salad/components'
import * as Icons from 'salad/icons'
import { AppLayout, StatusLayout } from 'salad/layouts'
import { VStack } from 'salad/primitives'
import { Path } from '@app/constants'
import { AppHeader } from '../app-header'
import { AppSidebar } from '../app-sidebar'

export type ErrorStatusProps =
  | {
      layout: 'inline' | 'dialog' | 'flow-page' | 'public-page'
      onRetry?: () => void
      children?: React.ReactNode
    }
  | {
      layout: 'hub-page'
      practice: Company | undefined
      onRetry?: () => void
      children?: React.ReactNode
    }
  | {
      layout: 'company-page'
      company: Company
      onRetry?: () => void
      children?: React.ReactNode
    }

function ErrorStatus(props: ErrorStatusProps) {
  const content = (
    <Status.Root variant={props.layout === 'dialog' ? 'dialog' : undefined}>
      {props.children ?? (
        <>
          <ErrorStatusIcon />
          <ErrorStatusTitle />
          {props.onRetry && <ErrorStatusAction onClick={props.onRetry} />}
        </>
      )}
    </Status.Root>
  )

  switch (props.layout) {
    case 'flow-page':
    case 'public-page':
      return (
        <StatusLayout.Root>
          {props.layout === 'public-page' && (
            <StatusLayout.Logo variant="small" as={RouterLink} to={Path.INDEX} />
          )}
          <StatusLayout.Content>{content}</StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'company-page':
    case 'hub-page':
      return (
        <AppLayout.Root size={448}>
          <AppLayout.Side>
            <AppSidebar {...props} />
          </AppLayout.Side>
          <AppLayout.Header>
            <AppHeader {...props} />
          </AppLayout.Header>
          <AppLayout.Content>
            <VStack css={{ height: '100%' }}>{content}</VStack>
          </AppLayout.Content>
        </AppLayout.Root>
      )
    case 'inline':
      return content
    case 'dialog':
      return (
        <Surface variant="flat" p={32}>
          {content}
        </Surface>
      )
    default:
      throw new ImpossibleError('Unhandled layout variant', props)
  }
}

export interface ErrorStatusIconProps extends Status.StatusIconProps {}

function ErrorStatusIcon({ children, ...rest }: ErrorStatusIconProps) {
  return <Status.Icon {...rest}>{children ?? <Icons.S64.Failure />}</Status.Icon>
}

export interface ErrorStatusTitleProps extends Status.StatusTitleProps {}

function ErrorStatusTitle({ children, ...rest }: ErrorStatusTitleProps) {
  return <Status.Title {...rest}>{children ?? 'Something went wrong'}</Status.Title>
}

export interface ErrorStatusDescriptionProps extends Status.StatusDescriptionProps {}

function ErrorStatusDescription({ children, ...rest }: ErrorStatusDescriptionProps) {
  return (
    <Status.Description {...rest}>{children ?? 'Please try again'}</Status.Description>
  )
}

export interface ErrorStatusActionProps extends Status.StatusActionProps {}

const ErrorStatusAction: GenericComponent<ErrorStatusActionProps> = ({
  children,
  ...rest
}: Status.StatusActionProps) => (
  <Status.Action {...rest}>{children ?? 'Try again'}</Status.Action>
)

export {
  ErrorStatus as Root,
  ErrorStatusIcon as Icon,
  ErrorStatusTitle as Title,
  ErrorStatusDescription as Description,
  ErrorStatusAction as Action,
}
