import { styled } from '../stitches'

const LineClampBase = styled('span', {
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
})

interface LineClampProps extends React.ComponentProps<typeof LineClampBase> {
  lines: number
}

export const LineClamp = ({ lines = 0, css, ...rest }: LineClampProps) => {
  return (
    <LineClampBase
      {...rest}
      css={{
        '-webkit-line-clamp': lines === 0 ? 'none' : lines,
        ...css,
      }}
    />
  )
}
