import type { AuthResponse } from 'domains/auth/types'
import type { CompanyId } from './companies'

export enum SyncSource {
  XERO = 'xero',
  QUICKBOOKS = 'quickbooks',
  GOOGLE = 'google',
  INTERNAL = 'internal',
}

export type AccountingApp = SyncSource.XERO | SyncSource.QUICKBOOKS
export type AuthSource = SyncSource.XERO | SyncSource.QUICKBOOKS | SyncSource.GOOGLE

export interface VendorCallbackPayload {
  syncSource: SyncSource
  code?: string
  scope?: string
  realmId?: string
  state?: string
}

export interface VendorVerify2FaPayload extends VendorCallbackPayload {
  totp: string
  isTrustedDevice: boolean
}

export interface VendorCallbackResponse extends AuthResponse {}

export interface ConnectVendorPayload {
  syncSource: AccountingApp
  companyId: CompanyId
  baseUrl: URL
}

export interface ConnectVendorResponse {
  location: string
}

export interface AddVendorCompanyPayload {
  syncSource: AccountingApp
  baseUrl: URL
  force?: boolean
}

export interface AddVendorCompanyResponse {
  location: string
}

export type SignupVendorPayload =
  | { source: AccountingApp; baseUrl: URL; feature?: 'skipConnect'; force?: boolean }
  | { source: SyncSource.GOOGLE; baseUrl: URL; force?: boolean }

export interface SignupVendorResponse {
  location: string
}
