import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { Flex, HStack, Text, Ellipsis } from '../../primitives'
import { theme } from '../../stitches'
import { IconButton, type IconButtonProps } from '../icon-button'

export interface PageHeaderProps {
  children?: React.ReactNode
}

function PageHeader({ children }: PageHeaderProps) {
  return (
    <Flex gap={8} css={{ height: theme.space[40], alignItems: 'center' }}>
      {children}
    </Flex>
  )
}

export interface PageHeaderBackProps extends IconButtonProps {}

const PageHeaderBack: GenericForwardRefComponent<PageHeaderBackProps> = forwardRef(
  function PageHeaderBack(props, ref) {
    return (
      <IconButton ref={ref} size={32} {...props}>
        <Icons.S24.Arrow variant="left" />
      </IconButton>
    )
  }
)

export interface PageHeaderTitleProps
  extends Omit<React.HTMLAttributes<unknown>, 'color'> {
  children?: React.ReactNode
}

function PageHeaderTitle({ children, ...rest }: PageHeaderTitleProps) {
  return (
    <Ellipsis
      css={{
        flex: '0 1 auto',
        minWidth: theme.space[64],
      }}
      asChild
    >
      <Text as="h2" variant="headline-h1" color="black" {...rest}>
        {children}
      </Text>
    </Ellipsis>
  )
}

export interface PageHeaderActionsProps {
  children?: React.ReactNode
}

function PageHeaderActions({ children }: PageHeaderActionsProps) {
  return (
    <HStack
      ml="auto"
      gap={8}
      css={{
        flex: 1,
        width: 'auto',
        minWidth: 'auto',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      {children}
    </HStack>
  )
}

export {
  PageHeader as Root,
  PageHeaderBack as Back,
  PageHeaderTitle as Title,
  PageHeaderActions as Actions,
}
