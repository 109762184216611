import type { Company } from 'api/types/companies'
import type {
  EveryAdminPermission,
  EveryBookkeepingPermission,
  EveryPaymentPermission,
  EveryManagePermission,
  UserRole,
  PaymentPermission,
  UserPermission,
} from 'api/types/permissions'
import type { RegisteredUser, UserId } from 'api/types/users'
import { alphabeticSort } from './data'
import { unique } from './helpers'
import { getCompanyUser } from './users'

export function getCompanyUserPermissions(userId: UserId | undefined, company: Company) {
  const companyUser = getCompanyUser(userId, company)

  if (companyUser) {
    return companyUser.permissions
  }
}

const creatorPaymentPermissions: PaymentPermission[] = [
  'INVOICE_PAYMENTS_VIEW',
  'INVOICE_PAYMENTS_CREATE',
  'INVOICE_PAYMENTS_EDIT',
  'MANUAL_PAYMENTS_VIEW',
  'MANUAL_PAYMENTS_CREATE',
  'MANUAL_PAYMENTS_EDIT',
]
const approverPaymentPermissions: PaymentPermission[] = [
  ...creatorPaymentPermissions,
  'INVOICE_PAYMENTS_APPROVE',
  'MANUAL_PAYMENTS_APPROVE',
]
const payerPaymentPermissions: PaymentPermission[] = [
  ...approverPaymentPermissions,
  'INVOICE_PAYMENTS_AUTHORISE',
  'MANUAL_PAYMENTS_AUTHORISE',
]
const adminPaymentPermissions: PaymentPermission[] = [...payerPaymentPermissions]

export const mapRolesToPaymentPermissions: Record<UserRole, PaymentPermission[]> = {
  CREATOR: creatorPaymentPermissions,
  APPROVER: approverPaymentPermissions,
  PAYER: payerPaymentPermissions,
  ADMIN: adminPaymentPermissions,
  CUSTOM: [],
}

const PAYMENT_PERMISSIONS = [
  'INVOICE_PAYMENTS_VIEW',
  'INVOICE_PAYMENTS_ASSIGNED_VIEW',
  'INVOICE_PAYMENTS_CREATE',
  'INVOICE_PAYMENTS_EDIT',
  'INVOICE_PAYMENTS_APPROVE',
  'INVOICE_PAYMENTS_AUTHORISE',
  'MANUAL_PAYMENTS_VIEW',
  'MANUAL_PAYMENTS_ASSIGNED_VIEW',
  'MANUAL_PAYMENTS_CREATE',
  'MANUAL_PAYMENTS_EDIT',
  'MANUAL_PAYMENTS_APPROVE',
  'MANUAL_PAYMENTS_AUTHORISE',
  'PAYROLL_PAYMENTS_VIEW',
  'PAYROLL_PAYMENTS_ASSIGNED_VIEW',
  'PAYROLL_PAYMENTS_CREATE',
  'PAYROLL_PAYMENTS_EDIT',
  'PAYROLL_PAYMENTS_APPROVE',
  'PAYROLL_PAYMENTS_AUTHORISE',
] satisfies EveryPaymentPermission

export const ADMIN_PERMISSIONS = [
  'APPROVALS_EDIT',
  'USERS_EDIT',
  'SUBSCRIPTIONS_VIEW',
  'SUBSCRIPTIONS_EDIT',
] satisfies EveryAdminPermission

const BOOKKEEPING_PERMISSIONS = [
  'BOOKKEEPING_VIEW',
  'BOOKKEEPING_ASSIGNED_VIEW',
  'BOOKKEEPING_PUBLISH',
] satisfies EveryBookkeepingPermission

export const MANAGE_PERMISSIONS = [
  'USERS_EDIT',
  'SUBSCRIPTIONS_VIEW',
  'SUBSCRIPTIONS_EDIT',
] satisfies EveryManagePermission

export const FULL_ADMIN_PERMISSIONS = [
  ...ADMIN_PERMISSIONS,
  ...[...BOOKKEEPING_PERMISSIONS, ...PAYMENT_PERMISSIONS].filter(
    (permission) => !permission.endsWith('ASSIGNED_VIEW')
  ),
]

export const checkIsPaymentPermission = (permission: UserPermission) =>
  PAYMENT_PERMISSIONS.includes(permission)

export const checkIsAdminPermission = (permission: UserPermission) =>
  ADMIN_PERMISSIONS.includes(permission)

export const checkIsBookkeepingPermission = (permission: UserPermission) =>
  BOOKKEEPING_PERMISSIONS.includes(permission)

export const checkHasAllAdminPermissions = (userPermissions: UserPermission[]) => {
  return FULL_ADMIN_PERMISSIONS.every((permission) =>
    userPermissions.includes(permission)
  )
}

export const checkIsCompanyAdmin = (userId: UserId, company: Company) => {
  const companyUserPermissions = getCompanyUserPermissions(userId, company)

  if (companyUserPermissions === undefined || companyUserPermissions.length === 0) {
    return false
  }

  return companyUserPermissions.some(checkIsAdminPermission)
}

/**
 * Any of
 */
export const COMPANY_ACCESS_PERMISSIONS: UserPermission[] = [
  // has access to company payments,
  'INVOICE_PAYMENTS_VIEW',
  'INVOICE_PAYMENTS_ASSIGNED_VIEW',
  'MANUAL_PAYMENTS_VIEW',
  'MANUAL_PAYMENTS_ASSIGNED_VIEW',
  'PAYROLL_PAYMENTS_VIEW',
  'PAYROLL_PAYMENTS_ASSIGNED_VIEW',
  // ...or to company bookkeeping
  'BOOKKEEPING_VIEW',
  'BOOKKEEPING_ASSIGNED_VIEW',
  'SUBSCRIPTIONS_EDIT',
]

export const checkHasCompanyAccess = (userId: UserId, company: Company) => {
  const permissions = getCompanyUserPermissions(userId, company)

  if (permissions === undefined || permissions.length === 0) {
    return false
  }

  return COMPANY_ACCESS_PERMISSIONS.some((permission) => permissions.includes(permission))
}

export function checkDifferentPermissionsAcrossCompanies(
  user: RegisteredUser,
  companies: Company[]
): boolean {
  const companyPermissionsFingerprints = companies.map((company) => {
    const permissions =
      getCompanyUserPermissions(user.id, company)?.sort(alphabeticSort) ?? []
    return permissions.join('—')
  })
  return unique(companyPermissionsFingerprints).length > 1
}

export const checkHasPayrollAccess = (permissions: UserPermission[]) =>
  ['PAYROLL_PAYMENTS_VIEW', 'PAYROLL_PAYMENTS_ASSIGNED_VIEW'].some((payrollPermission) =>
    permissions.includes(payrollPermission)
  )

export const checkHasRestrictedPaymentViewAccess = (permissions: UserPermission[]) =>
  [
    'MANUAL_PAYMENTS_ASSIGNED_VIEW',
    'INVOICE_PAYMENTS_ASSIGNED_VIEW',
    'PAYROLL_PAYMENTS_ASSIGNED_VIEW',
  ].some((assignedViewPermission) => permissions.includes(assignedViewPermission))

export const formatRolePreset = (preset: UserRole) =>
  preset === 'ADMIN' ? 'Admin' : 'Standard'
