import type { ApiRequest } from 'kitchen/types'
import type {
  UpdatePracticeHubRolePayload,
  UpdatePracticeClientUserPayload,
  BatchUpdatePracticeClientsUsersPayload,
  AddPracticeClientPayload,
  AddPracticeClientResponse,
  RemovePracticeClientPayload,
} from './types'

export const updatePracticeHubRole: ApiRequest<UpdatePracticeHubRolePayload, void> = (
  fetch,
  { userId, practiceId, ...json }
) => fetch.patch(`/practices/${practiceId}/users/${userId}`, { json }).void()

export const updatePracticeClientUser: ApiRequest<
  UpdatePracticeClientUserPayload,
  void
> = (fetch, { practiceId, clientId, userId, ...json }) =>
  fetch
    .patch(`/practices/${practiceId}/clients/${clientId}/users/${userId}`, { json })
    .void()

export const batchUpdatePracticeClientsUsers: ApiRequest<
  BatchUpdatePracticeClientsUsersPayload,
  void
> = async (fetch, payload) => {
  await Promise.all(payload.map((item) => updatePracticeClientUser(fetch, item)))
}

export const addPracticeClient: ApiRequest<
  AddPracticeClientPayload,
  AddPracticeClientResponse
> = (fetch, { practiceId }) =>
  fetch.post(`/practices/${practiceId}/clients`).json<AddPracticeClientResponse>()

export const removePracticeClient: ApiRequest<RemovePracticeClientPayload, void> = (
  fetch,
  { practiceId, clientId }
) => fetch.delete(`/practices/${practiceId}/clients/${clientId}`).void()
