export const File = () => (
  <svg fill="none" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.22 3.21a.72.72 0 0 0-1.22.5v2.57c0 .4.32.72.72.72h2.56c.64 0 .96-.77.5-1.22L10.23 3.2Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.85"
      d="M2.92 4c0-.6.49-1.08 1.08-1.08h6.62l2.46 2.46V13c0 .6-.49 1.07-1.08 1.07H4c-.6 0-1.08-.48-1.08-1.07V4Z"
    />
  </svg>
)
