import { path } from 'kitchen/router'
import * as Segment from './segment'

export const AUTH = path(Segment.AUTH)

export const AUTH_CONNECT_VENDOR = path(Segment.AUTH, Segment.CONNECT, Segment.SOURCE)
export const AUTH_EMAIL = path(Segment.AUTH, Segment.EMAIL)
export const AUTH_SETUP_PASSWORD = path(Segment.AUTH, Segment.SETUP_PASSWORD)

export const BILLING_SETUP_CANCELLED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.BILLING,
  Segment.SETUP,
  Segment.CANCELLED
)

export const CARD_CHALLENGE_STATUS = path(Segment.CARD_CHALLENGE, Segment.STATUS)

export const COMMENT_ATTACHMENT = path(Segment.COMMENT_ATTACHMENT)

export const COMPANIES_COMPANY_ID = path(Segment.COMPANIES, Segment.COMPANY_ID)

export const COMPANIES_HUB = path(Segment.COMPANIES, Segment.HUB)

export const COMPANIES_HUB_PAYMENTS = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.PAYMENTS
)

export const COMPANY_BANK_FEED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BANK_FEED
)

export const COMPANY_BANK_FEED_BATCH = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BANK_FEED,
  Segment.BATCH
)

export const COMPANY_BANK_FEED_TRANSACTION = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BANK_FEED,
  Segment.TRANSACTION_ID
)

export const COMPANY_BOOKKEEPING = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING
)

export const COMPANY_BOOKKEEPING_DOCUMENT = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.DETAILS
)

export const COMPANY_BOOKKEEPING_DOCUMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS
)

export const COMPANY_BOOKKEEPING_DOCUMENTS_ARCHIVED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.ARCHIVED
)

export const COMPANY_BOOKKEEPING_DOCUMENTS_INBOX = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.INBOX
)

export const COMPANY_BOOKKEEPING_DOCUMENTS_PUBLISHED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.PUBLISHED
)

export const COMPANY_BOOKKEEPING_DOCUMENT_COMMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.COMMENTS
)

export const COMPANY_BOOKKEEPING_DOCUMENT_EMAIL_CHAIN = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.EMAIL_CHAIN
)

export const COMPANY_BOOKKEEPING_SALES_DOCUMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.SALES_DOCUMENTS
)

export const COMPANY_BOOKKEEPING_SALES_DOCUMENTS_ARCHIVED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.SALES_DOCUMENTS,
  Segment.ARCHIVED
)

export const COMPANY_BOOKKEEPING_SALES_DOCUMENTS_INBOX = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.SALES_DOCUMENTS,
  Segment.INBOX
)

export const COMPANY_BOOKKEEPING_SALES_DOCUMENTS_PUBLISHED = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BOOKKEEPING,
  Segment.SALES_DOCUMENTS,
  Segment.PUBLISHED
)

export const COMPANY_CANCEL_SUBSCRIPTION = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.CANCEL
)
export const COMPANY_CONTACTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS
)

export const COMPANY_CONTACT_BANK_DETAILS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS,
  Segment.CONTACT_ID,
  Segment.BANK_DETAILS
)
export const COMPANY_CONTACT_GENERAL = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS,
  Segment.CONTACT_ID,
  Segment.GENERAL
)
export const COMPANY_CONTACT_RULES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS,
  Segment.CONTACT_ID,
  Segment.RULES
)
export const COMPANY_CUSTOMER_RULES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS,
  Segment.CONTACT_ID,
  Segment.CUSTOMER_RULES
)
export const COMPANY_SETTINGS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS
)
export const COMPANY_SETTINGS_ADVANCED_BOOKKEEPING = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.ADVANCED_BOOKKEEPING
)
export const COMPANY_SETTINGS_ADVANCED_BOOKKEEPING_DOCUMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.ADVANCED_BOOKKEEPING,
  Segment.DOCUMENTS
)
export const COMPANY_SETTINGS_ADVANCED_BOOKKEEPING_GENERAL = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.ADVANCED_BOOKKEEPING,
  Segment.GENERAL
)
export const COMPANY_SETTINGS_ADVANCED_BOOKKEEPING_SALES_DOCUMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.ADVANCED_BOOKKEEPING,
  Segment.SALES_DOCUMENTS
)
export const COMPANY_SETTINGS_ADVANCED_BOOKKEEPING_SYNC_MODE = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.ADVANCED_BOOKKEEPING,
  Segment.SYNC_MODE
)
export const COMPANY_SETTINGS_DETAILS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.DETAILS
)
export const COMPANY_SETTINGS_DIRECT_EMAILS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.DIRECT_EMAILS
)
export const COMPANY_SETTINGS_MEMBERS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.MEMBERS
)
export const COMPANY_SETTINGS_MEMBERS_COMPANY = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.MEMBERS,
  Segment.COMPANY
)
export const COMPANY_SETTINGS_MEMBERS_PRACTICE = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.MEMBERS,
  Segment.PRACTICE
)
export const COMPANY_SETTINGS_WORKFLOW = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SETTINGS,
  Segment.WORKFLOW
)
export const COMPANY_SUBSCRIBE_PLAN_ID_WITH_PAYER = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.SUBSCRIBE,
  Segment.PLAN_ID,
  Segment.PAYER_ID
)
export const COMPANY_SUBSCRIPTION_BILLING = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.MANAGE,
  Segment.BILLING
)

export const COMPANY_SUBSCRIPTION_INVOICES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.MANAGE,
  Segment.INVOICES
)

export const COMPANY_SUBSCRIPTION_INVOICE_DETAILS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.MANAGE,
  Segment.INVOICES,
  Segment.INVOICE_ID
)

export const COMPANY_SUBSCRIPTION_MANAGE = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.MANAGE
)

export const COMPANY_SUBSCRIPTION_PLAN = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.MANAGE,
  Segment.PLAN
)

export const COMPANY_SUBSCRIPTION_SELECT = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.SUBSCRIPTION,
  Segment.SELECT
)

export const COMPANY_SUPPLIER_RULES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONTACTS,
  Segment.CONTACT_ID,
  Segment.SUPPLIER_RULES
)
export const CONNECT_COMPANY = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.CONNECT
)

export const FORCE_2FA_SETUP = path(Segment.AUTH, Segment.SETUP_2FA)

export const INDEX = `/`
export const INVOICE_SOURCE = path(Segment.INVOICE_SOURCE, Segment.INVOICE_ID)
export const ONBOARDING = path(Segment.COMPANIES, Segment.COMPANY_ID, Segment.ONBOARDING)
export const ONBOARDING_COMPANY_SYNC = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.ONBOARDING,
  Segment.COMPANY_SYNC
)
export const PAY = path(Segment.COMPANIES, Segment.COMPANY_ID, Segment.PAY)
export const PAYMENT_LINK = path(Segment.PAYMENT, Segment.PAYRUN_ID)
export const PAYMENT_LINK_APPROVED = path(
  Segment.PAYMENT,
  Segment.PAYRUN_ID,
  Segment.APPROVED
)
export const PAYMENT_LINK_DELETED = path(
  Segment.PAYMENT,
  Segment.PAYRUN_ID,
  Segment.DELETED
)
export const PAYMENT_LINK_FAILED_TO_APPROVE = path(
  Segment.PAYMENT,
  Segment.PAYRUN_ID,
  Segment.FAILED_TO_APPROVE
)
export const PAYMENT_LINK_FAILED_TO_DELETE = path(
  Segment.PAYMENT,
  Segment.PAYRUN_ID,
  Segment.FAILED_TO_DELETE
)
export const PAYMENT_SUCCESS = path(Segment.PAYMENT, Segment.PAYRUN_ID, Segment.SUCCESS)

export const PAYMENT_VIEW = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAYMENT,
  Segment.PAYRUN_ID
)

export const PAYRUN_AUDIT_LOG = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.AUDIT_LOG,
  Segment.PAYRUN_ID
)

export const PAY_EXPENSES_MANUAL = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.EXPENSES,
  Segment.MANUAL_PAYMENT
)

export const PAY_EXPENSES_NEW = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.EXPENSES,
  Segment.NEW
)

export const PAY_INVOICES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES
)
export const PAY_INVOICES_DOCUMENT_COMMENTS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.COMMENTS
)

export const PAY_INVOICES_DOCUMENT_ID = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.DOCUMENTS,
  Segment.DOCUMENT_ID,
  Segment.DETAILS
)

export const PAY_INVOICES_PAID = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PAID
)

export const PAY_INVOICES_PAID_PAYMENT = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PAID,
  Segment.PAYMENT_ID
)

export const PAY_INVOICES_PAY = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PAY
)

export const PAY_INVOICES_PAY_BATCH = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PAY,
  Segment.BATCH
)

export const PAY_INVOICES_PENDING = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PENDING
)

export const PAY_INVOICES_PENDING_PAYMENT = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.PENDING,
  Segment.PAYMENT_ID
)

export const PAY_INVOICES_REVIEW = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.INVOICES,
  Segment.REVIEW
)

export const PAY_REVIEW = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.REVIEW
)

export const PAY_REVIEW_PAYMENT_LINK = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.REVIEW_PAYMENT_LINK,
  Segment.PAYRUN_ID
)

export const PRACTICE_ONBOARDING = path(Segment.ONBOARDING, Segment.PRACTICE)

export const PRACTICE_SUBSCRIPTION = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION
)

export const PRACTICE_SUBSCRIPTION_BILLING = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION,
  Segment.BILLING
)

export const PRACTICE_SUBSCRIPTION_CALLBACK = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION,
  Segment.CALLBACK
)

export const PRACTICE_SUBSCRIPTION_INVOICES = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION,
  Segment.INVOICES
)

export const PRACTICE_SUBSCRIPTION_INVOICE_DETAILS = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION,
  Segment.INVOICES,
  Segment.INVOICE_ID
)

export const PRACTICE_SUBSCRIPTION_PLANS = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.SUBSCRIPTION,
  Segment.PLANS
)
export const PRACTICE_TEAM = path(Segment.COMPANIES, Segment.HUB, Segment.TEAM)
export const PRACTICE_TEAM_MEMBER = path(
  Segment.COMPANIES,
  Segment.HUB,
  Segment.TEAM,
  Segment.USER_ID
)
export const REMITTANCE_ADVICE = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.PAY,
  Segment.REMITTANCE_ADVICE,
  Segment.PAYRUN_ID
)
export const USER_ONBOARDING = path(Segment.ONBOARDING, Segment.USER)
export const USER_SECURITY_SETTINGS = path(
  Segment.USER,
  Segment.SETTINGS,
  Segment.SECURITY
)

export const USER_SETTINGS = path(Segment.USER, Segment.SETTINGS)

export const USER_SETTINGS_PROFILE = path(Segment.USER, Segment.SETTINGS, Segment.PROFILE)

export const VERIFY_IDENTITIES = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.VERIFY,
  Segment.IDENTITIES
)
