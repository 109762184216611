import { ProgressBar, type ProgressBarProps } from '../progress-bar'

export interface StepBarProps<Value> extends Omit<ProgressBarProps, 'value' | 'total'> {
  value: Value
  steps: Value[] | undefined
}

export const StepBar = <Value,>({
  value,
  steps = [],
  css,
  ...rest
}: StepBarProps<Value>) => {
  const index = Math.max(steps.indexOf(value) + 1, 1)
  const total = steps.length + 1

  if (steps.length === 0) {
    return null
  }

  return (
    <ProgressBar
      value={index}
      total={total}
      css={{ ...css, width: 88, height: 4 }}
      {...rest}
    />
  )
}
