import {
  isBetween,
  isSameDay,
  isSameOrBefore,
  isSameOrAfter,
  isAfter,
} from 'kitchen/utils/date-time'

import type { DateRange, SpecialDate, Day, DayMeta } from './types'

type HoverRangeResult = {
  isInHoveredRange: boolean
  isHoverRangeStart: boolean
  isHoverRangeEnd: boolean
}

function calculateHoverRange({
  day,
  selectedFrom,
  selectedTo,
  hoveredDate,
  singleDate,
  activeRangePart,
}: CalculateMetaParams): HoverRangeResult {
  if (singleDate || !hoveredDate || Boolean(selectedFrom) === Boolean(selectedTo)) {
    return { isInHoveredRange: false, isHoverRangeStart: false, isHoverRangeEnd: false }
  }

  // active range part is controlled by calendar parent
  if (activeRangePart) {
    const isReSelectingFrom = selectedFrom && activeRangePart === 'dateFrom'
    const isReSelectingTo = selectedTo && activeRangePart === 'dateTo'

    if (isReSelectingFrom || isReSelectingTo) {
      return { isInHoveredRange: false, isHoverRangeStart: false, isHoverRangeEnd: false }
    }
  }

  const isRangeStart = isSameDay(selectedFrom, day.date)
  const isRangeEnd = isSameDay(selectedTo, day.date)

  // direct case
  if (selectedFrom && !selectedTo) {
    const isInHoveredRange = isBetween(day.date, selectedFrom, hoveredDate)

    const isHoverRangeStart =
      isInHoveredRange &&
      ((isRangeStart && isSameOrAfter(hoveredDate, day.date)) ||
        (isSameDay(hoveredDate, day.date) && isSameOrBefore(day.date, selectedFrom)))

    const isHoverRangeEnd =
      isInHoveredRange &&
      ((isRangeStart && isSameOrBefore(hoveredDate, day.date)) ||
        (isSameDay(hoveredDate, day.date) && isSameOrAfter(day.date, selectedFrom)))

    return { isInHoveredRange, isHoverRangeStart, isHoverRangeEnd }
  }

  // reversed case
  if (!selectedFrom && selectedTo) {
    const isInHoveredRange =
      isBetween(day.date, selectedTo, hoveredDate) && isAfter(selectedTo, hoveredDate)

    const isHoverRangeStart =
      isInHoveredRange &&
      ((isRangeEnd && isSameOrAfter(hoveredDate, day.date)) ||
        (isSameDay(hoveredDate, day.date) && isSameOrBefore(day.date, selectedTo)))

    const isHoverRangeEnd =
      isInHoveredRange &&
      ((isRangeEnd && isSameOrBefore(hoveredDate, day.date)) ||
        (isSameDay(hoveredDate, day.date) && isSameOrAfter(day.date, selectedTo)))

    return { isInHoveredRange, isHoverRangeStart, isHoverRangeEnd }
  }

  return { isInHoveredRange: false, isHoverRangeStart: false, isHoverRangeEnd: false }
}

function isDayDisabled({ day, disabledDates }: { day: Day; disabledDates: DateRange[] }) {
  return disabledDates.some((range) => isDayInRange({ day, range }))
}

function isDayInRange({ day, range }: { day: Day; range: DateRange }) {
  if (!range.dateTo) {
    // to Infinity
    return isSameOrAfter(day.date, range.dateFrom, 'day')
  }
  if (!range.dateFrom) {
    // from -Infinity
    return isSameOrBefore(day.date, range.dateTo, 'day')
  }
  return isBetween(day.date, range.dateFrom, range.dateTo)
}

interface CalculateMetaParams {
  day: Day
  specialDates: SpecialDate[]
  disabledDates: DateRange[]
  disabledDateChecker?: (date: Date) => boolean
  selectedFrom?: Date
  selectedTo?: Date
  hoveredDate?: Date
  singleDate: boolean
  activeRangePart?: keyof DateRange
}

export function calculateDayMeta(metaParams: CalculateMetaParams): Partial<DayMeta> {
  const {
    day,
    specialDates,
    disabledDates,
    disabledDateChecker,
    selectedFrom,
    selectedTo,
  } = metaParams
  const isDisabled =
    disabledDateChecker?.(day.date.toDate()) || isDayDisabled({ day, disabledDates })
  const isRangeStart = isSameDay(selectedFrom, day.date)
  const isRangeEnd = isSameDay(selectedTo, day.date)
  const specialDate = specialDates.find(({ date }) => isSameDay(day.date, date))

  const isInSelectedRange = Boolean(
    selectedFrom && selectedTo && isBetween(day.date, selectedFrom, selectedTo)
  )

  const { isInHoveredRange, isHoverRangeStart, isHoverRangeEnd } =
    calculateHoverRange(metaParams)

  return {
    isInSelectedRange,
    isInHoveredRange,
    isSelectedFrom: isInHoveredRange ? isHoverRangeStart : isRangeStart,
    isSelectedTo: isInHoveredRange ? isHoverRangeEnd : isRangeEnd,
    isDisabled,
    ...(specialDate && { specialDate }),
  }
}
