const rotation = {
  left: 0,
  right: 180,
} as const

interface ChevronProps {
  variant?: keyof typeof rotation
}

export const Chevron = ({ variant = 'left' }: ChevronProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ transform: `rotate(${rotation[variant]}deg)` }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7929 2.29289L16.2071 3.70711L7.91424 12L16.2071 20.2929L14.7929 21.7071L5.08582 12L14.7929 2.29289Z"
      fill="currentColor"
    />
  </svg>
)
