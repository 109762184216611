import { useControlled } from 'kitchen/hooks/use-controlled'
import { useIbanRifm } from 'kitchen/hooks/use-iban-rifm'
import { forwardRef } from 'react'
import { Input, type InputProps } from '../input'

export interface IbanInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
}

export const IbanInput = forwardRef<HTMLInputElement, IbanInputProps>(
  function IbanInputRef(
    { defaultValue = '', value: controlledValue, onChange, ...rest },
    forwardedRef
  ) {
    const [value, setValue] = useControlled<string>({
      defaultValue,
      value: controlledValue,
      onChange,
    })

    const rifm = useIbanRifm({
      value,
      onChange: setValue,
    })

    return (
      <Input
        ref={forwardedRef}
        placeholder="#### #### #### #### #### ##"
        {...rifm}
        {...rest}
      />
    )
  }
)
