import type { RegisteredUser, User } from 'api/types/users'
import { Controller, useForm, yupResolver } from 'kitchen/forms'
import { Button, Input, InputGroup, Label, NumberInput } from 'salad/components'
import { HStack, VStack } from 'salad/primitives'
import { PhoneCountryCallingCodeSelectPopover } from '../../../phone/components'
import { usePhoneCountryCallingCodes } from '../../../phone/hooks'
import type { FormValues } from './types'
import { getDefaultValues, getValidationSchema } from './utils'

interface UpdateUserDetailsFormProps {
  user: RegisteredUser | User
  isLoading: boolean
  onSubmit: (formValues: FormValues) => void
  onCancel: () => void
}

export function UpdateUserDetailsForm({
  user,
  isLoading,
  onSubmit,
  onCancel,
}: UpdateUserDetailsFormProps) {
  const phoneCountryCallingCodes = usePhoneCountryCallingCodes()

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: getDefaultValues(user, phoneCountryCallingCodes),
    resolver: yupResolver(getValidationSchema()),
    mode: 'onSubmit',
  })

  return (
    <VStack
      gap={32}
      as="form"
      onSubmit={handleSubmit((formValues) => {
        onSubmit(formValues)
      })}
    >
      <InputGroup.Root>
        <InputGroup.Label>First name</InputGroup.Label>
        <Input {...register('firstName')} disabled={isLoading} />
      </InputGroup.Root>
      <InputGroup.Root>
        <InputGroup.Label>Last name</InputGroup.Label>
        <Input {...register('lastName')} disabled={isLoading} />
      </InputGroup.Root>
      <VStack gap={8}>
        <Label>Phone number (optional)</Label>
        <HStack
          css={{ justifyContent: 'space-between', gridAutoColumns: '1fr 4fr' }}
          gap={24}
        >
          <Controller
            name="phoneCountryCallingCode"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const hasErrors = error !== undefined

              return (
                <InputGroup.Root>
                  <PhoneCountryCallingCodeSelectPopover
                    phoneCountryCallingCodes={phoneCountryCallingCodes}
                    value={field.value}
                    isInvalid={hasErrors}
                    onSelect={(value) => {
                      setValue('phoneCountryCallingCode', value, {
                        shouldValidate: true,
                      })
                    }}
                  />
                </InputGroup.Root>
              )
            }}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const hasErrors = error !== undefined

              return (
                <InputGroup.Root>
                  <NumberInput {...field} aria-invalid={hasErrors} />
                </InputGroup.Root>
              )
            }}
          />
        </HStack>
        <InputGroup.Message
          variant={errors.phoneCountryCallingCode ? 'negative' : 'neutral'}
        >
          {errors.phoneCountryCallingCode?.message || 'For WhatsApp invoice submission'}
        </InputGroup.Message>
      </VStack>
      <HStack gap={8} css={{ gridTemplateColumns: 'min-content 1fr' }}>
        <Button.Root variant="minor" onClick={onCancel} disabled={isLoading}>
          <Button.Content>Cancel</Button.Content>
        </Button.Root>
        <Button.Root type="submit" variant="common" loading={isLoading}>
          <Button.Content>Save</Button.Content>
        </Button.Root>
      </HStack>
    </VStack>
  )
}
