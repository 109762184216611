import type { UserRole, UserPermission } from 'api/types/permissions'
import { ImpossibleError } from 'kitchen/utils/error'
import { mapRolesToPaymentPermissions } from 'kitchen/utils/permissions'
import type { PresetType } from './types'

export const checkRoleCanBeAssigned =
  (currentUserPermissions: UserPermission[]) => (role: UserRole) => {
    return mapRolesToPaymentPermissions[role].every((permission) =>
      currentUserPermissions.includes(permission)
    )
  }

export const getPresetTypeLabel = (input: PresetType) => {
  switch (input) {
    case 'admin':
      return 'Admin role'
    case 'standard':
      return 'Standard role'
    default:
      throw new ImpossibleError('unhandled preset type', input)
  }
}

export function getRoleDescription(role: UserRole) {
  switch (role) {
    case 'ADMIN':
      return "Sets up teams and workflows. Has a general overview of most activities. Needs 'Payroll' permission for full access."
    case 'PAYER':
      return 'Edits, authorises and completes approved payments with the bank.'
    case 'APPROVER':
      return 'Edits and approves payments within Apron, but does not pay.'
    case 'CREATOR':
      return 'Creates payments for others to approve and pay.'
    case 'CUSTOM':
      return 'Set your own parameters. Some options are mutually exclusive.'
    default:
      throw new ImpossibleError('unhandled role', role)
  }
}
