import type { CSS } from '../stitches'
import { theme } from '../stitches'
import type { FontWeight } from '../tokens'

interface WeightStyles extends Record<FontWeight, CSS> {}

export const fontWeight = Object.keys(theme.fontWeights).reduce((acc, key) => {
  acc[key] = {
    fontWeight: theme.fontWeights[key],
    fontVariationSettings: `'wght' ${theme.fontWeights[key]}`,
  }
  return acc
}, {} as WeightStyles)
