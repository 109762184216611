import { Flex } from '../../primitives'
import { styled, theme } from '../../stitches'

export const Cell = styled(Flex, {
  margin: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  size: '32px',
  borderRadius: theme.radii['round'],
})
