import { VStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'

export interface AttachmentStatusProps {
  children?: React.ReactNode
}

function AttachmentStatus({ children }: AttachmentStatusProps) {
  return (
    <VStack
      px={8}
      gap={2}
      css={{
        placeItems: 'center',
        placeContent: 'center',
        backgroundColor: theme.colors['grey-2'],
      }}
    >
      {children}
    </VStack>
  )
}

export interface AttachmentStatusTitleProps {
  children?: React.ReactNode
}

function AttachmentStatusTitle({ children }: AttachmentStatusTitleProps) {
  return (
    <Text as="p" variant="headline-h1" color="grey-40">
      {children}
    </Text>
  )
}

export interface AttachmentStatusDescriptionProps {
  children?: React.ReactNode
}

function AttachmentStatusDescription({ children }: AttachmentStatusDescriptionProps) {
  return (
    <Text as="p" variant="paragraph-16">
      {children}
    </Text>
  )
}

export {
  AttachmentStatus as Root,
  AttachmentStatusTitle as Title,
  AttachmentStatusDescription as Description,
}
