import type { User } from 'api/types/users'
import type { SignupVendorResponse, ConnectVendorResponse } from 'api/types/vendors'
import type { AuthMode, UserJwtTokenPayload } from 'domains/auth/types'
import { formatUserDetails } from '../user/utils'

interface AuthResponseRedirectPayload {
  authMode: AuthMode
  response: SignupVendorResponse | ConnectVendorResponse
  returnUrl?: string
}

// piggy-back on BE-generated state param to get it back in Vendor callback response.
export function authResponseRedirect(input: AuthResponseRedirectPayload) {
  const url = new URL(input.response.location)

  const state = url.searchParams.get('state') ?? ''
  const value = JSON.stringify({
    state,
    authMode: input.authMode,
    returnUrl: input.returnUrl,
  })

  url.searchParams.set('state', value)
  window.location.assign(url)
}

interface AuthResponseStateParams {
  state: string
  authMode?: AuthMode
  returnUrl?: string
}

export function parseAuthResponseState(input: URLSearchParams): AuthResponseStateParams {
  const state = input.get('state')

  if (state === null) {
    throw new Error('state url parameter is required')
  }

  return JSON.parse(state)
}

export function decodeJwtPayload(accessToken: string) {
  const content: UserJwtTokenPayload = JSON.parse(atob(accessToken.split('.')[1]))
  return content
}

export function getForce2FaInitiatorDetails(initiator: User) {
  return formatUserDetails(initiator, { fallbackToEmail: true, showEmail: true })
}
