import { Grid } from '../../primitives'
import { theme, styled } from '../../stitches'

export const Point = styled(Grid, {
  placeItems: 'center',
  borderRadius: theme.radii['round'],
  size: theme.space[24],
  variants: {
    variant: {
      green: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['light-green-60'],
      },
      grey: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['grey-10'],
      },
    },
    active: {
      true: {
        backgroundColor: theme.colors['light-green'],
      },
      false: {
        backgroundColor: theme.colors['transparent'],
        borderColor: theme.colors['grey-15'],
        borderWidth: 2,
        borderStyle: 'solid',
        color: theme.colors['transparent'],
      },
    },
  },
  defaultVariants: {
    variant: 'green',
  },
})
