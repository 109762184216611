import { Slot } from '@radix-ui/react-slot'
import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { Ellipsis } from '../../primitives'
import { theme, styled } from '../../stitches'
import { typography } from '../../variants'
import * as Button from '../button'

const FilterButtonSlot = styled(Slot, {
  ...typography['button-small'],
  '[data-filter-button-label]': {
    marginInlineStart: theme.space[0],
    marginInlineEnd: theme.space[0],
  },
  '[data-filter-button-label] + [data-filter-button-content]': {
    marginInlineStart: theme.space[6],
  },
  '[data-filter-button-end]': {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.space[4],
    alignItems: 'center',
    marginInlineEnd: theme.space[0],
  },
  '[data-filter-button-content]': {
    minWidth: 0,
    maxWidth: '100%',
  },
  variants: {
    active: {
      true: {
        '&:not(:disabled) [data-filter-button-label]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-filter-button-label]:not([data-icon])::after': {
          content: ':',
        },
      },
      false: {},
    },
  },
})

export interface FilterButtonProps
  extends Omit<Button.ButtonProps, 'variant' | 'size' | 'elevated' | 'radius' | 'hug'> {}

const FilterButton: GenericForwardRefComponent<FilterButtonProps> = forwardRef(
  function FilterButton({ children, active, ...rest }, ref) {
    return (
      <FilterButtonSlot active={active}>
        <Button.Root
          ref={ref}
          variant="minor"
          radius="round"
          size="small"
          active={active}
          // TODO: Remove in LIF-48
          data-active={active}
          hug
          {...rest}
        >
          {children}
        </Button.Root>
      </FilterButtonSlot>
    )
  }
)

export interface FilterButtonLabelProps {
  children?: React.ReactNode
}

function FilterButtonLabel({ children, ...rest }: FilterButtonLabelProps) {
  return (
    <Button.Start data-filter-button-label {...rest}>
      {children}
    </Button.Start>
  )
}

export interface FilterButtonContentProps {
  children?: React.ReactNode
}

function FilterButtonContent({ children, ...rest }: FilterButtonContentProps) {
  return (
    <Ellipsis asChild>
      <Button.Content data-filter-button-content {...rest}>
        {children}
      </Button.Content>
    </Ellipsis>
  )
}

export interface FilterButtonEndProps {
  children?: React.ReactNode
}

function FilterButtonEnd({ children, ...rest }: FilterButtonEndProps) {
  return (
    <Button.End data-filter-button-end {...rest}>
      {children}
    </Button.End>
  )
}

export {
  FilterButton as Root,
  FilterButtonLabel as Label,
  FilterButtonContent as Content,
  FilterButtonEnd as End,
}
