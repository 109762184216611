import { useContext } from 'react'

export function useRequiredContext<T>(
  name: string,
  context: React.Context<T>
): NonNullable<T> {
  const value = useContext(context)

  if (value === null || value === undefined) {
    throw new Error(`Hook must be used inside context provider of ${name}`)
  }

  return value
}
