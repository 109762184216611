import type { ApiRequest } from 'kitchen/types'
import type { SendAnalyticsEventPayload } from './types'

export const sendAnalyticsEvent: ApiRequest<SendAnalyticsEventPayload, void> = (
  fetch,
  payload
) => {
  const { data, ...rest } = payload
  return fetch
    .post('/apron/frontend-event', {
      json: {
        data: JSON.stringify(data),
        ...rest,
      },
    })
    .void()
}
