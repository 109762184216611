export const Search = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM10.4262 11.9262C9.45479 12.6031 8.27375 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.27375 12.6031 9.45479 11.9262 10.4262L15 13.5L13.5 15L10.4262 11.9262Z"
      fill="currentColor"
    />
  </svg>
)
