import type { CurrencyCode } from 'domains/money/types'
import { useRifm } from 'rifm'
import * as formats from '../utils/formats'
import * as regex from '../utils/regex'

export interface ReferenceRifmOptions
  extends Omit<Parameters<typeof useRifm>[0], 'format'> {
  currency: CurrencyCode
}

export const useReferenceRifm = (options: ReferenceRifmOptions) =>
  useRifm({
    ...options,
    accept: regex.reference,
    value: formats.reference(options.value, options.currency),
    format: (value) => formats.reference(value, options.currency),
    onChange: (value) => options.onChange(formats.reference(value, options.currency)),
  })
