import * as Sentry from '@sentry/react'
import { rudderAnalytics } from 'domains/analytics/rudderstack'
import { useAuthChannel } from 'domains/auth/hooks'
import { useRevokeAuthSession } from 'domains/auth/queries'
import { useNavigate } from 'kitchen/router'
import { setLogoutLocation } from 'kitchen/utils/auth'
import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Path } from '../constants'

interface LogoutParams {
  broadcastToAllTabs?: boolean
}

export function useLogout() {
  const navigate = useNavigate()
  const intercom = useIntercom()
  const authChannel = useAuthChannel()
  const { mutate: revokeAuthSession } = useRevokeAuthSession()

  return useCallback(
    async ({ broadcastToAllTabs = true }: LogoutParams = {}) => {
      navigate(Path.AUTH, { replace: true })
      revokeAuthSession()
      setLogoutLocation(null)

      if (broadcastToAllTabs) {
        authChannel.post({ type: 'logout' })
      }
      Sentry.setUser(null)
      rudderAnalytics.reset()
      intercom.shutdown()
      intercom.boot({
        customAttributes: {
          env: process.env.BUILD_MODE,
        },
      })
    },
    [authChannel, revokeAuthSession, intercom, navigate]
  )
}
