import { useState } from 'react'
import { StepBar } from '../step-bar'
import * as CustomScrollArea from './custom-scroll-area'

const preventPointerEvent = (event: React.PointerEvent<HTMLDivElement>) =>
  event.preventDefault()

export interface StepBarScrollAreaProps extends CustomScrollArea.CustomScrollAreaProps {
  steps: number[]
}

export function StepBarScrollArea({ children, steps, ...rest }: StepBarScrollAreaProps) {
  const [value, setValue] = useState<number>(0)

  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth } = event.currentTarget
    const progress = scrollLeft / scrollWidth

    setValue(Math.round(steps.length * progress))
  }

  return (
    <CustomScrollArea.Root type="auto" {...rest}>
      <CustomScrollArea.Viewport onScroll={handleScroll}>
        {children}
      </CustomScrollArea.Viewport>
      <CustomScrollArea.Scrollbar
        orientation="horizontal"
        onPointerDown={preventPointerEvent}
        onPointerMove={preventPointerEvent}
        onPointerUp={preventPointerEvent}
      >
        <StepBar value={value} steps={steps} mx="auto" />
      </CustomScrollArea.Scrollbar>
    </CustomScrollArea.Root>
  )
}
