const literal = {
  w600: "'wght' 600",
  w700: "'wght' 700",
  w900: "'wght' 900",
} as const

const semantic = {
  'price-tag-large-main': literal.w700,
  'price-tag-large-support': literal.w900,
  'price-tag-medium-main': literal.w700,
  'price-tag-medium-support': literal.w900,
  'price-tag-small-main': literal.w700,
  'price-tag-small-support': literal.w900,
  'headline-h1': literal.w700,
  'headline-h2': literal.w700,
  'headline-h3': literal.w700,
  'button-large': literal.w600,
} as const

export const fontVariations = {
  ...literal,
  ...semantic,
} as const

export type FontVariation = keyof typeof fontVariations
