import { Time } from 'kitchen/constants'
import { useRef, memo } from 'react'
import * as Icons from '../../icons'
import { keyframes, styled } from '../../stitches'
import { textColor } from '../../variants'

const rotation = keyframes({
  '0%': {
    transform: 'rotate(-90deg)',
  },
  '50%': {
    transform: 'rotate(270deg)',
  },
  '100%': {
    transform: 'rotate(270deg)',
  },
})

const draw = keyframes({
  '0%': { strokeDashoffset: 64 },
  '50%': { strokeDashoffset: 0 },
  '100%': { strokeDashoffset: -64 },
})

const ANIMATION_DURATION = 2 * Time.SECOND

const SpinnerBase = styled(Icons.S24.Arc, {
  strokeDasharray: 64,
  strokeDashoffset: 64,
  strokeLinecap: 'round',
  animation: `${draw} ${ANIMATION_DURATION}ms ease-in infinite, ${rotation} ${ANIMATION_DURATION}ms ease-out infinite`,
  animationDelay: 'var(--spinner-delay)',
  variants: {
    color: textColor,
    size: {
      16: { size: 16 },
      24: { size: 24 },
      32: { size: 32 },
      48: { size: 48 },
      64: { size: 64 },
    },
  },
})

export interface SpinnerProps extends React.ComponentProps<typeof SpinnerBase> {}

export const Spinner = memo(function MemoizedSpinner({ css, ...rest }: SpinnerProps) {
  const mountTime = useRef(Date.now())
  const mountDelay = -(mountTime.current % ANIMATION_DURATION)

  return (
    <SpinnerBase
      css={{
        '--spinner-delay': `${mountDelay}ms`,
        ...css,
      }}
      {...rest}
    />
  )
})
