import type { Company } from 'api/types/companies'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import { useNavigate, generatePath } from 'kitchen/router'
import { Container, Separator, PageHeader } from 'salad/components'
import { VStack, HStack, Box } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { media } from 'salad/tokens'
import { TrialStatusChip } from '@app/components/trial-status-chip'
import { Path } from '@app/constants'
import { AppSidebar } from '../app-sidebar'
import { CompanySwitcher } from '../company-switcher'
import { SideDrawer } from '../side-drawer'

export type AppHeaderProps =
  | {
      layout: 'company-page'
      company: Company
      title?: React.ReactNode
      onBack?: () => void
      control?: React.ReactNode
    }
  | {
      layout: 'hub-page'
      practice: Company | undefined
      title?: React.ReactNode
      onBack?: () => void
      control?: React.ReactNode
    }

export function AppHeader(props: AppHeaderProps) {
  const navigate = useNavigate()
  const isBp2 = useMatchMedia(media['bp2'])

  if (isBp2) {
    if (props.title === undefined) {
      return <Box mb={24} />
    }

    return (
      <Container
        css={{
          paddingBlock: theme.space[24],
          backgroundColor: theme.colors['page-background'],
        }}
      >
        <PageHeader.Root>
          {props.onBack && <PageHeader.Back onClick={props.onBack} />}
          <PageHeader.Title>{props.title}</PageHeader.Title>
          {props.layout === 'company-page' && props.onBack === undefined && (
            <TrialStatusChip company={props.company} />
          )}
          <PageHeader.Actions>{props.control}</PageHeader.Actions>
        </PageHeader.Root>
      </Container>
    )
  }

  return (
    <VStack mb={16} css={{ backgroundColor: theme.colors['white'] }}>
      <Container>
        <HStack
          gap={16}
          css={{
            height: 72,
            alignItems: 'center',
            gridAutoColumns: 'auto 1fr auto',
          }}
        >
          <SideDrawer>
            <AppSidebar {...props} />
          </SideDrawer>
          {props.layout === 'company-page' && (
            <>
              <CompanySwitcher
                variant="header"
                value={props.company}
                onValueChange={(company) =>
                  navigate(generatePath(Path.PAY_INVOICES_PAY, { companyId: company.id }))
                }
              />
              <HStack css={{ minWidth: 40, alignItems: 'center' }}>
                {props.control}
              </HStack>
            </>
          )}
        </HStack>
      </Container>
      <Separator size={1} css={{ marginBlockStart: -1 }} />
    </VStack>
  )
}
