import { styled, theme } from 'salad/stitches'
import { typography } from '../../variants'

export interface MessageProps extends React.ComponentProps<typeof Message> {}

export const Message = styled('span', {
  display: 'block',
  ...typography['paragraph-14'],
  '[aria-invalid="true"] ~ &': {
    color: theme.colors['dark-red'],
  },
  '[aria-required="true"] ~ &': {
    color: theme.colors['dark-yellow'],
  },
  variants: {
    variant: {
      neutral: {
        color: theme.colors['grey-60'],
      },
      warning: {
        color: theme.colors['dark-yellow'],
      },
      negative: {
        color: theme.colors['dark-red'],
      },
    },
    align: {
      start: { textAlign: 'start' },
      center: { textAlign: 'center' },
      end: { textAlign: 'end' },
    },
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
      },
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
})
