import { forwardRef } from 'react'
import { styled, theme } from '../../stitches'

const RadioIndicatorSurface = styled('span', {
  display: 'grid',
  placeItems: 'center',
  size: theme.space[20],
  margin: theme.space[2],
  borderRadius: theme.radii['round'],
  borderWidth: theme.space[2],
  borderStyle: 'solid',
  borderColor: theme.colors['black'],
  transitionProperty: 'border-color',
  transitionDuration: '0.2s',
  '&[data-disabled]': {
    borderColor: theme.colors['black-alpha-20'],
    pointerEvents: 'none',
  },
  '&[data-state="checked"]': {
    borderStyle: theme.colors['dark-blue'],
    backgroundColor: theme.colors['dark-blue'],
    '&[data-disabled]': { opacity: 0.8 },
  },
})

const RadioIndicatorShape = styled('span', {
  display: 'block',
  size: theme.space[12],
  borderRadius: theme.radii['round'],
  borderWidth: theme.space[2],
  borderColor: 'transparent',
  borderStyle: 'solid',
  transitionProperty: 'background-color, border-color',
  transitionDuration: '0.2s',
  ':hover:not([data-state="checked"]) > &': {
    borderColor: theme.colors['grey-15'],
    backgroundClip: 'content-box',
    backgroundColor: theme.colors['grey-15'],
  },
  '[data-state="checked"] > &': {
    backgroundColor: theme.colors['white'],
    backgroundClip: 'content-box',
    borderColor: 'transparent',
  },
})

interface RadioIndicatorProps {
  checked?: boolean
  disabled?: boolean
}

export const RadioIndicator = forwardRef<HTMLSpanElement, RadioIndicatorProps>(
  function RadioIndicatorRef({ checked, disabled, ...rest }, ref) {
    return (
      <RadioIndicatorSurface
        ref={ref}
        /**
         * Compatibility states with RadioGroup from radix
         * https://www.radix-ui.com/docs/primitives/components/radio-group
         */
        data-state={checked ? 'checked' : undefined}
        data-disabled={disabled || undefined}
        {...rest}
      >
        <RadioIndicatorShape />
      </RadioIndicatorSurface>
    )
  }
)
