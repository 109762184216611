import * as Sentry from '@sentry/react'
import type { Company } from 'api/types/companies'
import { ErrorBoundaryFallback } from './error-boundary-fallback'

interface ErrorBoundaryBaseProps {
  children: React.ReactNode
  onBack?: () => void
  onRetry?: () => void
}

interface FlowErrorBoundaryProps extends ErrorBoundaryBaseProps {
  layout: 'flow-page'
}

interface CompanyErrorBoundaryProps extends ErrorBoundaryBaseProps {
  layout: 'company-page'
  company: Company
}

interface HubErrorBoundaryProps extends ErrorBoundaryBaseProps {
  layout: 'hub-page'
  practice: Company | undefined
}

type ErrorBoundaryProps =
  | FlowErrorBoundaryProps
  | CompanyErrorBoundaryProps
  | HubErrorBoundaryProps

export function ErrorBoundary({ children, ...rest }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFallback {...rest} error={error} resetError={resetError} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
