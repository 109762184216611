import { useHasPractice } from 'api/hooks/companies'
import { useHasFeatureFlag } from 'api/hooks/features'
import type { Company } from 'api/types/companies'
import { getDefaultBankFeedFilterSearch } from 'domains/bank-feed/utils'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import {
  useNavigate,
  generatePath,
  ActiveRouterLink,
  MatchesPatterns,
} from 'kitchen/router'
import { Item, Separator, SidebarNav } from 'salad/components'
import * as Icons from 'salad/icons'
import { Box } from 'salad/primitives'
import { media } from 'salad/tokens'
import { Path } from '@app/constants'
import { CompanySwitcher } from '../company-switcher'

interface AppSidebarCompanyNavItemsProps {
  company: Company
}

export function AppSidebarCompanyNavItems({ company }: AppSidebarCompanyNavItemsProps) {
  const [hasSalesInvoices] = useHasFeatureFlag(company.id, ['SALES_INVOICES'])
  const navigate = useNavigate()
  const isBp2 = useMatchMedia(media['bp2'])
  const hasPractice = useHasPractice()

  return (
    <>
      <SidebarNav.Item as={ActiveRouterLink} to={Path.COMPANIES_HUB}>
        <Item.Start>
          <Icons.S24.Arrow variant="left" />
        </Item.Start>
        <Item.Content>{hasPractice ? 'Practice Hub' : 'Hub'}</Item.Content>
      </SidebarNav.Item>
      {isBp2 ? (
        <Box py={16}>
          <CompanySwitcher
            variant="sidebar"
            value={company}
            onValueChange={(company) =>
              navigate(generatePath(Path.COMPANIES_COMPANY_ID, { companyId: company.id }))
            }
          />
        </Box>
      ) : (
        <Separator variant="solid" size={1} my={12} px={10} />
      )}
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.PAY_INVOICES_PAY, { companyId: company.id })}
        match={[{ path: Path.PAY, end: false }]}
      >
        <Item.Start>
          <Icons.S24.CircleArrowRight />
        </Item.Start>
        <Item.Content>Pay</Item.Content>
      </SidebarNav.Item>
      <MatchesPatterns
        patterns={[
          { path: Path.COMPANY_BOOKKEEPING, end: false },
          { path: Path.COMPANY_BANK_FEED, end: false },
        ]}
      >
        {(matches) => (
          <>
            <SidebarNav.Item
              as={ActiveRouterLink}
              to={generatePath(Path.COMPANY_BOOKKEEPING_DOCUMENTS_INBOX, {
                companyId: company.id,
              })}
              indicator="text-color"
              aria-current={matches}
            >
              <Item.Start>
                <Icons.S24.Document />
              </Item.Start>
              <Item.Content>Bookkeeping</Item.Content>
              <Item.End>
                <Icons.S16.Dropdown variant={matches ? 'pressed' : 'default'} />
              </Item.End>
            </SidebarNav.Item>
            {matches && (
              <>
                <SidebarNav.Item
                  as={ActiveRouterLink}
                  to={generatePath(Path.COMPANY_BOOKKEEPING_DOCUMENTS_INBOX, {
                    companyId: company.id,
                  })}
                  match={[{ path: Path.COMPANY_BOOKKEEPING_DOCUMENTS, end: false }]}
                >
                  <Item.Start css={{ size: 24 }} />
                  <Item.Content>
                    {hasSalesInvoices ? 'Purchases' : 'Documents'}
                  </Item.Content>
                </SidebarNav.Item>
                {hasSalesInvoices && (
                  <SidebarNav.Item
                    as={ActiveRouterLink}
                    to={generatePath(Path.COMPANY_BOOKKEEPING_SALES_DOCUMENTS_INBOX, {
                      companyId: company.id,
                    })}
                    match={[
                      { path: Path.COMPANY_BOOKKEEPING_SALES_DOCUMENTS, end: false },
                    ]}
                  >
                    <Item.Start css={{ size: 24 }} />
                    <Item.Content>Sales</Item.Content>
                  </SidebarNav.Item>
                )}
                <SidebarNav.Item
                  as={ActiveRouterLink}
                  to={{
                    pathname: generatePath(Path.COMPANY_BANK_FEED, {
                      companyId: company.id,
                    }),
                    search: getDefaultBankFeedFilterSearch().toString(),
                  }}
                  match={[{ path: Path.COMPANY_BANK_FEED, end: false }]}
                >
                  <Item.Start css={{ size: 24 }} />
                  <Item.Content>Unreconciled Transactions</Item.Content>
                </SidebarNav.Item>
              </>
            )}
          </>
        )}
      </MatchesPatterns>
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.COMPANY_CONTACTS, {
          companyId: company.id,
        })}
        match={[{ path: Path.COMPANY_CONTACTS, end: false }]}
      >
        <Item.Start>
          <Icons.S24.Contacts />
        </Item.Start>
        <Item.Content>Contacts</Item.Content>
      </SidebarNav.Item>
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={generatePath(Path.COMPANY_SETTINGS, {
          companyId: company.id,
        })}
        match={[
          { path: Path.COMPANY_SETTINGS, end: false },
          { path: Path.COMPANY_SUBSCRIPTION_MANAGE, end: false },
        ]}
      >
        <Item.Start>
          <Icons.S24.Settings />
        </Item.Start>
        <Item.Content>Settings</Item.Content>
      </SidebarNav.Item>
    </>
  )
}
