import { Box } from '../../primitives'
import { styled, theme } from '../../stitches'
import { backgroundColor } from '../../variants'

export const Badge = styled(Box, {
  size: 8,
  borderRadius: theme.radii['round'],
  backgroundColor: theme.colors['dark-blue'],
  variants: { color: backgroundColor },
})
