import { useEffect } from 'react'
import { SCALES } from './constants'
import { useImageViewerContext } from './context'

export function ZoomOnScroll() {
  const { containerRef, scale, zoomToScale } = useImageViewerContext()

  useEffect(() => {
    const container = containerRef.current
    if (container === null) {
      return
    }
    const handleMouseWheel = (event: WheelEvent) => {
      event.preventDefault()
      const updatedScale = scale + event.deltaY * -0.01
      const result = Math.min(
        Math.max(SCALES[0], updatedScale),
        SCALES[SCALES.length - 1]
      )
      return zoomToScale(result)
    }
    container.addEventListener('wheel', handleMouseWheel)

    return () => container.removeEventListener('wheel', handleMouseWheel)
  }, [containerRef, scale, zoomToScale])

  return null
}
