import { styled } from '../../stitches'
import { typography, textColor } from '../../variants'

export interface LabelProps extends React.ComponentProps<typeof Label> {}

export const Label = styled('label', {
  display: 'block',
  whiteSpace: 'nowrap',
  variants: {
    color: textColor,
    size: {
      16: { ...typography['label-16'] },
      13: { ...typography['label-13'] },
    },
  },
  defaultVariants: {
    size: 16,
    color: 'grey-60',
  },
})
