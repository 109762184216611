import { useControlled } from 'kitchen/hooks/use-controlled'
import { useNumberRifm } from 'kitchen/hooks/use-number-rifm'
import { forwardRef } from 'react'
import { Input, type InputProps } from '../input'

export interface NumberInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: string | number
  value?: string | number
  onChange?: (value: string) => void
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInputRef(
    { defaultValue = '', value: controlledValue, onChange, ...rest },
    forwardedRef
  ) {
    const [value, setValue] = useControlled<string>({
      defaultValue: String(defaultValue),
      value: String(controlledValue),
      onChange,
    })

    const rifm = useNumberRifm({
      value,
      onChange: setValue,
    })

    return <Input ref={forwardedRef} inputMode="numeric" {...rifm} {...rest} />
  }
)
