import { styled, theme } from '../../stitches'
import { typography } from '../../variants'

export const TabsLink = styled('a', {
  ...typography['title-16'],
  position: 'relative',
  display: 'inline-block',
  color: theme.colors['tabs-text'],
  transition: 'color 200ms ease-in-out',
  '&[disabled]': {
    pointerEvents: 'none',
  },
  '&::after': {
    content: `''`,
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '2px',
    background: theme.colors['tabs-indicator-background'],
    transition: 'transform 200ms ease-in-out',
    transformOrigin: 'bottom right',
    borderRadius: theme.radii['round'],
  },
  '&:hover, &[aria-current="true"]': {
    color: theme.colors['tabs-text-pressed'],
    '&::after': {
      transformOrigin: 'bottom left',
    },
  },
  variants: {
    variant: {
      default: {
        '&::after': {
          top: '100%',
          transform: 'translateY(3px) translateZ(0) scaleX(0)',
        },
        '&:hover': {
          '&::after': {
            transform: 'translateY(3px) translateZ(0) scaleX(1)',
          },
        },
        '&[aria-current="true"]': {
          '&:after': {
            transform: 'translateY(3px) translateZ(0) scaleX(1)',
          },
        },
      },
      inset: {
        height: '100%',
        display: 'grid',
        placeContent: 'center',
        '&:after': {
          bottom: '0',
          transform: 'translateY(0) translateZ(0) scaleX(0)',
        },
        '&:hover': {
          '&::after': {
            transform: 'translateY(0) translateZ(0) scaleX(1)',
          },
        },
        '&[aria-current="true"]': {
          '&:after': {
            transform: 'translateY(0) translateZ(0) scaleX(1)',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
