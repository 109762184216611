import { useEffect } from 'react'

function getCount(target: HTMLElement) {
  const parsed = Number(target.dataset.blurCount)
  return Number.isNaN(parsed) ? 0 : Math.max(parsed, 0)
}

function setCount(target: HTMLElement, count: number) {
  target.dataset.blurCount = String(Math.max(count, 0))
}

export function GlobalBlur() {
  useEffect(() => {
    const root = document.querySelector<HTMLElement>('#root')

    if (root) {
      const blurCount = getCount(root)

      root.classList.add('blurred')
      setCount(root, blurCount + 1)

      return () => {
        const nextCount = getCount(root)

        if (nextCount === 1) {
          root.classList.remove('blurred')
        }

        setCount(root, nextCount - 1)
      }
    }
  }, [])

  return null
}
