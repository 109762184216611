import * as yup from 'kitchen/validations'

export function getValidationSchema() {
  return yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phoneCountryCallingCode: yup
      .object()
      .nullable()
      .when(['phoneNumber'], {
        is: (phoneNumber: string) => phoneNumber.length > 0,
        then: (schema) => schema.required('Please choose country calling code'),
        otherwise: (schema) => schema,
      }),
  })
}
