import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

export interface AlphaNumericRifmOptions
  extends Omit<Parameters<typeof useRifm>[0], 'format'> {
  uppercase?: boolean
  maxLength?: number
}

export const useAlphaNumericRifm = (options: AlphaNumericRifmOptions) =>
  useRifm({
    ...options,
    accept: /[A-Z0-9]/gi,
    value: formats.alphaNumeric(options.value, options.maxLength),
    format: (value) => formats.alphaNumeric(value, options.maxLength),
    replace: (value) => (options.uppercase ? value.toUpperCase() : value),
    onChange: (value) => options.onChange(formats.alphaNumeric(value, options.maxLength)),
  })
