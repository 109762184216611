import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { CompanyId } from 'api/types/companies'
import type { User } from 'api/types/users'
import { CacheKey } from 'kitchen/constants'
import { usePublicFetch } from 'kitchen/hooks/use-public-fetch'
import { useParams } from 'kitchen/router'
import type { UseMutationOptions } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { sendAnalyticsEvent } from './requests'
import { rudderAnalytics } from './rudderstack'
import type { SendAnalyticsEventPayload } from './types'

export const useSendAnalyticsEvent = (
  variant: 'native' | 'rudderstack' = 'native',
  options?: UseMutationOptions<SendAnalyticsEventPayload, void>
) => {
  const params = useParams<{ companyId: CompanyId }>()
  const queryClient = useQueryClient()
  const fetch = usePublicFetch()

  return useMutation((payload) => {
    const currentUser = queryClient.getQueryData<User>([CacheKey.CURRENT_USER])

    switch (variant) {
      case 'native':
        if (process.env.BUILD_MODE === 'development') {
          return Promise.resolve()
        }
        return sendAnalyticsEvent(fetch, {
          userId: currentUser?.id,
          companyId: params.companyId,
          ...payload,
        })
      case 'rudderstack':
        if (process.env.BUILD_MODE !== 'production' || process.env.PLAYWRIGHT) {
          return Promise.resolve()
        }
        rudderAnalytics.track(payload.event, payload.data)
        return Promise.resolve()

      default:
        throw new ImpossibleError('unhandled analytics variant', variant)
    }
  }, options)
}
