import { ImpossibleError } from 'kitchen/utils/error'

const HORIZONTAL_ROTATIONS = {
  right: 180,
  left: 0,
} as const

type HorizontalRotationVariant = keyof typeof HORIZONTAL_ROTATIONS

interface HorizontalArrowProps {
  variant?: HorizontalRotationVariant
}

const HorizontalArrow = ({ variant = 'right' }: HorizontalArrowProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ transform: `rotate(${HORIZONTAL_ROTATIONS[variant]}deg)` }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.29 13.125 4.167 4.168-1.414 1.414-6-6L3.336 12l.707-.707 6-6 1.414 1.414-4.168 4.168h12.698v2.25H7.29Z"
      fill="currentColor"
    />
  </svg>
)

const VERTICAL_ROTATIONS = {
  top: 180,
  bottom: 0,
} as const

type VerticalRotationVariant = keyof typeof VERTICAL_ROTATIONS

interface VerticalArrowProps {
  variant: VerticalRotationVariant
}

const VerticalArrow = ({ variant }: VerticalArrowProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ transform: `rotate(${VERTICAL_ROTATIONS[variant]}deg)` }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 16.3008L17.2929 12.1329L18.7071 13.5471L12.7071 19.5471L12 20.2542L11.2929 19.5471L5.29291 13.5471L6.70712 12.1329L10.875 16.3008V3H13.125V16.3008Z"
      fill="currentColor"
    />
  </svg>
)

type RotationVariant = HorizontalRotationVariant | VerticalRotationVariant

interface ArrowProps {
  variant: RotationVariant
}

export const Arrow = ({ variant }: ArrowProps) => {
  switch (variant) {
    case 'top':
    case 'bottom':
      return <VerticalArrow variant={variant} />
    case 'left':
    case 'right':
      return <HorizontalArrow variant={variant} />
    default:
      throw new ImpossibleError('unhandled arrow icon variant', variant)
  }
}
