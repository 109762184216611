import type { Contact } from 'api/types/contacts'
import { Ellipsis } from '../../primitives'
import { Avatar } from '../avatar'
import * as Item from '../item'
import * as Select from '../select'
import type { Option } from './types'

interface ContactOptionProps extends Omit<Select.SelectOptionProps<Contact>, 'value'> {
  option: Option
  children: React.ReactNode
}

export function ContactOption({ option, children, ...rest }: ContactOptionProps) {
  return (
    <Select.Option value={option.value} {...rest}>
      {children}
    </Select.Option>
  )
}

export const getDefaultOption = (option: Option) => (
  <ContactOption option={option}>
    <Item.Root>
      <Item.Start>
        <Avatar variant="square" name={option.label} size="small" aria-hidden />
      </Item.Start>
      <Ellipsis title={option.label} asChild>
        <Item.Content>{option.label}</Item.Content>
      </Ellipsis>
    </Item.Root>
  </ContactOption>
)
