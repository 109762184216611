import type { CurrencyCode } from 'domains/money/types'

export function getReferenceMaxLength(currency: CurrencyCode) {
  switch (currency) {
    case 'EUR':
      return 35
    default:
      return 18
  }
}
