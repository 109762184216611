import * as BaseSwitch from '@radix-ui/react-switch'
import { forwardRef } from 'react'
import { styled, theme } from '../../stitches'

const SwitchContainer = styled('div', {
  position: 'relative',
  variants: {
    size: {
      14: {},
      20: { padding: theme.space[2] },
    },
  },
  defaultVariants: {
    size: 14,
  },
})

const SwitchRoot = styled(BaseSwitch.Root, {
  display: 'block',
  backgroundColor: theme.colors['grey-15'],
  borderRadius: theme.radii['round'],
  transition: 'background-color 0.15s',
  '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
  '&[data-state="checked"]': { backgroundColor: theme.colors['black'] },
  '&[data-disabled]': { backgroundColor: theme.colors['black-alpha-10'] },
  '&:not([data-disabled])': { cursor: 'pointer' },
  variants: {
    size: {
      14: { width: 32, height: 20, padding: theme.space[2] },
      20: { width: 44, height: 28, padding: theme.space[4] },
    },
  },
  defaultVariants: {
    size: 14,
  },
})

const SwitchThumb = styled(BaseSwitch.Thumb, {
  display: 'block',
  backgroundColor: theme.colors['white'],
  borderRadius: theme.radii['round'],
  transition: 'transform 0.2s',
  '&[data-state="checked"]': { transform: 'translateX(16px)' },
  '&[data-disabled]': { backgroundColor: theme.colors['white-alpha-60'] },
  variants: {
    size: {
      14: {
        size: 14,
        transform: 'translateX(1px)',
        '&[data-state="checked"]': { transform: 'translateX(13px)' },
      },
      20: {
        size: 20,
        transform: 'translateX(0px)',
        '&[data-state="checked"]': { transform: 'translateX(16px)' },
      },
    },
  },
  defaultVariants: {
    size: 14,
  },
})

export interface SwitchProps extends React.ComponentProps<typeof SwitchRoot> {}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(function Switch(
  { style, css, size, ...rest },
  forwardedRef
) {
  return (
    <SwitchContainer style={style} size={size} css={css} ref={forwardedRef}>
      <SwitchRoot size={size} {...rest}>
        <SwitchThumb size={size} />
      </SwitchRoot>
    </SwitchContainer>
  )
})
