import { createContext } from 'react'

export interface InputGroupAriaProps extends React.AriaAttributes {
  id?: string
}

export interface InputGroupContextValue {
  inputId: string
  messageId: string
  getAriaProps: (props: InputGroupAriaProps) => InputGroupAriaProps
}

export const InputGroupContext = createContext<InputGroupContextValue | null>(null)
