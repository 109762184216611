import { styled } from '../stitches'
import { typography, textColor, fontWeight } from '../variants'

export interface TextProps extends React.ComponentProps<typeof Text> {
  as?: React.ElementType
}

export const Text = styled('span', {
  variants: {
    variant: typography,
    color: textColor,
    weight: fontWeight,
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textUnderlineOffset: '2px',
      },
    },
    align: {
      start: { textAlign: 'start' },
      center: { textAlign: 'center' },
      end: { textAlign: 'end' },
      /** @deprecated use `start` */
      left: { textAlign: 'left' },
      /** @deprecated use `end` */
      right: { textAlign: 'right' },
    },
  },
})
