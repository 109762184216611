import { type GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { ButtonBase, Text } from '../../primitives'
import { styled, theme } from '../../stitches'

const AddCardButtonBase = styled(ButtonBase, {
  display: 'grid',
  gap: theme.space[8],
  alignItems: 'center',
  minHeight: 80,
  paddingBlock: theme.space[24],
  paddingInline: theme.space[32],
  color: theme.colors['dark-blue'],
  borderRadius: theme.radii[16],
  backgroundImage: theme.backgrounds['add-card-button-border'],
  transitionProperty: 'background-color',
  transitionDuration: '0.3s',
  '&:hover': {
    backgroundColor: theme.colors['grey-15'],
    backgroundImage: 'none',
  },
  '&:active, &[aria-pressed="true"]': {
    backgroundColor: theme.colors['grey-20'],
    backgroundImage: 'none',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    color: theme.colors['black-alpha-20'],
    backgroundColor: 'transparent',
    backgroundImage: theme.backgrounds['add-card-button-border-disabled'],
  },
  variants: {
    align: {
      default: {
        gridTemplateColumns: 'auto 1fr',
      },
      center: {
        justifyContent: 'center',
        gridAutoFlow: 'column',
      },
    },
  },
  defaultVariants: {
    align: 'default',
  },
})

export interface AddCardButtonProps
  extends React.ComponentProps<typeof AddCardButtonBase> {}

export const AddCardButton: GenericForwardRefComponent<AddCardButtonProps> = forwardRef(
  function AddCardButton({ align, children, ...rest }, forwardedRef) {
    return (
      <AddCardButtonBase ref={forwardedRef} type="button" align={align} {...rest}>
        {align === 'center' ? <Icons.S16.Plus /> : <Icons.S24.Plus />}
        <Text variant="title-16">{children}</Text>
      </AddCardButtonBase>
    )
  }
)
