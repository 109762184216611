import { parseBoolean } from 'kitchen/utils/helpers'
import { useId, useMemo } from 'react'
import {
  InputGroupContext,
  type InputGroupContextValue,
} from '../../context/input-group-context'

interface InputGroupProviderProps {
  children?: React.ReactNode
}

export function InputGroupProvider(props: InputGroupProviderProps) {
  const id = useId()
  const value = useMemo((): InputGroupContextValue => {
    const inputId = `input-group-input-${id}`
    const messageId = `input-group-message-${id}`

    return {
      inputId,
      messageId,
      getAriaProps: (props) =>
        parseBoolean(props['aria-invalid'])
          ? { id: inputId, 'aria-errormessage': messageId }
          : { id: inputId, 'aria-describedby': messageId },
    }
  }, [id])

  return <InputGroupContext.Provider value={value} {...props} />
}
