export const media = {
  bp0: '(min-width: 0px)',
  bp1: '(min-width: 480px)',
  bp2: '(min-width: 768px)',
  bp3: '(min-width: 1024px)',
  bp4: '(min-width: 1280px)',
  bp5: '(min-width: 2560px)',
  hover: '(hover)',
} as const

export type Media = keyof typeof media
