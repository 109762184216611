export const radii = {
  round: '9999px', // a hack to make round corners for any height without turning into ellipse
  4: '4px',
  8: '8px',
  16: '16px',
  /** website only */
  32: '32px',
} as const

export type Radius = keyof typeof radii
