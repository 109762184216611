import { useCurrentUser, useUpdateUser } from 'api/hooks/users'
import type { RegisteredUser, User } from 'api/types/users'
import { Dialog, Surface, Toast } from 'salad/components'
import type { DialogContentProps } from 'salad/components/dialog'
import { Text, VStack } from 'salad/primitives'
import { UpdateUserDetailsForm } from './update-user-details-form'

interface UpdateUserDetailsDialogProps extends DialogContentProps {
  user: RegisteredUser | User
}

function UpdateUserDetailsDialogContent({ user, ...rest }: UpdateUserDetailsDialogProps) {
  const toast = Toast.useContext()
  const dialogContext = Dialog.useContext()
  const currentUser = useCurrentUser()
  const isCurrentUser = currentUser.data?.id === user.id

  const updateUserDetails = useUpdateUser({
    onSuccess: () => {
      toast.show(
        <Toast.Root>
          <Toast.Title>Details edited</Toast.Title>
        </Toast.Root>
      )
      dialogContext.setOpen(false)
    },
    onError: () => {
      toast.show(
        <Toast.Root variant="error">
          <Toast.Title>It's not possible to edit user details at the moment</Toast.Title>
        </Toast.Root>
      )
    },
  })

  return (
    <Dialog.Content {...rest} variant={512}>
      <Surface p={32}>
        <VStack gap={24}>
          <VStack gap={4}>
            <Dialog.Title asChild>
              <Text variant="headline-h2">
                Edit {isCurrentUser ? 'details' : 'member details'}
              </Text>
            </Dialog.Title>
          </VStack>
          <UpdateUserDetailsForm
            user={user}
            isLoading={updateUserDetails.isLoading}
            onSubmit={({ firstName, lastName, phoneCountryCallingCode, phoneNumber }) => {
              updateUserDetails.mutate({
                userId: user.id,
                firstName,
                lastName,
                phone:
                  phoneCountryCallingCode && phoneNumber
                    ? {
                        countryCode: phoneCountryCallingCode.countryCode,
                        callingCode: phoneCountryCallingCode.callingCode,
                        number: phoneNumber,
                      }
                    : null,
              })
            }}
            onCancel={() => {
              dialogContext.setOpen(false)
            }}
          />
        </VStack>
      </Surface>
    </Dialog.Content>
  )
}

export const { Root, Trigger } = Dialog
export { UpdateUserDetailsDialogContent as Content }
