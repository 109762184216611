import {
  getMonthName,
  getNumberOfDaysInMonth,
  isSameOrAfter,
  isSameOrBefore,
  setDate,
} from 'kitchen/utils/date-time'
import * as Icons from '../../icons'
import { Box, Flex, Text } from '../../primitives'
import { NavigationButton } from '../navigation-button'
import { useCalendarContext } from './context'
import { useCalendarDays } from './use-calendar-days'

export const CalendarHeader = () => {
  const {
    disabledDates,
    displayedYear,
    displayedMonth,
    setDisplayedMonth,
    setDisplayedYear,
  } = useCalendarContext()

  const { previousMonth, nextMonth } = useCalendarDays({
    currentYear: displayedYear,
    currentMonth: displayedMonth,
  })

  const onPreviousMonthClick = () => {
    setDisplayedMonth(previousMonth.month)
    setDisplayedYear(previousMonth.year)
  }

  const onNextMonthClick = () => {
    setDisplayedMonth(nextMonth.month)
    setDisplayedYear(nextMonth.year)
  }

  const isPrevButtonDisabled = disabledDates?.some((range) => {
    const displayedMonthFirstDay = 0
    return (
      range.dateFrom === undefined &&
      isSameOrAfter(
        range.dateTo,
        setDate({
          year: displayedYear,
          month: displayedMonth,
          day: displayedMonthFirstDay,
        })
      )
    )
  })
  const isNextButtonDisabled = disabledDates?.some((range) => {
    const displayedMonthLastDay = getNumberOfDaysInMonth(displayedYear, displayedMonth)
    return (
      range.dateTo === undefined &&
      isSameOrBefore(
        range.dateFrom,
        setDate({
          year: displayedYear,
          month: displayedMonth,
          day: displayedMonthLastDay,
        })
      )
    )
  })
  return (
    <Flex mb="16" align="center" css={{ gridColumn: '1/8' }}>
      <NavigationButton
        size="small"
        title="Previous month"
        disabled={isPrevButtonDisabled}
        onClick={onPreviousMonthClick}
      >
        <Icons.S16.Chevron variant="left" />
      </NavigationButton>
      <Box css={{ marginX: 'auto' }}>
        <Text as="h3" variant="headline-h3">
          {getMonthName(displayedMonth)} {displayedYear}
        </Text>
      </Box>
      <NavigationButton
        size="small"
        disabled={isNextButtonDisabled}
        onClick={onNextMonthClick}
        title="Next month"
      >
        <Icons.S16.Chevron variant="right" />
      </NavigationButton>
    </Flex>
  )
}
