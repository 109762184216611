import { createContext, useContext, useMemo } from 'react'
import type * as YF from 'ya-fetch'
import { useCreateAuthorizedFetch } from '../hooks/use-create-authorized-fetch'

type FetchContextValue = YF.Instance<YF.Payload>

const FetchContext = createContext<FetchContextValue | undefined>(undefined)

export const FetchProvider = ({ children }: { children: React.ReactNode }) => {
  const parentFetchContextValue = useContext(FetchContext)
  const createAuthorizedFetch = useCreateAuthorizedFetch()

  const authorizedFetch = useMemo(
    () => parentFetchContextValue ?? createAuthorizedFetch(),
    [createAuthorizedFetch, parentFetchContextValue]
  )

  return <FetchContext.Provider value={authorizedFetch}>{children}</FetchContext.Provider>
}

/**
 * Returns `ya-fetch` instance
 */
export function useFetch() {
  const context = useContext(FetchContext)
  if (context === undefined) {
    throw new Error('useFetch must be used within a FetchProvider')
  }
  return context
}
