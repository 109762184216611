import { useAlphaNumericRifm } from 'kitchen/hooks/use-alpha-numeric-rifm'
import { useControlled } from 'kitchen/hooks/use-controlled'
import { forwardRef } from 'react'
import { Input, type InputProps } from '../input'

export interface AlphaNumericInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
  uppercase?: boolean
  maxLength?: number
}

export const AlphaNumericInput = forwardRef<HTMLInputElement, AlphaNumericInputProps>(
  function AlphaNumericInputRef(
    {
      defaultValue = '',
      value: controlledValue,
      onChange,
      uppercase,
      maxLength,
      ...rest
    },
    forwardedRef
  ) {
    const [value, setValue] = useControlled<string>({
      defaultValue,
      value: controlledValue,
      onChange,
    })

    const rifm = useAlphaNumericRifm({
      value,
      onChange: setValue,
      uppercase,
      maxLength,
    })

    return <Input ref={forwardedRef} {...rifm} {...rest} />
  }
)
