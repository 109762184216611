export const Download = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4697 5.65967L9.00006 8.12934V1H7.00006V8.12934L4.53039 5.65967L3.46973 6.72033L7.46973 10.7203L8.00006 11.2507L8.53039 10.7203L12.5304 6.72033L11.4697 5.65967ZM14 12H2V14H14V12Z"
      fill="currentColor"
    />
  </svg>
)
