import { getCompanyUserPermissions } from 'kitchen/utils/permissions'
import type { CompanyId } from '../types/companies'
import type { UserPermission } from '../types/permissions'
import type { UserId } from '../types/users'
import { type CompaniesOptions, useCompanies } from './companies'
import { useCurrentUser } from './users'

interface CompaniesUsersPermissionPayload {
  companies: CompanyId[]
  userId: UserId
}

interface CompaniesUserPermissionOptions<Select = UserPermission[][]>
  extends Omit<CompaniesOptions<Select>, 'select'> {
  select: (data: UserPermission[][]) => Select
}

function useCompaniesUserPermissions<Select = UserPermission[][]>(
  payload: CompaniesUsersPermissionPayload,
  options: CompaniesUserPermissionOptions<Select>
) {
  return useCompanies(
    {
      status: ['DRAFT', 'ACTIVE'],
    },
    {
      ...options,
      select: (companies): Select => {
        const result = companies
          .filter((company) => payload.companies.includes(company.id))
          .map((company) => {
            const permissions = getCompanyUserPermissions(payload.userId, company)

            if (permissions === undefined) {
              throw new Error(
                `Permissions not found for ${payload.userId} in company ${company.id}`
              )
            }

            return permissions
          })

        return options.select(result)
      },
    }
  )
}

interface CurrentUserCompaniesPermissionsOptions<Select = UserPermission[][]>
  extends CompaniesUserPermissionOptions<Select> {}

export const useCurrentUserCompaniesPermissions = <Select = UserPermission[][]>(
  companies: CompanyId[],
  options: CurrentUserCompaniesPermissionsOptions<Select>
) => {
  const currentUser = useCurrentUser({
    suspense: true,
  })

  if (currentUser.data === undefined) {
    throw new Error('Current user is required')
  }

  return useCompaniesUserPermissions({ companies, userId: currentUser.data.id }, options)
}

export const useCurrentUserHasCompaniesPermissions = (
  companies: CompanyId[],
  permissions: UserPermission[]
) => {
  const result = useCurrentUserCompaniesPermissions(companies, {
    suspense: true,
    select: (data) =>
      permissions.map((permission) => data.every((item) => item.includes(permission))),
  })

  return result.data ?? []
}

interface CurrentUserPermissionOptions
  extends Omit<CurrentUserCompaniesPermissionsOptions<UserPermission[]>, 'select'> {}

export const useCurrentUserCompanyPermissions = (
  companyId: CompanyId,
  options?: CurrentUserPermissionOptions
) =>
  useCurrentUserCompaniesPermissions([companyId], {
    ...options,
    select: (data) => data[0],
  })

export const useCurrentUserHasPermission = (
  companyId: CompanyId,
  input: UserPermission[]
) => {
  const permissions =
    useCurrentUserCompanyPermissions(companyId, {
      suspense: true,
    }).data ?? []

  return input.map((item) => permissions.includes(item))
}

export const useCurrentUserHasAnyPermission = (
  companyId: CompanyId,
  input: UserPermission[]
) => {
  const permissions =
    useCurrentUserCompanyPermissions(companyId, {
      suspense: true,
    }).data ?? []

  return input.some((item) => permissions.includes(item))
}
