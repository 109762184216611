import { Flex } from '../../primitives'
import { styled, theme } from '../../stitches'

export const ButtonGroup = styled(Flex, {
  '> :not(:first-child)': {
    borderStartStartRadius: 0,
    borderEndStartRadius: 0,
  },
  '> :not(:last-child)': {
    borderStartEndRadius: 0,
    borderEndEndRadius: 0,
  },
  '[data-active="true"] ~ *': {
    boxShadow: `-2px 0px 0 0px ${theme.colors['grey-2']}`,
  },
})
