import { useControlled } from 'kitchen/hooks/use-controlled'
import { forwardRef, useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import { type TextAreaBaseProps, TextAreaBase } from '../../primitives'
import { styled } from '../../stitches'

const AutosizeTextAreaGrid = styled('span', {
  display: 'grid',
  gridTemplate: `'input'`,
  'textarea, [data-autosize-spacer]': {
    gridArea: 'input',
  },
})

const AutosizeTextAreaSpacer = styled('span', {
  visibility: 'hidden',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
})

const AutosizeTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function AutosizeTextArea(
    { defaultValue = '', value, onChange, placeholder, ...rest },
    forwardedRef
  ) {
    const [replicatedValue, setReplicatedValue] = useControlled({
      defaultValue,
      value,
    })

    return (
      <>
        <AutosizeTextAreaSpacer data-autosize-spacer aria-hidden>
          {replicatedValue || placeholder}{' '}
        </AutosizeTextAreaSpacer>
        <TextAreaBase
          ref={forwardedRef}
          value={value}
          placeholder={placeholder}
          onChange={(event) => {
            setReplicatedValue(event.currentTarget.value)
            onChange?.(event)
          }}
          {...rest}
        />
      </>
    )
  }
)

export interface TextAreaProps extends TextAreaBaseProps {
  autosize?: boolean
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { autosize, rows = 1, ...rest },
  forwardedRef
) {
  const context = useContext(InputGroupContext)
  const isInsideInputGroup = context !== null

  if (isInsideInputGroup) {
    return autosize ? (
      <AutosizeTextArea
        ref={forwardedRef}
        rows={rows}
        {...context.getAriaProps(rest)}
        {...rest}
      />
    ) : (
      <TextAreaBase
        ref={forwardedRef}
        rows={rows}
        {...context.getAriaProps(rest)}
        {...rest}
      />
    )
  }

  return autosize ? (
    <AutosizeTextAreaGrid>
      <AutosizeTextArea ref={forwardedRef} rows={rows} {...rest} />
    </AutosizeTextAreaGrid>
  ) : (
    <TextAreaBase ref={forwardedRef} rows={rows} {...rest} />
  )
})
