import type { ApiRequest } from 'kitchen/types'
import { ResponseError } from 'kitchen/utils/error'
import type {
  PricingPlan,
  CompanySubscription,
  SubscriptionPayload,
  SubscriptionUsage,
  SubscriptionUsagePayload,
  CompanySubscriptionsPayload,
  SubscribeToPricingPlanPayload,
  SubscribeToPricingPlanResponse,
  BillingDetails,
  BillingDetailsPayload,
  BillingPaymentMethod,
  BillingPaymentMethodPayload,
  SetupBillingPaymentMethodPayload,
  SetupBillingPaymentMethodResponse,
  CancelSubscriptionPayload,
  UpdateBillingDetailsPayload,
  SubscriptionsListPayload,
  PracticeClientSubscriptionsListResponse,
  UpdateSubscriberPayerPayload,
  BillingInvoicesPayload,
  BillingInvoice,
  GenerateBillingInvoicePDFPayload,
  GenerateBillingInvoicePDFResponse,
  BillingInvoiceLineItem,
  BillingInvoiceLineItemsPayload,
  GenerateBillingInvoiceCSVPayload,
  PracticeClientsSubscriptionsInfoPayload,
  PracticeClientsSubscriptionsInfo,
  PricingPlansListPayload,
  PricingPlanPayload,
} from './types'

export const getPricingPlansList: ApiRequest<PricingPlansListPayload, PricingPlan[]> = (
  fetch,
  { subscriberId },
  signal
) =>
  fetch
    .get('/billing/pricing-plans', { signal, params: { subscriberId } })
    .json<PricingPlan[]>()

export const getPricingPlan: ApiRequest<PricingPlanPayload, PricingPlan> = (
  fetch,
  { planId },
  signal
) => fetch.get(`/billing/pricing-plans/${planId}`, { signal }).json<PricingPlan>()

export const getCompanySubscriptions: ApiRequest<
  CompanySubscriptionsPayload,
  CompanySubscription[]
> = (fetch, payload, signal) =>
  fetch
    .get('/billing/subscriptions', { signal, params: payload })
    .json<CompanySubscription[]>()

export const getSubscription: ApiRequest<SubscriptionPayload, CompanySubscription> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get(`/billing/subscriptions/${payload.id}`, { signal })
    .json<CompanySubscription>()

export const getBillingPaymentMethod: ApiRequest<
  BillingPaymentMethodPayload,
  BillingPaymentMethod | null
> = (fetch, payload, signal) =>
  fetch
    .get('/billing/payment-methods/active', { signal, params: payload })
    .json<BillingPaymentMethod | null>()

export const getSubscriptionUsage: ApiRequest<
  SubscriptionUsagePayload,
  SubscriptionUsage
> = (fetch, payload, signal) =>
  fetch
    .get(`/billing/subscriptions/${payload.id}/usage`, { signal })
    .json<SubscriptionUsage>()

export const setupBillingPaymentMethod: ApiRequest<
  SetupBillingPaymentMethodPayload,
  SetupBillingPaymentMethodResponse
> = (fetch, payload) =>
  fetch
    .post('/billing/payment-methods/setup', { json: payload })
    .json<SetupBillingPaymentMethodResponse>()

export const getBillingDetails: ApiRequest<
  BillingDetailsPayload,
  BillingDetails | null
> = (fetch, payload, signal) =>
  fetch
    .get(`/billing/subscribers/${payload.id}`, { signal })
    .json<BillingDetails | null>()
    .catch((error) => {
      if (error instanceof ResponseError && error.response.status === 404) {
        return null
      }

      throw error
    })

export const updateBillingDetails: ApiRequest<UpdateBillingDetailsPayload, void> = (
  fetch,
  { id, ...payload }
) => fetch.put(`/billing/subscribers/${id}`, { json: payload }).void()

export const subscribeToPricingPlan: ApiRequest<
  SubscribeToPricingPlanPayload,
  SubscribeToPricingPlanResponse
> = (fetch, payload) =>
  fetch
    .post('/billing/subscriptions', { json: payload })
    .json<SubscribeToPricingPlanResponse>()

export const cancelSubscription: ApiRequest<CancelSubscriptionPayload, void> = (
  fetch,
  payload
) => fetch.post(`/billing/subscriptions/${payload.id}/cancel`).void()

export const getPracticeClientsSubscriptionsList: ApiRequest<
  SubscriptionsListPayload,
  PracticeClientSubscriptionsListResponse
> = (fetch, { practiceId, ...json }, signal) =>
  fetch
    .post(`/billing/practice/${practiceId}/clients/subscriptions`, {
      json,
      signal,
    })
    .json<PracticeClientSubscriptionsListResponse>()

export const updateSubscriberPayer: ApiRequest<UpdateSubscriberPayerPayload, void> = (
  fetch,
  { subscriberId, payerId }
) => fetch.put(`/billing/subscribers/${subscriberId}/payer`, { json: { payerId } }).void()

export const getBillingInvoices: ApiRequest<BillingInvoicesPayload, BillingInvoice[]> = (
  fetch,
  { payerId }
) => fetch.get('/billing/invoices', { params: { payerId } }).json<BillingInvoice[]>()

export const getBillingInvoiceLineItems: ApiRequest<
  BillingInvoiceLineItemsPayload,
  BillingInvoiceLineItem[]
> = (fetch, { id }) =>
  fetch.get(`/billing/invoices/${id}/line-items`).json<BillingInvoiceLineItem[]>()

export const generateBillingInvoicePDF: ApiRequest<
  GenerateBillingInvoicePDFPayload,
  URL
> = (fetch, { id }) =>
  fetch
    .get(`/billing/invoices/${id}/pdf`)
    .json<GenerateBillingInvoicePDFResponse>()
    .then((res) => new URL(res.url))

export const generateBillingInvoiceCSV: ApiRequest<
  GenerateBillingInvoiceCSVPayload,
  string
> = (fetch, { id }) => fetch.get(`/billing/invoices/${id}/csv`).text()

export const getPracticeClientsSubscriptionsInfo: ApiRequest<
  PracticeClientsSubscriptionsInfoPayload,
  PracticeClientsSubscriptionsInfo[]
> = (fetch, { newPlanId, companyIds }, signal) =>
  fetch
    .post(`/billing/plans/${newPlanId}/switch-details`, {
      json: companyIds,
      signal,
    })
    .json<PracticeClientsSubscriptionsInfo[]>()
