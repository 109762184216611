import { styled } from '../../stitches'
import * as Dialog from '../dialog'
import { Surface } from '../surface'

const IFrame = styled('iframe', {
  display: 'block',
  background: 'unset',
  border: 'unset',
  width: 390,
  height: 400,
  maxWidth: '100%',
})

interface CardChallengeProps {
  title: string
  url: string
}

export const CardChallenge = ({ title, url }: CardChallengeProps) => (
  <Dialog.Root open>
    <Dialog.Content closable={false} css={{ maxWidth: 'max-content' }}>
      <Surface variant="flat" p={8} css={{ overflow: 'hidden' }}>
        <IFrame title={title} src={url} />
      </Surface>
    </Dialog.Content>
  </Dialog.Root>
)
