import * as Sentry from '@sentry/react'
import type { FetchErrorMeta } from 'kitchen/types'
import * as YF from 'ya-fetch'
import { settled } from '../utils/async'
import { randomString } from '../utils/data'
import { ExtendedResponseError } from '../utils/error'

export const usePublicFetch = () =>
  YF.create({
    resource: process.env.API_URL,
    async onRequest(_, options) {
      options.headers.set('X-Request-Id', randomString(10))
    },
    async onFailure(error) {
      if (error instanceof YF.ResponseError) {
        const errorMeta = await settled<FetchErrorMeta>(error.response.json())
        if (errorMeta.status === 'fulfilled') {
          throw new ExtendedResponseError(error.response, errorMeta.value)
        } else {
          throw error
        }
      }
      Sentry.captureException(error, { tags: { type: 'network_call' } })
      throw error
    },
  })
