export const fontSizes = {
  'price-tag-large-main': '64px',
  'price-tag-large-support': '36px',
  'price-tag-medium-main': '32px',
  'price-tag-medium-support': '18px',
  'price-tag-small-main': '24px',
  'price-tag-small-support': '14px',
  'headline-h1': '32px',
  'headline-h2': '24px',
  'headline-h3': '20px',
  'title-16': '16px',
  'title-14': '14px',
  'paragraph-20': '20px',
  'paragraph-16': '16px',
  'paragraph-14': '14px',
  'paragraph-12': '12px',
  'label-16': '16px',
  'label-13': '13px',
  'button-large': '20px',
  'avatar-large': '14px',
  'avatar-medium': '12px',
  'avatar-small': '9px',
} as const

export type FontSize = keyof typeof fontSizes
