import { motion } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'
import type { ImageProps } from '../../primitives'
import { Box, ZStack } from '../../primitives'
import { theme } from '../../stitches'

import { ImageViewerContextProvider, useImageViewerContext } from './context'

export interface ImageViewerProps {
  children: React.ReactNode
}

function ImageViewer({ children }: ImageViewerProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <ImageViewerContextProvider containerRef={containerRef}>
      <ZStack
        ref={containerRef}
        css={{
          padding: theme.space[16],
          backgroundColor: theme.colors['document-preview-background'],
          size: '100%',
          minHeight: '300px',
          overflow: 'hidden',
        }}
      >
        {children}
      </ZStack>
    </ImageViewerContextProvider>
  )
}

interface ImageViewerImageProps extends ImageProps {}

function ImageViewerImage(props: ImageViewerImageProps) {
  const { imageAnimation } = useImageViewerContext()
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const onMouseUp = () => setIsDragging(false)
    document.addEventListener('pointerup', onMouseUp)
    return () => document.removeEventListener('pointerup', onMouseUp)
  }, [])

  return (
    <Box
      role="img"
      aria-label="attachment preview"
      as={motion.div}
      animate={imageAnimation}
      drag
      dragMomentum={false}
      data-is-dragging={isDragging}
      onPointerDown={() => setIsDragging(true)}
      css={{
        size: '100%',
        placeSelf: 'center',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'grab',
        '&[data-is-dragging="true"]': {
          cursor: 'grabbing',
        },
      }}
      // using "style" prevents Stitches from finding css variables in url thus breaking it
      style={{ backgroundImage: `url('${props.src}')` }}
    />
  )
}

export { ImageViewer as Root, ImageViewerImage as Image }
