import { createDataSVG } from './helpers'

export const em = (input: number, base = 16) => `${input / base}em`

export const createDashedBorderDataSVG = (stroke: string, radius: string) =>
  createDataSVG(`
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="100%"
        fill="none"
        rx="${radius}"
        ry="${radius}"
        stroke="${stroke}"
        stroke-width="4"
        stroke-dasharray="12, 12"
        stroke-dashoffset="0"
        stroke-linecap="square"
      />
    </svg>
  `)
