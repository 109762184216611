import { createContext } from 'react'

export interface SelectContextValue<Value> {
  multiple: boolean
  value: Value[]
  setValue: (value: Value[]) => void
  compare: (source: Value, value: Value) => boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectContext = createContext<SelectContextValue<any> | null>(null)
