export const Cross = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13.591L18.409 20L20 18.409L13.591 12L20 5.59098L18.409 3.99999L12 10.409L5.59099 3.99999L4 5.59098L10.409 12L4 18.409L5.59099 20L12 13.591Z"
      fill="currentColor"
    />
  </svg>
)
