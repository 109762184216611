import { styled, theme } from 'salad/stitches'
import { Container } from '../../components'

const SwimlanesLayout = styled(Container, {
  display: 'grid',
  minWidth: '200vw',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `'content details'`,
  height: '100%',
  gap: theme.space[24],
  '@bp1': {
    gap: theme.space[32],
    minWidth: 985,
    gridTemplateColumns: `3fr 4fr`,
  },
})

const SwimlanesLayoutMain = styled('main', {
  gridArea: 'content',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 0,
  scrollSnapAlign: 'center',
})

const SwimlanesLayoutSide = styled('aside', {
  gridArea: 'details',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 0,
  scrollSnapAlign: 'center',
})

export {
  SwimlanesLayout as Root,
  SwimlanesLayoutMain as Main,
  SwimlanesLayoutSide as Side,
}
