import { useControlled } from 'kitchen/hooks/use-controlled'
import { setRefs } from 'kitchen/utils/helpers'
import { useRef, forwardRef } from 'react'
import { VStack, Text } from '../../primitives'
import { Input, type InputProps } from '../input'
import * as Popover from '../popover'
import { ScrollArea } from '../scroll-area'
import * as Select from '../select'
import { Surface } from '../surface'

export interface AutoCompleteProps<Option>
  extends Omit<
    InputProps,
    'defaultValue' | 'value' | 'size' | 'onChange' | 'onSelect' | 'children'
  > {
  defaultValue?: string
  value?: string
  options: Option[]
  open?: boolean
  onChange?: (value: string) => void
  onSelect: (option: Option) => void
  onOpenChange?: (open: boolean) => void
  children: (option: Option) => React.ReactNode
  emptyMessage?: React.ReactElement | string
  dialogLabel?: string
  listboxLabel?: string
}

interface AutoCompleteComponent
  extends React.ForwardRefExoticComponent<AutoCompleteProps<unknown>> {
  <Option>(props: AutoCompleteProps<Option>): React.ReactNode
}

export const AutoComplete: AutoCompleteComponent = forwardRef(function AutoCompleteRef(
  {
    open: controlledOpen,
    onOpenChange,
    options = [],
    onSelect,
    defaultValue = '',
    value: controlledValue,
    onChange,
    children,
    emptyMessage = 'Start typing to see results.',
    onFocus,
    onKeyDown,
    dialogLabel,
    listboxLabel,
    ...rest
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const firstItemRef = useRef<HTMLButtonElement | null>(null)

  const [value, setValue] = useControlled<string>({
    defaultValue,
    value: controlledValue,
    onChange,
  })

  const [open, setOpen] = useControlled<boolean>({
    defaultValue: false,
    value: controlledOpen,
    onChange: onOpenChange,
  })

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Anchor asChild>
        <Input
          {...rest}
          ref={setRefs(ref, inputRef)}
          value={value}
          onFocus={(event) => {
            onFocus?.(event)

            if (event.defaultPrevented) {
              return
            }

            setOpen(true)
          }}
          onChange={(event) => {
            setValue(event.currentTarget.value)
          }}
          onKeyDown={(event) => {
            onKeyDown?.(event)

            if (event.defaultPrevented) {
              return
            }

            function focusFirst() {
              if (open) {
                event.preventDefault()
                firstItemRef.current?.focus()
              }
            }

            switch (event.key) {
              case 'Tab': {
                focusFirst()
                return
              }
              case 'ArrowDown': {
                focusFirst()
                if (options.length > 0) {
                  event.preventDefault()
                  setOpen(true)
                }
                return
              }
              case 'Enter': {
                event.preventDefault()

                if (options.length > 0 && open) {
                  onSelect(options[0])
                  setOpen(false)
                } else {
                  setOpen(true)
                }

                return
              }
            }
          }}
          autoComplete="off"
        />
      </Popover.Anchor>
      <Popover.Content
        aria-label={dialogLabel}
        align="start"
        onOpenAutoFocus={(event) => {
          event.preventDefault()
          const input = inputRef.current
          const element = input?.closest('[data-input-group]') ?? input

          if (input && element && event.currentTarget instanceof HTMLElement) {
            const elementRect = element.getBoundingClientRect()
            event.currentTarget.style.width = `${elementRect.width}px`

            if (input !== element) {
              const inputRect = input.getBoundingClientRect()
              const leftOffset = elementRect.x - inputRect.x
              event.currentTarget.style.marginLeft = `${leftOffset}px`
            }
          }
        }}
        onInteractOutside={(event) => {
          if (event.target === inputRef.current) {
            event.preventDefault()
          }
        }}
      >
        <Surface variant="popover">
          <ScrollArea variant="custom-scrollbar" css={{ maxHeight: 282 }}>
            <Select.Root
              value={null}
              onValueChange={(value) => {
                if (value !== null) {
                  onSelect(value)
                }
              }}
              aria-label={listboxLabel}
              autoClose
            >
              <VStack gap={2} p={16}>
                {options.length === 0 && (
                  <Text variant="paragraph-14" color="grey-60">
                    {emptyMessage}
                  </Text>
                )}
                {options.map((option, index) => (
                  <Select.Option
                    key={index}
                    ref={index === 0 ? firstItemRef : undefined}
                    value={option}
                  >
                    {children(option)}
                  </Select.Option>
                ))}
              </VStack>
            </Select.Root>
          </ScrollArea>
        </Surface>
      </Popover.Content>
    </Popover.Root>
  )
})
