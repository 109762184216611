import * as Portal from '@radix-ui/react-portal'
import * as ToastBase from '@radix-ui/react-toast'
import { Time } from 'kitchen/constants'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef, useRef, useState, useMemo, useContext, cloneElement } from 'react'
import { ToastsContext, type ToastsContextValue } from '../../context/toast-context'
import * as Icons from '../../icons'
import { HStack, ButtonBase, type ButtonBaseProps } from '../../primitives'
import { styled, theme, keyframes } from '../../stitches'
import { typography } from '../../variants'

function useToastsContext() {
  return useRequiredContext('ToastsContext', ToastsContext)
}

export interface ToastProviderProps extends ToastBase.ToastProviderProps {}

let globalId = -1
function ToastProvider({ children, ...rest }: ToastProviderProps) {
  const [toast, setToast] = useState<React.ReactElement | null>(null)

  const contextValue = useMemo<ToastsContextValue>(() => {
    const show = (element: React.ReactElement) =>
      setToast(cloneElement(element, { key: (globalId += 1) }))

    const hide = () => setToast(null)

    return {
      show,
      hide,
    }
  }, [setToast])

  return (
    <ToastBase.Provider duration={3 * Time.SECOND} swipeDirection="up" {...rest}>
      <ToastsContext.Provider value={contextValue}>
        {children}
        {toast !== null && (
          <>
            {toast}
            <Portal.Root>
              <ToastContainer>
                <ToastViewport />
              </ToastContainer>
            </Portal.Root>
          </>
        )}
      </ToastsContext.Provider>
    </ToastBase.Provider>
  )
}

const ToastContainer = styled('div', {
  position: 'fixed',
  padding: theme.space[16],
  transform: 'translateX(-50%)',
  left: '50%',
  top: 0,
})

const ToastViewport = styled(ToastBase.Viewport, {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.space[8],
  justifyItems: 'center',
})

const slideIn = keyframes({
  from: {
    opacity: 0,
    transform: `translateY(calc(-100% - ${theme.space[4]}))`,
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

const slideOut = keyframes({
  from: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  to: {
    transform: `translateY(calc(-100% - ${theme.space[4]}))`,
    opacity: 0,
  },
})

const swipeDown = keyframes({
  from: {
    opacity: 1,
    transform: 'translateY(var(--radix-toast-swipe-end-y))',
  },
  to: {
    transform: `translateY(calc(-100% - ${theme.space[4]}))`,
    opacity: 0,
  },
})

const ToastRoot = styled(ToastBase.Root, {
  borderRadius: theme.radii[8],
  placeItems: 'center',
  color: theme.colors['white'],
  whiteSpace: 'nowrap',
  ...typography['paragraph-16'],
  '&[data-state="open"]': {
    animation: `${slideIn} 0.15s cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideOut} 0.15s cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&[data-swipe="move"]': {
    transform: 'translateY(var(--radix-toast-swipe-move-y))',
  },
  '&[data-swipe="cancel"]': {
    transform: 'translateY(0)',
    transition: 'transform 0.15s ease-out',
  },
  '&[data-swipe="end"]': {
    animation: `${swipeDown} 0.15s ease-out`,
  },
  variants: {
    variant: {
      default: {
        backgroundColor: theme.colors['black'],
      },
      accent: {
        backgroundColor: theme.colors['dark-blue'],
      },
      error: {
        backgroundColor: theme.colors['light-red'],
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface ToastProps extends React.ComponentProps<typeof ToastRoot> {
  closable?: boolean
}

function Toast({
  children,
  duration,
  closable = true,
  onSwipeMove,
  onSwipeEnd,
  onEscapeKeyDown,
  onOpenChange,
  ...rest
}: ToastProps) {
  const toastRef = useRef<HTMLLIElement | null>(null)
  const toasts = useContext(ToastsContext)

  function handleOpenChange(next: boolean) {
    const toastElement = toastRef.current

    if (next === false && toasts !== null && toastElement !== null) {
      toastElement.addEventListener('animationend', () => toasts.hide(), {
        once: true,
      })
    }

    onOpenChange?.(next)
  }

  function preventUserClose<E extends Event>(callback?: (event: E) => void) {
    return function handleEvent(event: E) {
      callback?.(event)
      if (!event.defaultPrevented && !closable) {
        event.preventDefault()
      }
    }
  }

  return (
    <ToastRoot
      ref={toastRef}
      onOpenChange={handleOpenChange}
      duration={closable ? duration : Infinity}
      onSwipeMove={preventUserClose(onSwipeMove)}
      onSwipeEnd={preventUserClose(onSwipeEnd)}
      onEscapeKeyDown={preventUserClose(onEscapeKeyDown)}
      asChild
      {...rest}
    >
      <HStack px={12} py={8} gap={8} css={{ width: 'fit-content' }}>
        {children}
      </HStack>
    </ToastRoot>
  )
}

export interface ToastTitleProps extends ToastBase.ToastTitleProps {}

function ToastTitle({ children, ...rest }: ToastTitleProps) {
  return (
    <ToastBase.Title asChild {...rest}>
      <HStack as="span" px={4} py={2} gap={8} css={{ alignItems: 'center' }}>
        {children}
      </HStack>
    </ToastBase.Title>
  )
}

export interface ToastActionProps extends ButtonBaseProps {
  altText: string
}

const ToastAction: GenericForwardRefComponent<ToastActionProps> = forwardRef(
  function ToastActionRef({ altText, children, css, ...rest }, forwardedRef) {
    return (
      <ToastBase.Action altText={altText} asChild>
        <ButtonBase
          ref={forwardedRef}
          css={{ color: theme.colors['white-alpha-80'], ...css }}
          {...rest}
        >
          <HStack as="span" px={4} py={2} gap={8}>
            {children}
          </HStack>
        </ButtonBase>
      </ToastBase.Action>
    )
  }
)

export interface ToastCloseProps extends Omit<ButtonBaseProps, 'children'> {}

const ToastClose: GenericForwardRefComponent<ToastCloseProps> = forwardRef(
  function ToastCloseRef({ css, ...rest }, forwardedRef) {
    return (
      <ToastBase.Close asChild>
        <ButtonBase
          ref={forwardedRef}
          css={{ padding: theme.space[4], color: theme.colors['white-alpha-80'], ...css }}
          {...rest}
        >
          <Icons.S16.Cross />
        </ButtonBase>
      </ToastBase.Close>
    )
  }
)

export {
  ToastProvider as Provider,
  ToastViewport as Viewport,
  Toast as Root,
  ToastTitle as Title,
  ToastAction as Action,
  ToastClose as Close,
  useToastsContext as useContext,
}
