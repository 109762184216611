import type { PaymentPermission, UserPermission, UserRole } from 'api/types/permissions'
import { useFormContext } from 'kitchen/forms'
import { ImpossibleError } from 'kitchen/utils/error'
import {
  checkIsPaymentPermission,
  mapRolesToPaymentPermissions,
} from 'kitchen/utils/permissions'
import { useEffect, useState } from 'react'
import { ButtonGroup, Message } from 'salad/components'
import { Box, HStack, Text, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { PermissionToggleButton } from './permission-toggle-button'
import { TooltipWrapper } from './tooltip-wrapper'
import type { ContributorRole, RoleEditorFormValues } from './types'

const paymentActionPrefixes = [
  'PAYROLL_PAYMENTS',
  'INVOICE_PAYMENTS',
  'MANUAL_PAYMENTS',
] as const

const paymentActionPostfixes = [
  'VIEW',
  'ASSIGNED_VIEW',
  'EDIT',
  'CREATE',
  'APPROVE',
  'AUTHORISE',
] as const

type PermissionPrefix = (typeof paymentActionPrefixes)[number]
type PermissionPostfix = (typeof paymentActionPostfixes)[number]

function getPrefixes(permissions: UserPermission[]) {
  return paymentActionPrefixes.filter((prefix) =>
    permissions.some((permission) => permission.startsWith(prefix))
  )
}

function getPostfixes(permissions: UserPermission[]): PermissionPostfix[] {
  return paymentActionPostfixes.filter((postfix) =>
    permissions.some((permission) => permission.endsWith(`PAYMENTS_${postfix}`))
  )
}

const getDefaultPrefixes = (role: UserRole): PermissionPrefix[] => {
  return getPrefixes(mapRolesToPaymentPermissions[role].filter(checkIsPaymentPermission))
}

const getContributorPostfixes = (
  contributorRole: ContributorRole
): PermissionPostfix[] => {
  switch (contributorRole) {
    case 'approver':
      return ['APPROVE']
    case 'payer':
      return ['AUTHORISE']
    default:
      return []
  }
}

const getDefaultPostfixes = (
  role: UserRole,
  contributorRole: ContributorRole,
  allowedPostfixes: readonly PermissionPostfix[]
): PermissionPostfix[] => {
  if (role === 'CUSTOM') {
    return [
      ...getContributorPostfixes(contributorRole),
      allowedPostfixes.includes('VIEW') ? 'VIEW' : 'ASSIGNED_VIEW',
    ]
  }
  return getPostfixes(mapRolesToPaymentPermissions[role].filter(checkIsPaymentPermission))
}

const getDisplayedPaymentSettings = (
  role: UserRole,
  contributorRole: ContributorRole
): {
  prefixes: readonly PermissionPrefix[]
  postfixes: readonly PermissionPostfix[]
  disabledPrefixes: readonly PermissionPrefix[]
  disabledPostfixes: readonly PermissionPostfix[]
} => {
  switch (role) {
    case 'CREATOR':
      return {
        prefixes: paymentActionPrefixes,
        postfixes: ['VIEW', 'CREATE'],
        disabledPrefixes: ['INVOICE_PAYMENTS', 'MANUAL_PAYMENTS'],
        disabledPostfixes: ['CREATE'],
      }
    case 'APPROVER':
      return {
        prefixes: paymentActionPrefixes,
        postfixes: ['VIEW', 'ASSIGNED_VIEW', 'CREATE', 'APPROVE'],
        disabledPrefixes: ['INVOICE_PAYMENTS', 'MANUAL_PAYMENTS'],
        disabledPostfixes: ['CREATE', 'APPROVE'],
      }
    case 'PAYER':
      return {
        prefixes: paymentActionPrefixes,
        postfixes: ['VIEW', 'ASSIGNED_VIEW', 'CREATE', 'APPROVE', 'AUTHORISE'],
        disabledPrefixes: ['INVOICE_PAYMENTS', 'MANUAL_PAYMENTS'],
        disabledPostfixes: ['CREATE', 'APPROVE', 'AUTHORISE'],
      }
    case 'ADMIN':
      return {
        prefixes: paymentActionPrefixes,
        postfixes: ['VIEW', 'CREATE', 'APPROVE', 'AUTHORISE'],
        disabledPrefixes: ['INVOICE_PAYMENTS', 'MANUAL_PAYMENTS'],
        disabledPostfixes: ['CREATE', 'APPROVE', 'AUTHORISE'],
      }
    case 'CUSTOM':
      return {
        prefixes: paymentActionPrefixes,
        postfixes: ['VIEW', 'ASSIGNED_VIEW', 'CREATE', 'APPROVE', 'AUTHORISE'],
        disabledPrefixes: [],
        disabledPostfixes: getContributorPostfixes(contributorRole),
      }
    default:
      throw new ImpossibleError('unhandled role', role)
  }
}

const getPrefixLabel = (prefix: PermissionPrefix) => {
  switch (prefix) {
    case 'INVOICE_PAYMENTS':
      return 'Supplier'
    case 'MANUAL_PAYMENTS':
      return 'Manual'
    case 'PAYROLL_PAYMENTS':
      return 'Payroll'
    default:
      throw new ImpossibleError('unhandled prefix', prefix)
  }
}

const getPostfixLabel = (postfix: PermissionPostfix) => {
  switch (postfix) {
    case 'APPROVE':
      return 'Approve'
    case 'ASSIGNED_VIEW':
      return 'Assigned only'
    case 'VIEW':
      return 'Default'
    case 'AUTHORISE':
      return 'Pay'
    case 'CREATE':
      return 'Create'
    case 'EDIT':
      return 'Edit'
    default:
      throw new ImpossibleError('unhandled postfix', postfix)
  }
}

const checkIsViewOnlyState = (postfixes: Set<PermissionPostfix>) => {
  const paymentActionSettings: PermissionPostfix[] = ['CREATE', 'APPROVE', 'AUTHORISE']
  return paymentActionSettings.every((setting) => !postfixes.has(setting))
}

const getPermissions = (
  prefixes: PermissionPrefix[],
  postfixes: PermissionPostfix[]
): PaymentPermission[] => {
  return prefixes.flatMap((prefix) =>
    postfixes.map((postfix) => `${prefix}_${postfix}` as PaymentPermission)
  )
}

type TabPrefixes = Partial<Record<UserRole, Set<PermissionPrefix>>>
type TabPostfixes = Partial<Record<UserRole, Set<PermissionPostfix>>>

interface PaymentPermissionsFieldsProps {
  currentUserPermissions: UserPermission[]
  contributorRole: ContributorRole
  error?: string
  currentUserIsPracticeHubAdmin: boolean
}

export function PaymentPermissionsFields({
  currentUserPermissions,
  contributorRole,
  error,
  currentUserIsPracticeHubAdmin,
}: PaymentPermissionsFieldsProps) {
  const { watch, setValue } = useFormContext<RoleEditorFormValues>()
  const value = watch('paymentPermissions')
  const roleTab = watch('role')

  const allowedPrefixes = currentUserIsPracticeHubAdmin
    ? paymentActionPrefixes
    : getPrefixes(currentUserPermissions)
  const allowedPostfixes = currentUserIsPracticeHubAdmin
    ? paymentActionPostfixes
    : getPostfixes(currentUserPermissions)

  const [prefixes, setPrefixes] = useState<TabPrefixes>(() => ({
    [roleTab]:
      value.length === 0
        ? new Set(getDefaultPrefixes(roleTab))
        : new Set(getPrefixes(value)),
  }))
  const [postfixes, setPostfixes] = useState<TabPostfixes>(() => ({
    [roleTab]:
      value.length === 0
        ? new Set(getDefaultPostfixes(roleTab, contributorRole, allowedPostfixes))
        : new Set(getPostfixes(value)),
  }))

  function updateValue() {
    const tabPrefixes = prefixes[roleTab]
    const tabPostfixes = postfixes[roleTab]
    if (tabPrefixes && tabPostfixes) {
      const canViewOnly = checkIsViewOnlyState(tabPostfixes)
      if (canViewOnly) {
        tabPostfixes.delete('EDIT')
      } else {
        tabPostfixes.add('EDIT')
      }
      setValue(
        'paymentPermissions',
        getPermissions(Array.from(tabPrefixes), Array.from(tabPostfixes))
      )
    }
  }

  function onUpdatePrefixes(updatedPrefixes: Set<PermissionPrefix>) {
    setPrefixes({ ...prefixes, [roleTab]: updatedPrefixes })
  }

  function onUpdatePostfixes(updatedPostfixes: Set<PermissionPostfix>) {
    setPostfixes({ ...postfixes, [roleTab]: updatedPostfixes })
  }

  useEffect(() => {
    if (prefixes[roleTab] === undefined) {
      onUpdatePrefixes(new Set(getDefaultPrefixes(roleTab)))
    }
    if (postfixes[roleTab] === undefined) {
      onUpdatePostfixes(
        new Set(getDefaultPostfixes(roleTab, contributorRole, allowedPostfixes))
      )
    }
    if (prefixes[roleTab] && postfixes[roleTab]) {
      updateValue()
    }
  }, [roleTab])

  useEffect(() => {
    updateValue()
  }, [prefixes, postfixes])

  const tabPrefixes = prefixes[roleTab]
  const tabPostfixes = postfixes[roleTab]

  const displayedSettings = getDisplayedPaymentSettings(roleTab, contributorRole)

  return (
    <VStack gap={40}>
      <VStack gap={8}>
        <HStack
          css={{ alignItems: 'center', gridAutoColumns: '78px max-content' }}
          gap={16}
        >
          <Text variant="title-16" align="end">
            Access to
          </Text>
          <HStack gap={8}>
            {displayedSettings.prefixes.map((prefix) => (
              <TooltipWrapper
                key={prefix}
                enabled={!allowedPrefixes.includes(prefix)}
                tooltipContent="You can only give out the permissions you have"
              >
                <PermissionToggleButton
                  disabled={
                    !allowedPrefixes.includes(prefix) ||
                    displayedSettings.disabledPrefixes.includes(prefix)
                  }
                  checked={tabPrefixes?.has(prefix)}
                  onCheckedChange={(checked) => {
                    const updatedPrefixes = new Set(tabPrefixes)
                    if (checked) {
                      updatedPrefixes.add(prefix)
                    } else {
                      updatedPrefixes.delete(prefix)
                    }
                    onUpdatePrefixes(updatedPrefixes)
                  }}
                >
                  {getPrefixLabel(prefix)}
                </PermissionToggleButton>
              </TooltipWrapper>
            ))}
          </HStack>
        </HStack>
        {error && <Message variant="negative">{error}</Message>}
      </VStack>
      <HStack
        css={{ alignItems: 'center', gridAutoColumns: '78px max-content' }}
        gap={16}
      >
        <Text variant="title-16" align="end">
          Actions
        </Text>
        <HStack gap={8}>
          <PermissionToggleButton checked disabled>
            View
          </PermissionToggleButton>
          {displayedSettings.postfixes
            .filter((postfix) => !['VIEW', 'ASSIGNED_VIEW'].includes(postfix))
            .map((postfix) => {
              return (
                <TooltipWrapper
                  key={postfix}
                  enabled={!allowedPostfixes.includes(postfix)}
                  tooltipContent="You can only give out the permissions you have"
                >
                  <PermissionToggleButton
                    disabled={
                      !allowedPostfixes.includes(postfix) ||
                      displayedSettings.disabledPostfixes.includes(postfix) ||
                      (postfix === 'CREATE' && tabPostfixes?.has('ASSIGNED_VIEW'))
                    }
                    checked={tabPostfixes?.has(postfix)}
                    onCheckedChange={(checked) => {
                      const updatedPostfixes = new Set(tabPostfixes)
                      if (checked) {
                        updatedPostfixes.add(postfix)
                      } else {
                        updatedPostfixes.delete(postfix)
                      }
                      onUpdatePostfixes(updatedPostfixes)
                    }}
                  >
                    {getPostfixLabel(postfix)}
                  </PermissionToggleButton>
                </TooltipWrapper>
              )
            })}
        </HStack>
      </HStack>
      <VStack gap={12}>
        <HStack
          css={{ alignItems: 'center', gridAutoColumns: '78px max-content' }}
          gap={16}
        >
          <Text variant="title-16" align="end">
            Visibility
          </Text>

          <ButtonGroup>
            {displayedSettings.postfixes.includes('VIEW') && (
              <TooltipWrapper
                enabled={!allowedPostfixes.includes('VIEW')}
                tooltipContent="You can only give out the permissions you have"
              >
                <PermissionToggleButton
                  variant={
                    displayedSettings.postfixes.includes('ASSIGNED_VIEW')
                      ? 'simple'
                      : 'default'
                  }
                  disabled={
                    !allowedPostfixes.includes('VIEW') ||
                    displayedSettings.disabledPostfixes.includes('VIEW') ||
                    !displayedSettings.postfixes.includes('ASSIGNED_VIEW')
                  }
                  checked={tabPostfixes?.has('VIEW')}
                  onCheckedChange={(checked) => {
                    const updatedPostfixes = new Set(tabPostfixes)
                    if (checked) {
                      updatedPostfixes.delete('ASSIGNED_VIEW')
                      updatedPostfixes.add('VIEW')

                      if (
                        getDefaultPostfixes(
                          roleTab,
                          contributorRole,
                          allowedPostfixes
                        ).includes('CREATE')
                      ) {
                        updatedPostfixes.add('CREATE')
                      }
                    }
                    onUpdatePostfixes(updatedPostfixes)
                  }}
                >
                  {getPostfixLabel('VIEW')}
                </PermissionToggleButton>
              </TooltipWrapper>
            )}

            {displayedSettings.postfixes.includes('ASSIGNED_VIEW') && (
              <PermissionToggleButton
                variant="simple"
                disabled={displayedSettings.disabledPostfixes.includes('ASSIGNED_VIEW')}
                checked={tabPostfixes?.has('ASSIGNED_VIEW')}
                onCheckedChange={(checked) => {
                  const updatedPostfixes = new Set(tabPostfixes)
                  if (checked) {
                    updatedPostfixes.delete('VIEW')
                    updatedPostfixes.add('ASSIGNED_VIEW')

                    updatedPostfixes.delete('CREATE')
                  }
                  onUpdatePostfixes(updatedPostfixes)
                }}
              >
                {getPostfixLabel('ASSIGNED_VIEW')}
              </PermissionToggleButton>
            )}
          </ButtonGroup>
        </HStack>
        <Box css={{ marginInlineStart: `calc(78px + ${theme.space[16]})` }}>
          {tabPostfixes?.has('VIEW') && (
            <Text variant="paragraph-16">
              Member can see all bills and payments from selected payment types.
            </Text>
          )}
          {tabPostfixes?.has('ASSIGNED_VIEW') && (
            <Text variant="paragraph-16">
              Member can only see the bills they are assigned to via approval workflows.
              Can’t create payments.
            </Text>
          )}
        </Box>
      </VStack>
    </VStack>
  )
}
