import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

interface NumberRifmOptions extends Omit<Parameters<typeof useRifm>[0], 'format'> {}

export const useNumberRifm = (options: NumberRifmOptions) =>
  useRifm({
    ...options,
    format: formats.number,
  })
