export const Hide = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.694 3.72017L12.2798 2.30595L10.9481 3.63765C10.1095 3.24976 9.13055 3 8 3C3.77338 3 1.66574 6.4909 1.08691 7.63891C0.971031 7.86872 0.971031 8.13128 1.08691 8.36109C1.3897 8.96163 2.11084 10.2033 3.33481 11.251L2.30595 12.2798L3.72017 13.694L13.694 3.72017ZM4.75552 9.83024L9.39324 5.19252C8.96702 5.0704 8.50309 5 8 5C5.44587 5 3.90122 6.81451 3.17421 8C3.51503 8.55576 4.03555 9.24976 4.75552 9.83024ZM12.8258 8C12.6716 7.74854 12.4806 7.46879 12.251 7.18405L13.67 5.76503C14.2999 6.51171 14.7052 7.22649 14.9131 7.63891C15.029 7.86872 15.029 8.13128 14.9131 8.36109C14.3343 9.5091 12.2266 13 8 13C7.49495 13 7.02016 12.9502 6.57461 12.8604L8.45459 10.9804C10.7389 10.7817 12.143 9.11344 12.8258 8Z"
      fill="currentColor"
    />
  </svg>
)
