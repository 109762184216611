import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { Text, type TextProps, VStack } from '../../primitives'
import { theme } from '../../stitches'
import * as Snippet from '../snippet'

export interface OnboardingSnippetProps extends Snippet.SnippetProps {}

const OnboardingSnippet: GenericForwardRefComponent<OnboardingSnippetProps> = forwardRef<
  HTMLDivElement,
  OnboardingSnippetProps
>(function OnboardingSnippetRef({ children, ...rest }, ref) {
  return (
    <Snippet.Root ref={ref} p={24} {...rest}>
      <VStack
        gap={24}
        css={{ justifyItems: 'center', svg: { marginBlockStart: theme.space[16] } }}
      >
        {children}
      </VStack>
    </Snippet.Root>
  )
})

export interface OnboardingSnippetLabelProps extends TextProps {}

function OnboardingSnippetLabel(props: OnboardingSnippetLabelProps) {
  return <Text variant="title-16" align="center" {...props} />
}

export { OnboardingSnippet as Root, OnboardingSnippetLabel as Label }
