import { useHasFeatureFlag } from 'api/hooks/features'
import { useAuthSession } from 'domains/auth/queries'
import { decodeJwtPayload } from 'domains/auth/utils'
import { useLocation } from 'kitchen/router'
import { ImpossibleError } from 'kitchen/utils/error'
import type { ReactNode } from 'react'
import { Navigate } from 'react-router'
import { ErrorStatus } from '@app/components/error-status'
import { LoadingStatus } from '@app/components/loading-status'
import { Path } from '@app/constants'
import { useLogout } from '@app/hooks/use-logout'

interface MaybeRequire2Fa {
  children: ReactNode
}

export function MaybeRequire2Fa({ children }: MaybeRequire2Fa) {
  const authSession = useAuthSession()
  const logout = useLogout()
  const location = useLocation()
  const [hasForce2FaFeature] = useHasFeatureFlag(['FORCE_2FA'])

  if (!hasForce2FaFeature) {
    return children
  }

  if (authSession.isLoading) {
    return <LoadingStatus layout="flow-page" />
  }

  if (authSession.isError) {
    return (
      <ErrorStatus.Root layout="flow-page">
        <ErrorStatus.Icon />
        <ErrorStatus.Title>Something went wrong</ErrorStatus.Title>
        <ErrorStatus.Description>Please try again</ErrorStatus.Description>
        <ErrorStatus.Action onClick={() => logout()}>Logout</ErrorStatus.Action>
      </ErrorStatus.Root>
    )
  }

  const jwtTokenPayload = decodeJwtPayload(authSession.data.accessToken)
  switch (jwtTokenPayload['2fa_state']) {
    case 'ENROLLED':
    case 'NOT_ENROLLED':
      return children
    case 'NOT_ENROLLED_BUT_ENFORCED':
      return <Navigate to={Path.FORCE_2FA_SETUP} state={{ from: location }} />
    default:
      throw new ImpossibleError('Unhandled 2fa state', jwtTokenPayload['2fa_state'])
  }
}
