import { Slot } from '@radix-ui/react-slot'
import { forwardRef, useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import * as Icons from '../../icons'
import { Box, Grid } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { IconButton, type IconButtonProps } from '../icon-button'
import { InputGroupProvider } from '../input-group-provider'
import type { LabelProps } from '../label'
import { Label } from '../label'
import type { MessageProps } from '../message'
import { Message } from '../message'

const InputGroupBorder = styled('div', {
  borderWidth: 2,
  borderStyle: 'solid',
  zIndex: -1,
  borderRadius: theme.radii[8],
  borderColor: theme.colors['grey-15'],
  transition: 'border-color 0.3s',
})

const InputGroupStart = styled('div', {
  alignSelf: 'center',
  marginInlineEnd: theme.space[8],
})

const InputGroupEnd = styled('div', {
  alignSelf: 'center',
  marginInlineStart: theme.space[8],
})

const InputGroupGrid = styled(Grid, {
  width: '100%',
  'input, textarea, [data-input]': {
    gridArea: 'input',
    color: theme.colors['black'],
    '&::placeholder, [data-input-placeholder]': {
      opacity: 1,
      color: theme.colors['grey-40'],
    },
    '&:hover ~ [data-input-border]': {
      borderColor: theme.colors['grey-30'],
    },
    [`&[aria-required="true"] ~ ${Message}`]: {
      color: theme.colors['dark-yellow'],
    },
    [`&[aria-invalid="true"] ~ ${Message}`]: {
      color: theme.colors['dark-red'],
    },
    '&[readonly], &[aria-readonly="true"]': {
      '& ~ [data-input-border]': {
        background: theme.colors.transparent,
        borderColor: theme.colors.transparent,
      },
    },
    '&:focus, &[aria-expanded="true"]': {
      '&:not([readonly], [aria-readonly="true"]) ~ [data-input-border]': {
        borderColor: theme.colors['dark-blue'],
      },
    },
    '&[aria-required="true"]': {
      '& ~ [data-input-border]': {
        borderColor: theme.colors['dark-yellow-80'],
      },
      '&:hover, &:focus, &[aria-expanded="true"]': {
        '& ~ [data-input-border]': {
          borderColor: theme.colors['dark-yellow'],
        },
      },
    },
    '&[aria-invalid="true"]': {
      '& ~ [data-input-border]': {
        borderColor: theme.colors['light-red'],
      },
      '&:hover, &:focus, &[aria-expanded="true"]': {
        '& ~ [data-input-border]': {
          borderColor: theme.colors['dark-red'],
        },
      },
    },
    '&[aria-disabled="true"], &:disabled': {
      color: theme.colors['black'],
      '& ~ [data-input-border]': {
        borderColor: theme.colors['transparent'],
      },
      '&:hover, &:focus, &[aria-expanded="true"]': {
        '& ~ [data-input-border]': {
          borderColor: theme.colors['transparent'],
        },
      },
    },
  },
  '[data-autosize-spacer]': {
    gridArea: 'input',
  },
  [`${InputGroupStart}`]: {
    gridArea: 'start',
  },
  [`${InputGroupEnd}`]: {
    gridArea: 'end',
  },
  '[data-input-label]': {
    gridArea: 'label',
  },
  '[data-input-message]': {
    gridArea: 'message',
  },
  '&:has([readonly], [aria-readonly="true"], :disabled) [data-input-label]': {
    cursor: 'auto',
  },
  variants: {
    hug: {
      true: {
        width: 'fit-content',
        minWidth: 0,
        maxWidth: '100%',
      },
    },
    size: {
      small: {
        ...typography['paragraph-16'],
        '--input-side-padding': theme.space[10],
      },
      medium: {
        ...typography['paragraph-16'],
        '--input-side-padding': theme.space[12],
      },
    },
    variant: {
      control: {
        gridTemplate: `
          'label     label     label     '
          'start     input     end       '
          'message   message   message   ' auto / auto 1fr auto
        `,
        [`[data-input-border]`]: {
          display: 'none',
        },
      },
      bordered: {
        isolation: 'isolate',
        gridTemplate: `
          'label     label     label     label     label  '
          'pl        start     input     end       pr     '
          'message   message   message   message   message' auto / var(--input-side-padding) auto 1fr auto var(--input-side-padding)
        `,
        '[data-input-border]': {
          gridArea: 'start / pl / end /pr',
          background: theme.colors.white,
        },
        'button[data-input="true"]': {
          gridArea: 'start / pl / end /pr',
          paddingInline: 'var(--input-side-padding)',
        },
        '[data-input-label]': {
          marginBlockEnd: theme.space[8],
        },
        '[data-input-message]:not(:empty)': {
          marginBlockStart: theme.space[8],
        },
        '&:has(> [readonly], > [aria-readonly="true"])': {
          '--input-side-padding': 0,
        },
        'input, textarea, [data-input]': {
          '&[aria-disabled="true"], &:disabled': {
            '& ~ [data-input-border]': {
              borderColor: theme.colors.transparent,
              background: theme.colors['black-alpha-5'],
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
    variant: 'bordered',
  },
  compoundVariants: [
    {
      size: 'small',
      variant: 'bordered',
      css: {
        '[data-input-label]': {
          marginBlockEnd: theme.space[4],
        },
        '[data-input-message]:not(:empty)': {
          marginBlockStart: theme.space[4],
        },
        'input, textarea, [data-input], [data-autosize-spacer]': {
          paddingBlock: theme.space[8],
        },
      },
    },
    {
      size: 'medium',
      variant: 'control',
      css: {
        '[data-input-label]': {
          marginBlockEnd: theme.space[4],
        },
        'input, textarea, [data-input], [data-autosize-spacer]': {
          paddingBlock: theme.space[4],
          alignSelf: 'center',
        },
      },
    },
    {
      size: 'medium',
      variant: 'bordered',
      css: {
        'input, textarea, [data-input], [data-autosize-spacer]': {
          paddingBlock: '14px',
        },
      },
    },
  ],
})

export interface InputGroupLabelProps extends LabelProps {}

function InputGroupLabel({ css, ...rest }: InputGroupLabelProps) {
  const context = useContext(InputGroupContext)
  return (
    <div data-input-label>
      <Label htmlFor={context?.inputId} css={{ display: 'inline', ...css }} {...rest} />
    </div>
  )
}

export interface InputGroupMessageProps extends MessageProps {}

function InputGroupMessage(props: InputGroupMessageProps) {
  const context = useContext(InputGroupContext)
  return <Message id={context?.messageId} data-input-message {...props} />
}

export interface InputClearProps extends IconButtonProps {}

function InputClear(props: InputClearProps) {
  return (
    <IconButton
      size={24}
      css={{
        visibility: 'hidden',
        '[data-input-group]:focus-within &': { visibility: 'visible' },
      }}
      title="Clear"
      tabIndex={-1}
      {...props}
    >
      <Icons.S16.Cross />
    </IconButton>
  )
}

export interface InputGroupProps extends React.ComponentProps<typeof InputGroupGrid> {
  hug?: boolean
}

const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(function InputGroup(
  { children, css, variant = 'bordered', ...rest },
  forwardedRef
) {
  return (
    <Box ref={forwardedRef} data-input-group css={css}>
      <InputGroupGrid variant={variant} {...rest}>
        <InputGroupProvider>
          {children}
          <InputGroupBorder data-input-border />
        </InputGroupProvider>
      </InputGroupGrid>
    </Box>
  )
})

export interface InputContentProps extends React.HTMLAttributes<HTMLElement> {
  asChild?: boolean
}

const InputContent = forwardRef<HTMLDivElement, InputContentProps>(
  function InputContentRef({ asChild, ...rest }, ref) {
    const Comp = asChild ? Slot : Box
    return <Comp ref={ref} data-input {...rest} />
  }
)

export {
  InputGroup as Root,
  InputGroupStart as Start,
  InputGroupEnd as End,
  InputGroupLabel as Label,
  InputGroupMessage as Message,
  InputContent as Content,
  InputClear as Clear,
}
