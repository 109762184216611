import { useQuery } from '@tanstack/react-query'
import { useIsAuthenticated } from 'domains/auth/queries'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import { usePublicFetch } from 'kitchen/hooks/use-public-fetch'
import type { FetchError, UseQueryOptions } from 'kitchen/types'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { getFeatureFlags } from '../requests/features'
import type { CompanyId } from '../types/companies'
import type { FeatureFlag } from '../types/features'

export const useFeatureFlags = (
  companyId: CompanyId | undefined,
  options?: UseQueryOptions<FeatureFlag[]>
) => {
  const isAuthenticated = useIsAuthenticated()

  const authorizedFetch = useFetch()
  const publicFetch = usePublicFetch()

  const fetch = isAuthenticated ? authorizedFetch : publicFetch

  return useQuery<FeatureFlag[], FetchError>(
    [CacheKey.FEATURES, companyId, isAuthenticated],
    ({ signal }) => getFeatureFlags(fetch, { companyId }, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export function useHasFeatureFlag(input: FeatureFlag[]): boolean[]
export function useHasFeatureFlag(
  companyId: CompanyId | undefined,
  input: FeatureFlag[]
): boolean[]
export function useHasFeatureFlag(
  arg1?: CompanyId | FeatureFlag[] | undefined,
  arg2?: FeatureFlag[]
): boolean[] {
  const companyId = typeof arg1 === 'string' ? arg1 : undefined
  const features = Array.isArray(arg1) ? arg1 : arg2 ?? []

  const featureFlags = useFeatureFlags(companyId, { suspense: true }).data ?? []
  return features.map((flag) => featureFlags.includes(flag))
}
