import {
  useVirtualizer,
  defaultRangeExtractor,
  type Range,
} from '@tanstack/react-virtual'
import { useLocale } from 'kitchen/hooks/use-locale'
import { alphabeticSort, groupBy } from 'kitchen/utils/data'
import type React from 'react'
import { useRef, useMemo, useCallback } from 'react'
import { Box, Grid } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Label } from '../label'
import { ScrollArea } from '../scroll-area'
import { useContactSelectContext } from './context'
import { getDefaultOption } from './get-default-option'
import type { Option } from './types'

const VirtualRowWrapper = styled('div', {
  paddingInline: theme.space[16],
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  background: theme.colors.white,
  variants: {
    variant: {
      header: {},
    },
    sticky: {
      true: {
        position: 'sticky',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'header',
      sticky: true,
      css: {
        zIndex: 1,
      },
    },
  ],
})

type VirtualRowWrapperProps = React.ComponentProps<typeof VirtualRowWrapper>

interface HeaderRowProps extends VirtualRowWrapperProps {
  value: string
}

function HeaderRow({ value, ...rest }: HeaderRowProps) {
  return (
    <VirtualRowWrapper variant="header" {...rest}>
      <Grid
        px={8}
        pt={2}
        pb={4}
        css={{
          height: '100%',
          alignItems: 'flex-end',
        }}
      >
        <Label size={13}>{value}</Label>
      </Grid>
    </VirtualRowWrapper>
  )
}

interface ValueRowProps extends VirtualRowWrapperProps {
  value: Option
  renderOption?: (option: Option) => React.ReactNode
}

function ValueRow({ value, renderOption = getDefaultOption, ...rest }: ValueRowProps) {
  return <VirtualRowWrapper {...rest}>{renderOption(value)}</VirtualRowWrapper>
}

const ITEM_SIZE = 40
const ITEM_GAP = 4

interface AlphabeticContactOptionsProps {
  children?: React.ReactNode
  stickyGroupHeaders?: boolean
  renderOption?: (option: Option) => React.ReactNode
}

export function VirtualOptionList({
  children,
  stickyGroupHeaders = false,
  renderOption = getDefaultOption,
}: AlphabeticContactOptionsProps) {
  const { options } = useContactSelectContext()
  const locale = useLocale()

  const activeStickyIndexRef = useRef<number>()
  const scrollParentRef = useRef<HTMLDivElement | null>(null)
  const scrollBodyRef = useRef<HTMLDivElement | null>(null)

  // string row means group header
  const rows: (string | Option)[] = useMemo(() => {
    const groupedOptions = groupBy(options, (option) =>
      option.label.charAt(0).toLocaleLowerCase()
    )
    const groupNames = Object.keys(groupedOptions).sort((a, b) =>
      alphabeticSort(a, b, locale)
    )

    return groupNames.flatMap((groupName) => [
      groupName,
      ...groupedOptions[groupName].sort((a, b) =>
        alphabeticSort(a.label, b.label, locale)
      ),
    ])
  }, [options, locale])

  const isActiveSticky = (index: number) =>
    stickyGroupHeaders ? activeStickyIndexRef.current === index : false

  const scrollMargin = scrollBodyRef.current ? scrollBodyRef.current.offsetTop : 0

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    scrollMargin,
    estimateSize: (index) => (index === 0 ? ITEM_SIZE : ITEM_SIZE + ITEM_GAP),
    getScrollElement: () => scrollParentRef.current,
    getItemKey: (index) => {
      const row = rows[index]
      return typeof row === 'string' ? row : row.value.id
    },
    rangeExtractor: useCallback(
      (range: Range) => {
        if (stickyGroupHeaders === false) {
          return defaultRangeExtractor(range)
        }

        const stickyIndexes = rows.flatMap((row, index) =>
          typeof row === 'string' ? index : []
        )

        const currentStickyIndex =
          [...stickyIndexes].reverse().find((index) => range.startIndex >= index) ?? 0

        activeStickyIndexRef.current = currentStickyIndex

        const next = new Set([currentStickyIndex, ...defaultRangeExtractor(range)])

        return [...next].sort((a, b) => a - b)
      },
      [rows, stickyGroupHeaders]
    ),
  })

  if (rows.length > 0) {
    return (
      <ScrollArea ref={scrollParentRef} variant="custom-scrollbar">
        {children}
        <Box
          ref={scrollBodyRef}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const value = rows[virtualRow.index]
            const sticky = isActiveSticky(virtualRow.index)
            if (typeof value === 'string') {
              return (
                <HeaderRow
                  key={virtualRow.key}
                  value={value}
                  sticky={sticky}
                  style={{
                    paddingBlockStart: virtualRow.index === 0 ? 0 : ITEM_GAP,
                    height: `${virtualRow.size}px`,
                    transform: sticky
                      ? 'none'
                      : `translateY(${virtualRow.start - scrollMargin}px )`,
                  }}
                />
              )
            }
            return (
              <ValueRow
                key={virtualRow.key}
                value={value}
                renderOption={renderOption}
                style={{
                  paddingBlockStart: virtualRow.index === 0 ? 0 : ITEM_GAP,
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start - scrollMargin}px)`,
                }}
              />
            )
          })}
        </Box>
      </ScrollArea>
    )
  }
  return children
}
