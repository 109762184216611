import { useState, useLayoutEffect, useEffect } from 'react'

const useUniversalLayoutEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect

export function useMatchMedia(media: string) {
  const [matches, setMatches] = useState<boolean>(false)

  useUniversalLayoutEffect(() => {
    const mql = window.matchMedia(media)

    setMatches(mql.matches)
    const handleChange = () => setMatches(mql.matches)

    mql.addEventListener('change', handleChange)
    return () => mql.removeEventListener('change', handleChange)
  }, [media])

  return matches
}
