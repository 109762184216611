const literal = {
  yellow: 'rgba(255, 216, 1, 1)',
  'yellow-90': 'rgba(255, 202, 40, 1)',
  'yellow-80': 'rgba(255, 184, 0, 1)',

  'dark-green': 'rgba(100, 169, 22, 1)',
  'light-green': 'rgba(179, 229, 124, 1)',
  'light-green-30': 'rgba(235, 249, 219, 1)',
  'light-green-60': 'rgba(215, 244, 183, 1)',

  'dark-blue': 'rgba(0, 120, 212, 1)',

  'light-blue': 'rgba(194, 226, 250, 1)',
  'light-blue-20': 'rgba(239, 247, 253, 1)',
  'light-blue-40': 'rgba(224, 240, 252, 1)',
  'light-blue-50': 'rgba(216, 236, 252, 1)',
  'light-blue-80': 'rgba(206, 231, 251, 1)',

  'dark-red': 'rgba(203, 37, 37, 1)',
  'light-red': 'rgba(229, 113, 113, 1)',
  'light-red-20': 'rgba(253, 231, 231, 1)',
  'light-red-30': 'rgba(252, 207, 207, 1)',
  'light-red-70': 'rgba(251, 151, 154, 1)',
  'light-red-85': 'rgba(249, 128, 131, 1)',

  'dark-yellow': 'rgba(163, 95, 0, 1)',
  'dark-yellow-80': 'rgba(207, 138, 0, 1)',
  'light-yellow-60': 'rgba(255, 245, 194, 1)',

  'grey-60': 'rgba(112, 109, 92, 1)',
  'grey-40': 'rgba(163, 160, 143, 1)',
  'grey-30': 'rgba(186, 183, 168, 1)',
  'grey-20': 'rgba(212, 210, 196, 1)',
  'grey-15': 'rgba(223, 221, 211, 1)',
  'grey-10': 'rgba(236, 235, 227, 1)',
  'grey-5': 'rgba(245, 244, 237, 1)',
  'grey-2': 'rgba(250, 249, 242, 1)',

  black: 'rgba(0, 0, 0, 1)',
  'black-alpha-5': 'rgba(0, 0, 0, 0.05)',
  'black-alpha-10': 'rgba(0, 0, 0, 0.1)',
  'black-alpha-20': 'rgba(0, 0, 0, 0.2)',
  'black-alpha-30': 'rgba(0, 0, 0, 0.3)',
  'black-alpha-40': 'rgba(0, 0, 0, 0.4)',
  'black-alpha-60': 'rgba(0, 0, 0, 0.6)',
  'black-alpha-70': 'rgba(0, 0, 0, 0.7)',
  'black-alpha-80': 'rgba(0, 0, 0, 0.8)',

  white: '#fff',
  'white-alpha-15': 'rgba(255, 255, 255, 0.15)',
  'white-alpha-30': 'rgba(255, 255, 255, 0.3)',
  'white-alpha-50': 'rgba(255, 255, 255, 0.5)',
  'white-alpha-60': 'rgba(255, 255, 255, 0.6)',
  'white-alpha-70': 'rgba(255, 255, 255, 0.7)',
  'white-alpha-80': 'rgba(255, 255, 255, 0.8)',

  transparent: 'rgba(255, 255, 255, 0)',
} as const

const semantic = {
  'dialog-overlay-background': 'rgba(1, 50, 51, 0.6)',
  'dialog-overlay-icon': 'rgba(102, 131, 129, 1)',
  'document-preview-background': 'rgba(102, 102, 102, 1)',

  'page-text': literal['black'],
  'page-background': literal['grey-2'],
  'page-edit-background': literal['grey-10'],

  'diff-add-text': literal['black'],
  'diff-add-background': 'rgba(226, 249, 224, 1)',
  'diff-remove-text': literal['light-red'],
  'diff-remove-background': literal['light-red-20'],

  'tabs-text': literal['grey-60'],
  'tabs-text-pressed': literal['black'],
  'tabs-indicator-background': literal['black'],

  'button-main-text': literal['black'],
  'button-main-text-disabled': literal['black-alpha-30'],
  'button-main-background': literal['yellow'],
  'button-main-background-hover': literal['yellow-90'],
  'button-main-background-pressed': literal['yellow-80'],
  'button-main-background-disabled': literal['black-alpha-5'],

  'button-common-text': literal['white'],
  'button-common-text-disabled': literal['white-alpha-50'],
  'button-common-background': literal['black'],
  'button-common-background-hover': literal['black-alpha-80'],
  'button-common-background-pressed': literal['black-alpha-70'],
  'button-common-background-disabled': literal['black-alpha-30'],

  'button-minor-text': literal['black'],
  'button-minor-text-disabled': literal['black-alpha-30'],
  'button-minor-border': literal['grey-15'],
  'button-minor-border-hover': literal['grey-15'],
  'button-minor-border-pressed': literal['grey-20'],
  'button-minor-border-disabled': 'rgba(211, 218, 223, 0.3)',
  'button-minor-background': literal['transparent'],
  'button-minor-background-hover': literal['grey-15'],
  'button-minor-background-pressed': literal['grey-20'],
  'button-minor-background-disabled': 'rgba(211, 218, 223, 0.3)',

  'button-destructive-text': literal['white'],
  'button-destructive-text-disabled': literal['white-alpha-50'],
  'button-destructive-background': literal['light-red'],
  'button-destructive-background-hover': literal['light-red-85'],
  'button-destructive-background-pressed': literal['light-red-70'],
  'button-destructive-background-disabled': 'rgba(178, 178, 178, 1)',

  'button-minor-active-border': literal['light-blue-80'],
  'button-minor-active-border-hover': literal['light-blue'],
  'button-minor-active-border-pressed': literal['light-blue'],
  'button-minor-active-border-disabled': 'rgba(211, 218, 223, 0.3)',
  'button-minor-active-background': literal['light-blue-80'],
  'button-minor-active-background-hover': literal['light-blue'],
  'button-minor-active-background-pressed': literal['light-blue'],
  'button-minor-active-background-disabled': 'rgba(211, 218, 223, 0.3)',

  'surface-primary-background': literal['white'],
  'surface-primary-border': literal['grey-5'],
  'surface-primary-text': literal['black'],

  'surface-secondary-background': literal['grey-2'],
  'surface-secondary-text': literal['black'],

  'surface-blue-background': literal['dark-blue'],
  'surface-blue-text': literal['white'],

  'surface-black-background': literal['black'],
  'surface-black-text': literal['white'],

  'surface-ghost-border': literal['grey-10'],
  'surface-ghost-background': literal['transparent'],
  'surface-ghost-text': literal['grey-60'],

  'surface-log-border': literal['grey-10'],

  'avatar-celeste-text': 'rgba(17, 144, 122, 1)',
  'avatar-celeste-background': 'rgba(218, 248, 243, 1)',

  'avatar-blue-text': 'rgba(33, 131, 206, 1)',
  'avatar-blue-background': 'rgba(217, 238, 255, 1)',

  'avatar-lavender-text': 'rgba(92, 97, 222, 1)',
  'avatar-lavender-background': 'rgba(224, 226, 255, 1)',

  'avatar-purple-text': 'rgba(140, 73, 206, 1)',
  'avatar-purple-background': 'rgba(239, 229, 255, 1)',

  'avatar-pink-text': 'rgba(200, 82, 146, 1)',
  'avatar-pink-background': 'rgba(255, 231, 244, 1)',

  'avatar-silk-text': 'rgba(181, 108, 75, 1)',
  'avatar-silk-background': 'rgba(255, 229, 217, 1)',

  'avatar-wheat-text': 'rgba(172, 122, 44, 1)',
  'avatar-wheat-background': 'rgba(255, 235, 204, 1)',

  'avatar-blond-text': 'rgba(148, 122, 12, 1)',
  'avatar-blond-background': 'rgba(255, 245, 204, 1)',

  'elevation-100-shadow': 'rgba(70, 58, 0, 0.1)',
  'elevation-200-shadow': 'rgba(70, 58, 0, 0.2)',
  'elevation-300-shadow': literal['light-blue-50'],
  'elevation-400-shadow': literal['light-blue-50'],
  'elevation-300-shadow-focused': literal['light-blue'],
} as const

const website = {
  'website-page-background-enter': 'rgba(251, 239, 175, 1)',
  'website-page-background-leave': 'rgba(251, 249, 241, 1)',
  'website-page-background': 'rgba(251, 239, 175, 1)',
  'website-media-background': 'rgba(245, 243, 230, 1)',
  'website-yellow': 'rgba(249, 217, 0, 1)',
  'website-yellow-10': 'rgba(255, 202, 40, 1)',
  'website-yellow-20': 'rgba(255, 184, 0, 1)',
  'website-highlight': 'rgba(251, 239, 175, 1)',
  'website-highlight-10': 'rgba(251, 234, 130, 1)',
  'website-highlight-20': 'rgba(255, 224, 51, 1)',
  'website-highlight-alpha-50': 'rgba(255, 216, 1, 0.5)',
  'website-highlight-alpha-70': 'rgba(255, 216, 1, 0.70)',
  'website-highlight-alpha-100': 'rgba(255, 216, 1, 1)',
  'website-grey-10': 'rgba(223, 220, 205, 1)',
  'website-grey-20': 'rgba(212, 209, 193, 1)',
  'website-grey-50': 'rgba(140, 136, 115, 1)',
  'website-elevation-shadow': 'rgba(93, 56, 0, 0.10)',
} as const

export const colors = {
  ...literal,
  ...semantic,
  ...website,
} as const

export type Color = keyof typeof colors
