import { captureException } from '@sentry/react'
import type { AccountingApp } from 'api/types/vendors'
import { SyncSource } from 'api/types/vendors'
import { ImpossibleError } from './error'

export const isAccountingApp = (source: string | undefined) =>
  source !== undefined &&
  ([SyncSource.XERO, SyncSource.QUICKBOOKS] as const).includes(source)

export function checkIsXeroAccountingApp(source: string | undefined) {
  return isAccountingApp(source) && source === 'xero'
}

export function checkIsQuickbooksAccountingApp(source: string | undefined) {
  return isAccountingApp(source) && source === 'quickbooks'
}

export function getAccountingAppLabel(source: AccountingApp) {
  switch (source) {
    case SyncSource.XERO:
      return 'Xero'
    case SyncSource.QUICKBOOKS:
      return 'QuickBooks'
    default:
      captureException(new ImpossibleError('Unknown accounting app source', source))
      return 'Unknown'
  }
}

export function getSyncSourceLabel(source: SyncSource) {
  switch (source) {
    case SyncSource.GOOGLE:
      return 'Google'
    case SyncSource.INTERNAL:
      return 'Email'
    default:
      return getAccountingAppLabel(source)
  }
}
