import {
  useMutation,
  useQuery,
  useQueryClient,
  type MutateOptions,
} from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type {
  FetchError,
  UseQueryOptions,
  UseMutationOptions,
  QueryHookFactory,
} from 'kitchen/types'
import { immutableRequestOptions, useDebouncedRequest } from 'kitchen/utils/fetch'
import { inviteCompanyUser, unInviteCompanyUser } from '../requests/companies'
import { getCurrentUser, getUserSearchResult } from '../requests/users'
import type {
  InviteUserPayload,
  UnInviteUserPayload,
  MePayload,
  AuthUser,
  UserSearchPayload,
  UserSearchResponse,
  UpdateUserDetailsPayload,
  User,
} from '../types/users'

export function useInviteUser(options?: MutateOptions<User, unknown, InviteUserPayload>) {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => inviteCompanyUser(fetch, payload), {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(...args)
    },
  })
}

export function useUnInviteUser(options?: UseMutationOptions<UnInviteUserPayload, void>) {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => unInviteCompanyUser(fetch, payload), {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(...args)
    },
  })
}

export interface UpdateCurrentUserOptions extends UseMutationOptions<MePayload, void> {}

export function useUpdateCurrentUser(options?: UpdateCurrentUserOptions) {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation(
    (payload: MePayload) => fetch.patch('/users/me', { json: payload }).void(),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
        await queryClient.invalidateQueries([CacheKey.COMPANIES])
        await options?.onSuccess?.(data, variables, context)
      },
    }
  )
}

export interface UpdateCompanyUserOptions
  extends UseMutationOptions<UpdateUserDetailsPayload, void> {}

export function useUpdateUser(options?: UpdateCompanyUserOptions) {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation(
    ({ userId, ...payload }: UpdateUserDetailsPayload) =>
      fetch.patch(`/users/${userId}`, { json: payload }).void(),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
        await queryClient.invalidateQueries([CacheKey.COMPANIES])
        await options?.onSuccess?.(data, variables, context)
      },
    }
  )
}

export const useCurrentUser: QueryHookFactory<void, AuthUser> = (options) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.CURRENT_USER],
    queryFn: () => getCurrentUser(fetch),
    ...immutableRequestOptions,
    ...options,
  })
}

export const useUserSearch = (
  payload: UserSearchPayload,
  options?: UseQueryOptions<UserSearchResponse | null>
) => {
  const fetch = useFetch()
  const debouncedRequest = useDebouncedRequest()

  return useQuery<UserSearchResponse | null, FetchError>(
    [CacheKey.USER_SEARCH, payload.companyId, payload.email],
    ({ signal }) =>
      debouncedRequest(() => getUserSearchResult(fetch, payload, signal), signal),
    options
  )
}
