/**
 *
 * @param ms
 * usage: await sleep(200); // then do stuff
 */
export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms))
}

export const settled = <R>(promise: Promise<R>) =>
  Promise.allSettled([promise]).then(([result]) => result)

export const sequence = <R>(input: Array<() => Promise<R>>) =>
  input.reduce<Promise<R[]>>(
    (acc, fn) => acc.then((result) => Promise.all([...result, fn()])),
    Promise.all([])
  )

export async function until<R>(
  fun: () => Promise<R>,
  check: (result: R) => boolean,
  delay: number
): Promise<R> {
  const result = await fun()

  if (check(result)) {
    return result
  }

  await sleep(delay)
  return until(fun, check, delay)
}

export type OnSettledProgress<T> = (
  index: number,
  results: PromiseSettledResult<T>[]
) => void

export function settledProgress<T>(
  promises: Promise<T>[],
  onSettle: OnSettledProgress<T> = () => {
    return
  }
) {
  const results: PromiseSettledResult<T>[] = []
  return Promise.all(
    promises.map((promise, index) =>
      settled(promise).then((result) => {
        results.push(result)
        onSettle(index, results)
        return result
      })
    )
  )
}
