import { useControlled } from 'kitchen/hooks/use-controlled'
import { useId } from 'react'
import { Collapsible, Separator, Switch } from 'salad/components'
import { HStack, Text, VStack } from 'salad/primitives'
import { TooltipWrapper } from './tooltip-wrapper'

interface PermissionsSectionProps {
  title: string
  children: React.ReactNode
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (value: boolean) => void
  toggleable?: boolean
  restricted?: boolean
}

export function PermissionsSection({
  title,
  defaultOpen = false,
  open,
  onOpenChange,
  children,
  toggleable = true,
  restricted = false,
}: PermissionsSectionProps) {
  const [isOpen, setOpen] = useControlled<boolean>({
    defaultValue: defaultOpen,
    value: open,
    onChange: onOpenChange,
  })
  const labelId = useId()
  const switchId = useId()

  return (
    <Collapsible.Root open={isOpen}>
      <HStack
        as="label"
        htmlFor={switchId}
        gap={32}
        pt={32}
        px={32}
        css={{
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: toggleable && !restricted ? 'pointer' : 'auto',
        }}
      >
        <Text variant="headline-h3" id={labelId}>
          {title}
        </Text>
        <TooltipWrapper
          enabled={restricted}
          tooltipContent="You can only give out the permissions you have"
        >
          {toggleable && (
            <Switch
              size={20}
              id={switchId}
              checked={isOpen}
              onCheckedChange={setOpen}
              disabled={restricted}
              aria-labelledby={labelId}
            />
          )}
        </TooltipWrapper>
      </HStack>
      <Collapsible.Content>
        <VStack pt={24} px={32}>
          {children}
        </VStack>
      </Collapsible.Content>

      <Separator size={1} mt={32} />
    </Collapsible.Root>
  )
}
