import type { Money } from 'domains/money/types'
import { accountDetails, nonDigits } from 'kitchen/utils/regex'
import * as yup from 'yup'

declare module 'yup' {
  export interface StringSchema {
    sanitizedDigitsLength(requiredLength: number): StringSchema
  }
}

export * from 'yup'
export * from './money'
export * from './payruns'
export * from './date'

/**
 * Checks how many digits a string contains, if all the other characters are trimmed.
 */
yup.addMethod(yup.string, 'sanitizedDigitsLength', function (requiredLength: number) {
  return this.test({
    name: 'sortCode',
    exclusive: true,
    message: `Should be ${requiredLength} digits`,
    test(value) {
      try {
        if (!value) {
          return false
        }
        const sanitized = value.replace(nonDigits, '').trim()
        return sanitized.length === requiredLength
      } catch (e) {
        return false
      }
    },
  })
})

export const maxMoneyAmount = (
  maxAmount: Money | undefined,
  formatter: (money: Money) => string
) => {
  if (!maxAmount) {
    return yup.number()
  }

  return yup
    .number()
    .moreThan(0, 'Required field')
    .max(maxAmount.amount, `Woah, there! The bill is only ${formatter(maxAmount)}`)
}

export const sortCode = ({ required = true } = {}) =>
  yup
    .string()
    .trim()
    .when([], {
      is: () => required === true,
      then: (schema) => schema.required('Required field').sanitizedDigitsLength(6),
      otherwise: (schema) => schema,
    })
    .matches(accountDetails, 'No letters please')

export const accountNumber = ({ required = true } = {}) =>
  yup
    .string()
    .trim()
    .when([], {
      is: () => required === true,
      then: (schema) => schema.required('Required field').sanitizedDigitsLength(8),
      otherwise: (schema) => schema,
    })
    .matches(accountDetails, 'No letters please')

export const paymentContact = ({ required = true } = {}) =>
  yup.object({
    legalName: yup
      .string()
      .trim()
      .when([], {
        is: () => required === true,
        then: (schema) => schema.required('Required field'),
        otherwise: (schema) => schema,
      }),
    sortCode: sortCode({ required }),
    accountNumber: accountNumber({ required }),
  })

export const invoiceNumber = ({ required = true } = {}) =>
  yup
    .string()
    .trim()
    .when([], {
      is: () => required === true,
      then: (schema) => schema.required('Required field'),
      otherwise: (schema) => schema,
    })

export const email = ({ required = true } = {}) =>
  yup
    .string()
    .trim()
    .when([], {
      is: () => required === true,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    })
    .email('Please use a valid email address')
