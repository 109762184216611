import type { CountryCode } from 'api/types/address'
import { useMemo } from 'react'
import { useLocale } from './use-locale'

export function useFormatCountryCode() {
  const locale = useLocale()
  return useMemo(() => {
    if (typeof Intl.DisplayNames !== 'function') {
      return function formatCountryCode(input: CountryCode | null | undefined) {
        if (typeof input === 'string') {
          return input
        }
      }
    }

    const regionNames = new Intl.DisplayNames([locale], {
      type: 'region',
    })

    return function formatCountryCode(input: CountryCode | null | undefined) {
      if (typeof input === 'string') {
        try {
          return regionNames.of(input)
        } catch (_) {}
      }
    }
  }, [locale])
}
