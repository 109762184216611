const literal = {
  400: '400',
  500: '500',
  700: '700',
  800: '800',
} as const

const semantic = {
  'price-tag-large-main': literal[400],
  'price-tag-large-support': literal[800],
  'price-tag-medium-main': literal[400],
  'price-tag-medium-support': literal[800],
  'price-tag-small-main': literal[400],
  'price-tag-small-support': literal[800],
  'headline-h1': literal[400],
  'headline-h2': literal[700],
  'headline-h3': literal[700],
  'title-16': literal[700],
  'title-14': literal[700],
  'paragraph-20': literal[400],
  'paragraph-16': literal[400],
  'paragraph-14': literal[400],
  'paragraph-12': literal[400],
  'label-16': literal[400],
  'label-13': literal[500],
  'button-large': literal[800],
  avatar: literal[700],
} as const

export const fontWeights = {
  ...literal,
  ...semantic,
} as const

export type FontWeight = keyof typeof fontWeights
