import 'salad/static/fonts.css'
import './features/bug-report/sentry-styles-override.css'
import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app'

if (
  ['staging', 'production', 'demo'].includes(process.env.BUILD_MODE) &&
  process.env.BRANCH_STAGING === undefined && // disable on PR preview stands
  process.env.PLAYWRIGHT === undefined // disable for test runs
) {
  Sentry.init({
    environment: process.env.BUILD_MODE,
    dsn: process.env.SENTRY_DSN,
    integrations:
      process.env.BUILD_MODE === 'production'
        ? [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()]
        : [],
    tracesSampleRate: 1.0,
    profilesSampleRate: 0.7,
    release: process.env.GIT_COMMIT_HASH,
    ignoreErrors: [/^(ManualCancellationError|AuthError|AbortError)/],
  })
}

const element = document.getElementById('root')

if (element !== null) {
  const root = createRoot(element)

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}
