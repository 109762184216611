import { VStack } from '../../primitives'
import { styled, theme } from '../../stitches'

export interface TableContentProps extends React.ComponentProps<typeof TableContent> {}

const TableContent = styled(VStack, {
  padding: theme.space[8],
  marginInline: `calc(${theme.space[8]} * -1)`,
  alignContent: 'center',
  overflow: 'hidden',
  'td:last-child &, th:last-child &': {
    width: 'auto',
  },
  variants: {
    align: {
      start: {
        justifyContent: 'start',
        textAlign: 'start',
      },
      end: {
        justifyContent: 'end',
        justifyItems: 'end',
        textAlign: 'end',
      },
    },
  },
  defaultVariants: {
    align: 'start',
  },
})

export { TableContent as Content }
