export const fontFeatureSettings = {
  'price-tag-large-main': "'pnum' on, 'lnum' on",
  'price-tag-large-support': "'pnum' on, 'lnum' on",
  'price-tag-medium-main': "'pnum' on, 'lnum' on",
  'price-tag-medium-support': "'pnum' on, 'lnum' on",
  'price-tag-small-main': "'pnum' on, 'lnum' on",
  'price-tag-small-support': "'pnum' on, 'lnum' on",
  'headline-h1': "'pnum' on, 'lnum' on, 'ss09' on",
  'headline-h2': "'pnum' on, 'lnum' on, 'ss09' on",
  'headline-h3': "'pnum' on, 'lnum' on, 'ss09' on",
  'button-large': "'pnum' on, 'lnum' on, 'ss09' on",
} as const

export type FontFeatureSetting = keyof typeof fontFeatureSettings
