import { Box, type BoxProps } from '../../primitives'
import { theme } from '../../stitches'
import type { Color } from '../../tokens'

function getWidth(value: number, total: number) {
  if (total === 0) {
    return value === 0 ? 0 : 100
  }

  return Math.trunc((value / total) * 100)
}

export interface ProgressBarProps extends Omit<BoxProps, 'children'> {
  value: number
  total: number
  color?: Color
}

export const ProgressBar = ({
  value,
  total,
  color = 'dark-blue',
  css,
  ...rest
}: ProgressBarProps) => {
  return (
    <Box
      role="progressbar"
      aria-valuemin={1}
      aria-valuemax={total}
      aria-valuenow={value}
      css={{
        backgroundColor: theme.colors['grey-10'],
        borderRadius: theme.radii[8],
        height: theme.space[8],
        overflow: 'hidden',
        ...css,
      }}
      {...rest}
    >
      <Box
        css={{
          borderRadius: theme.radii[8],
          backgroundColor: theme.colors[color],
          width: `${getWidth(value, total)}%`,
          height: '100%',
          transition: 'width 0.3s, background-color 0.3s',
        }}
      />
    </Box>
  )
}
