export const nonDigits = new RegExp(/\D/g)
export const digits = new RegExp(/\d/g)
export const whitespaces = new RegExp(/\s/g)
export const wordChars = new RegExp(/\w/g)
export const nonWordChars = new RegExp(/\W/g)
export const firstWordLetters = new RegExp(/\b(\w)/g)
export const alphaNumeric = new RegExp(/^[a-z0-9]+$/i)
export const nonAlphaNumeric = new RegExp(/[^a-z0-9]/gi)
export const accountDetails = new RegExp(`^[${digits.source}${whitespaces.source}-_—]*$`)

export const reference = /^[a-zA-Z0-9 \-/?:().+#=!%&*<>;{@“’]+$/gi
export const notReference = /[^a-zA-Z0-9 \-/?:().+#=!%&*<>;{@“’]/gi
export const specialChars = /[^a-z0-9 ]/gi
