import type { GenericForwardRefComponent } from 'kitchen/types'
import { useState, forwardRef, useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import * as Icons from '../../icons'
import { HStack } from '../../primitives'
import { IconButton } from '../icon-button'
import { Input, type InputProps } from '../input'
import * as InputGroup from '../input-group'

export interface PasswordInputProps
  extends Omit<InputProps, 'type' | 'value' | 'onChange'> {
  defaultVisible?: boolean
  value: string
  onValueChange: (value: string) => void
  /** @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password#allowing_autocomplete */
  autoComplete: 'new-password' | 'current-password' | 'off'
}

export const PasswordInput: GenericForwardRefComponent<PasswordInputProps> = forwardRef(
  function PasswordInput(
    { defaultVisible = false, autoComplete, value, onValueChange, ...rest },
    forwardedRef
  ) {
    const isInsideInputGroup = useContext(InputGroupContext) !== null
    const [visible, setVisible] = useState<boolean>(defaultVisible)

    const input = (
      <Input
        ref={forwardedRef}
        type={visible ? 'text' : 'password'}
        autoComplete={autoComplete}
        value={value}
        onChange={(event) => onValueChange(event.currentTarget.value)}
        {...rest}
      />
    )

    const toggleButton = (
      <IconButton
        size={24}
        onClick={() => setVisible((prev) => !prev)}
        style={{
          opacity: value.length === 0 ? 0 : 1,
          transition: 'opacity 0.3s',
        }}
      >
        {visible ? <Icons.S16.Show /> : <Icons.S16.Hide />}
      </IconButton>
    )

    return isInsideInputGroup ? (
      <>
        {input}
        <InputGroup.End>{toggleButton}</InputGroup.End>
      </>
    ) : (
      <HStack>
        {input}
        {toggleButton}
      </HStack>
    )
  }
)
