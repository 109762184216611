export const Show = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5C10.5541 5 12.0988 6.8145 12.8258 8C12.0988 9.1855 10.5541 11 8 11C5.44587 11 3.90122 9.1855 3.17421 8C3.90122 6.81451 5.44587 5 8 5ZM14.9131 7.63891C15.029 7.86872 15.029 8.13128 14.9131 8.36109C14.3343 9.5091 12.2266 13 8 13C3.77338 13 1.66574 9.5091 1.08691 8.36109C0.971031 8.13128 0.971031 7.86872 1.08691 7.63891C1.66574 6.4909 3.77338 3 8 3C12.2266 3 14.3343 6.4909 14.9131 7.63891ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      fill="currentColor"
    />
  </svg>
)
