import { Slot } from '@radix-ui/react-slot'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import type React from 'react'
import { useMemo, forwardRef } from 'react'
import { SelectContext, type SelectContextValue } from '../../context/select-context'
import * as Popover from '../popover'

function useSelectContext<Value>(): SelectContextValue<Value> {
  return useRequiredContext('Select', SelectContext)
}

interface SelectBaseProps<Value>
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'ref' | 'value' | 'multiple'> {
  compare?: (source: Value, value: Value) => boolean
  onClose?: () => void
}

interface SingleValueProps<Value> extends SelectBaseProps<Value> {
  value: Value | null
  multiple?: never
  autoClose?: boolean
  onValueChange: (value: Value | null) => void
}

interface MultipleValuesProps<Value extends Item[], Item> extends SelectBaseProps<Item> {
  value: Value
  multiple: true
  autoClose?: boolean
  onValueChange: (value: Value) => void
}

export type SelectProps<Value> =
  | MultipleValuesProps<Value[], Value>
  | SingleValueProps<Value>

function Select<Value>({
  value,
  multiple,
  onValueChange,
  compare,
  autoClose = multiple ? false : true,
  onClose,
  ...rest
}: SelectProps<Value>) {
  const popoverContext = Popover.useContext()

  const contextValue = useMemo((): SelectContextValue<Value> => {
    let normalizedValue: Value[] = []
    if (value !== null) {
      normalizedValue = Array.isArray(value) ? value : [value]
    }

    const setValue = (nextValue: Value[]) => {
      if (multiple) {
        onValueChange(nextValue)
      } else {
        onValueChange(nextValue[0] ?? null)
      }

      if (autoClose && popoverContext !== null) {
        popoverContext.setOpen(false)
        onClose?.()
      }
    }

    return {
      multiple: multiple ?? false,
      value: normalizedValue,
      compare: compare ?? Object.is,
      setValue,
    }
  }, [value, popoverContext, multiple, compare, onValueChange, autoClose, onClose])

  return (
    <SelectContext.Provider value={contextValue}>
      <Slot role="listbox" aria-multiselectable={multiple} {...rest} />
    </SelectContext.Provider>
  )
}

export interface SelectOptionContext<Value> extends SelectContextValue<Value> {
  selected: boolean
}

interface SelectOptionChildren<Value> {
  (context: SelectOptionContext<Value>): React.ReactNode
}

export interface SelectOptionProps<Value>
  extends Omit<React.ComponentProps<typeof Slot>, 'children'> {
  value: Value
  disabled?: boolean
  children: React.ReactNode | SelectOptionChildren<Value>
}

interface SelectOptionComponent
  extends React.ForwardRefExoticComponent<SelectOptionProps<unknown>> {
  <Value>(props: SelectOptionProps<Value>): React.ReactNode
}

const SelectOption: SelectOptionComponent = forwardRef(function SelectOptionRef(
  { value, children, onClick, ...rest },
  ref
) {
  const select = useSelectContext<unknown>()
  const selected = select.value.find((item) => select.compare(item, value)) !== undefined

  const onChangeSingle = () => {
    if (selected) {
      select.setValue([])
    } else {
      select.setValue([value])
    }
  }

  const onChangeMultiple = () => {
    if (selected) {
      select.setValue(select.value.filter((item) => !select.compare(item, value)))
    } else {
      select.setValue([...select.value, value])
    }
  }

  return (
    <Slot
      ref={ref}
      role="option"
      onClick={(event) => {
        onClick?.(event)

        if (rest.disabled || event.defaultPrevented) {
          return
        }

        if (select.multiple) {
          onChangeMultiple()
        } else {
          onChangeSingle()
        }
      }}
      aria-disabled={rest.disabled}
      aria-selected={selected}
      {...rest}
    >
      {typeof children === 'function' ? children({ ...select, selected }) : children}
    </Slot>
  )
})

export { Select as Root, SelectOption as Option, useSelectContext as useContext }
