import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import { Box, Flex, type FlexProps } from '../../primitives'
import { theme } from '../../stitches'
import type { Space } from '../../tokens'

export interface WrapProps extends Omit<FlexProps, 'direction' | 'wrap'> {
  gap?: Exclude<Space, 'auto'>
}

export const Wrap: GenericForwardRefComponent<WrapProps> = forwardRef(function WrapRef(
  { gap = 0, align, justify, children, ...rest },
  ref
) {
  const token = theme.space[gap]
  return (
    <Box ref={ref} {...rest}>
      <Flex
        align={align}
        justify={justify}
        css={{
          flexWrap: 'wrap',
          margin: `calc(${token} / -2)`,
          '& > *': { margin: `calc(${token} / 2)` },
        }}
      >
        {children}
      </Flex>
    </Box>
  )
})
