import { useCurrentUserHasPermission } from 'api/hooks/permissions'
import type { Company } from 'api/types/companies'
import { useCompanySubscriptions } from 'domains/billing/queries'
import { isActiveNotTrialSubscription } from 'domains/billing/utils'
import { Locale } from 'kitchen/constants'
import { useNavigate, generatePath } from 'kitchen/router'
import { pluralize } from 'kitchen/utils/formats'
import { diffDays, toDate } from 'kitchen/utils/native-date'
import { Chip } from 'salad/components'
import { ButtonBase } from 'salad/primitives'
import { Path } from '@app/constants'

interface TrialStatusChipProps {
  company: Company
}

export function TrialStatusChip({ company }: TrialStatusChipProps) {
  const navigate = useNavigate()

  const { data: hasActiveNotTrialSubscriptions } = useCompanySubscriptions(
    { subscriberId: company.id },
    { select: (subscriptions) => subscriptions.some(isActiveNotTrialSubscription) }
  )

  const [canViewSubscriptions] = useCurrentUserHasPermission(company.id, [
    'SUBSCRIPTIONS_VIEW',
  ])

  if (
    hasActiveNotTrialSubscriptions === undefined ||
    hasActiveNotTrialSubscriptions === true ||
    company.subscription === null ||
    company.subscription.pricingPlan.name !== 'Trial' ||
    company.subscription.expirationDate === undefined
  ) {
    return null
  }

  const daysLeft = diffDays(toDate(company.subscription.expirationDate), Locale.TODAY)

  if (daysLeft <= 7) {
    // Handled in SubscriptionStatusBanner
    return null
  }

  return (
    <ButtonBase
      onClick={() => {
        if (canViewSubscriptions) {
          navigate(
            generatePath(Path.COMPANY_SUBSCRIPTION_PLAN, { companyId: company.id })
          )
        }
      }}
      css={{ width: 'auto', display: 'none', '@bp2': { display: 'block' } }}
    >
      <Chip variant="neutral">{pluralize(daysLeft, 'day', 'days')} free trial</Chip>
    </ButtonBase>
  )
}
