export const Check = () => (
  <svg fill="none" width="16" height="16" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.949 4.813 6.999 13 2.117 7.884l1.768-1.768L7 9l5.05-5.813 1.899 1.626Z"
      fill="currentColor"
    />
  </svg>
)
