import type { CSS } from '../stitches'
import { theme } from '../stitches'
import type { Color } from '../tokens'

interface ColorStyles extends Record<Color, CSS> {}

export const backgroundColor = Object.keys(theme.colors).reduce((acc, key) => {
  acc[key] = { backgroundColor: theme.colors[key] }
  return acc
}, {} as ColorStyles)
