import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { ApronTheme } from './themes/apron'
import * as tokens from './tokens'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
  prefix,
  reset,
} = createStitches({
  theme: ApronTheme,
  media: tokens.media,
  utils: {
    marginX: (value: Stitches.ScaleValue<'space'> | 'auto') => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: Stitches.ScaleValue<'space'> | 'auto') => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    size: (value: Stitches.PropertyValue<'width'> | Stitches.ScaleValue<'space'>) => ({
      width: value,
      height: value,
      flexShrink: 0,
    }),
    paddedOutline: (color: Stitches.PropertyValue<'color'>) => ({
      boxShadow: `#fff 0px 0px 0px 2px, ${color} 0px 0px 0px 4px`,
    }),
    insetOutline: (color: Stitches.PropertyValue<'color'>) => ({
      boxShadow: `inset ${color} 0px 0px 0px 2px`,
    }),
  },
})

export type Config = typeof config
export type CSS = Stitches.CSS<Config>
export type Theme = Parameters<typeof createTheme>[0]
