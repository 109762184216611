export const Cross = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5883 14.0025L14.0025 12.5883L9.41426 8L13.9962 3.41805L12.582 2.00384L8.00005 6.58579L3.41177 1.99751L1.99756 3.41172L6.58583 8L2.00388 12.582L3.4181 13.9962L8.00005 9.41421L12.5883 14.0025Z"
      fill="currentColor"
    />
  </svg>
)
