interface PlusProps {
  variant?: 'default' | 'xs'
}

export const Plus = ({ variant = 'default' }: PlusProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        variant === 'xs'
          ? 'M8.5 3H7V7.25H2.75V8.75H7V13H8.5V8.75H12.75V7.25H8.5V3Z'
          : 'M9 1H7V7H1V9H7V15H9V9H15V7H9V1Z'
      }
      fill="currentColor"
    />
  </svg>
)
