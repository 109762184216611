import * as ScrollAreaBase from '@radix-ui/react-scroll-area'
import { styled, theme, keyframes } from '../../stitches'

export interface CustomScrollAreaProps
  extends React.ComponentProps<typeof CustomScrollArea> {}

const CustomScrollArea = styled(ScrollAreaBase.Root, {
  overflow: 'hidden',
  borderRadius: 'inherit',
  zIndex: 0,
})

export interface CustomScrollAreaViewportProps
  extends React.ComponentProps<typeof CustomScrollAreaViewport> {}

const CustomScrollAreaViewport = styled(ScrollAreaBase.Viewport, {
  size: '100%',
  borderRadius: 'inherit',
})

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
})

const CustomScrollAreaScrollbar = styled(ScrollAreaBase.Scrollbar, {
  display: 'flex',
  userSelect: 'none',
  touchAction: 'none',
  zIndex: 1,
  '&[data-orientation="vertical"]': {
    flexDirection: 'row',
    paddingBlockStart: theme.space[8],
    paddingInline: theme.space[2],
    width: theme.space[10],
  },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    paddingBlock: theme.space[2],
    paddingInlineStart: theme.space[8],
    height: theme.space[10],
  },
  '&[data-state="visible"]': {
    animation: `${fadeIn} 0.15s ease-out`,
  },
  '&[data-state="hidden"]': {
    animation: `${fadeOut} 0.15s ease-out`,
  },
})

const CustomScrollAreaThumb = styled(ScrollAreaBase.Thumb, {
  position: 'relative',
  flex: 1,
  backgroundColor: theme.colors['grey-15'],
  borderRadius: theme.radii[8],
})

const CustomScrollAreaCorner = styled(ScrollAreaBase.Corner, {
  size: theme.space[8],
})

export {
  CustomScrollArea as Root,
  CustomScrollAreaViewport as Viewport,
  CustomScrollAreaScrollbar as Scrollbar,
  CustomScrollAreaThumb as Thumb,
  CustomScrollAreaCorner as Corner,
}
