import { useControlled } from 'kitchen/hooks/use-controlled'
import { forwardRef, useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import { type InputBaseProps, InputBase } from '../../primitives'
import { styled } from '../../stitches'

const AutosizeInputGrid = styled('span', {
  display: 'grid',
  gridTemplate: `'input'`,
  width: 'fit-content',
  maxWidth: '100%',
  'input, [data-autosize-spacer]': {
    gridArea: 'input',
  },
})

const AutosizeInputSpacer = styled('span', {
  width: 'auto',
  height: 0,
  overflow: 'hidden',
  visibility: 'hidden',
  whiteSpace: 'pre',
  '& + input': { width: '100%' },
})

const AutosizeInput = forwardRef<HTMLInputElement, InputBaseProps>(function AutosizeInput(
  { defaultValue = '', value, onChange, placeholder, ...rest },
  forwardedRef
) {
  const [replicatedValue, setReplicatedValue] = useControlled({
    defaultValue,
    value,
  })

  return (
    <>
      <AutosizeInputSpacer data-autosize-spacer aria-hidden>
        {replicatedValue || placeholder}
      </AutosizeInputSpacer>
      <InputBase
        ref={forwardedRef}
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          setReplicatedValue(event.currentTarget.value)
          onChange?.(event)
        }}
        size={1}
        {...rest}
      />
    </>
  )
})

export interface InputProps extends InputBaseProps {
  autosize?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { autosize, ...rest },
  forwardedRef
) {
  const context = useContext(InputGroupContext)
  const isInsideInputGroup = context !== null

  if (isInsideInputGroup) {
    return autosize ? (
      <AutosizeInput ref={forwardedRef} {...context.getAriaProps(rest)} {...rest} />
    ) : (
      <InputBase ref={forwardedRef} {...context.getAriaProps(rest)} {...rest} />
    )
  }

  return autosize ? (
    <AutosizeInputGrid>
      <AutosizeInput ref={forwardedRef} {...rest} />
    </AutosizeInputGrid>
  ) : (
    <InputBase ref={forwardedRef} {...rest} />
  )
})
