import * as Sentry from '@sentry/react'
import { isAfter } from 'kitchen/utils/date-time'
import { nonNullable } from 'kitchen/utils/helpers'
import { useRef } from 'react'
import { Box, VStack } from '../../primitives'
import { styled } from '../../stitches'
import { Label } from '../label'
import * as Status from '../status'
import { CalendarActionButton } from './calendar-action-button'
import { CalendarHeader } from './calendar-header'
import { CalendarLegend } from './calendar-legend'
import { CalendarContextProvider, useCalendarContext } from './context'
import { MonthGrid } from './month-grid'
import type { CalendarProps, SpecialDate } from './types'

const CalendarWrapper = styled(Box, {
  display: 'inline-block',
})

export const todaySpecialDate: SpecialDate = {
  date: new Date(),
  legend: <Label size={13}>Today</Label>,
  color: 'black-alpha-20',
}

export const defaultSpecialDates: SpecialDate[] = [todaySpecialDate]

interface CalendarBaseProps
  extends Omit<CalendarProps, 'disabledDates' | 'specialDates' | 'values' | 'onSelect'> {}

const CalendarBase = ({
  disabledDateChecker,
  displayOuterDates = false,
  singleDate = false,
  children,
  onDayClick: onDayClickEffect,
  activeRangePart,
}: CalendarBaseProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { selectedFrom, setSelectedFrom, selectedTo, setSelectedTo, onSelect } =
    useCalendarContext()

  const defaultDaySelectHandler = (date: Date) => {
    if (!selectedFrom && !selectedTo) {
      setSelectedFrom(date)
    }
    if (selectedFrom && !selectedTo) {
      if (isAfter(selectedFrom, date)) {
        setSelectedTo(selectedFrom)
        setSelectedFrom(date)
      } else {
        setSelectedTo(date)
      }
    }
    if (selectedFrom && selectedTo) {
      setSelectedFrom(date)
      setSelectedTo(undefined)
    }
  }

  const onDayClick = (date: Date) => {
    onDayClickEffect?.(date)

    // Single date mode
    if (singleDate) {
      setSelectedFrom(date)
      setSelectedTo(date)

      const hasActionButton = nonNullable(
        wrapperRef.current?.querySelector('[data-calendar-action-button]')
      )
      if (hasActionButton) {
        return
      } else {
        return onSelect({ dateFrom: date, dateTo: date })
      }
    }

    // Range of dates mode

    // if calendar's parent controls which part is being selected
    if (activeRangePart) {
      if (activeRangePart === 'dateFrom') {
        setSelectedFrom(date)
      }
      if (activeRangePart === 'dateTo') {
        setSelectedTo(date)
      }
    } else {
      defaultDaySelectHandler(date)
    }
  }

  return (
    <CalendarWrapper ref={wrapperRef}>
      <CalendarHeader />
      <VStack gap={16}>
        <MonthGrid
          onDayClick={onDayClick}
          disabledDateChecker={disabledDateChecker}
          displayOuterDates={displayOuterDates}
          singleDate={singleDate}
        />
        <VStack gap={24}>{children}</VStack>
      </VStack>
    </CalendarWrapper>
  )
}

const Calendar = ({
  specialDates = defaultSpecialDates,
  disabledDates,
  values,
  onSelect,
  ...rest
}: CalendarProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <Status.Root css={{ height: '100%' }}>
          <Status.Title variant="headline-h3">Something went wrong</Status.Title>
          <Status.Description>
            {['staging', 'development'].includes(process.env.BUILD_MODE) &&
            error instanceof Error
              ? error.message
              : 'We are already fixing it'}
          </Status.Description>
        </Status.Root>
      )}
    >
      <CalendarContextProvider
        specialDates={specialDates}
        disabledDates={disabledDates}
        values={values}
        onSelect={onSelect}
      >
        <CalendarBase {...rest} />
      </CalendarContextProvider>
    </Sentry.ErrorBoundary>
  )
}

export { Calendar as Root }
export { CalendarActionButton as ActionButton }
export { CalendarLegend as Legend }
