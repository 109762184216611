import { useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { useCallback } from 'react'
import type { AuthSession } from '../types'

export function useUpdateAuthSession() {
  const queryClient = useQueryClient()

  return useCallback(
    async (data: AuthSession) => {
      queryClient.setQueryData([CacheKey.AUTH], data)

      return queryClient.resetQueries({
        predicate: ({ queryKey }) => !queryKey.includes(CacheKey.AUTH),
      })
    },
    [queryClient]
  )
}
