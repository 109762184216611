import { type GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef, Children } from 'react'
import { ButtonBase } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { ButtonGroup } from '../button-group'
import { Spinner, type SpinnerProps } from '../spinner'

const ButtonSpinner = styled(Spinner, {
  gridColumn: 'start / end',
  gridRow: 'start / end',
  justifySelf: 'center',
})

function getSpinnerSize(buttonSize: ButtonProps['size']): SpinnerProps['size'] {
  switch (buttonSize) {
    case 'large':
      return 24
    case 'medium':
    case 'small':
    case 'extra-small-icon':
    case 'extra-small':
    case 'icon':
      return 16
  }
}

const ButtonStart = styled('span', {
  gridArea: 'start',
  marginInlineEnd: theme.space[8],
})

const ButtonEnd = styled('span', {
  gridArea: 'end',
  marginInlineStart: theme.space[8],
})

export interface ButtonContentProps extends React.ComponentProps<typeof ButtonContent> {}

const ButtonContent = styled('span', {
  gridArea: 'content',
  justifySelf: 'center',
})

const ButtonRoot = styled(ButtonBase, {
  display: 'grid',
  gridTemplate: `
    '. start content end .' auto / 1fr auto auto auto 1fr
  `,
  alignItems: 'center',
  textAlign: 'center',
  transitionProperty: 'color, background-color, border-color, box-shadow',
  transitionDuration: '0.3s',
  whiteSpace: 'nowrap',
  '&[data-has-spinner="true"] > :not([data-button-spinner])': {
    color: theme.colors['transparent'],
  },
  variants: {
    variant: {
      main: {
        color: theme.colors['button-main-text'],
        backgroundColor: theme.colors['button-main-background'],
        '@hover': {
          '&:hover:not([data-inert="true"], [data-loading="true"])': {
            backgroundColor: theme.colors['button-main-background-hover'],
          },
        },
        '&:active:not([data-inert="true"], [data-loading="true"]), &[aria-pressed="true"], &[aria-expanded="true"], &[data-loading="true"]':
          {
            backgroundColor: theme.colors['button-main-background-pressed'],
          },
        '&:disabled, &[aria-disabled="true"]': {
          '&:not([data-loading="true"])': {
            color: theme.colors['button-main-text-disabled'],
            backgroundColor: theme.colors['button-main-background-disabled'],
          },
        },
      },
      common: {
        color: theme.colors['button-common-text'],
        backgroundColor: theme.colors['button-common-background'],
        '@hover': {
          '&:hover:not([data-inert="true"], [data-loading="true"])': {
            backgroundColor: theme.colors['button-common-background-hover'],
          },
        },
        '&:active:not([data-inert="true"], [data-loading="true"]), &[aria-expanded="true"], &[aria-pressed="true"], &[data-loading="true"]':
          {
            backgroundColor: theme.colors['button-common-background-pressed'],
          },
        '&:disabled, &[aria-disabled="true"]': {
          '&:not([data-loading="true"])': {
            color: theme.colors['button-common-text-disabled'],
            backgroundColor: theme.colors['button-common-background-disabled'],
          },
        },
      },
      destructive: {
        color: theme.colors['button-destructive-text'],
        backgroundColor: theme.colors['button-destructive-background'],
        '@hover': {
          '&:hover:not([data-inert="true"], [data-loading="true"])': {
            backgroundColor: theme.colors['button-destructive-background-hover'],
          },
        },
        '&:active:not([data-inert="true"], [data-loading="true"]), &[aria-expanded="true"], &[data-loading="true"], &[aria-pressed="true"]':
          {
            backgroundColor: theme.colors['button-destructive-background-pressed'],
          },
        '&:disabled, &[aria-disabled="true"]': {
          '&:not([data-loading="true"])': {
            color: theme.colors['button-destructive-text-disabled'],
            backgroundColor: theme.colors['button-destructive-background-disabled'],
          },
        },
      },
      minor: {
        color: theme.colors['button-minor-text'],
        backgroundClip: 'padding-box',
        backgroundColor: theme.colors['button-minor-background'],
        borderColor: theme.colors['button-minor-border'],
        borderWidth: 2,
        borderStyle: 'solid',
        '@hover': {
          '&:hover:not([data-inert="true"], [data-loading="true"])': {
            borderColor: theme.colors['button-minor-border-hover'],
            backgroundColor: theme.colors['button-minor-background-hover'],
          },
        },
        '&:active:not([data-inert="true"], [data-loading="true"]), &[aria-expanded="true"], &[aria-pressed="true"], &[data-loading="true"], &[aria-pressed="true"]':
          {
            borderColor: theme.colors['button-minor-border-pressed'],
            backgroundColor: theme.colors['button-minor-background-pressed'],
          },
        '&:disabled, &[aria-disabled="true"]': {
          '&:not([data-loading="true"])': {
            color: theme.colors['button-minor-text-disabled'],
            borderColor: theme.colors['button-minor-border-disabled'],
            backgroundColor: theme.colors['button-minor-background-disabled'],
          },
        },
        [`${ButtonGroup} > * + &`]: {
          borderInlineStartWidth: 0,
        },
      },
    },
    size: {
      'extra-small-icon': {
        ...typography['button-small'],
        minWidth: 32,
        minHeight: 32,
        padding: theme.space[6],
      },
      icon: {
        ...typography['button-small'],
        gridTemplateColumns: 'auto auto auto',
        width: 'fit-content',
        minWidth: 40,
        minHeight: 40,
        padding: theme.space[8],
      },
      'extra-small': {
        ...typography['button-small'],
        minWidth: 32,
        minHeight: 32,
        paddingBlock: theme.space[6],
        paddingInline: theme.space[12],
        [`${ButtonStart}`]: {
          marginInlineEnd: theme.space[4],
        },
        [`${ButtonEnd}`]: {
          marginInlineStart: theme.space[4],
        },
      },
      small: {
        ...typography['button-small'],
        minWidth: 40,
        minHeight: 40,
        paddingBlock: theme.space[8],
        paddingInline: theme.space[16],
      },
      medium: {
        ...typography['button-medium'],
        minWidth: 48,
        minHeight: 48,
        paddingBlock: theme.space[12],
        paddingInline: theme.space[24],
      },
      large: {
        ...typography['button-large'],
        minWidth: 64,
        minHeight: 64,
        paddingBlock: theme.space[12],
        paddingInline: theme.space[32],
      },
    },
    radius: {
      default: {
        borderRadius: theme.radii[8],
      },
      round: {
        borderRadius: theme.radii.round,
      },
    },
    hug: {
      true: {
        width: 'fit-content',
      },
    },
    elevated: {
      true: { boxShadow: theme.shadows['elevation-200'] },
      false: {},
    },
    active: {
      true: {},
      false: {},
    },
  },
  defaultVariants: {
    radius: 'default',
    hug: false,
  },
  compoundVariants: [
    {
      radius: 'default',
      size: 'large',
      css: {
        borderRadius: theme.radii[16],
      },
    },
    {
      radius: 'round',
      size: 'small',
      css: {
        [`& ${ButtonStart}`]: {
          marginInlineStart: `calc(${theme.space[4]} * -1)`,
        },
        [`& ${ButtonEnd}`]: {
          marginInlineEnd: `calc(${theme.space[4]} * -1)`,
        },
      },
    },
    {
      variant: 'minor',
      active: true,
      css: {
        borderColor: theme.colors['button-minor-active-border'],
        backgroundColor: theme.colors['button-minor-active-background'],
        '@hover': {
          '&:hover:not([data-inert="true"])': {
            backgroundColor: theme.colors['button-minor-active-background-hover'],
            borderColor: theme.colors['button-minor-active-border-hover'],
          },
        },
        '&:active:not([data-inert="true"]), &[aria-expanded="true"], &[aria-pressed="true"], &[data-loading="true"]':
          {
            backgroundColor: theme.colors['button-minor-active-background-pressed'],
            borderColor: theme.colors['button-minor-active-border-pressed'],
          },
        '&:disabled, &[aria-disabled="true"]': {
          '&:not([data-loading="true"])': {
            backgroundColor: theme.colors['button-minor-active-background-disabled'],
            borderColor: theme.colors['button-minor-active-border-disabled'],
          },
        },
      },
    },
  ],
})

export interface ButtonProps extends React.ComponentProps<typeof ButtonRoot> {
  inert?: boolean
  loading?: boolean
  spinner?: boolean
}

const Button: GenericForwardRefComponent<ButtonProps> = forwardRef(function Button(
  { loading, spinner = loading, inert, disabled, children, size = 'medium', ...rest },
  forwardedRef
) {
  const content = Children.map(children, (child) =>
    typeof child === 'number' || typeof child === 'string' ? (
      <ButtonContent>{child}</ButtonContent>
    ) : (
      child
    )
  )

  return (
    <ButtonRoot
      type="button"
      ref={forwardedRef}
      size={size}
      disabled={disabled || loading}
      data-has-spinner={spinner}
      data-loading={loading}
      data-inert={inert}
      {...rest}
    >
      {spinner && <ButtonSpinner size={getSpinnerSize(size)} data-button-spinner />}
      {content}
    </ButtonRoot>
  )
})

export {
  Button as Root,
  ButtonContent as Content,
  ButtonStart as Start,
  ButtonEnd as End,
}
