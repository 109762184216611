export const Failure = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="29" stroke="currentColor" strokeWidth="6" />
    <rect
      x="49.6777"
      y="10.0797"
      width="6"
      height="56"
      transform="rotate(45 49.6777 10.0797)"
      fill="currentColor"
    />
  </svg>
)
