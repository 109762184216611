import * as Portal from '@radix-ui/react-portal'
import { motion, AnimatePresence } from 'framer-motion'
import { Fragment } from 'react'
import * as Icons from '../../icons'
import { Box, Text, VStack } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Surface } from '../surface'
import { usePageDropzone } from './use-page-dropzone'

const OverlayBackdrop = styled(Box, {
  margin: theme.space[16],
  backgroundImage: theme.backgrounds['dropzone-border'],
  borderRadius: theme.radii[8],
  variants: {
    variant: {
      default: {
        backgroundColor: 'rgba(235, 245, 253, 0.8)',
      },
      dialog: {
        backgroundColor: 'rgba(239, 247, 253, 1)',
      },
    },
  },
})

const AbsoluteCenteredGrid = styled(motion.div, {
  display: 'grid',
  position: 'absolute',
  top: '50vh',
  left: '50vw',
  transform: 'translate(-50%, -50%)',
})

const PageDropzoneKnob = () => {
  return (
    <AbsoluteCenteredGrid
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Surface
        variant="flat"
        p={32}
        css={{
          width: '400px',
          maxWidth: '100%',
          backgroundColor: theme.colors['dark-blue'],
        }}
      >
        <VStack gap={4} css={{ placeItems: 'center' }}>
          <Text color="white">
            <Icons.S64.Upload />
          </Text>
          <Text color="white" variant="headline-h2">
            Drop to upload
          </Text>
        </VStack>
      </Surface>
    </AbsoluteCenteredGrid>
  )
}

interface PageDropzoneOverlayProps {
  isActive: boolean
  variant?: 'default' | 'dialog'
}

const PageDropzoneOverlay = ({
  isActive,
  variant = 'default',
}: PageDropzoneOverlayProps) => {
  const Root = variant === 'default' ? Portal.Root : Fragment
  return (
    <>
      <Root>
        <AnimatePresence>
          {isActive && (
            <AbsoluteCenteredGrid
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              css={{
                width: '100vw',
                height: '100vh',
                pointerEvents: 'none',
              }}
            >
              <OverlayBackdrop variant={variant} />
            </AbsoluteCenteredGrid>
          )}
        </AnimatePresence>
      </Root>
      {isActive && (
        <Root>
          <AnimatePresence>
            <PageDropzoneKnob />
          </AnimatePresence>
        </Root>
      )}
    </>
  )
}

interface PageDropzoneProps {
  children?: ({ isDragging }: { isDragging: boolean }) => React.ReactNode
  onFileDrop: (files: File[]) => void
  container?: HTMLElement | null
}

const PageDropzone = ({ children, onFileDrop, container }: PageDropzoneProps) => {
  const { isDragging } = usePageDropzone({ onFileDrop, root: container })
  return <>{children?.({ isDragging })}</>
}

export { PageDropzone as Root, PageDropzoneOverlay as Overlay }
