import type { GenericComponent } from 'kitchen/types'
import type { TextProps } from 'salad/primitives'
import { Text } from 'salad/primitives'
import { styled, theme } from 'salad/stitches'

const ListBase = styled(Text, {
  variants: {
    type: {
      bullet: {
        listStyleType: 'disc',
      },
    },
  },
  paddingLeft: theme.space[24],
  defaultVariants: {
    type: 'bullet',
  },
})

interface ListProps extends TextProps {}

const List: GenericComponent<ListProps> = function List({
  children,
  ...rest
}: ListProps) {
  return (
    <ListBase as="ul" {...rest}>
      {children}
    </ListBase>
  )
}

const ListItem = styled('li', {
  marginTop: theme.space[2],
})

export { List as Root, ListItem as Item }
