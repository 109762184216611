import type { UserRole } from 'api/types/permissions'
import { useFormContext } from 'kitchen/forms'
import { SegmentControl } from 'salad/components'
import { Text, VStack } from 'salad/primitives'
import { TooltipWrapper } from './tooltip-wrapper'
import type { ContributorRole, RoleEditorFormValues } from './types'
import { getRoleDescription } from './utils'

interface RoleFieldsProps {
  contributorRole: ContributorRole
  canAssignRole: (role: UserRole) => boolean
}

export function RoleFields({ contributorRole, canAssignRole }: RoleFieldsProps) {
  const { watch, setValue } = useFormContext<RoleEditorFormValues>()
  const value = watch('role')

  return (
    <VStack gap={32}>
      <SegmentControl.Root<UserRole>
        aria-label="Payment role"
        tabIndex={-1}
        orientation="horizontal"
        value={value}
        onValueChange={(value) => {
          setValue('role', value)
        }}
        css={{ gridTemplateColumns: 'repeat(3, 1fr) auto 1fr' }}
      >
        <TooltipWrapper
          enabled={!canAssignRole('CREATOR') || contributorRole !== undefined}
          tooltipContent={
            contributorRole === undefined
              ? 'You can only give out the permissions you have'
              : contributorRole === 'approver'
              ? 'Can’t assign creator to approve'
              : 'Can’t assign creator to pay'
          }
        >
          <SegmentControl.Item
            disabled={contributorRole !== undefined || !canAssignRole('CREATOR')}
            value="CREATOR"
          >
            Creator
          </SegmentControl.Item>
        </TooltipWrapper>

        <TooltipWrapper
          enabled={!canAssignRole('APPROVER') || contributorRole === 'payer'}
          tooltipContent={
            contributorRole === 'payer'
              ? 'Can’t assign approver to pay'
              : 'You can only give out the permissions you have'
          }
        >
          <SegmentControl.Item
            disabled={contributorRole === 'payer' || !canAssignRole('APPROVER')}
            value="APPROVER"
          >
            Approver
          </SegmentControl.Item>
        </TooltipWrapper>

        <TooltipWrapper
          enabled={!canAssignRole('PAYER')}
          tooltipContent="You can only give out the permissions you have"
        >
          <SegmentControl.Item value="PAYER" disabled={!canAssignRole('PAYER')}>
            Payer
          </SegmentControl.Item>
        </TooltipWrapper>

        <SegmentControl.Separator />

        <SegmentControl.Item value="CUSTOM">Custom</SegmentControl.Item>
      </SegmentControl.Root>
      <Text variant="paragraph-16">{getRoleDescription(value)}</Text>
    </VStack>
  )
}
