import { isDayjs } from 'dayjs'
import type { DateTimeFormatter } from 'kitchen/types'
import { Time, Locale } from '../constants'
import * as formats from './formats'

export function toDate(input: unknown): Date {
  if (typeof input === 'number') {
    return new Date(input)
  }

  if (typeof input === 'string') {
    /**
     * By design JavaScript (TypeScript) interprets date strings as a UTC time,
     * not as local time. It may lead to mistakes in converting (receiving previous day)
     * if you're in a time zone west of GMT
     *
     * Because of that to support date string format YYYY-MM-DD we need to
     * match it separately and then manually construct a date ensuring that the date
     * is treated as local time
     */
    const dateParts = input.match(/^(\d{4})-(\d{2})-(\d{2})$/)

    if (dateParts) {
      const year = parseInt(dateParts[1], 10)
      /**
       * Month is 0-indexed
       */
      const month = parseInt(dateParts[2], 10) - 1
      const day = parseInt(dateParts[3], 10)

      return new Date(year, month, day)
    } else {
      /**
       * For other string formats we can use the standard Date constructor
       */
      return new Date(input)
    }
  }

  if (input instanceof Date) {
    return new Date(input.getTime())
  }

  if (isDayjs(input)) {
    return input.toDate()
  }

  return new Date(NaN)
}

export function getUTCTime(input: Date) {
  return Date.UTC(
    input.getFullYear(),
    input.getMonth(),
    input.getDate(),
    input.getHours(),
    input.getMinutes(),
    input.getSeconds(),
    input.getMilliseconds()
  )
}

const getTimezoneOffset = (input: Date): number => input.getTime() - getUTCTime(input)

function diffPeriod(left: Date, right: Date, period = 1): number {
  const timestampLeft = left.getTime() - getTimezoneOffset(left)
  const timestampRight = right.getTime() - getTimezoneOffset(right)

  return Math.round((timestampLeft - timestampRight) / period)
}

export const diffDays = (left: Date, right: Date) =>
  diffPeriod(startOfDay(left), startOfDay(right), Time.DAY)

export function startOfDay(input: Date) {
  const clone = toDate(input)
  clone.setHours(0, 0, 0, 0)
  return clone
}

export function endOfDay(input: Date) {
  const clone = toDate(input)
  clone.setHours(23, 59, 59, 999)
  return clone
}

export function startOfMonth(input: Date): Date {
  const clone = toDate(input)

  clone.setDate(1)
  clone.setHours(0, 0, 0, 0)

  return clone
}

export function endOfMonth(input: Date): Date {
  const clone = toDate(input)
  const month = clone.getMonth()

  clone.setFullYear(clone.getFullYear(), month + 1, 0)
  clone.setHours(23, 59, 59, 999)

  return clone
}

export function getNextMonth(input: Date): Date {
  return toDate(endOfMonth(input).getTime() + Time.DAY)
}

export const daysInMonth = (month: Date | null) =>
  month === null
    ? Locale.MAX_DAYS_IN_MONTH
    : Math.abs(diffDays(endOfMonth(month), startOfMonth(month)) + 1)

export const isValidDate = (input: Date) => !Number.isNaN(Number(input))

export function toISODateString(input: unknown) {
  const date = toDate(input)

  if (isValidDate(date)) {
    return date.toISOString().split('T')[0]
  }

  return undefined
}

export const createRelativeDayFormat = (locale: string, now: Date) => {
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })

  return (input: unknown) => {
    const date = toDate(input)

    if (isValidDate(date)) {
      const days = diffDays(date, now)
      return formats.capitalizeFirstLetter(rtf.format(days, 'day'))
    }
  }
}

export function getDateTimeFormatter(
  locale: string,
  options: Readonly<Intl.DateTimeFormatOptions>
): DateTimeFormatter {
  return {
    format(input) {
      const date = toDate(input)

      if (isValidDate(date)) {
        const formatter = new Intl.DateTimeFormat(locale, options)
        return formatter.format(date).replace(/ /g, ' ')
      }
    },
    formatRange(inputFrom, inputTo) {
      const from = toDate(inputFrom)
      const to = toDate(inputTo)

      const formatter = new Intl.DateTimeFormat(locale, options)

      if (isValidDate(from)) {
        return isValidDate(to) ? formatter.formatRange(from, to) : formatter.format(from)
      }
    },
    formatToParts: (input) => {
      const formatter = new Intl.DateTimeFormat(locale, options)
      return formatter.formatToParts(input)
    },
    formatRangeToParts: (from, to) => {
      const formatter = new Intl.DateTimeFormat(locale, options)
      return formatter.formatRangeToParts(from, to)
    },
  }
}
