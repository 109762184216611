export const letterSpacings = {
  'price-tag-large-main': '0.01em',
  'price-tag-large-support': '0.05em',
  'price-tag-medium-main': '0.03em',
  'price-tag-medium-support': '0.08em',
  'price-tag-small-main': '0.04em',
  'price-tag-small-support': '0.09em',
  'headline-h1': '0.02em',
  'title-16': 'normal',
  'title-14': 'normal',
  'paragraph-20': 'normal',
  'paragraph-16': 'normal',
  'paragraph-14': 'normal',
  'paragraph-12': 'normal',
  'label-16': 'normal',
  'label-13': '0.03em',
  'button-large': '0.06em',
  avatar: '0.02em',
} as const

export type LetterSpacing = keyof typeof letterSpacings
