import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { useControlled } from 'kitchen/hooks/use-controlled'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import { createContext, useMemo } from 'react'
import { keyframes, styled, theme } from '../../stitches'

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
})

const close = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
})

const StyledRoot = styled(CollapsiblePrimitive.Root, {})
const StyledTrigger = styled(CollapsiblePrimitive.Trigger, {
  '&[data-disabled]': {
    color: theme.colors['black-alpha-30'],
  },
  '&:enabled': {
    cursor: 'pointer',
  },
})
const StyledContent = styled(CollapsiblePrimitive.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 130ms ease-in-out forwards` },
  '&[data-state="closed"]': { animation: `${close} 130ms ease-in-out forwards` },
})

type FunctionalTriggerChildren = ({ isOpen }: { isOpen: boolean }) => React.ReactNode

export interface TriggerProps
  extends Omit<React.ComponentProps<typeof StyledTrigger>, 'children'> {
  children: FunctionalTriggerChildren | React.ReactNode
}

const CollapsibleTrigger = ({ children, ...props }: TriggerProps) => {
  const { isOpen } = useCollapsible()
  const returnedChildren =
    typeof children === 'function' ? children({ isOpen }) : children

  return <StyledTrigger {...props}>{returnedChildren}</StyledTrigger>
}

export interface CollapsibleProps extends React.ComponentProps<typeof StyledRoot> {
  defaultOpen?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const Collapsible = ({
  children,
  open,
  defaultOpen = false,
  onOpenChange,
  ...props
}: CollapsibleProps) => {
  const [isOpen, setOpen] = useControlled({
    defaultValue: defaultOpen,
    value: open,
    onChange: onOpenChange,
  })
  const contextValue = useMemo(() => ({ isOpen, setOpen }), [isOpen, setOpen])

  return (
    <CollapsibleContext.Provider value={contextValue}>
      <StyledRoot open={isOpen} onOpenChange={setOpen} {...props}>
        {children}
      </StyledRoot>
    </CollapsibleContext.Provider>
  )
}

interface CollapsibleContextAPI {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

const CollapsibleContext = createContext<CollapsibleContextAPI | undefined>(undefined)

function useCollapsible() {
  return useRequiredContext('Collapsible', CollapsibleContext)
}

export {
  StyledContent as Content,
  CollapsibleTrigger as Trigger,
  Collapsible as Root,
  useCollapsible,
}
