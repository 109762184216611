import type { ValueOf } from 'kitchen/types'
import { useMemo } from 'react'
import { getDateTimeFormatter } from '../utils/native-date'
import { useLocale } from './use-locale'

const SupportedDateTimePart = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  WEEKDAY: 'weekday',
  HOUR: 'hour',
  MINUTE: 'minute',
} as const

type SupportedDateTimePart = ValueOf<typeof SupportedDateTimePart>

type DateTimePartStyle<Part> = Part extends keyof Intl.DateTimeFormatOptions
  ? Intl.DateTimeFormatOptions[Part]
  : never

const normalizeDatePart = <Style>(
  style: DateTimePartStyle<Intl.DateTimeFormatPartTypes> | 'auto',
  fallback: Style
) => (style === 'auto' ? fallback : style) as Style

export function useDateTimePartFormat<Part extends Intl.DateTimeFormatPartTypes>(
  part: Part,
  style: DateTimePartStyle<Part> | 'auto' = 'auto'
) {
  const locale = useLocale()
  return useMemo(() => {
    switch (part) {
      case SupportedDateTimePart.DAY:
        return getDateTimeFormatter(locale, {
          day: normalizeDatePart(style, '2-digit'),
        })
      case SupportedDateTimePart.MONTH:
        return getDateTimeFormatter(locale, {
          month: normalizeDatePart(style, '2-digit'),
        })
      case SupportedDateTimePart.YEAR:
        return getDateTimeFormatter(locale, {
          year: normalizeDatePart(style, 'numeric'),
        })
      case SupportedDateTimePart.WEEKDAY:
        return getDateTimeFormatter('local', {
          weekday: normalizeDatePart(style, 'short'),
        })
      case SupportedDateTimePart.HOUR:
        return getDateTimeFormatter(locale, {
          hour: normalizeDatePart(style, '2-digit'),
        })
      case SupportedDateTimePart.MINUTE:
        return getDateTimeFormatter(locale, {
          minute: normalizeDatePart(style, '2-digit'),
        })
      default:
        throw new Error(`Unsupported date time part: ${part}`)
    }
  }, [locale, part, style]) as Part extends SupportedDateTimePart
    ? ReturnType<typeof getDateTimeFormatter>
    : never
}
