import type { CurrencyCode } from 'domains/money/types'
import { getReferenceMaxLength } from './reference'
import * as regex from './regex'

export function number(input: string) {
  const parsed = input.replace(regex.nonDigits, '')

  if (Number.isNaN(Number.parseInt(parsed))) {
    return ''
  }

  return parsed
}

export function decimalNumber(input: string) {
  const matches = input.match(/\d+[.]?\d{0,2}/) || []
  return matches.join('')
}

export function phoneNumber(input: string) {
  /**
   * Replace with empty string all the characters if it's:
   * 1. Not a digit
   * 2. Not a space
   * 3. Not a plus sign as the first character
   */
  return input.replace(/(?!^\+)[^0-9 ]/g, '')
}

/**
 * @example 123 456
 */
export function code(input: string) {
  const parsed = number(input)

  if (!parsed) {
    return ''
  }
  // "1234" => ["123", "4"]
  const matches = parsed.match(/(\d{1,3})/g)

  if (!matches) {
    return ''
  }

  return chain(matches.slice(0, 2), ' ')
}

export function sortCode(input: string) {
  const parsed = number(input)

  if (!parsed) {
    return ''
  }

  // "123" => ["12", "3"]
  const matches = parsed.match(/(\d{1,2})/g)

  if (!matches) {
    return ''
  }

  return chain(matches.slice(0, 3), '-')
}

export function date(input: string) {
  const numbersOnly = number(input)

  if (!numbersOnly) {
    return ''
  }

  const result = numbersOnly
    .split('')
    .reduce(
      (acc, value, index) =>
        index === 2 || index === 4 ? `${acc}/${value}` : `${acc}${value}`,
      ''
    )
    .slice(0, 10)

  // user entered trailing delimiter
  if (['/', '-', '.'].some((char) => input.endsWith(char))) {
    // "1/" => "01/"
    if (result.match(/^[1-9]$/)) {
      return `0${result}/`
    }
    // "12/3/" => "12/03/"
    if (result.match(/^\d{2}\/[1-9]$/)) {
      return `${result.slice(0, -1)}0${result.slice(-1)}/`
    }
    // "12" => "12/"
    if (result.match(/^\d{2}$/)) {
      return `${result}/`
    }
    // "12/34" => "12/34/"
    if (result.match(/^\d{2}\/\d{2}$/)) {
      return `${result}/`
    }
  }
  return result
}

// https://apron.atlassian.net/browse/GEN-3699
export const reference = (input: string, currency: CurrencyCode) =>
  input.replace(regex.notReference, '').slice(0, getReferenceMaxLength(currency))

export function alphaNumeric(input: string, length?: number) {
  return input.replace(regex.nonAlphaNumeric, '').slice(0, length)
}

// https://apron.atlassian.net/browse/PAY-544
export const payeeReference = (input: string, currency: CurrencyCode) =>
  alphaNumeric(reference(input, currency))

// https://www.iban.com/structure
export function iban(input: string) {
  const parsed = alphaNumeric(input)

  if (!parsed) {
    return ''
  }

  const matches = parsed.match(/([a-z0-9]{1,4})/gi)

  if (!matches) {
    return ''
  }

  return chain(matches, ' ')
}

export function chain(input: (string | null | undefined)[], jointer = ' • ') {
  return input.filter(Boolean).join(jointer)
}

export const capitalizeFirstLetter = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1)

export const humanize = (input: string) =>
  capitalizeFirstLetter(input.toLowerCase().replaceAll('_', ' '))

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? `1 ${singular}` : `${count} ${plural}`

export const items = <I>(input: I[], format: (item: I) => string | number) => {
  const visible = input.slice(0, 3)
  const hidden = input.slice(3)

  const prefix = visible.map(format).join(', ')
  const suffix = hidden.length > 0 ? ` and ${hidden.length} more` : ''

  return prefix + suffix
}
