import type { Status } from 'salad/components'
import { ErrorStatus } from '../error-status'

/** @deprecated Use ErrorStatus component  */
function SomethingWentWrong({ children, ...rest }: ErrorStatus.ErrorStatusProps) {
  return (
    <ErrorStatus.Root {...rest}>
      <ErrorStatus.Icon />
      <ErrorStatus.Title />
      {children}
    </ErrorStatus.Root>
  )
}

function SomethingWentWrongRetry(props: Status.StatusActionProps) {
  return (
    <>
      <ErrorStatus.Description />
      <ErrorStatus.Action {...props} />
    </>
  )
}

/** @deprecated Use ErrorStatus component for custom errors */
export const { Action, Description } = ErrorStatus
export { SomethingWentWrong as Root, SomethingWentWrongRetry as Retry }
