import { Slot } from '@radix-ui/react-slot'
import { forwardRef } from 'react'
import { styled } from '../stitches'

interface EllipsisBaseProps extends React.ComponentPropsWithoutRef<'span'> {
  asChild?: boolean
}

const EllipsisBase = forwardRef<HTMLElement, EllipsisBaseProps>(function EllipsisBase(
  { asChild, ...rest },
  ref
) {
  const Comp = asChild ? Slot : 'span'
  return <Comp ref={ref} {...rest} />
})

export const Ellipsis = styled(EllipsisBase, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})
