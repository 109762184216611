import { styled } from '../stitches'

export interface InputBaseProps extends React.ComponentProps<typeof InputBase> {}

export const InputBase = styled('input', {
  appearance: 'none',
  font: 'inherit',
  border: 'none',
  borderRadius: 'none',
  background: 'none',
  outline: 'none',
  textAlign: 'unset',
  padding: 0,
  margin: 0,
  minWidth: 0,
  '&::-webkit-search-cancel-button': { display: 'none' },
})
