import { forwardRef } from 'react'
import * as Icons from '../../icons'
import { Grid, VStack, HStack, Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { IconButton } from '../icon-button'

export type BannerLayout = 'narrow' | 'wide'

const BannerBase = styled(HStack, {
  gap: theme.space[16],
  gridAutoColumns: '1fr auto auto',
  alignItems: 'center',
  borderRadius: theme.radii[16],
  variants: {
    variant: {
      accent: {
        '[data-banner-title]': {
          color: theme.colors['black'],
        },
        '[data-banner-content]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-banner-icon]': {
          color: theme.colors['black-alpha-40'],
        },
        backgroundColor: theme.colors['light-blue-40'],
      },
      neutral: {
        borderWidth: 1,
        borderColor: theme.colors['grey-5'],
        borderStyle: 'solid',
        '[data-banner-title]': {
          color: theme.colors['black'],
        },
        '[data-banner-content]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-banner-icon]': {
          color: theme.colors['black-alpha-40'],
        },
        backgroundColor: theme.colors['white'],
      },
      positive: {
        '[data-banner-title]': {
          color: theme.colors['black'],
        },
        '[data-banner-content]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-banner-icon]': {
          color: theme.colors['black-alpha-40'],
        },
        backgroundColor: theme.colors['light-green-30'],
      },
      warning: {
        '[data-banner-title]': {
          color: theme.colors['black'],
        },
        '[data-banner-content]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-banner-icon]': {
          color: theme.colors['black-alpha-40'],
        },
        backgroundColor: theme.colors['light-yellow-60'],
      },
      negative: {
        '[data-banner-title]': {
          color: theme.colors['black'],
        },
        '[data-banner-content]': {
          color: theme.colors['black-alpha-60'],
        },
        '[data-banner-icon]': {
          color: theme.colors['black-alpha-40'],
        },
        backgroundColor: theme.colors['light-red-20'],
      },
    },
    layout: {
      narrow: {
        alignItems: 'stretch',
        padding: theme.space[24],
        '[data-banner-title]': {
          ...typography['headline-h2'],
        },
        '[data-banner-paragraph]': {
          ...typography['paragraph-16'],
        },
        '[data-banner-close-button]': {
          alignSelf: 'start',
        },
        '[data-banner-actions]': {
          marginBlockStart: theme.space[16],
        },
      },
      wide: {
        padding: theme.space[32],
        '[data-banner-title]': {
          gridArea: 'title',
          ...typography['headline-h3'],
        },
        '[data-banner-paragraph]': {
          ...typography['paragraph-16'],
        },
        '[data-banner-content]': {
          gridArea: 'content',
        },
        '[data-banner-actions]': {
          gridArea: 'actions',
        },
        '[data-banner-close-button]': {
          alignSelf: 'center',
        },
      },
    },
  },
  defaultVariants: {
    layout: 'narrow',
  },
})

const BannerGrid = styled(Grid, {
  gap: theme.space[4],
  variants: {
    layout: {
      narrow: {
        gridTemplate: `
          "title  " auto
          "content" 1fr
          "actions" auto / 1fr
        `,
      },
      wide: {
        gridTemplate: `
          "title   actions"
          "content actions" auto / 1fr auto
        `,
      },
    },
  },
  defaultVariants: {
    layout: 'narrow',
  },
})

export interface BannerProps
  extends Required<Pick<React.ComponentProps<typeof BannerBase>, 'variant'>>,
    Pick<React.ComponentProps<typeof BannerBase>, 'layout' | 'css'> {
  onClose?: () => void
  children?: React.ReactNode
}

const Banner = forwardRef<HTMLDivElement, BannerProps>(function Banner(
  { variant = 'accent', layout = 'narrow', children, onClose, ...rest },
  forwardedRef
) {
  return (
    <BannerBase ref={forwardedRef} variant={variant} layout={layout} {...rest}>
      <BannerGrid layout={layout}>{children}</BannerGrid>
      {onClose && (
        <IconButton size={24} onClick={() => onClose()} data-banner-close-button>
          <Icons.S24.Cross />
        </IconButton>
      )}
    </BannerBase>
  )
})

export interface BannerTitleProps {
  children?: React.ReactNode
}

const BannerTitle = (props: BannerTitleProps) => (
  <Text as="h2" data-banner-title {...props} />
)

export interface BannerContentProps {
  children?: React.ReactNode
}

const BannerContent = (props: BannerContentProps) => (
  <VStack gap={4} data-banner-content {...props} />
)

export interface BannerParagraphProps {
  children?: React.ReactNode
}

const BannerParagraph = (props: BannerParagraphProps) => (
  <BannerContent>
    <Text as="p" data-banner-paragraph {...props} />
  </BannerContent>
)

export interface BannerActionsProps {
  children?: React.ReactNode
}

const BannerActions = ({ ...rest }: BannerActionsProps) => (
  <HStack
    gap={16}
    css={{
      alignItems: 'center',
      gridAutoColumns: 'auto',
      gridTemplateColumns: '1fr',
      whiteSpace: 'nowrap',
    }}
    data-banner-actions
    {...rest}
  />
)

export {
  Banner as Root,
  BannerTitle as Title,
  BannerContent as Content,
  BannerParagraph as Paragraph,
  BannerActions as Actions,
}
