import { useCodeRifm } from 'kitchen/hooks/use-code-rifm'
import { useControlled } from 'kitchen/hooks/use-controlled'
import { forwardRef } from 'react'
import { Input, type InputProps } from '../input'

export interface CodeInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
  onInputEnd?: () => void
}

const CODE_LENGTH = 6

export const CodeInput = forwardRef<HTMLInputElement, CodeInputProps>(
  function CodeInputRef(
    { defaultValue = '', value: controlledValue, onInputEnd, onChange, ...rest },
    forwardedRef
  ) {
    const [value, setValue] = useControlled<string>({
      defaultValue,
      value: controlledValue,
      onChange: (value) => {
        onChange?.(value)
        if (value.length === CODE_LENGTH) {
          onInputEnd?.()
        }
      },
    })

    const rifm = useCodeRifm({
      value,
      onChange: setValue,
    })

    return (
      <Input
        ref={forwardedRef}
        placeholder="### ###"
        inputMode="decimal"
        {...rifm}
        {...rest}
      />
    )
  }
)
