import { createContext, useMemo, useContext } from 'react'

interface IntlContextValue {
  locale: string
}

const IntlContext = createContext<IntlContextValue | null>(null)

export interface IntlProviderProps extends IntlContextValue {
  children?: React.ReactNode
}

export const IntlProvider = ({ locale, children }: IntlProviderProps) => (
  <IntlContext.Provider value={useMemo(() => ({ locale }), [locale])}>
    {children}
  </IntlContext.Provider>
)

export function useIntl() {
  const value = useContext(IntlContext)

  if (value === null) {
    throw new Error('Must be used inside IntlContext.Provider')
  }

  return value
}
