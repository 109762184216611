import { styled } from '../stitches'

export interface TextAreaBaseProps extends React.ComponentProps<typeof TextAreaBase> {}

export const TextAreaBase = styled('textarea', {
  appearance: 'none',
  font: 'inherit',
  border: 'none',
  borderRadius: 'none',
  background: 'none',
  outline: 'none',
  padding: 0,
  minWidth: 0,
  resize: 'none',
  wordBreak: 'break-word',
})
