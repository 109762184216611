import { colors } from './colors'

export const shadows = {
  'elevation-top': `0px -4px 16px ${colors['elevation-100-shadow']}`,
  'elevation-bottom': `0px 4px 16px ${colors['elevation-100-shadow']}`,
  'elevation-100': `0px 4px 12px ${colors['elevation-100-shadow']}`,
  'elevation-200': `0px 8px 12px ${colors['elevation-200-shadow']}`,
  'elevation-300': `0px 4px 12px ${colors['elevation-300-shadow']}`,
  'elevation-400': `0px 8px 12px ${colors['elevation-300-shadow']}`,
  'focus-elevation-300': [
    `0px 4px 12px ${colors['elevation-300-shadow']}`,
    `inset ${colors['elevation-300-shadow-focused']} 0px 0px 0px 3px`,
  ].join(', '),
  'focus-elevation-400': [
    `0px 8px 12px ${colors['elevation-300-shadow']}`,
    `inset ${colors['elevation-300-shadow-focused']} 0px 0px 0px 3px`,
  ].join(', '),
  'website-elevation-card': `0px 18px 38px ${colors['website-elevation-shadow']}`,
} as const

export type Shadow = keyof typeof shadows
