import { useSyncedRef } from '@react-hookz/web'
import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import { getMonth, getYear } from 'kitchen/utils/date-time'
import { createContext, useEffect, useMemo, useState } from 'react'
import type { CalendarProps, DateRange, SpecialDate } from './types'

interface CalendarContextAPI {
  specialDates: SpecialDate[]
  disabledDates: DateRange[]
  displayedYear: number
  setDisplayedYear: React.Dispatch<React.SetStateAction<number>>
  displayedMonth: number
  setDisplayedMonth: React.Dispatch<React.SetStateAction<number>>
  selectedFrom: Date | undefined
  setSelectedFrom: React.Dispatch<React.SetStateAction<Date | undefined>>
  selectedTo: Date | undefined
  setSelectedTo: React.Dispatch<React.SetStateAction<Date | undefined>>
  onSelect: (range: DateRange) => void
}

const CalendarContext = createContext<CalendarContextAPI | null>(null)

interface CalendarContextProviderProps
  extends Pick<CalendarProps, 'values' | 'disabledDates' | 'specialDates' | 'onSelect'> {
  children: React.ReactNode
}

export const CalendarContextProvider = ({
  children,
  specialDates,
  disabledDates,
  values,
  onSelect,
}: CalendarContextProviderProps) => {
  const initialOpenYear = getYear(values?.dateTo || values?.dateFrom) // returns calculated or today's year
  const [displayedYear, setDisplayedYear] = useState<number>(initialOpenYear)
  const initialOpenMonth = getMonth(values?.dateTo || values?.dateFrom) // returns calculated or today's month
  const [displayedMonth, setDisplayedMonth] = useState<number>(initialOpenMonth)
  const [selectedFrom, setSelectedFrom] = useState<Date | undefined>(values?.dateFrom)
  const [selectedTo, setSelectedTo] = useState<Date | undefined>(values?.dateTo)
  const onSelectRef = useSyncedRef(onSelect)

  useEffect(() => {
    setSelectedFrom(values?.dateFrom)
    setSelectedTo(values?.dateTo)
  }, [values?.dateFrom, values?.dateTo])

  const value = useMemo(() => {
    const getSpecialDates = () => {
      switch (typeof specialDates) {
        case 'function':
          return specialDates({ selectedFrom, selectedTo, displayedYear, displayedMonth })
        case 'object':
          return specialDates
        case 'undefined':
          return []
        default:
          throw new Error('Unsupported specialDates type')
      }
    }

    return {
      specialDates: getSpecialDates(),
      disabledDates: disabledDates ?? [],
      displayedYear,
      displayedMonth,
      setDisplayedYear,
      setDisplayedMonth,
      selectedFrom,
      setSelectedFrom,
      selectedTo,
      setSelectedTo,
      onSelect: onSelectRef.current,
    }
  }, [
    disabledDates,
    displayedYear,
    displayedMonth,
    selectedFrom,
    selectedTo,
    onSelectRef,
    specialDates,
  ])

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>
}

export function useCalendarContext() {
  return useRequiredContext('CalendarContext', CalendarContext)
}
