import type { RegisteredUser } from 'api/types/users'
import * as formats from 'kitchen/utils/formats'
import {
  checkHasPayrollAccess,
  checkHasRestrictedPaymentViewAccess,
  formatRolePreset,
} from 'kitchen/utils/permissions'
import { formatRoleName } from 'kitchen/utils/roles'
import { forwardRef } from 'react'
import * as Icons from 'salad/icons'
import { HStack, ButtonBase, type ButtonBaseProps, Text, VStack } from 'salad/primitives'

interface CompanyUserRoleButtonProps extends ButtonBaseProps {
  user: RegisteredUser
  open?: boolean
}

export const CompanyUserRoleButton = forwardRef<
  HTMLButtonElement,
  CompanyUserRoleButtonProps
>(function CompanyUserRoleButtonRef({ user, open, ...rest }, ref) {
  const isInteractive = open !== undefined

  const hasPayrollAccess = checkHasPayrollAccess(user.permissions)
  const hasAssignedOnlyViewAccess = checkHasRestrictedPaymentViewAccess(user.permissions)

  const roleName = formatRoleName(user.preset)

  return (
    <ButtonBase ref={ref} disabled={!isInteractive} {...rest}>
      <HStack
        gap={12}
        css={{ alignItems: 'center', width: '200px', justifyContent: 'space-between' }}
      >
        <VStack gap={2}>
          <Text variant="paragraph-16">{formatRolePreset(user.preset)}</Text>
          <Text variant="paragraph-14" color="grey-60">
            {formats.chain(
              [
                user.preset !== 'ADMIN' ? roleName : undefined,
                hasPayrollAccess ? 'Payroll' : undefined,
                hasAssignedOnlyViewAccess ? 'Assigned only' : undefined,
              ],
              ', '
            )}
          </Text>
        </VStack>
        {isInteractive && (
          <Text>
            <Icons.S16.Dropdown variant={open ? 'pressed' : 'default'} />
          </Text>
        )}
      </HStack>
    </ButtonBase>
  )
})
