import * as Portal from '@radix-ui/react-portal'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Locale } from 'kitchen/constants'
import { IntlProvider } from 'kitchen/context/intl'
import { BrowserRouter as Router } from 'kitchen/router'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { IntercomProvider } from 'react-use-intercom'
import { Tooltip, Toast } from 'salad/components'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof ExtendedResponseError) {
        return
      }

      Sentry.captureException(error)
    },
  }),
})

interface ProvidersProps {
  children?: React.ReactNode
}

export const AppProviders = ({ children }: ProvidersProps) => (
  <Router>
    <QueryClientProvider client={queryClient}>
      <IntercomProvider
        appId={process.env.INTERCOM_APP_ID}
        shouldInitialize={!process.env.PLAYWRIGHT}
        autoBoot
        autoBootProps={{
          customAttributes: {
            env: process.env.BUILD_MODE,
          },
        }}
      >
        <IntlProvider locale={Locale.DEFAULT}>
          <Toast.Provider>
            <Tooltip.Provider>{children}</Tooltip.Provider>
          </Toast.Provider>
        </IntlProvider>
      </IntercomProvider>
      {process.env.BUILD_MODE === 'development' && (
        <Portal.Root>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="bottom-right"
            toggleButtonProps={{ style: { bottom: 135, right: 14 } }}
          />
        </Portal.Root>
      )}
    </QueryClientProvider>
  </Router>
)
