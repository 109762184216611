import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type { MutationHookFactory } from 'kitchen/types'
import {
  updatePracticeHubRole,
  updatePracticeClientUser,
  batchUpdatePracticeClientsUsers,
  addPracticeClient,
  removePracticeClient,
} from './requests'
import type {
  UpdatePracticeClientUserPayload,
  BatchUpdatePracticeClientsUsersPayload,
  UpdatePracticeHubRolePayload,
  AddPracticeClientPayload,
  AddPracticeClientResponse,
  RemovePracticeClientPayload,
} from './types'

export const useUpdatePracticeHubRole: MutationHookFactory<
  UpdatePracticeHubRolePayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => updatePracticeHubRole(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useUpdatePracticeClientUser: MutationHookFactory<
  UpdatePracticeClientUserPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => updatePracticeClientUser(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useBatchUpdatePracticeClientsUsers: MutationHookFactory<
  BatchUpdatePracticeClientsUsersPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => batchUpdatePracticeClientsUsers(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useAddPracticeClient: MutationHookFactory<
  AddPracticeClientPayload,
  AddPracticeClientResponse
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => addPracticeClient(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useRemovePracticeClient: MutationHookFactory<
  RemovePracticeClientPayload,
  void
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => removePracticeClient(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.COMPANIES])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}
