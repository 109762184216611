import { usePractice } from 'api/hooks/companies'
import type { User } from 'api/types/users'
import { ActiveRouterLink } from 'kitchen/router'
import { getCompanyUserPermissions } from 'kitchen/utils/permissions'
import { Item, SidebarNav } from 'salad/components'
import * as Icons from 'salad/icons'
import { Path } from '@app/constants'

interface AppSidebarHubNavItemsProps {
  user: User
}

export function AppSidebarHubNavItems({ user }: AppSidebarHubNavItemsProps) {
  const practice = usePractice().data
  const hasPractice = practice !== undefined
  const permissions = hasPractice
    ? getCompanyUserPermissions(user.id, practice)
    : undefined

  return (
    <>
      <SidebarNav.Label>{hasPractice ? 'Practice Hub' : 'Hub'}</SidebarNav.Label>
      <SidebarNav.Item
        as={ActiveRouterLink}
        to={Path.COMPANIES_HUB}
        match={[{ path: Path.COMPANIES_HUB }]}
      >
        <Item.Start>
          <Icons.S24.Dashboard />
        </Item.Start>
        <Item.Content>{hasPractice ? 'Clients' : 'Companies'}</Item.Content>
      </SidebarNav.Item>
      {permissions !== undefined && (
        <>
          <SidebarNav.Item as={ActiveRouterLink} to={Path.PRACTICE_TEAM}>
            <Item.Start>
              <Icons.S24.Members />
            </Item.Start>
            <Item.Content>Team</Item.Content>
          </SidebarNav.Item>
          {permissions.includes('SUBSCRIPTIONS_VIEW') && (
            <SidebarNav.Item
              as={ActiveRouterLink}
              to={Path.PRACTICE_SUBSCRIPTION_PLANS}
              match={[{ path: Path.PRACTICE_SUBSCRIPTION, end: false }]}
            >
              <Item.Start>
                <Icons.S24.Manual />
              </Item.Start>
              <Item.Content>Billing</Item.Content>
            </SidebarNav.Item>
          )}
        </>
      )}
    </>
  )
}
