import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

interface IbanRifmOptions extends Omit<Parameters<typeof useRifm>[0], 'format'> {}

export const useIbanRifm = (options: IbanRifmOptions) =>
  useRifm({
    ...options,
    accept: /[A-Z0-9]/gi,
    format: formats.iban,
    value: formats.alphaNumeric(options.value),
    replace: (value) => value.toUpperCase(),
    onChange: (value) => options.onChange(formats.alphaNumeric(value)),
  })
