import { useIsStuck } from 'kitchen/hooks/use-is-stuck'
import type { GenericComponent } from 'kitchen/types'
import { useRef } from 'react'
import { NavigationButton } from '../../components'
import * as Icons from '../../icons'
import { VStack, Grid } from '../../primitives'
import type { StackProps } from '../../primitives'
import { styled, theme } from '../../stitches'

export interface DocumentLayoutPreviewProps
  extends React.ComponentProps<typeof DocumentLayoutPreview> {}

const DocumentLayoutPreview = styled(Grid, {
  grid: '1fr / 1fr',
  overflow: 'auto',
  backgroundColor: theme.colors['document-preview-background'],
})

export interface DocumentLayoutDetailsProps
  extends React.ComponentProps<typeof DocumentLayoutDetailsInside> {}

const DocumentLayoutDetailsOutside = styled(Grid, {
  grid: '1fr / 1fr',
  overflow: 'auto',
  backgroundColor: theme.colors['white'],
})

const DocumentLayoutDetailsInside = styled(Grid, {
  position: 'relative',
  zIndex: 0,
  gridAutoFlow: 'row',
  alignItems: 'start',
})

const DocumentLayoutActionsContainer = styled(Grid, {
  gridArea: 'actions',
  position: 'sticky',
  bottom: 0,
  paddingBlock: theme.space[24],
  paddingInline: theme.space[32],
  backgroundColor: theme.colors['white'],
  '&[data-is-stuck="true"]': {
    boxShadow: theme.shadows['elevation-top'],
  },
})

const DocumentLayoutHeaderContainer = styled(Grid, {
  gridArea: 'header',
  position: 'sticky',
  top: 0,
  height: '100%',
  zIndex: 1,
  backgroundColor: theme.colors.white,
  '&[data-is-stuck="true"]': {
    boxShadow: theme.shadows['elevation-bottom'],
  },
  borderBottom: `1px solid ${theme.colors['grey-10']}`,
  gridTemplate: `
    'content  close' auto / 1fr 80px
  `,
  '@bp2': {
    gridTemplate: `
      '. content  close' auto / minmax(32px, 1fr) minmax(auto, 480px) minmax(80px, 1fr)
    `,
  },
})

export interface DocumentLayoutProps
  extends React.ComponentProps<typeof DocumentLayout> {}

const DocumentLayout = styled(Grid, {
  height: '100%',
  backgroundColor: theme.colors['white'],
  variants: {
    variant: {
      horizontal: {
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr minmax(auto, 640px)',
        justifyContent: 'center',
        [`${DocumentLayoutPreview}`]: {
          display: 'none',
          '@bp3': { display: 'grid' },
        },
        [`${DocumentLayoutDetailsInside}`]: {
          gridTemplate: `
            'header' 72px
            'content' 1fr
            'actions' auto / 1fr
          `,
          '@bp2': {
            gridTemplate: `
              'header  header   header' 72px
              '.       content .      ' 1fr
              'actions actions actions' auto / minmax(32px, 1fr) minmax(auto, 480px) minmax(32px, 1fr)
            `,
          },
        },
        [`${DocumentLayoutActionsContainer}`]: {
          justifyContent: 'center',
          gridTemplateColumns: 'minmax(auto, 448px)',
        },
      },
      vertical: {
        gridTemplate: `
          'close  ' 80px
          'preview' auto
          'details' 1fr / 1fr
        `,
        rowGap: theme.space[16],
        '@bp2': {
          gridTemplate: `
            '.       preview close  ' 80px
            '.       preview .      ' 1fr
            'details details details' 1fr
            'details details details' auto / 112px 1fr 112px
          `,
        },
        [`${DocumentLayoutPreview}`]: {
          gridArea: 'preview',
        },
        [`${DocumentLayoutDetailsOutside}`]: {
          gridArea: 'details',
        },
        [`${DocumentLayoutDetailsInside}`]: {
          gridTemplate: `
            'content' 1fr
            'actions' auto / 1fr
          `,
        },
        [`${DocumentLayoutActionsContainer}`]: {
          paddingRight: theme.space[96],
        },
      },
    },
  },
  defaultVariants: {
    variant: 'horizontal',
  },
})

const DocumentLayoutDetails: GenericComponent<DocumentLayoutDetailsProps> = (props) => (
  <DocumentLayoutDetailsOutside>
    <DocumentLayoutDetailsInside {...props} />
  </DocumentLayoutDetailsOutside>
)

const DocumentLayoutDetailsClose: GenericComponent<
  React.ComponentProps<typeof NavigationButton>
> = (props) => {
  if (props.size === 'small') {
    return (
      <NavigationButton
        css={{
          gridArea: 'close',
          alignSelf: 'center',
          justifySelf: 'center',
          '@bp2': {
            marginInlineEnd: theme.space[24],

            justifySelf: 'start',
          },
          '@bp3': {
            justifySelf: 'center',
          },
        }}
        {...props}
      >
        <Icons.S16.Cross />
      </NavigationButton>
    )
  }
  return (
    <NavigationButton
      css={{
        gridArea: 'close',
        alignSelf: 'end',
        justifySelf: 'end',
        marginInline: theme.space[16],
        '@bp1': { marginInline: theme.space[32], top: theme.space[32] },
        '@bp2': { position: 'sticky' },
      }}
      {...props}
    >
      <Icons.S32.Cross />
    </NavigationButton>
  )
}

const DocumentLayoutDetailsHeader = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const isStuck = useIsStuck(
    ref,
    'top',
    undefined,
    DocumentLayoutDetailsInside.toString()
  )

  return (
    <DocumentLayoutHeaderContainer
      ref={ref}
      data-is-stuck={ref.current !== null && isStuck === false}
    >
      {children}
    </DocumentLayoutHeaderContainer>
  )
}

const DocumentLayoutDetailsTabs = ({ children }: { children: React.ReactNode }) => {
  return <DocumentLayoutDetailsContent py={0}>{children}</DocumentLayoutDetailsContent>
}

export interface DocumentLayoutDetailsContentProps extends StackProps {}

const DocumentLayoutDetailsContent = ({
  css,
  ...rest
}: DocumentLayoutDetailsContentProps) => (
  <VStack
    px={{ '@initial': 32, '@bp2': 16 }}
    py={40}
    css={{ gridArea: 'content', ...css }}
    {...rest}
  />
)

export interface DocumentLayoutDetailsActionsProps extends StackProps {}

function DocumentLayoutActions({ children, ...rest }: DocumentLayoutDetailsActionsProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const isStuck = useIsStuck(
    ref,
    'bottom',
    undefined,
    DocumentLayoutDetailsInside.toString()
  )

  return (
    <DocumentLayoutActionsContainer ref={ref} data-is-stuck={!isStuck} {...rest}>
      <div>{children}</div>
    </DocumentLayoutActionsContainer>
  )
}

export {
  DocumentLayout as Root,
  DocumentLayoutPreview as Preview,
  DocumentLayoutDetails as Details,
  DocumentLayoutDetailsHeader as Header,
  DocumentLayoutDetailsTabs as Tabs,
  DocumentLayoutDetailsContent as Content,
  DocumentLayoutDetailsClose as Close,
  DocumentLayoutActions as Actions,
}
