import { usePractice } from 'api/hooks/companies'
import { useCurrentUser } from 'api/hooks/users'
import type {
  BookkeepingPermission,
  UserPermission,
  UserRole,
} from 'api/types/permissions'
import type { RegisteredUser } from 'api/types/users'
import { FormProvider, useForm, yupResolver } from 'kitchen/forms'
import { ImpossibleError } from 'kitchen/utils/error'
import { unique, isNonEmpty, stopPropagation } from 'kitchen/utils/helpers'
import {
  ADMIN_PERMISSIONS,
  MANAGE_PERMISSIONS,
  checkHasAllAdminPermissions,
  checkHasPayrollAccess,
  checkIsAdminPermission,
  checkIsBookkeepingPermission,
  checkIsPaymentPermission,
  mapRolesToPaymentPermissions,
} from 'kitchen/utils/permissions'
import * as Yup from 'kitchen/validations'
import { useState } from 'react'
import { Button, Message, Separator } from 'salad/components'
import { HStack, VStack } from 'salad/primitives'
import { AdminPermissionsFields } from './admin-permissions-fields'
import { BookkeepingPermissionsFields } from './bookkeeping-permissions-fields'
import { PaymentPermissionsFields } from './payment-permissions-fields'
import { PermissionsSection } from './permissions-section'
import { PresetTypeSelector } from './preset-type-selector'
import { RoleFields } from './role-fields'
import type {
  ContributorRole,
  PresetType,
  RoleEditorFormValues,
  UserRoleEditorVariant,
} from './types'
import { checkRoleCanBeAssigned } from './utils'

const schema = Yup.object({
  adminPermissions: Yup.array()
    .test(
      'has-some-permissions',
      'Please select some permissions',
      (adminPermissions, { parent }) => {
        return (
          isNonEmpty(adminPermissions) ||
          isNonEmpty(parent.paymentPermissions) ||
          isNonEmpty(parent.bookkeepingPermissions)
        )
      }
    )
    .test(
      'has-payment-or-bookkeeping',
      () => (
        <>
          <b>Managing members</b> requires at least one active <b>Payment</b> or{' '}
          <b>Bookkeeping</b> permission.
        </>
      ),
      (adminPermissions, { parent }) => {
        if (adminPermissions?.includes('USERS_EDIT')) {
          return (
            parent.paymentPermissions.length > 0 ||
            parent.bookkeepingPermissions.length > 0
          )
        }
        return true
      }
    ),
})

interface UserRoleEditorProps {
  variant?: UserRoleEditorVariant
  rolePreset: UserRole | undefined
  permissions: UserPermission[] | undefined
  currentUserPermissions: UserPermission[]
  contributorRole?: ContributorRole
  user?: RegisteredUser
  loading?: boolean
  onSubmit: (role: UserRole, permissions: UserPermission[]) => void
  onCancel: () => void
}

export function UserRoleEditor({
  variant = 'default',
  rolePreset: defaultRolePreset,
  permissions = [],
  currentUserPermissions,
  contributorRole,
  user,
  loading,
  onSubmit,
  onCancel,
}: UserRoleEditorProps) {
  const { data: currentUser } = useCurrentUser({ suspense: true })

  if (currentUser === undefined) {
    throw new Error('Current user is required')
  }

  const { data: practice } = usePractice({ suspense: true })
  const isUserPracticeHubAdmin = user?.practiceHubRole === 'ADMIN'
  const currentPracticeUser = practice?.registeredUsers.find(
    (practiceUser) => practiceUser.id === currentUser.id
  )
  const isCurrentUserPracticeHubAdmin = currentPracticeUser?.practiceHubRole === 'ADMIN'
  const [presetType, setPresetType] = useState<PresetType>(() =>
    defaultRolePreset === 'ADMIN' ? 'admin' : 'standard'
  )

  const canAssignRole = (role: UserRole) =>
    isCurrentUserPracticeHubAdmin || checkRoleCanBeAssigned(currentUserPermissions)(role)

  const getDefaultRoleValue = (presetType: PresetType): UserRole => {
    if (presetType === 'admin') {
      return 'ADMIN'
    }

    if (defaultRolePreset !== undefined) {
      return defaultRolePreset === 'ADMIN' ? 'PAYER' : defaultRolePreset
    }

    return contributorRole === 'approver' && canAssignRole('APPROVER')
      ? 'APPROVER'
      : contributorRole === 'payer' && canAssignRole('PAYER')
      ? 'PAYER'
      : canAssignRole('CREATOR')
      ? 'CREATOR'
      : 'CUSTOM'
  }

  const getDefaultValues = (presetType: PresetType): RoleEditorFormValues => {
    const defaultRoleValue = getDefaultRoleValue(presetType)

    if (defaultRolePreset === defaultRoleValue && permissions.length > 0) {
      const paymentPermissions = permissions.filter(checkIsPaymentPermission)
      const bookkeepingPermissions = permissions.filter(checkIsBookkeepingPermission)
      const adminPermissions = permissions.filter(checkIsAdminPermission)

      return {
        role: defaultRoleValue,
        paymentPermissions,
        bookkeepingPermissions,
        adminPermissions: isUserPracticeHubAdmin
          ? unique(adminPermissions.concat(MANAGE_PERMISSIONS))
          : adminPermissions,
      }
    }

    switch (presetType) {
      case 'standard':
        return {
          role: getDefaultRoleValue('standard'),
          paymentPermissions: [],
          bookkeepingPermissions: [],
          adminPermissions: isUserPracticeHubAdmin ? MANAGE_PERMISSIONS : [],
        }
      case 'admin':
        return {
          role: 'ADMIN',
          paymentPermissions: [
            ...mapRolesToPaymentPermissions['ADMIN'],
            ...(isCurrentUserPracticeHubAdmin ||
            checkHasPayrollAccess(currentUserPermissions)
              ? ([
                  'PAYROLL_PAYMENTS_VIEW',
                  'PAYROLL_PAYMENTS_CREATE',
                  'PAYROLL_PAYMENTS_EDIT',
                  'PAYROLL_PAYMENTS_APPROVE',
                  'PAYROLL_PAYMENTS_AUTHORISE',
                ] as const)
              : []),
          ],
          bookkeepingPermissions: ['BOOKKEEPING_VIEW', 'BOOKKEEPING_PUBLISH'],
          adminPermissions: ADMIN_PERMISSIONS,
        }
      default:
        throw new ImpossibleError('unhandled preset type:', presetType)
    }
  }

  const defaultValues = getDefaultValues(presetType)

  const { formState, ...form } = useForm<RoleEditorFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return (
    <FormProvider {...form} formState={formState}>
      <VStack
        as="form"
        onSubmit={stopPropagation(
          form.handleSubmit((values) => {
            const role = presetType === 'admin' ? 'ADMIN' : values.role

            return onSubmit(role, [
              ...values.paymentPermissions,
              ...values.adminPermissions,
              ...values.bookkeepingPermissions,
              // implicitly add with any other permissions
              'USERS_VIEW',
              'APPROVALS_VIEW',
              'COMMENTS_VIEW',
              'COMMENTS_EDIT',
            ])
          })
        )}
      >
        <VStack gap={32}>
          <VStack px={32}>
            <PresetTypeSelector
              value={presetType}
              onValueChange={(value) => {
                if (value === 'admin') {
                  form.reset(getDefaultValues('admin'))
                } else {
                  form.reset(getDefaultValues('standard'))
                }
                setPresetType(value)
              }}
              disabled={
                !isCurrentUserPracticeHubAdmin &&
                !checkHasAllAdminPermissions(currentUserPermissions)
              }
            />
          </VStack>
          <Separator size={1} />
        </VStack>

        <PermissionsSection
          key={`payment-${presetType}`}
          title="Payment permissions"
          toggleable={presetType === 'standard'}
          restricted={
            !isCurrentUserPracticeHubAdmin &&
            currentUserPermissions.filter(checkIsPaymentPermission).length === 0
          }
          defaultOpen={
            presetType === 'admin' ||
            permissions.filter(checkIsPaymentPermission).length > 0 ||
            contributorRole !== undefined
          }
          onOpenChange={(value) => {
            if (value === false) {
              form.setValue('paymentPermissions', [])
            }
          }}
        >
          <VStack gap={24}>
            {presetType === 'standard' && (
              <RoleFields
                contributorRole={contributorRole}
                canAssignRole={canAssignRole}
              />
            )}
            <Separator variant="dotted" />
            <PaymentPermissionsFields
              currentUserPermissions={currentUserPermissions}
              contributorRole={contributorRole}
              currentUserIsPracticeHubAdmin={isCurrentUserPracticeHubAdmin}
            />
          </VStack>
        </PermissionsSection>

        <PermissionsSection
          key={`bookkeeping-${presetType}`}
          title="Bookkeeping permissions"
          toggleable={presetType === 'standard'}
          restricted={
            !isCurrentUserPracticeHubAdmin &&
            currentUserPermissions.filter(checkIsBookkeepingPermission).length === 0
          }
          defaultOpen={
            presetType === 'admin' ||
            permissions.filter(checkIsBookkeepingPermission).length > 0
          }
          onOpenChange={(value) => {
            if (value === false) {
              form.setValue('bookkeepingPermissions', [])
            } else {
              const defaultViewPermissions: BookkeepingPermission[] =
                isCurrentUserPracticeHubAdmin ||
                currentUserPermissions.includes('BOOKKEEPING_VIEW')
                  ? ['BOOKKEEPING_VIEW']
                  : ['BOOKKEEPING_ASSIGNED_VIEW']
              const defaultPublishPermissions: BookkeepingPermission[] =
                presetType === 'admin' ? ['BOOKKEEPING_PUBLISH'] : []

              form.setValue('bookkeepingPermissions', [
                ...defaultViewPermissions,
                ...defaultPublishPermissions,
              ])
            }
          }}
        >
          <BookkeepingPermissionsFields
            presetType={presetType}
            currentUserPermissions={currentUserPermissions}
            displayedOptions={
              presetType === 'admin'
                ? ['BOOKKEEPING_VIEW', 'BOOKKEEPING_PUBLISH']
                : ['BOOKKEEPING_ASSIGNED_VIEW', 'BOOKKEEPING_VIEW', 'BOOKKEEPING_PUBLISH']
            }
            restricted={
              !isCurrentUserPracticeHubAdmin &&
              !currentUserPermissions.includes('BOOKKEEPING_PUBLISH')
            }
          />
        </PermissionsSection>
        <PermissionsSection
          key={`admin-${presetType}`}
          title="Admin permissions"
          toggleable={presetType === 'standard' && !isUserPracticeHubAdmin}
          restricted={
            !isCurrentUserPracticeHubAdmin &&
            currentUserPermissions.filter(checkIsAdminPermission).length === 0
          }
          defaultOpen={
            presetType === 'admin' ||
            permissions.filter(checkIsAdminPermission).length > 0 ||
            defaultValues.adminPermissions.length > 0
          }
          onOpenChange={(value) => {
            if (value === false) {
              form.setValue('adminPermissions', [])
            }
          }}
        >
          <AdminPermissionsFields
            user={user}
            currentUserPermissions={currentUserPermissions}
            presetType={presetType}
            restricted={!isCurrentUserPracticeHubAdmin}
          />
        </PermissionsSection>

        <VStack gap={24} p={32}>
          {(formState.errors.adminPermissions ||
            formState.errors.paymentPermissions ||
            formState.errors.bookkeepingPermissions) && (
            <Message variant="negative">
              {formState.errors.adminPermissions?.message ||
                formState.errors.paymentPermissions?.message ||
                formState.errors.bookkeepingPermissions?.message}
            </Message>
          )}
          <HStack gap={8} css={{ gridAutoColumns: 'auto 1fr' }}>
            <Button.Root variant="minor" size="medium" hug onClick={onCancel}>
              Cancel
            </Button.Root>
            <Button.Root type="submit" variant="common" size="medium" loading={loading}>
              {(() => {
                switch (variant) {
                  case 'default': {
                    return 'Submit'
                  }
                  case 'update-user-role': {
                    return 'Save'
                  }
                  case 'invite-company-user-role-step':
                  case 'invite-practice-user-role-step': {
                    return 'Continue'
                  }
                  default:
                    throw new ImpossibleError(
                      'Impossible state: user role editor',
                      variant
                    )
                }
              })()}
            </Button.Root>
          </HStack>
        </VStack>
      </VStack>
    </FormProvider>
  )
}
