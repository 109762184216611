import type { TrackingCategory } from 'domains/tracking-category/types'
import type { ApiRequest } from 'kitchen/types'
import { getRequiredCompanySettings } from 'kitchen/utils/companies'
import { isNotFoundError } from 'kitchen/utils/error'
import type {
  Company,
  CompanyIdPayload,
  ConnectionsListResponse,
  CompanySettings,
  CompanySettingsResponse,
  CompanyStatusResponse,
  CreateCompanyResponse,
  UpdateCompanySettingsPayload,
  ApprovalWorkflowsResponse,
  ApprovalWorkflow,
  UpdateCompanyPayload,
  CreateApprovalWorkflowPayload,
  UpdateApprovalWorkflowPayload,
  RemoveApprovalWorkflowPayload,
  UserDependenciesPayload,
  UserDependenciesResponse,
  UpdateCompanyUserDirectEmailPayload,
  AdvancedCompanySettings,
  CompaniesListPayload,
  CompaniesStats,
  GenerateCompanyUserInvoiceEmailPayload,
  GeneratedCompanyUserInvoiceEmail,
} from '../types/companies'
import type {
  RegisteredUser,
  InviteUserPayload,
  UnInviteUserPayload,
} from '../types/users'

export const getCompanies: ApiRequest<CompaniesListPayload, Company[]> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get('/connections', {
      signal,
      params: {
        pagination: JSON.stringify({ page: 1, perPage: 100 }),
        sort: JSON.stringify({ field: 'id', order: 'name' }),
        status: payload.status,
      },
    })
    .json<ConnectionsListResponse>()
    .then((data) => data.connections)

export const getCompaniesStats: ApiRequest<void, CompaniesStats> = (fetch) =>
  fetch
    .get('/connections-summary')
    .json<{ companies: CompaniesStats }>()
    .then((data) => data.companies)

export const createCompany: ApiRequest<void, CreateCompanyResponse> = (fetch) =>
  fetch.post('/companies').json<CreateCompanyResponse>()

export const getCompanySettings: ApiRequest<CompanyIdPayload, CompanySettings> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get(`/companies/${payload.companyId}/settings`, { signal })
    .json<CompanySettingsResponse>()
    .catch((error) => {
      if (isNotFoundError(error)) {
        // handle 404 as a valid response
        return {}
      }
      throw error
    })
    .then(getRequiredCompanySettings)

export const updateCompanySettings: ApiRequest<UpdateCompanySettingsPayload, void> = (
  fetch,
  { companyId, ...settings }
) => fetch.post(`/companies/${companyId}/settings`, { json: settings }).void()

export const getCompanyStatus: ApiRequest<CompanyIdPayload, CompanyStatusResponse> = (
  fetch,
  { companyId },
  signal
) => fetch.get(`/companies/${companyId}/status`, { signal }).json<CompanyStatusResponse>()

export const updateCompany: ApiRequest<UpdateCompanyPayload, void> = (
  fetch,
  { companyId, ...json }
) => fetch.patch(`/companies/${companyId}`, { json }).void()

export const syncCompany: ApiRequest<CompanyIdPayload, void> = (fetch, payload, signal) =>
  fetch.post(`/companies/${payload.companyId}/sync`, { signal }).void()

export const disconnectCompany: ApiRequest<CompanyIdPayload, void> = (fetch, payload) =>
  fetch.post(`/companies/${payload.companyId}/disconnect`).void()

export const getApprovalWorkflows: ApiRequest<CompanyIdPayload, ApprovalWorkflow[]> = (
  fetch,
  payload,
  signal
) =>
  fetch
    .get(`/companies/${payload.companyId}/workflows`, { signal })
    .json<ApprovalWorkflowsResponse>()
    .then((data) => data.workflows)

export const createApprovalWorkflow: ApiRequest<CreateApprovalWorkflowPayload, void> = (
  fetch,
  { companyId, workflow }
) => fetch.post(`/companies/${companyId}/workflows`, { json: workflow }).void()

export const updateApprovalWorkflow: ApiRequest<UpdateApprovalWorkflowPayload, void> = (
  fetch,
  { companyId, workflow }
) =>
  fetch
    .patch(`/companies/${companyId}/workflows/${workflow.id}`, { json: workflow })
    .void()

export const removeApprovalWorkflow: ApiRequest<RemoveApprovalWorkflowPayload, void> = (
  fetch,
  { companyId, workflowId }
) => fetch.delete(`/companies/${companyId}/workflows/${workflowId}`).void()

export const inviteCompanyUser: ApiRequest<InviteUserPayload, RegisteredUser> = (
  fetch,
  { companyId, ...payload }
) =>
  fetch.post(`/companies/${companyId}/invite`, { json: payload }).json<RegisteredUser>()

export const unInviteCompanyUser: ApiRequest<UnInviteUserPayload, void> = (
  fetch,
  { companyId, ...payload }
) => fetch.post(`/companies/${companyId}/uninvite`, { json: payload }).void()

export const getUserDependencies: ApiRequest<
  UserDependenciesPayload,
  UserDependenciesResponse
> = (fetch, { companyId, userId }, signal) =>
  fetch
    .get(`/companies/${companyId}/dependencies/${userId}`, { signal })
    .json<UserDependenciesResponse>()

export const updateCompanyUserDirectEmail: ApiRequest<
  UpdateCompanyUserDirectEmailPayload,
  void
> = (fetch, { companyId, ...payload }) =>
  fetch
    .post(`/companies/${companyId}/user-company-invoice-email`, { json: payload })
    .void()

export const generateCompanyUserInvoiceEmail: ApiRequest<
  GenerateCompanyUserInvoiceEmailPayload,
  GeneratedCompanyUserInvoiceEmail
> = (fetch, { companyId, ...payload }) =>
  fetch
    .get(`/companies/${companyId}/generate-user-invoice-email`, {
      params: Object.fromEntries(
        Object.entries(payload).filter(([_, value]) => Boolean(value))
      ),
    })
    .json<GeneratedCompanyUserInvoiceEmail>()

export const getCompanyAdvancedSettings: ApiRequest<
  CompanyIdPayload,
  AdvancedCompanySettings
> = (fetch, { companyId }, signal) =>
  fetch
    .get(`/companies/${companyId}/advanced-settings`, { signal })
    .json<AdvancedCompanySettings>()

export const getTrackingCategoriesList: ApiRequest<
  CompanyIdPayload,
  TrackingCategory[]
> = (fetch, { companyId }, signal) =>
  fetch
    .get(`/companies/${companyId}/tracking-categories`, { signal })
    .json<TrackingCategory[]>()

export const refreshCompanyAccountingData: ApiRequest<CompanyIdPayload, void> = (
  fetch,
  { companyId }
) => fetch.post(`/companies/${companyId}/refresh-accounting-data`).void()
