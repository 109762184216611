import * as apronTokens from '../tokens/apron'
import * as baseTokens from '../tokens/base'

export const ApronTheme = {
  backgrounds: apronTokens.backgrounds,
  colors: apronTokens.colors,
  fonts: apronTokens.fonts,
  textTransforms: apronTokens.textTransforms,
  fontSizes: apronTokens.fontSizes,
  fontWeights: apronTokens.fontWeights,
  fontFeatureSettings: apronTokens.fontFeatureSettings,
  fontVariations: apronTokens.fontVariations,
  gradients: apronTokens.gradients,
  letterSpacings: apronTokens.letterSpacings,
  lineHeights: apronTokens.lineHeights,
  shadows: apronTokens.shadows,
  space: baseTokens.space,
  radii: baseTokens.radii,
}
