const rotation = {
  default: 0,
  pressed: 180,
} as const

interface DropdownProps extends React.SVGAttributes<SVGElement> {
  variant?: keyof typeof rotation
}

export const Dropdown = ({ variant = 'default', ...rest }: DropdownProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={{ transform: `rotate(${rotation[variant]}deg)` }}
    {...rest}
  >
    <path d="M8 12L2 4H14L8 12Z" fill="currentColor" />
  </svg>
)
