export const Contacts = () => (
  <svg fill="none" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 4h7v16h-7V4ZM9 4H6v16h3V4ZM4 2h14a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4V2Z"
      clipRule="evenodd"
    />
  </svg>
)
