import { useCurrentUser } from 'api/hooks/users'
import type { Company } from 'api/types/companies'
import { RouterLink } from 'kitchen/router'
import { ImpossibleError } from 'kitchen/utils/error'
import { SidebarNav } from 'salad/components'
import { SidebarLayout } from 'salad/layouts'
import { Path } from '@app/constants'
import { UserActions } from '../user-actions'
import { AppSidebarCompanyNavItems } from './app-sidebar-company-nav-items'
import { AppSidebarHubNavItems } from './app-sidebar-hub-nav-items'

export type AppSidebarProps =
  | { layout: 'company-page'; company: Company }
  | { layout: 'hub-page' }

export function AppSidebar(props: AppSidebarProps) {
  const currentUser = useCurrentUser()

  return (
    <SidebarLayout.Root>
      <SidebarLayout.Logo as={RouterLink} to={Path.INDEX} />
      {currentUser.isSuccess && (
        <>
          <SidebarLayout.Tabs>
            <SidebarNav.Root>
              {(() => {
                switch (props.layout) {
                  case 'hub-page':
                    return <AppSidebarHubNavItems user={currentUser.data} />
                  case 'company-page':
                    return <AppSidebarCompanyNavItems company={props.company} />
                  default:
                    throw new ImpossibleError('Unhandled layout', props)
                }
              })()}
            </SidebarNav.Root>
          </SidebarLayout.Tabs>
          <SidebarLayout.End>
            <UserActions layout="sidebar" user={currentUser.data} />
          </SidebarLayout.End>
        </>
      )}
    </SidebarLayout.Root>
  )
}
