import { useDateTimePartFormat } from 'kitchen/hooks/use-date-time-part-format'
import { useState } from 'react'
import { Box, Grid, VStack } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Label } from '../label'
import { calculateDayMeta } from './calculate-day-meta'
import { Cell } from './cell'
import { useCalendarContext } from './context'
import { DateCell } from './date-cell'
import type { DateRange } from './types'
import { useCalendarDays } from './use-calendar-days'

const CalendarGrid = styled(Grid, {
  columnGap: theme.space[12],
  rowGap: theme.space[2],
  gridTemplateColumns: 'repeat(7, minmax(min-content, auto))',
})

interface MonthGridProps {
  onDayClick: (date: Date) => void
  //** custom check for a date to be disabled, if it's hard to pre-calculate all cases */
  disabledDateChecker?: (date: Date) => boolean
  //** sets whether prev and next month days are visible */
  displayOuterDates?: boolean
  //** toggles if user can select a range or single date */
  singleDate?: boolean
  activeRangePart?: keyof DateRange
}

export const MonthGrid = ({
  onDayClick,
  disabledDateChecker,
  displayOuterDates = false,
  singleDate = false,
  activeRangePart,
}: MonthGridProps) => {
  const {
    displayedYear,
    displayedMonth,
    selectedFrom,
    selectedTo,
    disabledDates,
    specialDates,
  } = useCalendarContext()
  const weekdayFormat = useDateTimePartFormat('weekday')
  const [hoveredDate, setHoveredDate] = useState<Date | undefined>()

  const { display } = useCalendarDays({
    currentYear: displayedYear,
    currentMonth: displayedMonth,
  })

  const displayedDays = [
    ...display.previousMonth,
    ...display.currentMonth,
    ...display.nextMonth,
  ]

  return (
    <VStack>
      <CalendarGrid py={2}>
        {displayedDays.slice(0, 7).map((day) => (
          <Cell key={day.date.toISOString()}>
            <Label size={13}>{weekdayFormat.format(day.date.toDate())}</Label>
          </Cell>
        ))}
      </CalendarGrid>
      <Box
        onMouseLeave={() => {
          setHoveredDate(undefined)
        }}
      >
        <CalendarGrid css={{ overflow: 'hidden', gridTemplateRows: 'repeat(6, 1fr)' }}>
          {displayedDays.map((day) => {
            const displayedDay = { ...day }
            const dayMeta = calculateDayMeta({
              day,
              disabledDateChecker,
              disabledDates,
              specialDates,
              selectedFrom,
              selectedTo,
              hoveredDate,
              singleDate,
              activeRangePart,
            })
            displayedDay.meta = {
              ...displayedDay.meta,
              ...dayMeta,
            }
            return (
              <DateCell
                onClick={onDayClick}
                onHover={setHoveredDate}
                key={displayedDay.date.format()}
                day={displayedDay}
                displayOuterDates={displayOuterDates}
              >
                {displayedDay.dayOfMonth}
              </DateCell>
            )
          })}
        </CalendarGrid>
      </Box>
    </VStack>
  )
}
