import { useQuery } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { usePublicFetch } from 'kitchen/hooks/use-public-fetch'
import type { FetchError, UseQueryOptions } from 'kitchen/types'
import { getAppNotifications } from '../requests/apron'
import type { AppNotification } from '../types/apron'

export const useAppNotifications = (options?: UseQueryOptions<AppNotification[]>) => {
  const fetch = usePublicFetch()
  return useQuery<AppNotification[], FetchError>(
    [CacheKey.APP_NOTIFICATIONS],
    ({ signal }) => getAppNotifications(fetch, signal),
    options
  )
}
