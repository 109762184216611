import { useState, useEffect } from 'react'

export type StickySide = 'top' | 'left' | 'right' | 'bottom' | 'none'

function getRootMargin(side: Exclude<StickySide, 'none'>, offset: number) {
  switch (side) {
    case 'top':
      return `-${offset + 1}px 0px 0px 0px`
    case 'right':
      return `0px -${offset + 1}px 0px 0px`
    case 'bottom':
      return `0px 0px -${offset + 1}px 0px`
    case 'left':
      return `0px 0px 0px -${offset + 1}px`
  }
}

export function useIsStuck<Element extends HTMLElement>(
  ref: React.MutableRefObject<Element | null>,
  side: StickySide = 'none',
  offset = 0,
  root?: string
) {
  const [isStuck, setStuck] = useState<boolean>(false)

  useEffect(() => {
    const element = ref.current

    if (side !== 'none' && element !== null) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          switch (side) {
            case 'top':
            case 'bottom':
              return setStuck(!entry.isIntersecting)
            case 'left':
            case 'right':
              return setStuck(entry.isIntersecting)
          }
        },
        {
          root: root ? element.closest(root) : null,
          rootMargin: getRootMargin(side, offset),
          threshold: [1],
        }
      )

      observer.observe(element)
      return () => observer.disconnect()
    }
  }, [side, ref, root, offset])

  return isStuck
}
