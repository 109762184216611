import { ImpossibleError } from 'kitchen/utils/error'
import { forwardRef } from 'react'
import { Box, type BoxProps } from '../../primitives'
import * as CustomScrollArea from './custom-scroll-area'
import { StepBarScrollArea, type StepBarScrollAreaProps } from './step-bar-scroll-area'

/**
 * Default scroll we use for pages
 */
interface NativeScrollAreaProps extends BoxProps {
  variant: 'native'
}

interface SnapXScrollAreaProps extends BoxProps {
  variant: 'snap-x'
}

/**
 * Scroll we use inside Surface or Swimlanes
 */
interface CustomScrollAreaProps
  extends Pick<CustomScrollArea.CustomScrollAreaProps, 'type' | 'scrollHideDelay'>,
    CustomScrollArea.CustomScrollAreaViewportProps {
  variant: 'custom-scrollbar'
  offsetX?: number
  offsetY?: number
}

interface StepBarScrollAreProps extends StepBarScrollAreaProps {
  variant: 'step-bar'
}

export type ScrollAreaProps =
  | NativeScrollAreaProps
  | SnapXScrollAreaProps
  | CustomScrollAreaProps
  | StepBarScrollAreProps

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  function ScrollAreaRef(props, forwardedRef) {
    switch (props.variant) {
      case 'native':
        return (
          <Box {...props} ref={forwardedRef} css={{ ...props.css, overflow: 'auto' }} />
        )
      case 'custom-scrollbar': {
        const {
          type = 'always',
          scrollHideDelay,
          variant: _,
          offsetX = 0,
          offsetY,
          style,
          ...rest
        } = props

        return (
          <CustomScrollArea.Root
            type={type}
            scrollHideDelay={scrollHideDelay}
            style={{ marginInline: offsetX * -1 }}
          >
            <CustomScrollArea.Viewport
              ref={forwardedRef}
              style={{ paddingInline: offsetX, ...style }}
              {...rest}
            />
            <CustomScrollArea.Scrollbar
              orientation="vertical"
              style={{ paddingBlock: offsetY }}
            >
              <CustomScrollArea.Thumb />
            </CustomScrollArea.Scrollbar>
            <CustomScrollArea.Scrollbar orientation="horizontal">
              <CustomScrollArea.Thumb />
            </CustomScrollArea.Scrollbar>
            <CustomScrollArea.Corner />
          </CustomScrollArea.Root>
        )
      }
      case 'step-bar':
        return <StepBarScrollArea {...props} ref={forwardedRef} />
      case 'snap-x':
        return (
          <Box
            {...props}
            ref={forwardedRef}
            css={{ ...props.css, overflow: 'auto', scrollSnapType: 'x mandatory' }}
          />
        )
      default:
        throw new ImpossibleError('Unhandled ScrollArea variant', props)
    }
  }
)
