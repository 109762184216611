import { styled } from '../stitches'
import { margin, padding } from '../variants'

export interface ListProps extends React.ComponentProps<typeof List> {}

export const List = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
  variants: {
    ...margin,
    ...padding,
  },
})

export const ListItem = styled('li')
