import type { AccountingApp, SyncSource } from 'api/types/vendors'
import { ImpossibleError } from 'kitchen/utils/error'
import { checkIsXeroAccountingApp, getAccountingAppLabel } from 'kitchen/utils/vendor'
import type { TransactionAssignee, UserAssignee, TransactionState } from './types'

export function isUserAssignee(assignee: TransactionAssignee): assignee is UserAssignee {
  return assignee !== null && assignee.userId !== null
}

export function getTransactionStateLabel(state: TransactionState, source: SyncSource) {
  switch (state) {
    case 'RESOLVED':
      return checkIsXeroAccountingApp(source) ? 'Reconciled' : 'Resolved'
    case 'UNRESOLVED':
      return checkIsXeroAccountingApp(source) ? 'Unreconciled' : 'Unresolved'
    case 'DELETED':
      return 'Deleted'
    default:
      throw new ImpossibleError('unhandled state', state)
  }
}

export function getDefaultBankFeedFilterSearch() {
  return new URLSearchParams({
    state: 'UNRESOLVED',
  })
}

export function isNotAssigned(assignedTo: string): assignedTo is 'NOT_ASSIGNED' {
  return assignedTo === 'NOT_ASSIGNED'
}

export function getSyncModeLabel(syncMode: 'AUTO' | 'MANUAL', source: AccountingApp) {
  switch (syncMode) {
    case 'AUTO':
      return `Direct sync to ${getAccountingAppLabel(source)}`
    case 'MANUAL':
      return `Manual upload`
    default:
      throw new ImpossibleError('unhandled BankFeed sync mode', syncMode)
  }
}

export function sortSuggestions<Value extends string>(
  data: {
    value: Value
    count: number
  }[]
) {
  return data.slice().sort((a, b) => a.value.localeCompare(b.value))
}
