import { forwardRef, useContext } from 'react'
import { InputGroupContext } from '../../context/input-group-context'
import type { BoxProps, TextProps, ButtonBaseProps } from '../../primitives'
import { Box, Text, ButtonBase, Grid } from '../../primitives'

export interface InputButtonProps extends ButtonBaseProps {}

const InputButton = forwardRef<HTMLButtonElement, InputButtonProps>(function InputButton(
  { children, ...rest },
  forwardedRef
) {
  const context = useContext(InputGroupContext)
  return (
    <ButtonBase ref={forwardedRef} {...context?.getAriaProps(rest)} {...rest} data-input>
      <Grid
        as="span"
        css={{
          alignItems: 'center',
          gridTemplate: `
            'text end' auto / 1fr auto
          `,
        }}
      >
        {children}
      </Grid>
    </ButtonBase>
  )
})

const InputButtonContent = ({ children, ...rest }: TextProps) => (
  <Text css={{ gridArea: 'text' }} {...rest}>
    {children || ' '}
  </Text>
)

const InputButtonPlaceholder = (props: TextProps) => (
  <InputButtonContent data-input-placeholder {...props} />
)

const InputButtonEnd = (props: BoxProps) => (
  <Box as="span" css={{ gridArea: 'end' }} {...props} />
)

export {
  InputButton as Root,
  InputButtonContent as Content,
  InputButtonPlaceholder as Placeholder,
  InputButtonEnd as End,
}
