import * as Icons from '../../icons'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { IconButton, type IconButtonProps } from '../icon-button'

const SearchInputRounded = styled('div', {
  display: 'grid',
  gridTemplate: `'start input end ' auto / auto 1fr auto`,
  gap: theme.space[4],
  height: theme.space[40],
  paddingInlineStart: theme.space[16],
  paddingInlineEnd: theme.space[12],
  alignItems: 'center',
  borderWidth: theme.space[2],
  borderStyle: 'solid',
  borderColor: theme.colors['grey-15'],
  borderRadius: theme.radii['round'],
  transition: 'border-color 0.3s',
  'input, textarea, [data-input]': {
    gridArea: 'input',
    color: theme.colors['black'],
    paddingInline: theme.space[4],
    paddingBlockEnd: 1,
    '&::placeholder, [data-input-placeholder]': {
      opacity: 1,
      color: theme.colors['grey-40'],
    },
  },
  '&:hover': {
    borderColor: theme.colors['grey-30'],
  },
  '&:focus-within': {
    borderColor: theme.colors['dark-blue'],
  },
  '&[aria-disabled="true"]': {
    borderColor: theme.colors.transparent,
    background: theme.colors['black-alpha-5'],
  },
  ...typography['paragraph-14'],
  variants: {
    size: {
      small: {
        height: theme.space[40],
      },
      medium: {
        height: theme.space[48],
      },
    },
  },
  defaultVariants: {
    size: 'small',
  },
})

const SearchInputRoundedStart = styled('div', {
  gridArea: 'start',
})

const SearchInputRoundedEnd = styled('div', {
  gridArea: 'end',
})

const SearchInputRoundedClear = (props: IconButtonProps) => (
  <IconButton size={24} title="Clear" tabIndex={-1} {...props}>
    <Icons.S16.Cross />
  </IconButton>
)

export {
  SearchInputRounded as Root,
  SearchInputRoundedStart as Start,
  SearchInputRoundedEnd as End,
  SearchInputRoundedClear as Clear,
}
