import type { GenericForwardRefComponent } from 'kitchen/types'
import { forwardRef } from 'react'
import type { StackProps, TextProps } from '../../primitives'
import { Box, VStack, HStack, Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { Link, type LinkProps } from '../link'

export interface NoticeProps extends React.ComponentProps<typeof NoticeBase> {}

const NoticeBase = styled(Box, {
  borderRadius: theme.radii[8],
  variants: {
    variant: {
      default: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['grey-5'],
      },
      positive: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['grey-5'],
        '[data-notice-header] > svg': { color: theme.colors['dark-green'] },
      },
      negative: {
        color: theme.colors['light-red'],
        backgroundColor: theme.colors['light-red-20'],
        '[data-notice-header] > svg': { color: theme.colors['dark-red'] },
      },
      warning: {
        color: theme.colors['black'],
        backgroundColor: theme.colors['light-yellow-60'],
        '[data-notice-header] > svg': { color: theme.colors['dark-yellow-80'] },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const Notice = ({ children, ...rest }: NoticeProps) => (
  <NoticeBase px="24" py="20" {...rest}>
    <VStack gap="8">{children}</VStack>
  </NoticeBase>
)

export interface NoticeHeaderProps extends Omit<StackProps, 'gap'> {}

const NoticeHeader = (props: NoticeHeaderProps) => (
  <HStack
    gap="8"
    css={{ gridTemplateColumns: 'auto 1fr' }}
    data-notice-header
    {...props}
  />
)

export interface NoticeTitleProps extends TextProps {
  children?: React.ReactNode
}

const NoticeTitle = ({ css, ...rest }: NoticeTitleProps) => (
  <Text as="h4" variant="label-13" color="black" css={{ order: 1, ...css }} {...rest} />
)

export interface NoticeParagraphProps extends TextProps {
  children?: React.ReactNode
}

const NoticeParagraph = (props: NoticeParagraphProps) => (
  <Text as="p" variant="paragraph-16" color="grey-60" {...props} />
)

export interface NoticeActionProps extends LinkProps {}

const NoticeAction: GenericForwardRefComponent<NoticeActionProps> = forwardRef(
  function NoticeActionRef({ children, ...rest }, ref) {
    const asElement = 'as' in rest ? rest.as : 'button'
    return (
      <Text variant="title-16">
        <Link as={asElement} ref={ref} {...rest}>
          {children}
        </Link>
      </Text>
    )
  }
)

export {
  Notice as Root,
  NoticeHeader as Header,
  NoticeTitle as Title,
  NoticeParagraph as Paragraph,
  NoticeAction as Action,
}
