import type { CountryCode } from 'api/types/address'
import type { CountryCallingCode, PhoneCountryCallingCodeId } from '../types'

export function getPhoneCountryCallingCodeId(
  countryCode: CountryCode,
  countryCallingCode: CountryCallingCode
): PhoneCountryCallingCodeId {
  if (!countryCode) {
    throw new Error('Country code must be provided')
  }

  if (!countryCallingCode) {
    throw new Error('Country calling code must be provided')
  }

  return `${countryCode}_${countryCallingCode}` as PhoneCountryCallingCodeId
}
