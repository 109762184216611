export const Plus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2H11V11H2V13H11V22H13V13H22V11H13V2Z"
      fill="currentColor"
    />
  </svg>
)
