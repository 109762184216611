export const lineHeights = {
  'price-tag-large-main': '64px',
  'price-tag-large-support': '42px',
  'price-tag-medium-main': '38px',
  'price-tag-medium-support': '27px',
  'price-tag-small-main': '28px',
  'price-tag-small-support': '20px',
  'headline-h1': '40px',
  'headline-h2': '32px',
  'headline-h3': '26px',
  'title-16': '20px',
  'title-14': '18px',
  'paragraph-20': '26px',
  'paragraph-16': '20px',
  'paragraph-14': '18px',
  'paragraph-12': '16px',
  'label-16': '20px',
  'label-13': '16px',
  'button-large': '24px',
  'avatar-large': '20px',
  'avatar-medium': '16px',
  'avatar-small': '12px',
} as const

export type LineHeight = keyof typeof lineHeights
