import type { BookkeepingPermission, UserPermission } from 'api/types/permissions'
import { useFormContext } from 'kitchen/forms'
import { remove } from 'kitchen/utils/data'
import { ButtonGroup, Checkbox } from 'salad/components'
import * as Icons from 'salad/icons'
import { Box, Grid, HStack, Text, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { PermissionToggleButton } from './permission-toggle-button'
import { TooltipWrapper } from './tooltip-wrapper'
import type { PresetType, RoleEditorFormValues } from './types'

interface VisibilityToggleProps {
  currentUserPermissions: UserPermission[]
  displayedOptions: BookkeepingPermission[]
}

const VisibilityToggle = ({
  currentUserPermissions,
  displayedOptions,
}: VisibilityToggleProps) => {
  const { watch, setValue } = useFormContext<RoleEditorFormValues>()
  const value = watch('bookkeepingPermissions')

  return (
    <ButtonGroup>
      <TooltipWrapper
        enabled={!currentUserPermissions.includes('BOOKKEEPING_VIEW')}
        tooltipContent="You can only give out the permissions you have"
      >
        <PermissionToggleButton
          variant={
            displayedOptions.includes('BOOKKEEPING_ASSIGNED_VIEW') ? 'simple' : 'default'
          }
          disabled={
            !currentUserPermissions.includes('BOOKKEEPING_VIEW') ||
            !displayedOptions.includes('BOOKKEEPING_ASSIGNED_VIEW')
          }
          checked={value.includes('BOOKKEEPING_VIEW')}
          onCheckedChange={(checked) => {
            const updatedValue = remove(value, [
              'BOOKKEEPING_ASSIGNED_VIEW',
              'BOOKKEEPING_VIEW',
            ])

            if (checked) {
              setValue('bookkeepingPermissions', [...updatedValue, 'BOOKKEEPING_VIEW'])
            } else {
              setValue('bookkeepingPermissions', [
                ...updatedValue,
                'BOOKKEEPING_ASSIGNED_VIEW',
              ])
            }
          }}
        >
          All items
        </PermissionToggleButton>
      </TooltipWrapper>

      {displayedOptions.includes('BOOKKEEPING_ASSIGNED_VIEW') && (
        <PermissionToggleButton
          variant="simple"
          checked={value.includes('BOOKKEEPING_ASSIGNED_VIEW')}
          disabled={!currentUserPermissions.includes('BOOKKEEPING_VIEW')}
          onCheckedChange={(checked) => {
            const updatedValue = remove(value, [
              'BOOKKEEPING_ASSIGNED_VIEW',
              'BOOKKEEPING_VIEW',
            ])

            if (checked) {
              setValue('bookkeepingPermissions', [
                ...updatedValue,
                'BOOKKEEPING_ASSIGNED_VIEW',
              ])
            } else {
              setValue('bookkeepingPermissions', [...updatedValue, 'BOOKKEEPING_VIEW'])
            }
          }}
        >
          Own items
        </PermissionToggleButton>
      )}
    </ButtonGroup>
  )
}

interface BookkeepingPermissionsFieldsProps {
  presetType: PresetType
  displayedOptions: BookkeepingPermission[]
  currentUserPermissions: UserPermission[]
  restricted: boolean
}

export function BookkeepingPermissionsFields({
  presetType,
  currentUserPermissions,
  displayedOptions,
  restricted,
}: BookkeepingPermissionsFieldsProps) {
  const { watch, setValue } = useFormContext<RoleEditorFormValues>()
  const value = watch('bookkeepingPermissions')

  return (
    <VStack gap={24}>
      <VStack gap={12}>
        <HStack
          css={{ alignItems: 'center', gridAutoColumns: '78px max-content' }}
          gap={16}
        >
          <Text variant="title-16" align="end">
            Visibility
          </Text>

          <VisibilityToggle
            currentUserPermissions={currentUserPermissions}
            displayedOptions={displayedOptions}
          />
        </HStack>
        <Box css={{ marginInlineStart: `calc(78px + ${theme.space[16]})` }}>
          {value.includes('BOOKKEEPING_VIEW') && (
            <Text variant="paragraph-16">
              Member can upload and see all documents and bank feed transactions.
            </Text>
          )}
          {value.includes('BOOKKEEPING_ASSIGNED_VIEW') && (
            <Text variant="paragraph-16">
              Member can only upload and see documents and bank feed transactions they’ve
              created or been assigned to.
            </Text>
          )}
        </Box>
      </VStack>

      {displayedOptions.includes('BOOKKEEPING_PUBLISH') && (
        <TooltipWrapper
          asButton
          enabled={restricted}
          tooltipContent="You can only give out the permissions you have"
        >
          <Checkbox.Root data-disabled={restricted}>
            {presetType === 'admin' ? (
              <Grid
                css={{
                  size: theme.space[32],
                  color: theme.colors['dark-blue'],
                  placeContent: 'center',
                }}
              >
                <Icons.S16.Check />
              </Grid>
            ) : (
              <Checkbox.Input
                disabled={restricted}
                checked={value.includes('BOOKKEEPING_PUBLISH')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValue('bookkeepingPermissions', [...value, 'BOOKKEEPING_PUBLISH'])
                  } else {
                    setValue(
                      'bookkeepingPermissions',
                      remove(value, 'BOOKKEEPING_PUBLISH')
                    )
                  }
                }}
              />
            )}
            <Checkbox.Label color={restricted ? undefined : 'black'}>
              Publish documents to ledger
            </Checkbox.Label>
          </Checkbox.Root>
        </TooltipWrapper>
      )}
    </VStack>
  )
}
