import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { refreshAuthSession } from 'domains/auth/requests'
import type { AuthSession } from 'domains/auth/types'
import { ErrorCode, type FetchErrorMeta } from 'kitchen/types'
import { useCallback } from 'react'
import * as YF from 'ya-fetch'
import { CacheKey } from '../constants'
import { settled } from '../utils/async'
import { randomString } from '../utils/data'
import { ExtendedResponseError, ApiError } from '../utils/error'
import { usePublicFetch } from './use-public-fetch'

/**
 * This hook is only to be consumed by fetch context!
 */
export const useCreateAuthorizedFetch = () => {
  const fetch = usePublicFetch()
  const queryClient = useQueryClient()
  return useCallback(
    () =>
      fetch.extend({
        async onRequest(_, options) {
          const authSession = await queryClient.ensureQueryData<AuthSession>({
            queryKey: [CacheKey.AUTH],
            queryFn: () => refreshAuthSession(fetch),
          })

          options.headers.set('Authorization', `Bearer ${authSession.accessToken}`)
          options.headers.set('X-Request-Id', randomString(10))
        },
        async onFailure(error) {
          if (error instanceof YF.ResponseError) {
            const errorMeta = await settled<FetchErrorMeta>(error.response.json())
            if (errorMeta.status === 'rejected') {
              throw error
            }

            // try refresh token
            if (
              error.response.status === 401 &&
              errorMeta.value.errorCode === ErrorCode.UNAUTHORIZED
            ) {
              try {
                await queryClient.refetchQueries(
                  { queryKey: [CacheKey.AUTH], exact: true },
                  { throwOnError: true, cancelRefetch: false }
                )
              } catch (refetchError) {
                if (
                  refetchError instanceof YF.ResponseError ||
                  refetchError instanceof ApiError
                ) {
                  throw refetchError
                }

                /**
                 * Possible reasons:
                 * - No auth queries left mounted
                 * - Request has been cancelled
                 * - Internet connection has been dropped
                 *
                 * In either case we shouldn't retry.
                 **/
                throw error
              }

              return YF.request(error.response.options)
            }

            throw new ExtendedResponseError(error.response, errorMeta.value)
          }
          if (error instanceof Error && error.name === 'AbortError') {
            throw error
          }
          Sentry.captureException(error, { tags: { type: 'network_call' } })
          throw error
        },
      }),
    [fetch, queryClient]
  )
}
