import type { VariantProps } from '@stitches/react'
import * as Icons from '../../icons'
import { ButtonBase, HStack, Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { IconButton } from '../icon-button'
import { Separator } from '../separator'
import { Surface } from '../surface'
import { useImageViewerContext } from './context'

const ImageViewerToolbarSurface = styled(Surface, {
  isolation: 'isolate',
  variants: {
    position: {
      bottom: {
        placeSelf: 'end center',
      },
      topLeft: {
        placeSelf: 'start',
      },
    },
  },
})

interface ImageViewerToolbarProps extends VariantProps<typeof ImageViewerToolbarSurface> {
  downloadUrl: string
}

function ImageViewerToolbar({
  position = 'bottom',
  downloadUrl,
  ...rest
}: ImageViewerToolbarProps) {
  const { rotate, scale, zoomIn, zoomOut, resetScale } = useImageViewerContext()

  return (
    <ImageViewerToolbarSurface
      position={position}
      variant="toolbar"
      colorScheme="black"
      px={20}
      py={16}
      {...rest}
    >
      <HStack gap={20}>
        <HStack>
          <IconButton onClick={() => rotate('right')} color="contrast" surface="black">
            <Icons.S16.Rotate variant="right" />
          </IconButton>
          <IconButton onClick={() => rotate('left')} color="contrast" surface="black">
            <Icons.S16.Rotate variant="left" />
          </IconButton>
        </HStack>
        <Separator orientation="vertical" size={2} color="white-alpha-50" py={8} />
        <HStack>
          <IconButton onClick={() => zoomOut()} color="contrast" surface="black">
            <Icons.S16.Minus />
          </IconButton>
          <ButtonBase
            aria-label="Reset zoom"
            css={{
              minWidth: '61px',
              textAlign: 'center',
              borderRadius: theme.radii[8],
              paddingInline: theme.space[8],
              '&:hover': {
                backgroundColor: theme.colors['white-alpha-50'],
              },
              '&:active, &[aria-pressed="true"]': {
                backgroundColor: theme.colors['white-alpha-60'],
              },
            }}
            onClick={() => resetScale()}
          >
            <Text color="white" variant="label-16">
              {Math.round(scale * 100)}%
            </Text>
          </ButtonBase>
          <IconButton onClick={() => zoomIn()} color="contrast" surface="black">
            <Icons.S16.Plus />
          </IconButton>
        </HStack>
        <Separator orientation="vertical" size={2} color="white-alpha-50" py={8} />
        <IconButton as="a" href={downloadUrl} target="_blank" download surface="black">
          <Icons.S16.Download />
        </IconButton>
      </HStack>
    </ImageViewerToolbarSurface>
  )
}

export { ImageViewerToolbar as Toolbar }
