import { Dialog, IconButton } from 'salad/components'
import * as Icons from 'salad/icons'
import { Box, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'

interface SideDrawer {
  children?: React.ReactNode
}

export function SideDrawer({ children }: SideDrawer) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <IconButton color="contrast">
          <Icons.S24.Menu />
        </IconButton>
      </Dialog.Trigger>
      <Dialog.Content variant="sidebar">
        {(dialog) => (
          <VStack
            css={{
              width: 240,
              height: '100%',
              gridAutoRows: 'auto 1fr',
              backgroundColor: theme.colors['white'],
            }}
          >
            <Box px={12} pt={12}>
              <IconButton color="contrast" onClick={() => dialog.setOpen(false)}>
                <Icons.S24.Cross />
              </IconButton>
            </Box>
            {children}
          </VStack>
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
