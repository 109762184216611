import { useUserSettings } from 'api/hooks/user-settings'
import { useCurrentUser } from 'api/hooks/users'
import { Navigate, useLocation } from 'kitchen/router'
import { ErrorStatus } from '@app/components/error-status'
import { LoadingStatus } from '@app/components/loading-status'
import { Path } from '@app/constants'

interface RequireKYCProps {
  children?: React.ReactNode
}

export function RequireKYC({ children }: RequireKYCProps) {
  const currentUser = useCurrentUser()
  const userSettings = useUserSettings()
  const location = useLocation()

  if (currentUser.isLoading || userSettings.isLoading) {
    return <LoadingStatus layout="flow-page" />
  }

  if (currentUser.isError || userSettings.isError) {
    return <ErrorStatus.Root layout="flow-page" />
  }

  if (
    currentUser.data.firstName &&
    currentUser.data.lastName &&
    userSettings.data.userOnboarded
  ) {
    return <>{children}</>
  }

  return <Navigate to={Path.USER_ONBOARDING} state={{ from: location }} replace />
}
