import * as Time from './time'

export const DEFAULT = 'en-GB'

export const TODAY = new Date()
export const YESTERDAY = new Date(TODAY.getTime() - Time.DAY)
export const DAYS_IN_WEEK = 7
export const MONTHS_IN_YEAR = 12
export const START_DAY_OF_MONTH = 1
export const MAX_DAYS_IN_MONTH = 31

/** Ref: https://en.wikipedia.org/wiki/List_of_the_verified_oldest_people */
export const OLDEST_PERSON_YEAR = 1904
/** Ref: https://www.theguardian.com/society/voluntary/page/0,7896,615176,00.html */
export const OLDEST_CHARITY_YEAR = 597
export const APOCALYPSE = 2666
export const CURRENT_YEAR = TODAY.getFullYear()
export const NEXT_YEAR = CURRENT_YEAR + 1
