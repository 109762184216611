import type { Company } from 'api/types/companies'
import { usePrevious } from 'kitchen/hooks/use-previous'
import { useLocation } from 'kitchen/router'
import { ErrorStatus } from '../error-status'

interface ErrorBoundaryFallbackBaseProps {
  error: unknown
  resetError: () => void
  onBack?: () => void
  onRetry?: () => void
}

interface FlowErrorBoundaryFallbackProps extends ErrorBoundaryFallbackBaseProps {
  layout: 'flow-page'
}

interface HubErrorBoundaryFallbackProps extends ErrorBoundaryFallbackBaseProps {
  layout: 'hub-page'
  practice: Company | undefined
}

interface CompanyErrorBoundaryFallbackProps extends ErrorBoundaryFallbackBaseProps {
  layout: 'company-page'
  company: Company
}

export type ErrorBoundaryFallbackProps =
  | FlowErrorBoundaryFallbackProps
  | HubErrorBoundaryFallbackProps
  | CompanyErrorBoundaryFallbackProps

export function ErrorBoundaryFallback({
  error,
  resetError,
  onBack,
  onRetry,
  ...rest
}: ErrorBoundaryFallbackProps) {
  const location = useLocation()
  const previous = usePrevious(location)

  if (previous && location.key !== previous.key) {
    resetError()
  }

  return (
    <ErrorStatus.Root {...rest}>
      <ErrorStatus.Icon />
      <ErrorStatus.Title />
      {process.env.BUILD_MODE === 'development' && error instanceof Error ? (
        <ErrorStatus.Description>{error.toString()}</ErrorStatus.Description>
      ) : (
        <ErrorStatus.Description />
      )}
      {onBack ? (
        <ErrorStatus.Action
          onClick={() => {
            resetError()
            onBack()
          }}
        >
          Go back
        </ErrorStatus.Action>
      ) : (
        <ErrorStatus.Action
          onClick={() => {
            resetError()
            onRetry?.()
          }}
        />
      )}
    </ErrorStatus.Root>
  )
}
