import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import { HStack, VStack, Text, type TextProps } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'
import { RadioIndicator } from '../radio-indicator'

const RadioGroupBase = styled(RadixRadioGroup.Root, {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.space[8],
  variants: {
    size: {
      small: {
        '[data-radio-item]': {
          '&:hover:not([data-disabled])': {
            backgroundColor: theme.colors['grey-5'],
          },
          '& > div': {
            gap: theme.space[8],
            padding: theme.space[8],
          },
        },
        '[data-radio-title]': {
          ...typography['title-14'],
        },
        '[data-radio-subtitle]': {
          ...typography['paragraph-14'],
        },
      },
      medium: {
        '[data-radio-item]': {
          '&:hover:not([data-disabled], [data-state="checked"])': {
            backgroundColor: theme.colors['grey-5'],
          },
          '&[data-state="checked"]': {
            backgroundColor: theme.colors['light-blue-40'],
            '[data-radio-subtitle]': {
              color: theme.colors['black-alpha-80'],
            },
          },
          '& > div': {
            gap: theme.space[16],
            padding: theme.space[16],
          },
        },
        '[data-radio-title]': {
          ...typography['title-16'],
        },
        '[data-radio-subtitle]': {
          ...typography['paragraph-16'],
          color: theme.colors['grey-60'],
        },
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

export interface RadioGroupProps<Value extends string>
  extends React.ComponentProps<typeof RadioGroupBase> {
  value: Value | undefined
  onValueChange: (value: Value) => void
}

function RadioGroup<Value extends string>(props: RadioGroupProps<Value>) {
  return <RadioGroupBase {...props} />
}

const RadioGroupItemBase = styled(RadixRadioGroup.Item, {
  borderRadius: theme.radii[8],
  textAlign: 'start',
  transition: 'background-color 0.2s',
  '&:not([data-disabled])': {
    cursor: 'pointer',
  },
  '&[data-disabled]': {
    '[data-radio-title]': {
      color: theme.colors['black-alpha-30'],
    },
    '[data-radio-subtitle]': {
      color: theme.colors['black-alpha-60'],
    },
  },
})

interface RadioGroupItemProps<Value extends string>
  extends RadixRadioGroup.RadioGroupItemProps {
  value: Value
}

function RadioGroupItem<Value extends string>({
  children,
  ...rest
}: RadioGroupItemProps<Value>) {
  return (
    <RadioGroupItemBase data-radio-item {...rest}>
      <HStack css={{ alignItems: 'center' }}>
        <RadixRadioGroup.Indicator asChild forceMount>
          <RadioIndicator />
        </RadixRadioGroup.Indicator>
        <VStack>{children}</VStack>
      </HStack>
    </RadioGroupItemBase>
  )
}

function RadioGroupLabel({ css, ...rest }: TextProps) {
  return (
    <Text
      variant="label-13"
      color="dark-blue"
      css={{ ...css, display: 'block', paddingBlockEnd: theme.space[2] }}
      {...rest}
    />
  )
}

function RadioGroupTitle(props: TextProps) {
  return <Text color="black" data-radio-title {...props} />
}

function RadioGroupSubtitle({ css, ...rest }: TextProps) {
  return (
    <Text
      data-radio-subtitle
      css={{ ...css, display: 'block', paddingBlockStart: theme.space[2] }}
      {...rest}
    />
  )
}

export {
  RadioGroup as Root,
  RadioGroupItem as Item,
  RadioGroupLabel as Label,
  RadioGroupTitle as Title,
  RadioGroupSubtitle as Subtitle,
}
