import { styled, theme } from '../stitches'
import { typography } from '../variants'
import { Text } from './text'

export interface DiffTextProps extends React.ComponentProps<typeof DiffText> {}

export const DiffText = styled(Text, {
  ...typography['paragraph-16'],
  width: 'fit-content',
  '-webkit-print-color-adjust': 'exact',
  borderRadius: theme.space[4],
  paddingInline: theme.space[2],
  paddingBlock: '1px',
  boxDecorationBreak: 'clone',
  color: theme.colors.black,
  variants: {
    diff: {
      before: {
        backgroundColor: theme.colors['diff-remove-background'],
        color: theme.colors['diff-remove-text'],
        textDecorationLine: 'line-through',
      },
      after: {
        backgroundColor: theme.colors['diff-add-background'],
        color: theme.colors['diff-add-text'],
      },
      unchanged: {
        backgroundColor: theme.colors['grey-5'],
      },
    },
  },
})
