import { useUserSearch } from 'api/hooks/users'
import type { Company, CompanyId } from 'api/types/companies'
import { Controller, useForm, yupResolver } from 'kitchen/forms'
import { notPracticeCompany } from 'kitchen/utils/companies'
import { nonNullable, stopPropagation } from 'kitchen/utils/helpers'
import * as yup from 'kitchen/validations'
import { useDeferredValue, useEffect, useId } from 'react'
import {
  AnimateHeight,
  AnimateTransition,
  Button,
  Checkbox,
  Input,
  InputGroup,
  Label,
  NumberInput,
  Spinner,
} from 'salad/components'
import { HStack, VStack } from 'salad/primitives'
import { PhoneCountryCallingCodeSelectPopover } from '../../../phone/components'
import type { PhoneCountryCallingCode } from '../../../phone/types'
import type { FlowVariant, UserInfoStepValues } from './types'

/**
 * useUserSearch() but only triggered once email is valid.
 * Allows to avoid unnecessary loading states
 */
function useDeferredUserSearch(email: string, companyId: CompanyId) {
  const deferredEmail = useDeferredValue(email.trim())

  const userSearch = useUserSearch(
    { email: deferredEmail, companyId },
    {
      keepPreviousData: true,
      enabled: false,
    }
  )
  const refetchUser = userSearch.refetch

  useEffect(() => {
    if (schema.isValidSync({ email: deferredEmail })) {
      refetchUser()
    }
  }, [deferredEmail, refetchUser])

  return userSearch
}

const schema = yup.object({
  email: yup.email({ required: true }),
  phoneCountryCallingCode: yup
    .object()
    .nullable()
    .when(['phoneNumber'], {
      is: (phoneNumber: string) => {
        return phoneNumber && phoneNumber.length > 0
      },
      then: (schema) => schema.required('Please choose country calling code'),
      otherwise: (schema) => schema,
    }),
})

interface UserInfoStepProps {
  values: UserInfoStepValues
  onContinue: (values: UserInfoStepValues) => void
  onBack?: () => void
  contributorRole?: 'approver' | 'payer'
  company: Company
  phoneCountryCallingCodes: PhoneCountryCallingCode[]
  variant: FlowVariant
}

export function InviteByEmailForm({
  values,
  onContinue,
  onBack,
  company,
  phoneCountryCallingCodes,
  variant,
}: UserInfoStepProps) {
  const formId = useId()

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UserInfoStepValues>({
    resolver: yupResolver(schema),
    defaultValues: values,
  })

  const email = watch('email')
  const userSearch = useDeferredUserSearch(email, company.id)
  const isSearchingEmail = userSearch.isInitialLoading || userSearch.isRefetching

  useEffect(() => {
    if (userSearch.data && userSearch.data.user) {
      setValue('firstName', userSearch.data.user.firstName ?? '—')
      setValue('lastName', userSearch.data.user.lastName ?? '—')
      setValue('inviteeType', 'MEMBER')
    }
  }, [userSearch.data, setValue])

  return (
    <VStack
      gap={56}
      as="form"
      id={formId}
      onSubmit={stopPropagation(
        handleSubmit((values) => {
          onContinue(values)
        })
      )}
    >
      <VStack gap={24}>
        <VStack gap={16}>
          <InputGroup.Root>
            <InputGroup.Label>Email</InputGroup.Label>
            <Input {...register('email')} aria-invalid={errors.email !== undefined} />
            {isSearchingEmail && (
              <InputGroup.End>
                <Spinner size={16} color="black-alpha-30" />
              </InputGroup.End>
            )}
            <InputGroup.Message>{errors.email?.message}</InputGroup.Message>
          </InputGroup.Root>
          {variant === 'company-member' &&
            notPracticeCompany(company) &&
            userSearch.data?.user === null && (
              <AnimateHeight>
                <AnimateTransition>
                  <Controller
                    control={control}
                    name="inviteeType"
                    render={({ field }) => {
                      return (
                        <Checkbox.Root>
                          <Checkbox.Input
                            checked={field.value === 'ACCOUNTANT'}
                            onChange={(event) => {
                              if (event.target.checked) {
                                field.onChange('ACCOUNTANT')
                              } else {
                                field.onChange('MEMBER')
                              }
                            }}
                          />
                          <Checkbox.Label>
                            This person is an external Accountant/Bookkeeper
                          </Checkbox.Label>
                        </Checkbox.Root>
                      )
                    }}
                  />
                </AnimateTransition>
              </AnimateHeight>
            )}
        </VStack>
        <InputGroup.Root>
          <InputGroup.Label>First name</InputGroup.Label>
          <Input
            {...register('firstName')}
            disabled={isSearchingEmail || nonNullable(userSearch.data?.user)}
          />
        </InputGroup.Root>
        <InputGroup.Root>
          <InputGroup.Label>Last name</InputGroup.Label>
          <Input
            {...register('lastName')}
            disabled={isSearchingEmail || nonNullable(userSearch.data?.user)}
          />
        </InputGroup.Root>
        <VStack gap={8}>
          <Label>Phone number (optional)</Label>
          <HStack
            css={{ justifyContent: 'space-between', gridAutoColumns: '1fr 4fr' }}
            gap={24}
          >
            <Controller
              name="phoneCountryCallingCode"
              control={control}
              render={({ field, fieldState: { error } }) => {
                const hasErrors = error !== undefined

                return (
                  <InputGroup.Root>
                    <PhoneCountryCallingCodeSelectPopover
                      phoneCountryCallingCodes={phoneCountryCallingCodes}
                      value={field.value}
                      isInvalid={hasErrors}
                      onSelect={(value) => {
                        setValue('phoneCountryCallingCode', value, {
                          shouldValidate: true,
                        })
                      }}
                    />
                  </InputGroup.Root>
                )
              }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => {
                const hasErrors = error !== undefined

                return (
                  <InputGroup.Root>
                    <NumberInput {...field} aria-invalid={hasErrors} />
                  </InputGroup.Root>
                )
              }}
            />
          </HStack>
          <InputGroup.Message
            variant={errors.phoneCountryCallingCode ? 'negative' : 'neutral'}
          >
            {errors.phoneCountryCallingCode?.message || 'For WhatsApp invoice submission'}
          </InputGroup.Message>
        </VStack>
      </VStack>
      <HStack gap={8} css={{ gridAutoColumns: 'auto 1fr' }}>
        <Button.Root variant="minor" size="medium" hug onClick={onBack}>
          Cancel
        </Button.Root>
        <Button.Root variant="common" size="medium" type="submit" form={formId}>
          Continue
        </Button.Root>
      </HStack>
    </VStack>
  )
}
