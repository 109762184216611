import { useCurrentUser } from 'api/hooks/users'
import type { User } from 'api/types/users'
import { formatUserDetails } from 'domains/user/utils'
import { useCallback } from 'react'

export function useFormatUserName({ fallbackToEmail = true } = {}) {
  const currentUser = useCurrentUser()
  return useCallback(
    (user: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'> | undefined) =>
      formatUserDetails(user, {
        fallbackToEmail,
        isSamePerson: currentUser.data?.id === user?.id,
      }),
    [currentUser.data?.id, fallbackToEmail]
  )
}
