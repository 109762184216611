import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { CacheKey, Time } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import { usePublicFetch } from 'kitchen/hooks/use-public-fetch'
import type { QueryHookFactory, MutationHookFactory, FetchError } from 'kitchen/types'
import { useEffect } from 'react'
import { useAuthChannel, useUpdateAuthSession } from './hooks'
import {
  refreshAuthSession,
  revokeAuthSession,
  sendEmailCode,
  loginWithCode,
  loginWithLink,
  acceptInvite,
  getAuthMethod,
  loginWithPassword,
  loginWith2FA,
  setup2FA,
  setupPassword,
  setupCode,
  changePassword,
  confirmPasswordChange2Fa,
  verify2FA,
  turnOff2Fa,
  startPasswordRecovery,
  completePasswordRecovery,
  getForce2FaDetails,
} from './requests'
import type {
  AuthSession,
  SendEmailCodePayload,
  SendEmailCodeResponse,
  LoginWithCodePayload,
  LoginWithCodeResponse,
  LoginWithLinkPayload,
  LoginWithLinkResponse,
  AcceptInvitePayload,
  AcceptInviteResponse,
  AuthMethodPayload,
  AuthMethodResponse,
  LoginWithPasswordPayload,
  LoginWithPasswordResponse,
  LoginWith2FAPayload,
  LoginWith2FAResponse,
  SetupPasswordPayload,
  Setup2FAResponse,
  SetupCodeMethodPayload,
  ChangePasswordPayload,
  ConfirmPasswordChange2FaPayload,
  Verify2FAPayload,
  Verify2FAResponse,
  TurnOff2FaPayload,
  RecoveryPasswordStartPayload,
  RecoveryPasswordCompletePayload,
  AuthForce2FaStatusResponse,
  AuthForce2FaStatus,
} from './types'

export const useAuthSession: QueryHookFactory<void, AuthSession> = (options) => {
  const fetch = usePublicFetch()
  const queryClient = useQueryClient()
  const authChannel = useAuthChannel()

  useEffect(() => {
    const unsubscribe = authChannel.subscribe((message) => {
      if (message.type === 'refresh') {
        queryClient.setQueryData([CacheKey.AUTH], message.session)
      }
    })

    return () => unsubscribe()
  }, [queryClient, authChannel])

  return useQuery({
    queryKey: [CacheKey.AUTH],
    async queryFn() {
      const session = await refreshAuthSession(fetch)
      authChannel.post({ type: 'refresh', session })
      return session
    },
    ...options,
    staleTime: 15 * Time.MINUTE,
    cacheTime: 15 * Time.MINUTE,
  })
}

export const useRevokeAuthSession: MutationHookFactory<void, void> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const auth = queryClient.getQueryData<AuthSession>([CacheKey.AUTH])

      if (auth === undefined) {
        // Already logged out
        return
      }

      await revokeAuthSession(fetch)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.resetQueries()
        return options?.onSuccess?.(data, variables, context)
      },
    }
  )
}

export const useAuthMethod: MutationHookFactory<AuthMethodPayload, AuthMethodResponse> = (
  options
) => {
  const fetch = usePublicFetch()

  return useMutation((payload) => getAuthMethod(fetch, payload), options)
}

export const useSendEmailCode: MutationHookFactory<
  SendEmailCodePayload,
  SendEmailCodeResponse
> = (options) => {
  const fetch = usePublicFetch()

  return useMutation((payload) => sendEmailCode(fetch, payload), options)
}

export const useIsAuthenticated = () => {
  const authSession = useAuthSession()
  return authSession.data !== undefined
}

export const useLoginWithCode: MutationHookFactory<
  LoginWithCodePayload,
  LoginWithCodeResponse
> = (options) => {
  const fetch = usePublicFetch()
  const updateAuthSession = useUpdateAuthSession()

  return useMutation((payload) => loginWithCode(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await updateAuthSession(data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useLoginWithLink: MutationHookFactory<
  LoginWithLinkPayload,
  LoginWithLinkResponse
> = (options) => {
  const fetch = usePublicFetch()
  const updateAuthSession = useUpdateAuthSession()

  return useMutation((payload) => loginWithLink(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await updateAuthSession(data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useAcceptInvite: MutationHookFactory<
  AcceptInvitePayload,
  AcceptInviteResponse
> = (options) => {
  const fetch = usePublicFetch()
  const updateAuthSession = useUpdateAuthSession()

  return useMutation((payload) => acceptInvite(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await updateAuthSession(data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useLoginWithPassword: MutationHookFactory<
  LoginWithPasswordPayload,
  LoginWithPasswordResponse
> = (options) => {
  const fetch = usePublicFetch()
  const updateAuthSession = useUpdateAuthSession()

  return useMutation((payload) => loginWithPassword(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await updateAuthSession(data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useLoginWith2FA: MutationHookFactory<
  LoginWith2FAPayload,
  LoginWith2FAResponse
> = (options) => {
  const fetch = usePublicFetch()
  const updateAuthSession = useUpdateAuthSession()

  return useMutation((payload) => loginWith2FA(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await updateAuthSession(data)
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useStartPasswordRecovery: MutationHookFactory<
  RecoveryPasswordStartPayload,
  void
> = (options) => {
  const fetch = usePublicFetch()
  return useMutation((payload) => startPasswordRecovery(fetch, payload), options)
}

export const useCompletePasswordRecovery: MutationHookFactory<
  RecoveryPasswordCompletePayload,
  void
> = (options) => {
  const fetch = usePublicFetch()
  return useMutation((payload) => completePasswordRecovery(fetch, payload), options)
}

export const useSetupPassword: MutationHookFactory<SetupPasswordPayload, void> = (
  options
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => setupPassword(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useChangePassword: MutationHookFactory<ChangePasswordPayload, void> = (
  options
) => {
  const fetch = useFetch()
  return useMutation((payload) => changePassword(fetch, payload), options)
}

export const useConfirmPasswordChange: MutationHookFactory<
  ConfirmPasswordChange2FaPayload,
  void
> = (options) => {
  const fetch = useFetch()
  return useMutation((payload) => confirmPasswordChange2Fa(fetch, payload), options)
}

export const useSetupCode: MutationHookFactory<SetupCodeMethodPayload, void> = (
  options
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => setupCode(fetch, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useSetup2FA: QueryHookFactory<void, Setup2FAResponse> = () => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.AUTH_ENROLLMENT_INFO],
    queryFn: () => setup2FA(fetch),
  })
}

export const useForce2FaDetails = () => {
  const fetch = useFetch()

  return useQuery<AuthForce2FaStatusResponse, FetchError, AuthForce2FaStatus>(
    [CacheKey.AUTH, CacheKey.FORCE_2FA_DETAILS],
    () => getForce2FaDetails(fetch),
    {
      select: (data) =>
        data.companiesWithEnforced2fa.length > 0
          ? {
              initiator: data.companiesWithEnforced2fa[0].enforcedBy,
              is2FaEnforced: true,
            }
          : {
              initiator: undefined,
              is2FaEnforced: false,
            },
    }
  )
}

export const useVerify2FA: MutationHookFactory<Verify2FAPayload, Verify2FAResponse> = (
  options
) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => verify2FA(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
      await queryClient.invalidateQueries([CacheKey.AUTH])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}

export const useTurnOff2Fa: MutationHookFactory<TurnOff2FaPayload, void> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => turnOff2Fa(fetch, payload), {
    ...options,
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries([CacheKey.CURRENT_USER])
      return options?.onSuccess?.(data, variables, context)
    },
  })
}
