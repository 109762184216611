import type { GenericComponent } from 'kitchen/types'
import { Logo, type LogoProps } from '../../components'
import { Box, Grid, type GridProps, type BoxProps } from '../../primitives'
import { theme } from '../../stitches'

export interface StatusLayoutProps extends GridProps {}

const StatusLayout = ({ css, ...rest }: StatusLayoutProps) => (
  <Grid
    css={{
      padding: theme.space[16],
      height: 'calc(100% - var(--app-header-offset, 0px))',
      justifyItems: 'center',
      justifyContent: 'center',
      gridTemplate: `
        "header header  header" 1fr
        ".      content .     " 1fr
        ".      content .     " 1fr
        ".      .       .     " 1fr
        ".      footer  .     " 1fr / 1fr minmax(0px, 480px) 1fr
      `,
      ...css,
    }}
    {...rest}
  />
)

function getLogoSpace(variant: LogoProps['variant']) {
  switch (variant) {
    case 'small':
      return 24
    case 'full':
    default:
      return 32
  }
}

export interface StatusLayoutLogoProps extends BoxProps, Pick<LogoProps, 'variant'> {}

const StatusLayoutLogo: GenericComponent<StatusLayoutLogoProps> = ({
  variant,
  css,
  ...rest
}: StatusLayoutLogoProps) => (
  <Box
    pt={getLogoSpace(variant)}
    px={getLogoSpace(variant)}
    css={{ gridArea: 'header', justifySelf: 'start', ...css }}
    {...rest}
  >
    <Logo variant={variant} />
  </Box>
)

export interface StatusLayoutContentProps {
  children?: React.ReactNode
}

const StatusLayoutContent = ({ children }: StatusLayoutContentProps) => (
  <Box css={{ gridArea: 'content', alignSelf: 'safe center' }}>{children}</Box>
)

export interface StatusLayoutFooterProps {
  children?: React.ReactNode
}

const StatusLayoutFooter = ({ children }: StatusLayoutFooterProps) => (
  <Box css={{ gridArea: 'footer', alignSelf: 'center' }}>{children}</Box>
)

export {
  StatusLayout as Root,
  StatusLayoutLogo as Logo,
  StatusLayoutContent as Content,
  StatusLayoutFooter as Footer,
}
