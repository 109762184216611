import {
  getNextCalendarMonth,
  getNumberOfDaysInMonth,
  getPreviousCalendarMonth,
  getWeekday,
  setDate,
} from 'kitchen/utils/date-time'
import { useMemo } from 'react'
import type { Day, DayMeta } from './types'

function createDays(year: number, month: number, meta: DayMeta): Day[] {
  const daysNumber = getNumberOfDaysInMonth(year, month)
  return [...Array(daysNumber)].map((_, index) => {
    const date = setDate({ year, month, day: index })
    return {
      date: setDate({ year, month, day: index }),
      dayOfMonth: date.format('D'),
      meta,
    }
  })
}

function getPrevMonthDisplayNumber(currentMonthDays: Day[]): number {
  const currentMonthFirstDayWeekday = getWeekday(currentMonthDays[0].date)
  return currentMonthFirstDayWeekday
}

function getNextMonthDisplayNumber(currentMonthDays: Day[]): number {
  const lastDay = currentMonthDays[currentMonthDays.length - 1]
  const lastDayWeekday = getWeekday(lastDay.date) + 1
  return 7 - lastDayWeekday
}

interface UseCalendarDays {
  currentYear: number
  /** month number starts from 0 */
  currentMonth: number
}
export const useCalendarDays = ({ currentYear, currentMonth }: UseCalendarDays) => {
  const previousMonth = useMemo(
    () => getPreviousCalendarMonth(currentYear, currentMonth),
    [currentYear, currentMonth]
  )
  const nextMonth = useMemo(
    () => getNextCalendarMonth(currentYear, currentMonth),
    [currentYear, currentMonth]
  )

  const currentMonthDays = useMemo(
    () => createDays(currentYear, currentMonth, { isCurrentMonth: true }),
    [currentYear, currentMonth]
  )
  const previousMonthDays = useMemo(
    () =>
      createDays(previousMonth.year, previousMonth.month, {
        isPreviousMonth: true,
      }),
    [previousMonth]
  )

  const nextMonthDays = useMemo(
    () =>
      createDays(nextMonth.year, nextMonth.month, {
        isNextMonth: true,
      }),
    [nextMonth]
  )
  const prevMonthDisplayDays = useMemo(
    () => getPrevMonthDisplayNumber(currentMonthDays),
    [currentYear, currentMonth]
  )
  const nextMonthDisplayDays = useMemo(
    () => getNextMonthDisplayNumber(currentMonthDays),
    [currentYear, currentMonth]
  )

  return {
    previousMonth,
    nextMonth,
    monthDays: {
      previousMonth: previousMonthDays,
      currentMonth: currentMonthDays,
      nextMonth: nextMonthDays,
    },
    display: {
      previousMonth:
        prevMonthDisplayDays > 0 ? previousMonthDays.slice(-prevMonthDisplayDays) : [],
      currentMonth: currentMonthDays,
      nextMonth:
        nextMonthDisplayDays > 0 ? nextMonthDays.slice(0, nextMonthDisplayDays) : [],
    },
  }
}
