import { Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { typography } from '../../variants'

export const Counter = styled(Text, {
  display: 'grid',
  borderRadius: theme.radii.round,
  width: 'fit-content',
  placeItems: 'center',
  flexShrink: 0,
  variants: {
    variant: {
      default: {
        backgroundColor: theme.colors['grey-10'],
      },
      filter: {
        backgroundColor: theme.colors['white-alpha-80'],
      },
    },
    size: {
      small: {
        height: '20px',
        minWidth: '20px',
        paddingInline: theme.space[4],
        ...typography['title-14'],
      },
      medium: {
        height: '24px',
        minWidth: '24px',
        paddingInline: theme.space[6],
        ...typography['title-16'],
      },
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'small',
  },
})
