import type { CountryCode } from 'api/types/address'
import { useFormatCountryCode } from 'kitchen/hooks/use-format-country-code'
import { useLocale } from 'kitchen/hooks/use-locale'
import { alphabeticSort, groupBy } from 'kitchen/utils/data'
import { cdn } from 'kitchen/utils/helpers'
import { matchSorter } from 'match-sorter'
import { Fragment, useState } from 'react'
import { VStack, Image } from '../../primitives'
import { theme } from '../../stitches'
import * as Item from '../item'
import { Label } from '../label'
import { SearchInput } from '../search-input'
import * as Select from '../select'

interface Option {
  label: string
  image: string
  value: CountryCode
}

interface CountryOptionProps {
  option: Option
}

function CountryOption({ option }: CountryOptionProps) {
  return (
    <Select.Option value={option.value}>
      <Item.Root>
        <Item.Start>
          <Image src={option.image} width={24} height={24} alt="" />
        </Item.Start>
        <Item.Content css={{ paddingInlineStart: theme.space[4] }}>
          {option.label}
        </Item.Content>
      </Item.Root>
    </Select.Option>
  )
}

interface CountryOptionGroupsProps {
  options: Option[]
}

function CountryOptionGroups({ options }: CountryOptionGroupsProps) {
  const locale = useLocale()
  const groups = groupBy(options, (option) => option.label.charAt(0), locale)
  return (
    <>
      {Object.keys(groups)
        .sort()
        .map((groupName) => {
          return (
            <Fragment key={groupName}>
              <VStack px={12}>
                <Label size={13}>{groupName}</Label>
              </VStack>
              <VStack gap={2}>
                {groups[groupName]
                  .slice()
                  .sort((a, b) => alphabeticSort(a.label, b.label, locale))
                  .map((option) => (
                    <CountryOption key={option.value} option={option} />
                  ))}
              </VStack>
            </Fragment>
          )
        })}
    </>
  )
}

interface BaseCountrySelectProps {
  options: CountryCode[]
  suggested?: CountryCode[]
}

export type CountrySelectProps = Select.SelectProps<CountryCode> & BaseCountrySelectProps

export function CountrySelect({
  suggested: suggestedSource = [],
  options,
  ...rest
}: CountrySelectProps) {
  const formatCountryCode = useFormatCountryCode()
  const [search, setSearch] = useState<string>('')

  const formatted: Option[] = options.map((code) => ({
    label: formatCountryCode(code) ?? code,
    image: cdn(`/flags/${code.toLowerCase()}.svg`),
    value: code,
  }))

  const suggested = formatted.filter(({ value }) => suggestedSource.includes(value))
  const filtered = matchSorter(formatted, search, { keys: ['label', 'value'] })

  return (
    <Select.Root onClose={() => setSearch('')} {...rest}>
      <VStack gap={12} p={16}>
        <VStack px={8}>
          <SearchInput
            placeholder="Search country..."
            value={search}
            onValueChange={setSearch}
          />
        </VStack>
        {search !== '' ? (
          <VStack gap={2}>
            {filtered.map((option) => (
              <CountryOption key={option.value} option={option} />
            ))}
          </VStack>
        ) : (
          <>
            <VStack gap={2}>
              {suggested.map((option) => (
                <CountryOption key={option.value} option={option} />
              ))}
            </VStack>
            <CountryOptionGroups options={formatted} />
          </>
        )}
      </VStack>
    </Select.Root>
  )
}
