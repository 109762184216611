import { InputButton, InputGroup, Item, Popover, Select, Surface } from 'salad/components'
import * as Icons from 'salad/icons'
import { VStack } from 'salad/primitives'
import type { PresetType } from './types'
import { getPresetTypeLabel } from './utils'

interface PresetTypeSelectorProps {
  value: PresetType
  onValueChange: (value: PresetType) => void
  disabled?: boolean
}

export function PresetTypeSelector({
  value,
  onValueChange,
  disabled,
}: PresetTypeSelectorProps) {
  return (
    <InputGroup.Root>
      <InputGroup.Label>Role type</InputGroup.Label>
      <Popover.Root>
        <Popover.Trigger asChild>
          {(trigger) => (
            <InputButton.Root disabled={disabled}>
              <InputButton.Content>{getPresetTypeLabel(value)}</InputButton.Content>
              {!disabled && (
                <InputButton.End>
                  <Icons.S16.Dropdown variant={trigger.isOpen ? 'pressed' : 'default'} />
                </InputButton.End>
              )}
            </InputButton.Root>
          )}
        </Popover.Trigger>
        <Popover.Content css={{ width: Popover.triggerWidth }}>
          <Surface variant="popover" p={16}>
            <Select.Root
              aria-label="Select role preset"
              value={value}
              onValueChange={(value) => {
                if (value === null) {
                  return
                }
                return onValueChange(value)
              }}
            >
              <VStack gap={2}>
                <Select.Option value="admin">
                  <Item.Root>
                    <Item.Content>{getPresetTypeLabel('admin')}</Item.Content>
                  </Item.Root>
                </Select.Option>
                <Select.Option value="standard">
                  <Item.Root>
                    <Item.Content>{getPresetTypeLabel('standard')}</Item.Content>
                  </Item.Root>
                </Select.Option>
              </VStack>
            </Select.Root>
          </Surface>
        </Popover.Content>
      </Popover.Root>
    </InputGroup.Root>
  )
}
