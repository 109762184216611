import { useRifm } from 'rifm'
import * as formats from '../utils/formats'

interface UseDecimalNumberRifmOptions
  extends Omit<Parameters<typeof useRifm>[0], 'format'> {}

export const useDecimalNumberRifm = (options: UseDecimalNumberRifmOptions) =>
  useRifm({
    ...options,
    format: formats.decimalNumber,
    accept: /[\d.]+/g,
  })
