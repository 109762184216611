const TRANSFORM = {
  left: 'scale(-1, 1)',
  right: 'scale(1, 1)',
} as const

interface RotateProps {
  variant: 'right' | 'left'
}

export function Rotate({ variant }: RotateProps) {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ transform: `${TRANSFORM[variant]}` }}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m9.46.3 3 3 .7.7-.7.7-3 3-1.42-1.4L9.34 5H8a4 4 0 1 0 4 4h2a6 6 0 1 1-6-6h1.34l-1.3-1.3L9.46.3Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
