import { useRequiredContext } from 'kitchen/hooks/use-required-context'
import { createContext } from 'react'
import type { Option } from './types'

interface ContactSelectContextAPI {
  options: Option[]
}

export const ContactSelectContext = createContext<ContactSelectContextAPI | null>(null)

export function useContactSelectContext() {
  return useRequiredContext('ContactSelectContext', ContactSelectContext)
}
