import type { PathPattern } from 'react-router'
import { useLocation, matchPath } from 'react-router-dom'

export const matchesPatterns = (patterns: PathPattern[], path: string) =>
  patterns.some((pattern) => matchPath(pattern, path) !== null)

export const useMatchesPatterns = (patterns: PathPattern[]) =>
  matchesPatterns(patterns, useLocation().pathname)

interface MatchPatternsProps {
  patterns: PathPattern[]
  children: (matches: boolean) => React.ReactNode
}

export const MatchesPatterns = ({ patterns, children }: MatchPatternsProps) =>
  children(useMatchesPatterns(patterns))
