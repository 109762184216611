export const Members = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.375 13.5C12.4816 13.5 15 10.9816 15 7.875C15 4.7684 12.4816 2.25 9.375 2.25C6.2684 2.25 3.75 4.7684 3.75 7.875C3.75 10.9816 6.2684 13.5 9.375 13.5ZM9.375 16.375C6.40647 16.375 4 18.7815 4 21.75H2C2 17.6769 5.3019 14.375 9.375 14.375C13.4481 14.375 16.75 17.6769 16.75 21.75H14.75C14.75 18.7815 12.3435 16.375 9.375 16.375ZM12.9375 7.875C12.9375 9.84251 11.3425 11.4375 9.375 11.4375C7.40749 11.4375 5.8125 9.84251 5.8125 7.875C5.8125 5.90749 7.40749 4.3125 9.375 4.3125C11.3425 4.3125 12.9375 5.90749 12.9375 7.875ZM16.0886 14.2052C18.8583 13.8545 21 11.4899 21 8.625C21 6.33694 19.6339 4.36794 17.6728 3.4892L16.6796 5.24186C18.0371 5.76566 19 7.08287 19 8.625C19 10.5287 17.5326 12.0896 15.6672 12.2384L16.0886 14.2052ZM20.7493 21.7498C20.7493 19.4075 19.5598 17.4855 17.9167 16.5729L19.0071 14.8827C21.2634 16.1975 22.7493 18.8307 22.7493 21.7498H20.7493Z"
      fill="currentColor"
    />
  </svg>
)
