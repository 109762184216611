import { useIsStuck, type StickySide } from 'kitchen/hooks/use-is-stuck'
import { useRef, forwardRef } from 'react'
import { ZStack, Grid } from '../../primitives'
import { styled, theme } from '../../stitches'
import type { Space } from '../../tokens'
import { Spinner } from '../spinner'

const SEPARATOR_STYLE = {
  content: `''`,
  display: 'block' as const,
  borderBottomWidth: 'var(--table-row-separator)',
  borderBottomStyle: 'solid' as const,
  borderBottomColor: theme.colors['grey-10'],
}

function getNormalizedHeight(input?: number | 'auto') {
  if (input === 'auto' || input === undefined || input <= 0) {
    return input
  }

  return `calc(${input}px - var(--table-row-separator, 0px))`
}

interface TableBaseProps extends React.ComponentProps<typeof TableBase> {}

const TableBase = styled('table', {
  tableLayout: 'fixed',
  border: 'none',
  borderSpacing: 0,
  backgroundColor: theme.colors['white'],
  width: '100%',
})

export interface TableProps extends TableBaseProps {
  space?: Space
  minHeight?: number
  minWidth?: number
  translateY?: number
  updating?: boolean
}

function Table({
  space = 16,
  minHeight,
  minWidth,
  translateY,
  updating,
  style,
  ...rest
}: TableProps) {
  return (
    <div style={{ minHeight, minWidth: minWidth ?? '100%' }} data-table-sticky-root>
      <ZStack>
        <TableBase
          data-updating={updating || undefined}
          style={{
            transform: translateY ? `translateY(${translateY}px)` : undefined,
            ...style,
          }}
          css={{
            '--table-row-space': theme.space[space],
          }}
          {...rest}
        />
        {updating && (
          <Grid
            css={{
              position: 'sticky',
              placeItems: 'center',
              justifySelf: 'start',
              alignSelf: 'end',
              left: 'calc(50% - 12px)',
              bottom: 'calc(50% - 24px)',
            }}
          >
            <Spinner size={24} color="black-alpha-60" />
          </Grid>
        )}
      </ZStack>
    </div>
  )
}

export interface TableHeadProps extends React.ComponentProps<typeof TableHead> {}

const TableHead = styled('thead', {
  backgroundColor: theme.colors['white'],
  'tr:first-of-type th': {
    paddingTop: theme.space[8],
  },
  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
      },
    },
  },
})

export interface TableBodyProps extends React.ComponentProps<typeof TableBody> {}

const TableBody = styled('tbody', {
  transition: 'opacity 0.3s',
  '[data-updating] &': { opacity: 0.3 },
})

export interface TableFootProps extends React.ComponentProps<typeof TableFoot> {}

const TableFoot = styled('tfoot')

export interface TableRowProps extends React.ComponentProps<typeof TableRow> {}

const TableRow = styled('tr', {
  clipPath: 'inset(0px -16px)',
  variants: {
    separator: {
      top: {
        '--table-row-separator': '1px',
        'td, th': { verticalAlign: 'top' },
        'td::before, th::before': SEPARATOR_STYLE,
      },
      bottom: {
        '--table-row-separator': '1px',
        'td, th': { verticalAlign: 'bottom' },
        'td::after, th::after': SEPARATOR_STYLE,
      },
      around: {
        '--table-row-separator': '1px',
        'td, th': { verticalAlign: 'top' },
        'td::before, th::before': SEPARATOR_STYLE,
        'td::after, th::after': SEPARATOR_STYLE,
      },
      between: {
        'td, th': { verticalAlign: 'top' },
        '& + &': { '--table-row-separator': '1px' },
        '& + & td::before, & + & th::before': SEPARATOR_STYLE,
      },
    },
  },
})

const TableCellContainer = styled('div', {
  display: 'grid',
  alignContent: 'center',
  '[data-sticky-side="left"] > &': {
    paddingRight: theme.space[8],
  },
  '[data-sticky-side="right"] > &': {
    paddingLeft: theme.space[8],
  },
})

interface TableCellBaseProps extends React.ComponentProps<typeof TableCellBase> {}

const TableCellBase = styled('td', {
  textAlign: 'start',
  padding: 0,
  backgroundColor: theme.colors['white'],
  '&[data-sticky]': {
    position: 'sticky',
    zIndex: 1,
    '--sticky-shadow': `calc(4px * var(--shadow-direction) * -1) 0px 4px 0px ${theme.colors['elevation-100-shadow']}`,
    '--offset-shadow': `calc(var(--shadow-offset) * var(--shadow-direction)) 0 0 ${theme.colors['white']}`,
    boxShadow: 'var(--offset-shadow)',
    '&[data-is-stuck]': {
      boxShadow: 'var(--sticky-shadow), var(--offset-shadow)',
    },
  },
  '&[data-sticky-side="right"]': {
    right: 0,
    '--shadow-direction': 1,
  },
  '&[data-sticky-side="left"]': {
    left: 0,
    '--shadow-direction': -1,
  },
  '&:first-child': {
    paddingLeft: 'var(--table-row-space)',
  },
  '&:last-child': {
    paddingRight: 'var(--table-row-space)',
  },
  variants: {
    interactive: {
      true: {
        'tr &': {
          cursor: 'pointer',
        },
        'tr:hover &': {
          backgroundColor: theme.colors['grey-5'],
          '&:first-child': {
            borderTopLeftRadius: theme.radii[8],
            borderBottomLeftRadius: theme.radii[8],
          },
          '&:last-child': {
            borderTopRightRadius: theme.radii[8],
            borderBottomRightRadius: theme.radii[8],
          },
        },
      },
    },
    selected: {
      true: {
        'tr &': {
          backgroundColor: theme.colors['light-blue-20'],
          '&:first-child': {
            borderTopLeftRadius: theme.radii[8],
            borderBottomLeftRadius: theme.radii[8],
          },
          '&:last-child': {
            borderTopRightRadius: theme.radii[8],
            borderBottomRightRadius: theme.radii[8],
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      interactive: true,
      selected: true,
      css: {
        'tr:hover &': {
          backgroundColor: theme.colors['light-blue-20'],
        },
      },
    },
  ],
})

export interface TableHeaderProps extends TableCellBaseProps {
  sticky?: Exclude<StickySide, 'top' | 'bottom'>
  height?: number | 'auto'
  offset?: number
}

function TableHeader({
  sticky = 'none',
  offset = 0,
  height,
  children,
  ...rest
}: TableHeaderProps) {
  const ref = useRef<HTMLTableCellElement | null>(null)
  const isStuck = useIsStuck(ref, sticky, offset, '[data-table-sticky-root]')
  return (
    <TableCellBase
      as="th"
      ref={ref}
      data-sticky={sticky !== 'none' || undefined}
      data-sticky-side={sticky}
      data-is-stuck={isStuck || undefined}
      data-interactive={rest.interactive}
      css={{ whiteSpace: 'nowrap', '--shadow-offset': `${offset}px` }}
      {...rest}
    >
      <TableCellContainer style={{ height: getNormalizedHeight(height) }}>
        {children}
      </TableCellContainer>
    </TableCellBase>
  )
}

export interface TableCellProps extends TableCellBaseProps {
  sticky?: StickySide
  height?: number | 'auto'
  minHeight?: number
  offset?: number
}

function TableCell({
  sticky = 'none',
  offset = 0,
  height,
  minHeight,
  children,
  ...rest
}: TableCellProps) {
  const ref = useRef<HTMLTableCellElement | null>(null)
  const isStuck = useIsStuck(ref, sticky, offset, '[data-table-sticky-root]')
  return (
    <TableCellBase
      as="td"
      ref={ref}
      data-sticky={sticky !== 'none' || undefined}
      data-sticky-side={sticky}
      data-is-stuck={isStuck || undefined}
      data-interactive={rest.interactive}
      data-selected={rest.selected}
      css={{ '--shadow-offset': `${offset}px` }}
      {...rest}
    >
      <TableCellContainer
        style={{
          height: getNormalizedHeight(height),
          minHeight: getNormalizedHeight(minHeight),
        }}
      >
        {children}
      </TableCellContainer>
    </TableCellBase>
  )
}

export interface TableSpacerProps {
  size: number
}

function TableSpacer({ size }: TableSpacerProps) {
  if (size === 0) {
    return null
  }

  return (
    <tr>
      <td style={{ height: size }} />
    </tr>
  )
}

export interface TableSpinnerProps {
  colSpan?: number
  height?: number | 'auto'
}

const TableSpinner = forwardRef<HTMLTableRowElement, TableSpinnerProps>(
  function TableSpinner({ colSpan, height }, forwardedRef) {
    return (
      <TableRow ref={forwardedRef}>
        <TableCellBase colSpan={colSpan}>
          <TableCellContainer style={{ height }}>
            <Spinner
              size={24}
              color="black-alpha-30"
              css={{
                position: 'sticky',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          </TableCellContainer>
        </TableCellBase>
      </TableRow>
    )
  }
)

export {
  Table as Root,
  TableHead as Head,
  TableBody as Body,
  TableFoot as Foot,
  TableRow as Row,
  TableHeader as Header,
  TableCell as Cell,
  TableSpacer as Spacer,
  TableSpinner as Spinner,
}
