const literal = {
  champ: '"Champ", Arial, sans-serif',
  dmsans: '"DM Sans", sans-serif',
  dmmono: '"DM Mono", monospace',
} as const

const semantic = {
  'price-tag-large-main': literal.champ,
  'price-tag-large-support': literal.champ,
  'price-tag-medium-main': literal.champ,
  'price-tag-medium-support': literal.champ,
  'price-tag-small-main': literal.champ,
  'price-tag-small-support': literal.champ,
  'headline-h1': literal.champ,
  'headline-h2': literal.dmsans,
  'headline-h3': literal.dmsans,
  'title-16': literal.dmsans,
  'title-14': literal.dmsans,
  'paragraph-20': literal.dmsans,
  'paragraph-16': literal.dmsans,
  'paragraph-14': literal.dmsans,
  'paragraph-12': literal.dmsans,
  'label-16': literal.dmsans,
  'label-13': literal.dmsans,
  'button-large': literal.champ,
  avatar: literal.dmsans,
}

export const fonts = {
  ...literal,
  ...semantic,
} as const

export type Font = keyof typeof fonts
