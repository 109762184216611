import { createDashedBorderDataSVG } from 'kitchen/utils/styles'
import { radii } from '../base'
import { colors } from './colors'

export const backgrounds = {
  'dropzone-border': `url('${createDashedBorderDataSVG(colors['dark-blue'], radii[8])}')`,
  'add-card-button-border': `url('${createDashedBorderDataSVG(
    colors['grey-15'],
    radii[16]
  )}')`,
  'add-card-button-border-disabled': `url('${createDashedBorderDataSVG(
    colors['grey-10'],
    radii[16]
  )}')`,
  'surface-ghost-border': `url('${createDashedBorderDataSVG(
    colors['surface-ghost-border'],
    radii[16]
  )}')`,
} as const

export type Background = keyof typeof backgrounds
