export const Upload = () => {
  return (
    <svg fill="none" width="64" height="64" viewBox="0 0 64 64">
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M32 56V28"
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2.5"
        d="m40 36-8-8-8 8"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23.377 18.539c2.114-4.88 6.972-8.289 12.623-8.289 7.594 0 13.75 6.156 13.75 13.75 0 .645-.044 1.28-.13 1.9a1.25 1.25 0 0 0 1.395 1.412A7.75 7.75 0 1 1 52 42.75H37v2.5h15c5.66 0 10.25-4.59 10.25-10.25 0-5.583-4.464-10.124-10.017-10.247.011-.25.017-.5.017-.753 0-8.975-7.275-16.25-16.25-16.25-6.094 0-11.402 3.354-14.183 8.313a7.75 7.75 0 0 0-12.034 7.154C5.137 24.601 1.75 28.904 1.75 34c0 6.213 5.037 11.25 11.25 11.25h14v-2.5H13a8.75 8.75 0 0 1-1.565-17.36 1.25 1.25 0 0 0 .987-1.55 5.25 5.25 0 0 1 8.898-4.942 1.25 1.25 0 0 0 2.057-.36Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
