import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type { FetchError, UseMutationOptions, UseQueryOptions } from 'kitchen/types'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { getRequiredUserSettings } from 'kitchen/utils/users'
import { getUserSettings, updateUserSettings } from '../requests/user-settings'
import type { UserSettings } from '../types/user-settings'

export interface UserSettingsOptions<Result = UserSettings>
  extends UseQueryOptions<UserSettings, Result> {}

export const useUserSettings = <Result = UserSettings>(
  options?: UserSettingsOptions<Result>
) => {
  const fetch = useFetch()
  return useQuery<UserSettings, FetchError, Result>(
    [CacheKey.USER_SETTINGS],
    ({ signal }) => getUserSettings(fetch, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export interface UpdateUserSettingsOptions
  extends UseMutationOptions<Partial<UserSettings>, void> {}

export const useUpdateUserSettings = (options?: UpdateUserSettingsOptions) => {
  const queryClient = useQueryClient()
  const fetch = useFetch()
  return useMutation((payload) => updateUserSettings(fetch, payload), {
    ...options,
    onMutate: (next) => {
      queryClient.setQueriesData<UserSettings>([CacheKey.USER_SETTINGS], (prev) =>
        getRequiredUserSettings({ ...prev, ...next })
      )
    },
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context)
      await queryClient.invalidateQueries([CacheKey.USER_SETTINGS], {
        refetchType: 'all',
      })
    },
  })
}
