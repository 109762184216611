import { InputButton, Surface, Popover } from 'salad/components'
import * as Icons from 'salad/icons'
import type { PhoneCountryCallingCode } from '../types'
import { formatCountryCallingCode } from '../utils'
import { PhoneCountryCallingCodeSelect } from './phone-country-calling-code-select'

interface PhoneCountryCallingCodeSelectPopoverProps {
  phoneCountryCallingCodes: PhoneCountryCallingCode[]
  size?: number
  value: PhoneCountryCallingCode | null
  onSelect: (value: PhoneCountryCallingCode | null) => void
  isDisabled?: boolean
  isRequired?: boolean
  isInvalid?: boolean
}

export function PhoneCountryCallingCodeSelectPopover({
  phoneCountryCallingCodes,
  size = 450,
  value,
  onSelect,
  isDisabled,
  isRequired,
  isInvalid,
}: PhoneCountryCallingCodeSelectPopoverProps) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild disabled={isDisabled}>
        {(trigger) => {
          if (value) {
            return (
              <InputButton.Root aria-invalid={isInvalid} aria-required={isRequired}>
                <InputButton.Content>
                  {formatCountryCallingCode(value.callingCode)}
                </InputButton.Content>
                <InputButton.End>
                  {!isDisabled && (
                    <Icons.S16.Dropdown
                      variant={trigger.isOpen ? 'pressed' : 'default'}
                    />
                  )}
                </InputButton.End>
              </InputButton.Root>
            )
          }

          return (
            <InputButton.Root aria-invalid={isInvalid} aria-required={isRequired}>
              <InputButton.Placeholder />
              {!isDisabled && (
                <InputButton.End>
                  <Icons.S16.Dropdown variant={trigger.isOpen ? 'pressed' : 'default'} />
                </InputButton.End>
              )}
            </InputButton.Root>
          )
        }}
      </Popover.Trigger>
      <Popover.Content align="start" size={size}>
        <Surface variant="popover">
          <PhoneCountryCallingCodeSelect
            suggested={['GB']}
            options={phoneCountryCallingCodes}
            value={value?.id || null}
            onSelect={(codeId) => {
              const code = phoneCountryCallingCodes.find((code) => code.id === codeId)

              onSelect(code || null)
            }}
          />
        </Surface>
      </Popover.Content>
    </Popover.Root>
  )
}
