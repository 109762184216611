import type { TextProps } from '../../primitives'
import { Text } from '../../primitives'
import { styled, theme } from '../../stitches'
import { textColor, typography } from '../../variants'

export interface ItemProps extends React.ComponentProps<typeof Item> {}

const Item = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: 0,
  borderRadius: theme.space[8],
  color: theme.colors['black'],
  textAlign: 'start',
  transitionDuration: '0.3s',
  transitionProperty: 'background-color, border-color',
  ':where(& [data-item-color-scheme="grey"])': {
    color: theme.colors['grey-40'],
  },
  '&:hover:not([aria-disabled="true"], [aria-pressed="true"]):is(:enabled, :any-link)': {
    backgroundColor: theme.colors['grey-5'],
  },
  '&:active:not([aria-disabled="true"]):is(:enabled, :any-link), &[aria-pressed="true"]':
    {
      backgroundColor: theme.colors['grey-10'],
    },
  '&[aria-current="page"] [data-item-content]': {
    fontWeight: theme.fontWeights[700],
  },
  '&[aria-hidden="true"]': {
    display: 'none',
  },
  '&:enabled': {
    cursor: 'pointer',
  },
  variants: {
    color: textColor,
    size: {
      small: {
        minHeight: 40,
        gap: theme.space[8],
        paddingBlock: theme.space[4],
        paddingInline: theme.space[8],
        ...typography['button-small'],
      },
      medium: {
        minHeight: 40,
        gap: theme.space[8],
        paddingBlock: theme.space[8],
        paddingInline: theme.space[12],
        ...typography['title-16'],
      },
      large: {
        minHeight: 56,
        gap: theme.space[12],
        paddingBlock: theme.space[8],
        paddingInline: theme.space[16],
        ...typography['title-16'],
      },
    },
    variant: {
      default: {
        '&:disabled, &[aria-disabled="true"]': {
          ':where(& [data-item-start], & [data-item-end], & [data-item-content])': {
            opacity: 0.3,
          },
        },
      },
      switcher: {
        color: theme.colors['black'],
        ...typography['title-14'],
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.colors['grey-5'],
        '&:hover:not([aria-disabled="true"], [aria-pressed="true"]):is(:enabled, :any-link)':
          {
            borderColor: theme.colors['grey-5'],
          },
        '&:active:not([aria-disabled="true"]):is(:enabled, :any-link), &[aria-pressed="true"]':
          {
            borderColor: theme.colors['grey-10'],
          },
      },
    },
    interactive: {
      true: {
        '&:not([aria-disabled="true"])': {
          cursor: 'pointer',
        },
        '&:hover:not([aria-disabled="true"], [aria-pressed="true"])': {
          backgroundColor: theme.colors['grey-5'],
        },
        '&:active:not([aria-disabled="true"]), &[aria-pressed="true"]': {
          backgroundColor: theme.colors['grey-10'],
        },
      },
    },
    indicator: {
      highlight: {
        '&[aria-selected="true"], &[aria-current="true"]': {
          backgroundColor: theme.colors['light-blue-80'],
          '&:hover:not(:disabled, [aria-disabled="true"])': {
            backgroundColor: theme.colors['light-blue'],
          },
          ':where(& [data-item-color-scheme="grey"])': {
            color: theme.colors['black-alpha-40'],
          },
        },
      },
      'text-color': {},
      none: {},
    },
    hug: {
      true: {},
      false: { width: '100%' },
    },
  },
  compoundVariants: [
    {
      size: 'large',
      variant: 'switcher',
      css: {
        minHeight: 64,
      },
    },
  ],
  defaultVariants: {
    variant: 'default',
    size: 'small',
    indicator: 'highlight',
    hug: false,
  },
})

Item.defaultProps = {
  type: 'button',
}

interface ItemStartProps extends TextProps {}

const ItemStart = ({ css, ...props }: ItemStartProps) => (
  <Text data-item-start css={{ flex: '0 0 auto', order: -1, ...css }} {...props} />
)

interface ItemEndProps extends TextProps {
  colorScheme?: 'grey'
}

const ItemEnd = ({ colorScheme, css, ...props }: ItemEndProps) => (
  <Text
    css={{ flex: '0 0 auto', order: 1, marginInlineStart: 'auto', ...css }}
    data-item-color-scheme={colorScheme}
    data-item-end
    {...props}
  />
)

interface ItemContentProps extends TextProps {
  colorScheme?: 'grey'
}

const ItemContent = ({ colorScheme, css, ...props }: ItemContentProps) => (
  <Text
    css={{ order: 0, minWidth: 0, ...css }}
    data-item-color-scheme={colorScheme}
    data-item-content
    {...props}
  />
)

export { Item as Root, ItemStart as Start, ItemEnd as End, ItemContent as Content }
